import Captions from "~/common/components/Captions";
import { ECaptionsVariant } from "~/common/components/Captions/types";

const data = [
  { variant: ECaptionsVariant.Bad, text: "Low exp. Staff above avg" },
  { variant: ECaptionsVariant.Equal, text: "Low exp. Staff equal to avg" },
  { variant: ECaptionsVariant.Excellent, text: "Low exp. Staff under avg" },
];

export const CaptionsForExperiencePerShift = () => <Captions data={data} />;
