import { useCallback, useMemo } from "react";

import { PreferencesTemplate } from "@m7-health/shared-utils";

import { ArrowLeft, ArrowRight } from "@mui/icons-material";
import { Grid, Typography } from "@mui/material";

import { getOrderedShiftTiles } from "#/features/CalendarV2/utils/shifts";
import { ShiftType } from "@/api";
import { ShiftV2, Tag } from "@/common/components";
import { CrossIcon } from "@/common/components/Icons";
import { pxToRem } from "@/common/utils/pxToRem";

import { TSelectedDaysObject } from "./TemplateChanger";

const clearOptionValue = "__clear__" as const;

//Scrolls the shift type selector left or right
const scroll = (direction: "left" | "right") => () => {
  const element = document.querySelector(".shifts-scroll") as HTMLElement;
  if (!element) return;
  const elementWidth = element.offsetWidth;

  const scrollAmount = direction === "left" ? -elementWidth : elementWidth;
  element.scrollBy({ left: scrollAmount, behavior: "smooth" });
};

export const ShiftTypeSelector = ({
  selectedDays,
  shiftsByKey,
  setTemplate,
  setSelectedDays,
}: {
  selectedDays: TSelectedDaysObject;
  shiftsByKey: Record<string, ShiftType.DTO>;
  setTemplate: React.Dispatch<React.SetStateAction<PreferencesTemplate.TTemplatedWeek[]>>;
  setSelectedDays: React.Dispatch<React.SetStateAction<TSelectedDaysObject>>;
}) => {
  const numberOfSelectedDays = Object.values(selectedDays).filter((day) => day.selected).length;

  const orderedShiftTypeKeys = useMemo(
    () => getOrderedShiftTiles(Object.keys(shiftsByKey), shiftsByKey) as ShiftType.DTO["key"][],
    [shiftsByKey],
  );

  //Sets all selected days to the clicked shift type
  const handleShiftTypeClick = useCallback(
    (shiftTypeKey: ShiftType.DTO["key"] | "__clear__") => {
      setTemplate((currentTemplate) => {
        const newTemplate = [...currentTemplate];
        Object.values(selectedDays).forEach((selectedDay) => {
          const week = newTemplate[selectedDay.weekIndex];
          if (week && selectedDay.selected) {
            week[selectedDay.dayIndex] = shiftTypeKey === "__clear__" ? null : shiftTypeKey;
          }
        });
        return newTemplate;
      });
      setSelectedDays({});
    },
    [setTemplate, setSelectedDays, selectedDays],
  );

  return (
    <>
      <Grid container item gap={1}>
        <Grid item>
          <Typography sx={{ fontSize: pxToRem(12) }}>
            Shift type will apply to <b>{numberOfSelectedDays}</b> selected days
          </Typography>
        </Grid>
        <Grid
          item
          container
          sx={{ maxWidth: "100%", flexWrap: "nowrap", flexDirection: "row", alignItems: "center" }}
        >
          <ArrowLeft fontSize="large" sx={{ ml: -1 }} onClick={scroll("left")} />
          <Grid
            item
            container
            sx={{
              flexWrap: "nowrap",
              scrollbarWidth: "none",
              "::-webkit-scrollbar": { display: "none" },
            }}
            className="shifts-scroll"
            overflow="scroll"
            gap={1}
          >
            <Tag
              backgroundColor="#C0C0C0"
              className="clear"
              icon={<CrossIcon />}
              isLoading={false}
              onClick={() => handleShiftTypeClick(clearOptionValue)}
              text={"Clear"}
              sx={{ overflow: "unset" }}
            />
            {orderedShiftTypeKeys.map((option) => {
              const shiftType = shiftsByKey[option];
              return (
                shiftType && (
                  <ShiftV2
                    disabled={false}
                    isLoading={false}
                    shiftType={shiftType}
                    onClick={() => handleShiftTypeClick(shiftType.key)}
                    sx={{ overflow: "unset" }}
                  />
                )
              );
            })}
          </Grid>
          <ArrowRight fontSize="large" sx={{ mr: -1 }} onClick={scroll("right")} />
        </Grid>
      </Grid>
    </>
  );
};
