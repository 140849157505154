import { YyyyMmDd } from "@m7-health/shared-utils";
import { PayloadAction } from "@reduxjs/toolkit";

import { TStoreSlice } from "~/store";

import { IUser } from "@/api";

import { THolidayScheduleState } from ".";

export type TSideBarState = {
  isCollapsed: boolean;
  selectedStaffId: IUser["id"] | null;
  selectedDayKey: YyyyMmDd | null;
};

export const SideBarInitState: TSideBarState = {
  isCollapsed: true,
  selectedStaffId: null,
  selectedDayKey: null,
};

export const SideBarActions = {
  updateSideBarData: (state, { payload }: PayloadAction<Partial<TSideBarState>>) => {
    state.sideBar = {
      ...state.sideBar,
      ...payload,
    };
  },
} satisfies TStoreSlice<THolidayScheduleState>;
