import styled from "styled-components";

import { Container, TableContainer } from "@mui/material";

import { lines } from "~/common/theming/colors";

type TCustomProps = {
  $withPadding: boolean;
  $stretchVertically: boolean;
};

export const ScrollableTableContainerStyled = styled(TableContainer)`
  width: 100%;
  height: 75vh;
`;

export const TableContainerWithSticky = styled(Container)<TCustomProps>`
  ${({ $stretchVertically }: TCustomProps) =>
    $stretchVertically &&
    `
      height: 100%;
      max-height: 100%;
      `}

  ${({ $withPadding }: TCustomProps) =>
    $withPadding
      ? `
      min-width: 50vw;
      width: 100%;
      box-sizing: border-box;
      max-width: 100vw;`
      : `
      min-width: 50vw;
      width: 100%;
      box-sizing: border-box;
      max-width: 100vw;
      padding: 0;
      `}

  .sticky {
    position: sticky;
    left: 0;
    background: white;
    z-index: 10;
  }

  td {
    border-bottom: 1px solid ${lines} !important;
    border-top: 1px solid ${lines} !important;
  }

  tr {
    height: 47px;
  }

  tr td {
    height: auto !important;
  }
`;
