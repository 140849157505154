import { PreferencesTemplate as SharedPreferencesTemplate } from "@m7-health/shared-utils";

import { APIElementResponse, APIListResponse } from "../_shared/types";
import { PreferenceRequest } from "../preference-request";
import { ISchedule } from "../schedule";
import { IUnit } from "../unit";
import { IUser } from "../user";

export type IPreferencesTemplate = PreferencesTemplate.DTO;

export namespace PreferencesTemplate {
  export interface DTO extends SharedPreferencesTemplate.DTO {}

  export namespace API {
    export const ROOT_PATH = "/preferences-template";
    export namespace List {
      export const PATH = ROOT_PATH;
      export interface QueryParams {
        unitIds?: IUnit["id"][];
      }

      export type Response = APIListResponse<DTO>;
    }

    export namespace Create {
      export const PATH = ROOT_PATH;
      export type QueryParams = Omit<
        DTO,
        "id" | "createdAt" | "updatedAt" | "deletedAt" | "userIds"
      >;
      export type Response = APIElementResponse<DTO>;
    }

    export namespace Update {
      export const PATH = (params: QueryParams) => `${ROOT_PATH}/${params.id}`;

      export type QueryParams = Partial<DTO> & { id: DTO["id"] };
      export type Response = APIElementResponse<DTO>;
    }

    export namespace Delete {
      export const PATH = (params: QueryParams) => `${ROOT_PATH}/${params.id}`;

      export type QueryParams = { id: DTO["id"] };
      export type Response = APIElementResponse<{ id: DTO["id"] }>;
    }

    export namespace Apply {
      export const PATH = `${ROOT_PATH}/apply`;

      export type QueryParams = { scheduleId: ISchedule["id"]; staffIds?: IUser["id"][] };
      export type Response = APIListResponse<PreferenceRequest.DTO>;
    }
  }
}
