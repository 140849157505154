import { ISODateString, Year } from "@m7-health/shared-utils";

import { APIListResponse, SDTO } from "~/api/_shared/types";

import { IStaffShift } from "../staffShift";
import { IUnit } from "../unit";

export type IHolidaySchedule = HolidaySchedule.DTO;
export type IHolidayDayDTO = HolidaySchedule.HolidayDayDTO;

export namespace HolidaySchedule {
  export enum EStatus {
    collecting = "sent_out",
    balancing = "in_review",
    published = "published",
    completed = "completed",
    current = "current",
    future = "future",
  }

  export interface HolidayDayDTO extends SDTO.base, SDTO.withTimestamps, SDTO.softDeletable {
    holidayDate: ISODateString;
    label: string;
    // Only present for staff when schedule is published. Empty otherwise.
    shifts: IStaffShift[];
  }

  export interface DTO extends SDTO.base, SDTO.withTimestamps, SDTO.softDeletable {
    status: EStatus;
    unitId: IUnit["id"];
    year: Year;
    label: string;
    staffsInputDeadline: ISODateString;
    targetPublishDeadline: ISODateString;

    holidayDays: HolidayDayDTO[];
  }

  export namespace API {
    export const ROOT_PATH = "/holiday-schedule";
    export namespace List {
      export const PATH = ROOT_PATH;
      export type QueryParams =
        | {
            unitIds?: IUnit["id"][];
          }
        | undefined;
      export type Response = APIListResponse<DTO>;
    }

    export namespace Update {
      export const PATH = ({ id }: QueryParams) => `${ROOT_PATH}/${id}`;

      export type QueryParams = {
        id: IHolidaySchedule["id"];
        status: EStatus;
      };

      export type Response = DTO;
    }
  }
}
