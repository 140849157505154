import { ISODateString, Timezone, Unit, YyyyMmDd } from "@m7-health/shared-utils";
import { PayloadAction } from "@reduxjs/toolkit";

import { Dayjs } from "~/common/packages/dayjs";
import { TStoreSlice } from "~/store";

import {
  IAttribute,
  INote,
  IPreferenceRequest,
  ISchedule,
  IScheduleShiftType,
  IShiftType,
  IStaffCategory,
  IStaffDetails,
  IStaffShift,
  IStaffType,
  ITargetLevel,
  ITimeOffRequest,
  IUnitTargetLevel,
  IUser,
  Note,
} from "@/api";
import { KeyBy } from "@/common/types";

import { TTargetLevelKey, TTimeTargetLevelKey } from "../components/HolidaySchedulerGrid/hooks";

import { THolidayScheduleState } from ".";

export type TGridState = {
  data: TData;
  selectedStaffCategory: IStaffCategory["key"] | null;
  // Whether we clicked a second time on the selected cell;
  showShiftTypeDropdownInCell: boolean;
};

type TWeightedShift = IStaffShift & {
  note: Note.EUpdate | null;
  weight: number;
  staffDetails: IStaffDetails;
  dayjsDate: Dayjs;
  fractionOfOverlap?: number | null;
  outsideSchedule?: boolean;
};

export type TStoreDate = {
  isoDate: ISODateString;
  dayjsDate: Dayjs;
  date: Date;
  formattedDate: YyyyMmDd;
  schedule: ISchedule;
  scheduleId: ISchedule["id"];
  isActive: boolean;
};

type TData = {
  // Dates of the schedule, with the schedule object attached to it
  dates: {
    allDates: TStoreDate[];
    indexedDates: Record<ISODateString | YyyyMmDd, TStoreDate>;
    from: TStoreDate | null;
    to: TStoreDate | null;
    timezone: Timezone | null;
    scheduleByDate: { [date in ISODateString | YyyyMmDd]: ISchedule };
  };

  // organized by keys that are computed by the getTimeTargetLevelKey function that renders a uniq and deterministic key
  //  based on the target level criteria such as category, attribute, shift type etc ....
  timeTargetLevels: {
    // Ordered keys old the order of target's rows in the grid, both "order headers", and per day headers
    orderedKeys: TTimeTargetLevelKey[];
    targets: {
      [key in TTimeTargetLevelKey]: Unit.Config.ITimeTargetLevel & {
        staffCategoryKey: IStaffCategory["key"];
      };
    };
  };

  // organized by keys that are computed by the getTimeTargetLevelKey function that renders a uniq and deterministic key
  //  based on the target level criteria such as category, attribute, shift type etc ....
  targetLevels: {
    // Ordered keys old the order of target's rows in the grid, both "corder headers", and per day headers
    orderedKeys: TTargetLevelKey[];
    orderedTargets: {
      [key in TTargetLevelKey]: {
        shiftTypeKey: IShiftType["key"];
        staffCategoryKey?: IStaffCategory["key"];
        staffTypeKey?: IStaffType["key"];
        attributeKey?: IAttribute["key"];
      };
    };
    byDate: {
      [key in YyyyMmDd]: {
        [targetLevelIdentifier in TTargetLevelKey]: ITargetLevel | IUnitTargetLevel;
      };
    };
  };

  // Staff notes, per user per day
  notes: { [staffId in IUser["id"]]: KeyBy<INote, "date"> };

  // Shift type of all involved schedules (current, plus the schedules where people are floated too)
  shiftTypes: {
    [scheduleId in ISchedule["id"]]: KeyBy<IScheduleShiftType, "key">;
  };

  // Staff details of all staff involved in the schedule, in current days, past and future 3 days,
  //  including staff floated to that unit in this range
  staffDetails: KeyBy<IStaffDetails, "userId">;

  // Preference request of the staff involved in the schedule, in current days, past and future 3 days,
  //   by staff id and date
  preferenceRequests: {
    [staffId in IUser["id"]]: {
      [date in YyyyMmDd]: IPreferenceRequest;
    };
  };

  timeOffRequests: {
    [staffId in IUser["id"]]: {
      [date in YyyyMmDd]: ITimeOffRequest;
    };
  };

  // staff shifts enhanced with some data (such as "Weight" for partial shift for example)
  //  and organized in different ways to be able to calculate some stats on the fly
  calculatedData: {
    perDay: {
      [date in YyyyMmDd]: {
        [key in TTargetLevelKey]: TWeightedShift[];
      };
    };
    perDayTime: {
      [date in YyyyMmDd]: {
        [key in TTimeTargetLevelKey]: TWeightedShift[];
      };
    };
    perStaff: {
      [staffId in IUser["id"]]: {
        shifts: TWeightedShift[];
        shiftsByDay: { [day in YyyyMmDd]: TWeightedShift[] };
        shiftTypes: { [ShiftTypeKey in IShiftType["key"]]: boolean };
        totalShifts: number;
        weekendShifts: number;
        unfairShifts: number;
        totalPreferences: number;
        totalNonEmptyDays: number;
      };
    };
  };
};

export const GridInitState: TGridState = {
  data: {
    dates: {
      allDates: [],
      indexedDates: {},
      from: null,
      to: null,
      timezone: null,
      scheduleByDate: {},
    },
    notes: {},
    staffDetails: {},
    shiftTypes: {},
    preferenceRequests: {},
    timeTargetLevels: {
      orderedKeys: [],
      targets: {},
    },
    targetLevels: {
      orderedKeys: [],
      orderedTargets: {},
      byDate: {},
    },
    calculatedData: {
      perDay: {},
      perDayTime: {},
      perStaff: {},
    },
    timeOffRequests: {},
  },
  selectedStaffCategory: null,
  showShiftTypeDropdownInCell: false,
};

export const GridActions = {
  updateGridData: ({ grid }, { payload }: PayloadAction<Partial<TGridState["data"]>>) => {
    grid.data = {
      ...grid.data,
      ...payload,
    };
  },

  setSelectedStaffCategory: ({ grid }, { payload }: PayloadAction<IStaffCategory["key"]>) => {
    grid.selectedStaffCategory = payload;
  },

  setShowShiftTypeDropdownInCell: ({ grid }, { payload }: PayloadAction<boolean>) => {
    grid.showShiftTypeDropdownInCell = payload;
  },
} satisfies TStoreSlice<THolidayScheduleState>;
