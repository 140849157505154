import { useCallback, useEffect, useState } from "react";

import { Uuid } from "@m7-health/shared-utils";

import { Box, Stack, Typography } from "@mui/material";

import CustomModal from "~/common/components/Modal";
import { useAppDispatch, useAppSelector } from "~/common/hooks/useRedux";
import { useToast } from "~/common/hooks/useToast";

import { IUser, useListStaffDetailsQuery, useUpdatePreferencesTemplateMutation } from "@/api";
import { AutocompleteStaffSelector, PreferencesTemplateLabel } from "@/common/components";
import { NOT_EXISTING_UUID } from "@/common/constants";
import { useCurrentUnitId, useErrors } from "@/common/hooks";

import { closeModal, openModal } from "../../store";

const activeArray = [true];

export const UpdatePreferencesTemplateUsersModal = () => {
  const dispatch = useAppDispatch();
  const unitId = useCurrentUnitId();
  const { showSuccess } = useToast();
  const { handleErrors } = useErrors();
  const preferencesTemplate = useAppSelector(
    (state) => state.roster.updatePreferencesTemplateUsersModal.preferencesTemplateToUpdate,
  );

  // Data
  const { data: homeUnitStaffDetails } = useListStaffDetailsQuery(
    { homeUnitIds: [unitId || NOT_EXISTING_UUID], active: activeArray },
    { skip: !unitId },
  );

  // State
  const [dataSelectedUserIds, setDataSelectedUserIds] = useState<IUser["id"][]>([]);
  const [stateUserIds, setStateUserIds] = useState<IUser["id"][]>(dataSelectedUserIds);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    // When incoming preferences Template changes, (re)set the state userIds
    if (preferencesTemplate?.id) {
      setDataSelectedUserIds(preferencesTemplate?.userIds);
      setStateUserIds(preferencesTemplate?.userIds);
    }
  }, [preferencesTemplate?.id, preferencesTemplate?.userIds]);

  // Modal content
  const modalContent = !preferencesTemplate ? (
    <></>
  ) : (
    <Box mt={3}>
      <Stack direction="row" alignItems="center" mb={2}>
        <PreferencesTemplateLabel preferencesTemplate={preferencesTemplate} />
        <Typography children={` ${preferencesTemplate.name}`} width={"200px"} noWrap />
      </Stack>
      <Typography children={preferencesTemplate.description} sx={{ mb: 3 }} fontStyle="italic" />
      <Box maxWidth={"500px"}>
        <AutocompleteStaffSelector
          limitTags={15}
          trackingLabel={null}
          label="Select Staff to Assign Preference Template"
          staffOptions={homeUnitStaffDetails || []}
          selectedStaffIds={stateUserIds}
          setSelectedStaffIds={setStateUserIds}
          sx={{ mb: 3 }}
        />
      </Box>
    </Box>
  );

  // Actions
  const { mutateAsync: updatePreferencesTemplate } = useUpdatePreferencesTemplateMutation({});
  const onSave = useCallback(() => {
    if (!preferencesTemplate) return;

    setIsLoading(true);
    updatePreferencesTemplate({
      id: preferencesTemplate.id,
      userIds: stateUserIds as Uuid[],
    })
      .then(() => {
        showSuccess("Preference Template updated successfully.");
        dispatch(closeModal("updatePreferencesTemplateUsersModal"));
        dispatch(openModal("updatePreferencesTemplatesModal"));
      })
      .catch(handleErrors)
      .finally(() => setIsLoading(false));
  }, [
    dispatch,
    handleErrors,
    preferencesTemplate,
    showSuccess,
    stateUserIds,
    updatePreferencesTemplate,
    setIsLoading,
  ]);
  const onCancel = useCallback(() => {
    if (window.confirm("Are you sure you want to discard changes?")) {
      dispatch(closeModal("updatePreferencesTemplateUsersModal"));
      dispatch(openModal("updatePreferencesTemplatesModal"));
    }
  }, [dispatch]);

  if (!preferencesTemplate) return null;

  return (
    <CustomModal
      isOpen={true}
      closeDisabled
      isLoading={isLoading}
      primaryBtnText="Save"
      modalContent={modalContent}
      modalHeaderText={"Assign Preference Template to Staff"}
      onSubmit={onSave}
      onSecondaryBtnClick={onCancel}
    />
  );
};
