import { YyyyMmDd } from "@m7-health/shared-utils";
import { createSlice } from "@reduxjs/toolkit";

import { INote, ISchedule, IStaffShift } from "~/api";

import {
  EDailyReportViewType,
  IDailyReportState,
  TDailyReportShiftTypeFilter,
  TStaffNotesByStaffId,
} from "../types";

import { initialState } from "./initialState";

import type { PayloadAction } from "@reduxjs/toolkit";

export type updatedNote = { userId: string } & Partial<INote>;
export const dailyReportSlice = createSlice({
  name: "dailyReport",
  initialState,
  reducers: {
    setShiftTypeFilter: (state, action: PayloadAction<TDailyReportShiftTypeFilter>) => {
      state.filters.shiftType = action.payload;
    },
    setStaffNotes: (state, action: PayloadAction<TStaffNotesByStaffId | undefined>) => {
      state.staffNotes = action.payload;
    },
    updateStaffNote: (state, action: PayloadAction<updatedNote>) => {
      const { userId, ...rest } = action.payload;

      state.staffNotes = {
        ...state.staffNotes,
        [userId]: {
          ...state.staffNotes?.[userId],
          ...rest,
        },
      };
      state.isEdited = true;
    },
    setScheduleId: (state, action: PayloadAction<ISchedule["id"]>) => {
      state.scheduleId = action.payload;
    },
    updateShift(
      state,
      action: PayloadAction<IDailyReportState["updatesToSave"][IStaffShift["id"]]>,
    ) {
      state.isEdited = true;
      state.updatesToSave = {
        ...state.updatesToSave,
        [action.payload.id]: action.payload,
      };
    },
    resetShiftToSaveState: (state) => {
      state.updatesToSave = {};
      state.isEdited = false;
    },
    setDate: (state, action: PayloadAction<YyyyMmDd>) => {
      state.date = action.payload;
    },
    setViewType: (state, action: PayloadAction<EDailyReportViewType>) => {
      state.filters.viewType = action.payload;
    },
    setIsAddLabelModelOpen: (state, action: PayloadAction<boolean>) => {
      state.isAddLabelModelOpen = action.payload;
    },
    setIsUnsavedChangesModalOpen: (
      state,
      action: PayloadAction<{
        isOpen: boolean;
        handleDiscardChanges?: Function;
      }>,
    ) => {
      state.unsavedChangesModal.isOpen = action.payload.isOpen;
      state.unsavedChangesModal.handleDiscardChanges = action.payload.handleDiscardChanges;
    },
    setIsDailyReportEdited: (state, action: PayloadAction<boolean>) => {
      state.isEdited = action.payload;
    },
  },
});

export const {
  setScheduleId,
  updateShift,
  setShiftTypeFilter,
  setDate,
  setViewType,
  resetShiftToSaveState,
  setStaffNotes,
  updateStaffNote,
  setIsAddLabelModelOpen,
  setIsUnsavedChangesModalOpen,
  setIsDailyReportEdited,
} = dailyReportSlice.actions;
export default dailyReportSlice.reducer;
