import React, { FC } from "react";

import { Chip, ChipProps } from "@mui/material";

import { IPreferenceRequirementRuleSet } from "@/api";
import { emptySx } from "@/common/types";

export const RuleSetLabel: FC<
  ChipProps & {
    ruleSet?: IPreferenceRequirementRuleSet;
  }
> = React.forwardRef(({ ruleSet, sx, ...otherProps }, ref) => {
  if (!ruleSet) return null;

  return (
    <Chip
      ref={ref}
      label={ruleSet.label}
      size={"small"}
      sx={{
        width: "90px",
        mr: 1,
        backgroundColor: ruleSet.color,
        borderRadius: "5px",
        ...(sx || emptySx),
      }}
      {...otherProps}
    />
  );
});
