import { buildApiListQueryHook } from "../_shared/helpers";

import { Submission } from "./types";

export const useGetCurrentSubmission = buildApiListQueryHook<
  Submission.API.GetCurrent.QueryParams,
  Submission.API.GetCurrent.Response
>(Submission.API.GetCurrent.PATH);

export const useListSubmissions = buildApiListQueryHook<
  Submission.API.List.QueryParams,
  Submission.API.List.Response
>(Submission.API.List.PATH);
