import styled from "styled-components";

import { darken, TableContainer } from "@mui/material";

import { black, lines, seaShell } from "~/common/theming/colors";
import { css } from "~/common/utils/cl";

import { CELL_DIMENSIONS } from "./constants";

const style = css`
  /** TABLE ----*/
  table th {
    font-weight: 100;
    padding: 0;
  }

  /** BORDERS ----*/
  table th {
    /* For headers apply both top and bottom borders to the <th> */
    border-top: 0.5px solid ${lines};
    border-bottom: 0.5px solid ${lines};
  }

  table td {
    /* For cells, apply the border to one of each side only (right but not left, bottom but not top) */
    border-bottom: 0.5px solid ${lines};
    border-right: 0.5px solid ${lines};
  }

  table .sticky-corner {
    border-bottom: 1px solid ${lines};
  }

  table td:first-child {
    /* Apply a left border on the first <td> or <th> in a row */
    border-left: 0.5px solid;
  }

  // Selected cells
  table td.selected > div {
    border-radius: 2px;
    -webkit-box-shadow: inset 0px 0px 0px 2px ${black};
    -moz-box-shadow: inset 0px 0px 0px 2px ${black};
    box-shadow: inset 0px 0px 0px 2px ${black};
  }

  .date-selected,
  .staff-selected {
    background: ${seaShell};
  }

  // Week delimiters
  table td.sunday {
    border-right: 4px solid ${darken(lines, 0.05)};
  }

  // Border effect on sticky column
  table th.sticky-col::before {
    content: ""; /* Necessary for the pseudo-element to be displayed */
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: ${lines}; /* Color of your "border" */
    z-index: 2; /* Higher z-index to make it appear on top */
  }

  // Border effect on sticky row
  table th.sticky-row::after {
    content: ""; /* Necessary for the pseudo-element to be displayed */
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 1px; /* Width of your "border" */
    background-color: ${lines}; /* Color of your "border" */
    z-index: 2; /* Higher z-index to make it appear on top */
  }
  /** ----BORDERS */

  /** STICKY CELLS ----*/
  table th.sticky-col,
  table th.sticky-row {
    padding: 0;
    border-top: 0;
    position: sticky;
    background-color: white;
    z-index: 4;
    opacity: 1;
    backdrop-filter: none;
    -webkit-backdrop-filter: none;
    transparency: 1;
  }

  table th.sticky-col {
    left: 0;
  }

  table th.sticky-row {
    top: 0;
  }

  table th.sticky-corner {
    z-index: 5;
    top: 0;
    left: 0;
    border-left: 0;
  }

  th.sticky-row > div > div {
    width: ${CELL_DIMENSIONS.width};
    min-width: ${CELL_DIMENSIONS.width};
    max-width: ${CELL_DIMENSIONS.width};
  }

  th.sticky-col {
    height: ${CELL_DIMENSIONS.height};
  }

  .shift-cell {
    position: relative;
  }

  .shift-tile-cell-wrapper {
    max-width: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .working-shift .MuiSvgIcon-root {
    font-size: 14px !important;
  }

  .header-cell-name-attribute {
    text-wrap: nowrap;
  }

  .shift-tile {
    padding: 0px 10px;
    height: 22px;
    .MuiSvgIcon-root,
    .MuiButton-startIcon {
      margin: 0;
      font-size: 0.6rem;
    }
    .MuiTypography-root {
      font-size: 0.7rem;
    }
  }

  .editing-from {
    opacity: 0;
  }

  // Hide scrollbars for all elements except the first one
  // See useSynchronizedHorizontalScroll.ts for more details
  .multi-weeks-sync-scroll:not(:first-child)::-webkit-scrollbar {
    display: none;
  }

  .multi-weeks-sync-scroll:not(:first-child) {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
`;

export const TableStyleContainer = styled(TableContainer)`
  ${style}
`;
