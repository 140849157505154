import { ISODateString } from "@m7-health/shared-utils";

import { StaffShift } from "../staffShift";
import { IUser } from "../user";

export namespace StaffMetadata {
  type TSubmissionWithAverage = {
    id: string;
    createdAt: ISODateString;
    statusSetAt: ISODateString;
    content: { ["satisfaction-question"]?: number; ["open-question"]?: string };
    averageSatisfactionScore: number | null;
  };

  export type TShiftStatusCount = {
    weekCounts: { [key in StaffShift.EStatus]: number };
    monthCounts: { [key in StaffShift.EStatus]: number };
    yearCounts: { [key in StaffShift.EStatus]: number };
  };

  export type TStaffAndUnitShiftStatusCount = {
    staff: TShiftStatusCount;
    unit: TShiftStatusCount;
  };

  export interface DTO {
    shiftStatusCounts: TStaffAndUnitShiftStatusCount;
    pulseCheckSubmissions: TSubmissionWithAverage[];
  }

  export namespace API {
    const PATH_ROOT = "/staff-details/metadata";
    export namespace Get {
      export const PATH = PATH_ROOT;
      export interface QueryParams {
        staffId: IUser["id"];
      }

      export type Response = { data: DTO };
    }
  }
}
