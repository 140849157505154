import { useMemo } from "react";

import { filter, flatten, groupBy, map, mapValues, sum, values } from "lodash";

import { TBasicStaffDetails, TScheduleDetails } from "~/features/Fairness/PreferencesHonored/types";

import { IStaffDetails, IUnit, useListStaffDetailsQuery } from "@/api";
import { NOT_EXISTING_UUID } from "@/common/constants";
import { useCurrentUnitId, useKeyBy } from "@/common/hooks";
import { KeyBy } from "@/common/types";

export const calculateStaffsAverageScore = (
  schedules: TScheduleDetails[],
  indexedStaffDetails: KeyBy<IStaffDetails, "userId">,
  homeUnitId?: IUnit["id"],
) => {
  const staffsGroupedById = groupBy(
    filter(
      flatten(map(schedules, "staffs")),
      (staff) => indexedStaffDetails[staff.id]?.homeUnitId === homeUnitId,
    ),
    "id",
  );

  const groupedStaffsWithAverageScore = mapValues(staffsGroupedById, (staffs) => {
    const averageScore = Number((sum(staffs.map(({ score }) => score)) / staffs.length).toFixed());

    // We mapped by id just above so we know for sure we have a staff for this id
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const staff: TBasicStaffDetails = staffs[0]!;
    return { ...staff, averageScore };
  });

  return values(groupedStaffsWithAverageScore);
};

export const useCalculateStaffsAverageScore = (
  schedules: TScheduleDetails[],
): TBasicStaffDetails[] => {
  const currentUnitId = useCurrentUnitId();

  // Gather staff detail for this unit
  const { data: staffDetails } = useListStaffDetailsQuery(
    { homeUnitIds: [currentUnitId || NOT_EXISTING_UUID] },
    { skip: !currentUnitId },
  );
  const indexedStaffDetails = useKeyBy(staffDetails, "userId");

  return useMemo(() => {
    return calculateStaffsAverageScore(schedules, indexedStaffDetails, currentUnitId);
  }, [currentUnitId, indexedStaffDetails, schedules]);
};
