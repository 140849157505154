import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { initialState } from "~/features/Fairness/store/initialState";
import { EFairnessTypes, ESchedulesCount } from "~/features/Fairness/types";

export const fairnessSlice = createSlice({
  name: "fairness",
  initialState,
  reducers: {
    selectFairnessType: (state, action: PayloadAction<EFairnessTypes>) => {
      state.fairnessType = action.payload;
    },
    selectSchedulesCount: (state, action: PayloadAction<ESchedulesCount>) => {
      state.schedulesCount = action.payload;
    },
  },
});

export const { selectFairnessType, selectSchedulesCount } = fairnessSlice.actions;
export default fairnessSlice.reducer;
