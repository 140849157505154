import { Fragment, useEffect, useMemo, useState } from "react";

import { EUnitPermissionAreas } from "@m7-health/shared-utils";
import { isEqual } from "lodash";

import { Article, EditNoteOutlined } from "@mui/icons-material";
import { Box, Collapse, Skeleton, Stack, Tooltip, Typography } from "@mui/material";

import { useAppConfigQuery } from "#/features/User/queries";
import {
  INote,
  IShiftType,
  IStaffDetails,
  IStaffShift,
  IUnit,
  IUser,
  IWorkingHours,
  StaffDetails,
  StaffShift,
} from "@/api";
import {
  AttributeTag,
  Checkbox,
  NoteUpdateTag,
  Radio,
  ShiftIncentiveLevelIcon,
} from "@/common/components";
import { CompactFloatedTag } from "@/common/components/CompactFloatedTag/CompactFloatedTag";
import { ExpandMore } from "@/common/components/ExpandMore/ExpandMore";
import { EmploymentTypeTag } from "@/common/components/PerDiemTag/PerDiemTag";
import { ShiftV2 } from "@/common/components/Shift/ShiftV2";
import WorkingHoursTag from "@/common/components/WorkingHoursTag";
import {
  useAppFlags,
  useAppSelector,
  useCheckUserPermission,
  useIsKiosk,
  useKeyBy,
} from "@/common/hooks";
import { localDayJs } from "@/common/packages/dayjs";
import { black, darkGray, mediumGray, white } from "@/common/theming/colors";
import { emptySx } from "@/common/types";
import { timeAdd, TimeStringToStandardTime } from "@/common/utils/dates";
import { formatPhoneNumber } from "@/common/utils/phoneNumber";
import { useStatusLabel, voidingShiftStatus } from "@/common/utils/shifts";

import StaffCalendar from "../../../../common/components/StaffCalendar/StaffCalendar";
import { HouseViewSideBar } from "../SideBar";
import { miscVisualHelpers } from "../SideBar/helpers/MiscVisuals";
import { shiftLastStatusUpdatedAt } from "../SideBar/helpers/shiftLastNoteUpdatedAt";

import "./StaffItem.scss";
import { StaffItemProps } from "./types";

const stopPropagation = (event: React.MouseEvent) => event.stopPropagation();

const renderShift = (shift: IStaffShift | undefined, shiftType: IShiftType | undefined) =>
  shiftType && shift ? (
    <Tooltip
      title={
        <ShiftV2 shiftType={shiftType} staffShift={shift} variant="medium" hoursAsLabel={false} />
      }
      enterTouchDelay={0}
      componentsProps={{
        tooltip: { sx: { p: 0, background: white, border: "1px solid " + black } },
      }}
      arrow
      placement="left"
    >
      <span className="shift-type">
        <ShiftV2
          miniView
          iconOnly
          shiftType={shiftType}
          staffShift={shift}
          variant="small"
          sx={{ width: "22px", p: "0 !important" }}
          showPartialShiftBrackets={false}
        />
      </span>
    </Tooltip>
  ) : (
    <></>
  );

const renderStaffName = (staffDetails: IStaffDetails | undefined) =>
  staffDetails ? (
    <span className="first-last-name">
      {staffDetails.user.firstName} {staffDetails.user.lastName}
    </span>
  ) : (
    <></>
  );

const renderStaffType = (staffDetails: IStaffDetails | undefined) =>
  staffDetails ? <span className="staff-type">{staffDetails.staffType.name}</span> : <></>;

const renderEmploymentType = (staffDetails: IStaffDetails | undefined) =>
  staffDetails ? <EmploymentTypeTag employmentType={staffDetails.employmentType} /> : <></>;

const renderShiftTime = (
  shift: IStaffShift | undefined,
  shiftType: IShiftType | undefined | null,
) =>
  !shift || !shiftType ? (
    <></>
  ) : (
    <Typography className="shift-time" whiteSpace={"nowrap"}>
      {TimeStringToStandardTime(shift.customStartTime || shiftType.startTime, "medium")}-
      {TimeStringToStandardTime(
        timeAdd(
          shift.customStartTime || shiftType.startTime,
          shift.customDuration || shiftType.durationSeconds,
        ),
        "medium",
      )}
    </Typography>
  );

const Positions = ({
  unitId,
  staffShift,
}: {
  unitId: IUnit["id"];
  staffShift: IStaffShift | undefined;
}) => {
  const unitsById = useKeyBy(useAppConfigQuery().data?.accessibleUnits || [], "id");
  const unit = useMemo(() => unitsById[unitId], [unitId, unitsById]);
  const unitAttributesByKey = useKeyBy(unit?.attributes || [], "key");

  return staffShift ? (
    <>
      {staffShift.attributes.map((attributeKey) => {
        const attribute = unitAttributesByKey[attributeKey];
        return attribute?.usedForShiftOverview ? <AttributeTag attribute={attribute} /> : <></>;
      })}
    </>
  ) : (
    <></>
  );
};

const renderShiftStatus = (shift: IStaffShift | undefined, unitId: IUnit["id"] | undefined) => {
  const isFloated = shift?.status === StaffShift.EStatus.floated;
  const status = shift?.status;

  const results = [];
  if (unitId && (isFloated || shift?.isWorkingAway))
    results.push(
      <CompactFloatedTag
        sx={{ position: "relative", top: "4px" }}
        unitId={unitId}
        isFloatedStatus={isFloated}
        grayIfNotFloatedStatus={true}
      />,
    );
  if (status && !isFloated)
    results.push(<NoteUpdateTag update={status} variant="compact" unitId={unitId} />);

  return <Fragment>{results}</Fragment>;
};

const renderPhoneNumber = (staffDetails: IStaffDetails | undefined, readonly: boolean) => {
  const phoneNumber = formatPhoneNumber(staffDetails?.user.phoneNumber);
  if (!phoneNumber) return <></>;

  if (readonly)
    return (
      <Typography className="phone-number" sx={{ whiteSpace: "nowrap" }}>
        {miscVisualHelpers.getPhoneNumber(phoneNumber, true)}
      </Typography>
    );

  return (
    <a href={`tel:${phoneNumber || ""}`} className="phone-number" onClick={stopPropagation}>
      {miscVisualHelpers.getPhoneNumber(phoneNumber)}
    </a>
  );
};

const renderWorkingHours = (workingHours: IWorkingHours | undefined, className?: string) => {
  const workingHoursNumber = !workingHours
    ? 0
    : parseInt((workingHours.cumulatedSeconds / 3600).toFixed(0));

  return (
    <WorkingHoursTag
      className={className}
      workingHours={workingHoursNumber}
      sx={{ top: "-5px", right: "10px" }}
      popOverOptions={{ offset: [0, -14] }}
    />
  );
};

const LastStatusChange = ({
  lastStatusChange,
  unitId,
}: {
  lastStatusChange?: {
    status: IStaffShift["status"];
    data?: StaffDetails.ShiftMetadataDTO;
  };
  unitId?: IUnit["id"];
}) => {
  const getStatusLabel = useStatusLabel(unitId);

  if (!lastStatusChange) return <></>;

  const date = shiftLastStatusUpdatedAt(lastStatusChange.data, lastStatusChange.status);
  const lastSomethingLabel = getStatusLabel(lastStatusChange.status);

  return (
    <Box display={"flex"} alignItems={"center"} flexDirection={"row"}>
      <Typography fontSize={"0.8rem"}>
        <i style={{ color: darkGray }}>Last {lastSomethingLabel}: </i>{" "}
        {date ? (
          <b>{localDayJs(date).format("MM-DD-YYYY")}</b>
        ) : (
          <i style={{ color: darkGray }}>N/A</i>
        )}
      </Typography>
    </Box>
  );
};

const renderFloatedUnitTag = ({
  currentUnit,
  unitOfItem,
}: {
  currentUnit: IUnit["id"];
  unitOfItem: IUnit["id"] | undefined;
}) => {
  if (!unitOfItem || unitOfItem === currentUnit) return <></>;

  return <CompactFloatedTag unitId={unitOfItem} />;
};

const UpdatableNote = ({
  note,
  staffDetails,
  readonly,
}: {
  note: INote | undefined;
  staffDetails: IStaffDetails | undefined;
  readonly: boolean;
}) => {
  const [noteModalOpened, setNoteModalOpened] = useState(false);

  const Icon = useMemo(() => (note?.content ? Article : EditNoteOutlined), [note?.content]);
  if (readonly && !note?.content)
    return (
      <Icon
        className="note"
        sx={{
          opacity: 0,
        }}
      />
    );

  return (
    // Make sure note click (icon or modal) doesn't trigger staff item click
    <span onClick={stopPropagation}>
      <Tooltip
        placement="top"
        title={
          note?.content || (
            <Stack direction="row" spacing={1} alignItems={"center"}>
              <i>Add note</i>
            </Stack>
          )
        }
        enterTouchDelay={0}
      >
        <Icon
          className="note"
          sx={{
            color: note?.content ? darkGray : mediumGray,
            ...(readonly ? {} : { "&:hover": { color: black } }),
          }}
          onClick={readonly ? undefined : () => setNoteModalOpened(true)}
        />
      </Tooltip>
      {noteModalOpened && !readonly && (
        <HouseViewSideBar.Helpers.UpdateNoteModal
          onClose={() => setNoteModalOpened(false)}
          note={note}
          staffDetails={staffDetails?.user}
        />
      )}
    </span>
  );
};

const MiniCalendarButton = ({ expanded, onClick }: { expanded: boolean; onClick?: () => void }) => {
  return (
    <>
      <Box onClick={stopPropagation} className="toggle-mini-calendar">
        <ExpandMore
          sx={{ cursor: "pointer" }}
          expand={expanded}
          onClick={onClick}
          aria-expanded={expanded}
          aria-label="show more"
        />
      </Box>
    </>
  );
};

const MiniCalendar = ({ expanded, staffId }: { staffId?: IUser["id"]; expanded: boolean }) => {
  const selectedDateForData = useAppSelector(
    (state) => state.houseView.pageFilters.selectedDateForData,
  );
  const [calendar, setCalendar] = useState<JSX.Element | undefined>();

  useEffect(() => {
    if (expanded && staffId && !calendar) {
      setCalendar(
        <StaffCalendar
          staffId={staffId}
          selectedDate={selectedDateForData}
          showPreferences={false}
        />,
      );
    }
  }, [expanded, staffId, calendar, selectedDateForData]);

  return (
    <Collapse in={expanded} orientation="vertical">
      {calendar || <Skeleton variant="rectangular" height={200} />}
    </Collapse>
  );
};

const UnitName = ({ unitId }: { selectedUnitId?: IUnit["id"]; unitId?: IUnit["id"] }) => {
  const unit = (useAppConfigQuery().data?.accessibleUnits || []).find(({ id }) => id === unitId);
  if (!unit) return <></>;

  return (
    <Typography color={darkGray} fontWeight="500" fontSize={"13px"} pl={1}>
      {unit?.name}
    </Typography>
  );
};

type LayoutElements = StaffItemProps["layout"][number];

export const StaffItem = ({
  shift,
  note,
  staffDetails: staffDetailsFromParams,
  workingHours: workingHoursFromParams,
  layout,
  unitId,
  onClick,
  hoverUpdated,
  hovered = false,
  lastStatusType,
  selectable = false,
  multiSelectable = false,
  selected = false,
  sx = emptySx,
  children,
}: StaffItemProps) => {
  const isKioskUser = useIsKiosk();
  const canManage = useCheckUserPermission("manage", EUnitPermissionAreas.houseView);
  const ffShowIncentiveShift = useAppFlags().incentivizedShifts;

  const userId = shift?.staffId || staffDetailsFromParams?.userId;
  const { shiftType, staffDetails, workingHours, lastStatusChange } = useAppSelector((state) => {
    const stateShiftType =
      shift && userId
        ? state.houseView.pageData.shiftTypes[shift.scheduleId]?.[shift.shiftTypeKey]
        : undefined;

    const stateStaffDetails =
      staffDetailsFromParams ||
      (userId ? state.houseView.pageData.staffDetails[userId] : undefined);

    const stateWorkingHours =
      workingHoursFromParams ||
      (userId ? state.houseView.pageData.staffWorkingHoursByStaffId[userId] : undefined);

    const stateLastStatusChange = userId
      ? state.houseView.pageData.metadataByStaffId[userId]
      : undefined;
    return {
      shiftType: stateShiftType,
      staffDetails: stateStaffDetails,
      workingHours: stateWorkingHours,
      lastStatusChange: stateLastStatusChange,
    };
  }, isEqual);
  const [expanded, setExpanded] = useState(false);

  const staffDetailsComponents: Partial<{ [key in LayoutElements]: JSX.Element }> = {};
  const shiftDetailsComponents: Partial<{
    [key in LayoutElements | "miniCalendarToggle"]: JSX.Element;
  }> = {};
  const line1: Partial<{ [key in "workingHoursLine1"]: JSX.Element }> = {};
  const line2: Partial<{ [key in "phoneNumber" | "workingHours"]: JSX.Element }> = {};
  const line3: Partial<{ [key in "lastStatusChange" | "unitName"]: JSX.Element }> = {};

  layout.forEach((element) => {
    switch (element) {
      case "shift":
        return (shiftDetailsComponents["shift"] = renderShift(shift, shiftType));
      case "staffName":
        return (staffDetailsComponents["staffName"] = renderStaffName(staffDetails));
      case "note":
        return (shiftDetailsComponents["note"] = (
          <UpdatableNote
            note={note}
            staffDetails={staffDetails}
            readonly={isKioskUser || !canManage}
          />
        ));
      case "staffType":
        return (staffDetailsComponents["staffType"] = renderStaffType(staffDetails));
      case "positions":
        return (staffDetailsComponents["positions"] = (
          <Positions staffShift={shift} unitId={unitId} />
        ));
      case "shiftIncentiveLevel":
        if (!ffShowIncentiveShift) return;
        return (staffDetailsComponents["shiftIncentiveLevel"] = shift?.incentiveLevelId ? (
          <ShiftIncentiveLevelIcon
            labelOnHover
            shiftIncentiveLevelId={shift.incentiveLevelId}
            unitId={unitId}
          />
        ) : (
          <></>
        ));
      case "employmentType":
        return (staffDetailsComponents["employmentType"] = renderEmploymentType(staffDetails));
      case "shiftTime":
        return (shiftDetailsComponents["shiftTime"] = renderShiftTime(shift, shiftType));
      case "floatedUnitTag":
        return (staffDetailsComponents["floatedUnitTag"] = renderFloatedUnitTag({
          currentUnit: unitId,
          unitOfItem: staffDetails?.homeUnitId,
        }));
      case "miniCalendar":
        shiftDetailsComponents["miniCalendarToggle"] = (
          <MiniCalendarButton expanded={expanded} onClick={() => setExpanded(!expanded)} />
        );
        shiftDetailsComponents["miniCalendar"] = (
          <MiniCalendar expanded={expanded} staffId={staffDetails?.userId} />
        );
        return;
      case "status":
        return (staffDetailsComponents["status"] = renderShiftStatus(
          shift,
          staffDetails?.homeUnitId,
        ));
      case "phoneNumber":
        return (line2["phoneNumber"] = renderPhoneNumber(staffDetails, isKioskUser || !canManage));
      case "workingHours":
      case "workingHoursLine1":
        const component = renderWorkingHours(workingHours, element);
        if (element === "workingHours") line2["workingHours"] = component;
        else line1["workingHoursLine1"] = component;

        return;
      case "lastStatusChange":
        return (line3["lastStatusChange"] = (
          <LastStatusChange
            lastStatusChange={
              lastStatusType
                ? {
                    status: lastStatusType,
                    data: lastStatusChange,
                  }
                : undefined
            }
            unitId={unitId}
          />
        ));
      case "unitName":
        return (line3["unitName"] = <UnitName unitId={staffDetails?.homeUnitId} />);
      default:
        return null;
    }
  });

  const statusThatVoidsShift = voidingShiftStatus(shift);
  const combinedLines =
    (line2.phoneNumber && line3.lastStatusChange) || (line2.workingHours && line3.unitName)
      ? null
      : { ...line2, ...line3 };

  const className = (() => {
    const classNames = ["house-view-staff-list-item"];
    if (onClick) classNames.push("clickable");
    if (selected) classNames.push("selected");
    if (selectable || multiSelectable) classNames.push("selectable");
    if (statusThatVoidsShift) classNames.push("with-status");
    if (hovered) classNames.push("hovered");

    return classNames.join(" ");
  })();

  return (
    <Stack
      sx={sx}
      direction="column"
      className={className}
      onClick={() => userId && onClick?.(userId)}
      onMouseEnter={() => hoverUpdated?.(userId)}
      onMouseLeave={() => hoverUpdated?.()}
    >
      <Stack direction="row" className="main-items" alignItems={"top"}>
        {selectable && <Radio trackingLabel="select-shift" checked={selected} />}
        {multiSelectable && <Checkbox trackingLabel="select-shift-multiple" checked={selected} />}
        {shiftDetailsComponents.shift}
        {shiftDetailsComponents.note}
        <Typography className="staff-details">
          {staffDetailsComponents.staffName}
          {staffDetailsComponents.staffName && staffDetailsComponents.staffType && ","}
          {staffDetailsComponents.staffType}
          {staffDetailsComponents.positions}
          {staffDetailsComponents.employmentType}
          {staffDetailsComponents.status}
          {staffDetailsComponents.floatedUnitTag}
          {staffDetailsComponents.shiftIncentiveLevel}
        </Typography>
        <Box flexGrow={1} />
        {shiftDetailsComponents.shiftTime}
        {line1.workingHoursLine1}
        {shiftDetailsComponents.miniCalendarToggle}
      </Stack>
      {combinedLines ? (
        <Stack direction="row" className="details-line" alignItems={"center"}>
          {line2.phoneNumber}
          {line3.lastStatusChange}
          <Box flexGrow={1} />
          {line2.workingHours}
          {line3.unitName}
        </Stack>
      ) : (
        <>
          <Stack direction="row" className="details-line" alignItems={"center"}>
            {line2.phoneNumber}
            <Box flexGrow={1} />
            {line2.workingHours}
          </Stack>
          <Stack direction="row" className="details-line" alignItems={"center"}>
            {line3.lastStatusChange}
            <Box flexGrow={1} />
            {line3.unitName}
          </Stack>
        </>
      )}
      {children}
      {shiftDetailsComponents.miniCalendar}
    </Stack>
  );
};
