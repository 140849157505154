import { buildApiMutationHook } from "../_shared/helpers";

import { OpenShift } from "./types";

export const useFillOpenShiftGapsMutation = buildApiMutationHook<
  OpenShift.API.CreateBulk.QueryParams,
  OpenShift.API.CreateBulk.Response
>(OpenShift.API.CreateBulk.PATH);

export const useCreateOpenShiftMutation = buildApiMutationHook<
  OpenShift.API.Create.QueryParams,
  OpenShift.API.Create.Response
>(OpenShift.API.Create.PATH);
