import { useMemo } from "react";

import { values } from "lodash";

import { IShift } from "~/routes/api/types";
import { useShiftsByScheduleQuery, useShiftsByUnitQuery, useShiftsQuery } from "~/routes/queries";

import { DeprecatedTShift } from "#/features/SchedulerGrid/types";

export const useShiftsForUnit = (): Record<DeprecatedTShift | "clear", IShift> | null => {
  const { data: allShiftOptionsGlobal = {}, isLoading: isLoadingShifts } = useShiftsQuery();
  const { data: allShiftOptionsByUnit = {}, isLoading: isLoadingShiftsByUnit } =
    useShiftsByUnitQuery();

  return useMemo(() => {
    let shiftOptions = null;

    // if done loading... set the shift options as the shift options for that unit if some exist, else the global shift options
    if (!isLoadingShifts && !isLoadingShiftsByUnit) {
      shiftOptions =
        Object.keys(allShiftOptionsByUnit).length > 0
          ? allShiftOptionsByUnit
          : allShiftOptionsGlobal;
    }
    return shiftOptions;
  }, [allShiftOptionsByUnit, allShiftOptionsGlobal, isLoadingShifts, isLoadingShiftsByUnit]);
};

export const useShiftsForSchedule = (
  scheduleId?: string,
): Record<DeprecatedTShift | "clear", IShift> | null => {
  const { data: allShiftOptionsGlobal = {}, isLoading: isLoadingShifts } = useShiftsQuery();
  const { data: allShiftOptionsBySchedule = {}, isLoading: isLoadingShiftsBySchedule } =
    useShiftsByScheduleQuery(scheduleId);

  return useMemo(() => {
    let shiftOptions = null;

    // if done loading... set the shift options as the shift options for that schedule if some exist, else the global shift options
    if (!isLoadingShifts && !isLoadingShiftsBySchedule) {
      shiftOptions =
        Object.keys(allShiftOptionsBySchedule).length > 0
          ? allShiftOptionsBySchedule
          : allShiftOptionsGlobal;
    }
    return shiftOptions;
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [
    JSON.stringify(allShiftOptionsBySchedule),
    JSON.stringify(allShiftOptionsGlobal),
    isLoadingShifts,
    isLoadingShiftsBySchedule,
  ]);
  /* eslint-enable react-hooks/exhaustive-deps */
};

export const useCustomSelectShiftsForUnit = (): { label: string; value: string }[] => {
  const { data: shiftOptions = {} } = useShiftsByUnitQuery();
  const dataDto = values(shiftOptions).map(({ name: label, key: value }) => ({
    label,
    value,
  }));
  return dataDto;
};
