import {
  ISODateString,
  PreferenceRequirementRuleSetEngine,
  Unit,
  YyyyMmDd,
} from "@m7-health/shared-utils";

import {
  IAttribute,
  INote,
  IPreferenceRequest,
  IPreferenceRequirementRule,
  IPreferenceRequirementRuleSet,
  ISchedule,
  IScheduleShiftType,
  IShiftType,
  IStaffCategory,
  IStaffDetails,
  IStaffShift,
  IStaffType,
  ITargetLevel,
  ITimeOffRequest,
  IUnitTargetLevel,
  IUser,
  Note,
  StaffCategory,
  StaffShift,
} from "@/api";
import { Dayjs } from "@/common/packages/dayjs";
import { KeyBy } from "@/common/types";

import { TTargetLevelKey, TTimeTargetLevelKey } from "../components/SchedulerGridV3/hooks";
import { ESchedulePageTabs } from "../types";

type TModalBase = {
  isOpen: boolean;
};

type TModals = {
  createSchedule?: TModalBase;
  autoBalanceLoading?: TModalBase;
  refreshStaff?: TModalBase;
  editSchedule?: TModalBase;
  restorePreferences?: TModalBase;
  shareWithSchedulers?: TModalBase;
  startBalancing?: TModalBase;
  autoBalance?: TModalBase;
  publishSchedule?: TModalBase;
  saveChanges?: TModalBase;
  updateTargetLevels?: TModalBase & {
    staffCategoryKeyFilter?: StaffCategory.EKey;
    shiftTypeKeyFilter?: IShiftType["key"];
    nthWeekFilter?: number;
  };
  unsavedChanges?: TModalBase & {
    handleDiscardChanges?: () => void;
  };
  timeOffRequestApproval?: TModalBase & {
    timeOffRequestId?: ITimeOffRequest["id"];
  };
  deleteSchedule?: TModalBase;
};

type TFilters = {
  fullView: boolean;
  showPreferences: boolean;
  showIncentivedShifts: boolean;
  showStatus: boolean;
  shiftType: IShiftType["key"][];
  attributes?: IAttribute["key"][];
  attributeEligibilityIconToShow: IAttribute["key"] | null;
  /** show position assignment in headers */
  showPositionAssignment?: boolean;
  /** show position assignment in shift cell */
  showPositionAssignmentOnShiftCell?: boolean;
  showPositionEligibility?: boolean;
  showTargetLevels?: boolean;
  showTimeTargetLevels?: boolean;
  showShiftTargetLevels?: boolean;
  devMode?: boolean;
  showAggregatedTargetLevels?: boolean;
};
const initialFilters: TFilters = {
  fullView: false,
  showPreferences: true,
  showIncentivedShifts: true,
  showStatus: true,
  shiftType: [],
  attributes: undefined,
  attributeEligibilityIconToShow: null,
  showPositionAssignment: undefined,
  showPositionAssignmentOnShiftCell: true,
  showPositionEligibility: undefined,
  showTargetLevels: undefined,
  showTimeTargetLevels: undefined,
  showShiftTargetLevels: undefined,
  devMode: false,
  showAggregatedTargetLevels: undefined,
};

// Enhanced shift with some data (such as "Weight" for partial shift for example)
//  and notes to know rapidly if we should count it in or not.
export type TWeightedShift = IStaffShift & {
  note: Note.EUpdate | null;
  weight: number;
  staffDetails: IStaffDetails;
  dayjsDate: Dayjs;
  fractionOfOverlap?: number | null;
  outsideSchedule?: boolean;
  holiday?: {
    currentLabel?: string;
    originalLabel?: string;
    originalDate?: ISODateString;
  };
};
type TData = {
  // Is core data loading or not
  isLoading: boolean;

  // Rule sets data
  ruleSets: {
    // Based on user rosters, attached link to shared engines
    engineByUser: Record<IUser["id"], PreferenceRequirementRuleSetEngine[]>;

    // Indexed rule set data
    ruleSetsById: KeyBy<IPreferenceRequirementRuleSet, "id">;
    rulesById: KeyBy<IPreferenceRequirementRule, "id">;
  };

  // Anything related to state updates (store to save, undo, redo, etc.)
  updates: {
    cleanOnNextRefetch?: boolean;

    redoHistory: { from: IStaffShift | null; to: IStaffShift | null }[];
    undoHistory: { from: IStaffShift | null; to: IStaffShift | null }[];

    // Latest data about an updated staff shift. That's the object we use when we save the data
    byStaffShiftId: { [staffShiftId in IStaffShift["id"]]: IStaffShift | null };
  };

  // organized by keys that are computed by the getTimeTargetLevelKey function that renders a uniq and deterministic key
  //  based on the target level criteria such as category, attribute, shift type etc ....
  timeTargetLevels: {
    // Ordered keys old the order of target's rows in the grid, both "order headers", and per day headers
    orderedKeys: TTimeTargetLevelKey[];
    targets: {
      [key in TTimeTargetLevelKey]: Unit.Config.ITimeTargetLevel & {
        staffCategoryKey: IStaffCategory["key"];
      };
    };
  };

  aggregatedTargetLevels: {
    keys: TTargetLevelKey[];
    targets: { [key in TTargetLevelKey]: IUnitTargetLevel };
  };

  // organized by keys that are computed by the getTimeTargetLevelKey function that renders a uniq and deterministic key
  //  based on the target level criteria such as category, attribute, shift type etc ....
  targetLevels: {
    // Ordered keys old the order of target's rows in the grid, both "corder headers", and per day headers
    orderedKeys: TTargetLevelKey[];
    orderedTargets: {
      [key in TTargetLevelKey]: {
        shiftTypeKey: IShiftType["key"];
        staffCategoryKey?: IStaffCategory["key"];
        staffTypeKey?: IStaffType["key"];
        attributeKey?: IAttribute["key"];
        label?: string;
      };
    };
    byDate: {
      [key in YyyyMmDd]: {
        [targetLevelIdentifier in TTargetLevelKey]: ITargetLevel | IUnitTargetLevel;
      };
    };
  };

  // Staff notes, per user per day
  notes: { [staffId in IUser["id"]]: KeyBy<INote, "date"> };

  // StaffShifts, per user per day
  staffShifts: {
    [staffId in IUser["id"]]: {
      [date in YyyyMmDd]: IStaffShift[];
    };
  };

  // Copy of staff shifts that we receive from the server, so we can compare which one is dirty
  //  and which one is not, to know if it's update, delete or create
  shiftsInDb: KeyBy<IStaffShift, "id">;

  // Shift type of all involved schedules (current, plus the schedules where people are floated too)
  shiftTypes: {
    [scheduleId in ISchedule["id"]]: KeyBy<IScheduleShiftType, "key">;
  };

  // Staff details of all staff involved in the schedule, in current days, past and future 3 days,
  //  including staff floated to that unit in this range
  staffDetails: KeyBy<IStaffDetails, "userId">;

  // Preference request of the staff involved in the schedule, in current days, past and future 3 days,
  //   by staff id and date
  preferenceRequests: {
    [staffId in IUser["id"]]: {
      [date in YyyyMmDd]: IPreferenceRequest;
    };
  };

  timeOffRequests: {
    [staffId in IUser["id"]]: {
      [date in YyyyMmDd]: ITimeOffRequest;
    };
  };

  // staff shifts enhanced with some data (such as "Weight" for partial shift for example)
  //  and organized in different ways to be able to calculate some stats on the fly
  calculatedData: {
    perDay: {
      [date in YyyyMmDd]: {
        [key in TTargetLevelKey]: TWeightedShift[];
      };
    };
    perDayTime: {
      [date in YyyyMmDd]: {
        [key in TTimeTargetLevelKey]: TWeightedShift[];
      };
    };
    perStaff: {
      [staffId in IUser["id"]]: {
        shifts: TWeightedShift[];
        shiftsByDay: { [day in YyyyMmDd]: TWeightedShift[] };
        shiftTypes: { [ShiftTypeKey in IShiftType["key"]]: boolean };
        totalShifts: number;
        weekendShifts: number;
        unfairShifts: number;
        totalPreferences: number;
        totalNonEmptyDays: number;
      };
    };
  };
};
const initialData: TData = {
  isLoading: false,
  updates: {
    redoHistory: [],
    undoHistory: [],
    byStaffShiftId: {},
  },
  ruleSets: {
    engineByUser: {},
    ruleSetsById: {},
    rulesById: {},
  },
  shiftsInDb: {},
  notes: {},
  staffShifts: {},
  shiftTypes: {},
  staffDetails: {},
  preferenceRequests: {},
  timeOffRequests: {},
  timeTargetLevels: {
    orderedKeys: [],
    targets: {},
  },
  aggregatedTargetLevels: {
    keys: [],
    targets: {},
  },
  targetLevels: {
    orderedKeys: [],
    orderedTargets: {},
    byDate: {},
  },
  calculatedData: {
    perDay: {},
    perDayTime: {},
    perStaff: {},
  },
};

export const initialState: ISchedulerGridState = {
  modals: {},
  grid: {
    filters: initialFilters,
    showShiftTypeDropdownInCell: false,
    selectedStaffCategory: null,
    data: initialData,
    sidebar: {
      isCollapsed: false,
      selectedStaffId: null,
      selectedDayKey: null,
    },
  },
  schedule: null,
  scheduleType: StaffShift.EScheduleType.draft,
  schedulePageTab: ESchedulePageTabs.current,
};

export interface ISchedulerGridState {
  modals: TModals;
  grid: {
    filters: TFilters;
    data: TData;
    selectedStaffCategory: IStaffCategory["key"] | null;
    // Whether we clicked a second time on the selected cell;
    showShiftTypeDropdownInCell: boolean;
    sidebar: {
      isCollapsed: boolean;
      selectedStaffId: IUser["id"] | null;
      selectedDayKey: YyyyMmDd | null;
    };
  };
  // M7 vs draft
  scheduleType: IStaffShift["scheduleType"];
  schedule: ISchedule | null;
  schedulePageTab: ESchedulePageTabs;
}
