import { Typography } from "@mui/material";

import CustomModal from "~/common/components/Modal";
import { useAppFlags } from "~/common/hooks/useAppFlags";

const defaultTitle = "Platform Updates in Progress";
const defaultMessage =
  "Our site is undergoing scheduled maintenance. Please check back at soon. We apologize for any inconvenience.";

const PlatformUpdatesMessage = () => {
  const { platformUpdatesInProgress: arePlatformUpdatesInProgress } = useAppFlags();

  const title = arePlatformUpdatesInProgress?.title || defaultTitle;
  const message = arePlatformUpdatesInProgress?.message || defaultMessage;

  return (
    <CustomModal
      isOpen={true}
      primaryBtnText="Close"
      closeDisabled={true}
      modalHeaderText={title}
      primaryDisabled={true}
      variant="warning"
      withBlurBackground={true}
      modalContent={
        <>
          <Typography>{message}</Typography>
          <Typography sx={{ marginTop: 3 }}>
            Please contact M7 at 727-777-7391 or{" "}
            <a href="mailto:help@m7health.com">help@m7health.com</a> if you need assistance.
          </Typography>
        </>
      }
    />
  );
};

export default PlatformUpdatesMessage;
