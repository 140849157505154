import { useLocation } from "react-router-dom";

import { EStaffRosterPages } from "../../types";

export const useIsStaffProfilePage = () => {
  const location = useLocation();

  const selectedRosterPage = location.pathname.split(/[//]/).at(5);

  return selectedRosterPage === EStaffRosterPages.staffDetails;
};
