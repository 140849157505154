import React from "react";

import { capitalize, upperCase } from "lodash";

import { Typography } from "@mui/material";

import { pxToRem } from "~/common/utils/pxToRem";

import { Roster } from "@/api";
import { lightGray } from "@/common/theming/colors";

import { HeaderCellContainer } from "./HeaderCell.styled";

interface IHeaderCellProps {
  title: string;
}

export const HeaderCell = ({ title }: IHeaderCellProps) => {
  return (
    <HeaderCellContainer
      flexDirection="column"
      alignItems="center"
      sx={{
        height: "52px",
      }}
    >
      <Typography
        sx={[
          {
            backgroundColor: Object.values(Roster.ENotificationType).includes(
              title as Roster.ENotificationType,
            )
              ? lightGray
              : "transparent",
            fontSize: pxToRem(17),
            lineHeight: pxToRem(14),
            p: 2,
            textWrap: "nowrap",
          },
        ]}
      >
        {Object.values(Roster.ENotificationType).includes(title as Roster.ENotificationType)
          ? title === Roster.ENotificationType.sms
            ? upperCase(title)
            : capitalize(title)
          : title}
      </Typography>
    </HeaderCellContainer>
  );
};
