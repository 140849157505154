/* eslint-disable no-console */

import { memo, useMemo } from "react";

import { m7DayJs, Timezone } from "@m7-health/shared-utils";
import { isEqual } from "lodash";

import { Grid } from "@mui/material";

import { StaffShift, Unit } from "~/api";
import Tag from "~/common/components/Tag";
import { FLOATING_ICON, iconComponentForShift } from "~/common/constants";
import { multipleShiftsColor, workingAwayStaffColor } from "~/common/theming/colors";
// eslint-disable-next-line deprecate/import
import { YyyyMmDd } from "~/common/types";

import { THouseViewState } from "#/features/HouseView/store";
import { ShiftV2 } from "@/common/components";
import { backgroundColorForUpdateOnSchedulerGrid } from "@/common/components/NoteUpdateTag/helpers";
import ShiftIcon from "@/common/components/ShiftIcon";
import { useAppFlags, useAppSelector } from "@/common/hooks";

import { TGridActions } from "../hooks/useGridActions";

export const ScheduleTableStaffShiftCell = memo(
  ({
    staffId,
    date: formattedDate,
    shiftInfo,
    scheduleId,
    shiftTypes,
    unitId,
    gridActions,
    timezone,
  }: {
    staffId: string;
    date: YyyyMmDd;
    shiftInfo: StaffShift.DTO[] | undefined;
    scheduleId: string;
    shiftTypes: THouseViewState["pageData"]["shiftTypes"];
    unitId: Unit.DTO["id"];
    gridActions: TGridActions;
    timezone: Timezone;
  }) => {
    const {
      staffId: editedStaffId,
      currentDate: currentDateSidebar,
      editingFromDate,
      editingToDate,
      unsavedShift,
    } = useAppSelector(
      (state) => ({
        editingFromDate: state.houseView.editShifts.fromDate,
        editingToDate: state.houseView.editShifts.toDate,
        unsavedShift: state.houseView.editShifts.staffShifts?.[0],
        staffId: state.houseView.editShifts.staffId,
        currentDate: state.houseView.pageFilters.selectedDateForData,
        autoSave: state.houseView.editShifts.autoSave,
      }),
      isEqual,
    );

    /** HIGH-LEVEL STATE */
    const editingShiftIsCurrentStaff = unsavedShift?.staffId === staffId;
    // Show unsaved shift if editing
    if (unsavedShift && editingToDate === formattedDate && editingShiftIsCurrentStaff)
      shiftInfo = [unsavedShift];
    const { balancingTheHouseSidebar } = useAppFlags();
    const hasMultipleShifts = shiftInfo && shiftInfo.length > 1;
    const firstStatus = shiftInfo && shiftInfo.find((shift) => shift.status)?.status;
    const firstShift = shiftInfo && shiftInfo[0];
    const shiftKey = firstShift?.shiftTypeKey;
    const isWorkingHome = firstShift?.scheduleId === scheduleId;
    const MultiIcon = iconComponentForShift("Multi");
    const availableScheduleShiftTypes = shiftTypes?.[scheduleId];

    const dateSelected = currentDateSidebar === formattedDate;
    const staffSelected = editedStaffId === staffId;
    const isSelected = staffSelected && dateSelected;
    const classNames = useMemo(() => {
      const names = [
        "shift-cell",
        "staff-id-" + staffId,
        "day-" + formattedDate,
        shiftKey ? "shift-type-" + shiftKey : "_NO_SHIFT_",
      ];
      if (m7DayJs(formattedDate).day() === 6) names.push("sunday");
      if (editingShiftIsCurrentStaff) {
        if (editingFromDate === formattedDate) names.push("editing-from");
        if (editingToDate === formattedDate) names.push("editing-to");
      }
      if (dateSelected) names.push("date-selected");
      if (staffSelected) names.push("staff-selected");
      return names;
    }, [
      staffId,
      formattedDate,
      shiftKey,
      editingShiftIsCurrentStaff,
      editingFromDate,
      editingToDate,
      dateSelected,
      staffSelected,
    ]);

    const draggable = !!(
      balancingTheHouseSidebar &&
      !hasMultipleShifts &&
      firstShift?.shiftTypeKey
    );

    return (
      <td
        className={classNames.join(" ")}
        draggable={draggable}
        key={`${formattedDate} - ${staffId}-${shiftKey || "no-shift"}`}
        //>> Must match TGridCellData
        data-date={formattedDate}
        data-unit-id={unitId}
        data-staff-id={staffId}
        data-staff-shift-id={firstShift?.id}
        data-schedule-id={scheduleId}
        data-shift-key={shiftKey || "__no-shift__"}
        data-timezone={timezone}
        //<<
        onClick={gridActions.onCellClick}
        style={{
          cursor: balancingTheHouseSidebar ? "pointer" : "default",
          border: isSelected ? "1.5px solid black" : undefined,
        }}
        onDragStart={gridActions.handleDragStart}
        onDragOver={gridActions.handleDragOver}
        onDrop={gridActions.handleDrop}
      >
        <div className="shift-tile-cell-wrapper">
          <div className="inside-cell">
            <div className={"working-shift"}>
              {hasMultipleShifts && (
                <Tag
                  backgroundColor={multipleShiftsColor}
                  className="shift-tile"
                  icon={<MultiIcon />}
                  miniView
                  sx={{
                    cursor: balancingTheHouseSidebar ? "pointer" : "default",
                  }}
                />
              )}
              {!hasMultipleShifts &&
                shiftKey &&
                (!isWorkingHome ? (
                  <Tag
                    backgroundColor={workingAwayStaffColor}
                    className={["shift-tile", "floated"].join(" ")}
                    icon={<FLOATING_ICON />}
                    text={null}
                    sx={{
                      cursor: balancingTheHouseSidebar ? "pointer" : "default",
                      maxWidth: "22px",
                    }}
                  />
                ) : (
                  availableScheduleShiftTypes?.[firstShift.shiftTypeKey] &&
                  firstShift && (
                    <ShiftV2
                      shiftType={availableScheduleShiftTypes[firstShift.shiftTypeKey]!}
                      staffShift={firstShift}
                      hoursVariant="short"
                      icon={
                        <ShiftIcon
                          firstShift={firstShift}
                          availableShiftTypes={availableScheduleShiftTypes}
                          firstStatusOnDay={firstStatus}
                          fullView={true}
                        />
                      }
                      miniView={true}
                      sx={{ cursor: balancingTheHouseSidebar ? "pointer" : "default" }}
                    />
                  )
                ))}
              {firstStatus && (
                <Grid
                  sx={{
                    position: "absolute",
                    right: 0,
                    top: 0,
                    height: 10,
                    width: 10,
                    borderRadius: 0.8,
                    backgroundColor: backgroundColorForUpdateOnSchedulerGrid(firstStatus),
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </td>
    );
  },
);
