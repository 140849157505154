import { useCallback, useState } from "react";
import { Controller } from "react-hook-form";

import { identity, pickBy, random } from "lodash";
import upperFirst from "lodash/upperFirst";

import { Close } from "@mui/icons-material";
import {
  Box,
  Chip,
  Divider,
  FormControl,
  FormHelperText,
  InputLabel,
  Typography,
} from "@mui/material";
import Select from "@mui/material/Select";

import { moderateGray, redAlert } from "~/common/theming/colors";
import { useAppTracking } from "~/modules/mixpanel/Context";
import { Mxp } from "~/modules/mixpanel/types";

import { useDeepMemo, useKeyBy } from "@/common/hooks";
import { NotNullKeyBy } from "@/common/types";

import CustomButton from "../Button";
import Checkbox from "../Checkbox";
import MenuItem from "../MenuItem";

import { ICustomSelectControlledProps, ICustomSelectItem, ICustomSelectProps } from "./types";

export const CustomSelectControlled = <T,>({
  control,
  name,
  defaultValue,
  ...rest
}: ICustomSelectControlledProps<T>) => {
  return (
    <Controller
      defaultValue={defaultValue || ""}
      name={name || ""}
      control={control}
      render={({ field }) => <CustomSelect<T> name={name} field={field} {...rest} />}
    />
  );
};

export const CustomSelect = <T,>({
  items,
  label,
  trackingLabel,
  onChange,
  value,
  width,
  noneOption = false,
  customTopOption,
  onCustomOptionSelect,
  hideSelectedOption = true,
  size = "small",
  name,
  multiple,
  field,
  errors,
  noValueText,
  optionRenderComponent,
  checked,
  renderMultiSelectedValue,
  style,
  isAllSelectedWithEmptyOption,
  className,
  customDisplayOption,
  limit,
  withCloseButton,
  renderNoneValue,
  ...rest
}: ICustomSelectProps<T>) => {
  type TAttribute = ICustomSelectItem<T>["value"];

  const [isOpen, setIsOpen] = useState(false);
  const errorMessage = name ? upperFirst((errors?.[name]?.message as string) ?? "") : null;

  const track = useAppTracking();
  const onClickWrapper = () => {
    track(Mxp.Event.elementClicked, {
      [Mxp.Property.element.type]: "Select",
      [Mxp.Property.element.label]: trackingLabel || label.toString(),
    });
    // onClick is not exposed by the custom Switch component
    //  so we don't need to wrap it for now;
  };

  const onCustomOptionSelectHandler = (event: React.MouseEvent<HTMLLIElement>) => {
    setIsOpen(false);
    if (onCustomOptionSelect) onCustomOptionSelect(event);
  };

  const itemsByValue = useKeyBy(items, "value") as NotNullKeyBy<ICustomSelectItem<T>, "value">;

  const getLabelForItemValue = useCallback(
    (selectedValue: TAttribute) => itemsByValue[selectedValue]?.label,
    [itemsByValue],
  );

  const inputLabelId = name ? `${name}_custom_select` : `custom_select_${random(0, 1000)}`;

  const restWithoutUndefined = useDeepMemo(() => pickBy(rest, identity), [rest]);

  return (
    <FormControl
      className={className}
      fullWidth={Boolean(width)}
      sx={{ width: width ? `${width}` : "100%", ...style }}
      size={"small"}
    >
      <InputLabel id={inputLabelId} shrink={rest.displayEmpty || rest.shrink || undefined}>
        {label}
      </InputLabel>
      <Select
        onClick={onClickWrapper}
        MenuProps={rest.MenuProps}
        name={name}
        label={label}
        multiple={multiple}
        value={value}
        variant="outlined"
        open={isOpen}
        error={!!errorMessage}
        onOpen={() => setIsOpen(true)}
        onClose={() => setIsOpen(false)}
        size={size}
        labelId={inputLabelId}
        notched={rest.displayEmpty || undefined}
        renderValue={
          customDisplayOption
            ? (_rValue: string) => {
                return <Typography>{customDisplayOption}</Typography>;
              }
            : multiple
              ? (rValue: string) => {
                  const selected = rValue as unknown as TAttribute[];
                  const itemsToShow = limit ? selected?.slice(0, limit) : selected;
                  let selectionNodes;
                  if (renderMultiSelectedValue) {
                    selectionNodes = (itemsToShow ?? []).map((selectedValue) => {
                      const selectedItem = itemsByValue[selectedValue];
                      return selectedItem ? renderMultiSelectedValue(selectedItem) : <></>;
                    });
                    // If there are no selected values, then render the empty option label by passing undefined to the renderMultiSelectedValue function
                    if (selected.length === 0) {
                      return renderMultiSelectedValue(undefined);
                    }
                  } else {
                    selectionNodes = (itemsToShow ?? []).map((selectedValue) => (
                      <Chip
                        key={selectedValue?.toString()}
                        label={getLabelForItemValue(selectedValue)}
                      />
                    ));
                  }
                  if (multiple && limit && selected.length > limit) {
                    selectionNodes.push(
                      <Box display={"flex"} alignItems={"center"}>
                        <Typography key="more" variant="body2">
                          {`+${selected.length - limit}...`}
                        </Typography>
                      </Box>,
                    );
                  }

                  return (
                    <Box
                      className={"selected-options"}
                      sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}
                    >
                      {selectionNodes}
                    </Box>
                  );
                }
              : undefined
        }
        {...field}
        onChange={(event) => {
          field?.onChange(event);
          onChange?.(event);
        }}
        {...restWithoutUndefined}
      >
        {withCloseButton && (
          <CustomButton onClick={() => setIsOpen(false)} {...closeDropdownButtonStaticProps} />
        )}
        {noneOption ? (
          <MenuItem className="custom-select-none-option" value="" trackingLabel={null}>
            {renderNoneValue?.() || "None"}
          </MenuItem>
        ) : null}
        {customTopOption
          ? [
              <MenuItem
                trackingLabel={null}
                onClick={onCustomOptionSelectHandler}
                key="custom_top_option"
              >
                {customTopOption}
              </MenuItem>,
              <Divider key="custom_top_option_divider" />,
            ]
          : null}
        {items.map((itemToRender) => {
          const { label: optionLabel, value: optionValue } = itemToRender;
          return (
            <MenuItem
              // Boolean is unofficially supported by The MenuItem
              // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-assignment
              value={optionValue as any}
              key={`${optionValue?.toString() || ""}`}
              // onClick={onCustomOptionSelectHandler}
              sx={{ display: hideSelectedOption && optionValue === value ? "none" : "block" }}
              trackingLabel={null}
            >
              {/* use checkbox only if not empty option label in dropdown */}
              {checked && optionValue !== "" && (
                <Checkbox
                  // Check if the option is checked based on the current value
                  // With multi select we need to check if the value is in the array
                  // value represents what is selected, optionValue represents the current option to check or not

                  // Rules:
                  // if the optionValue is in the value array, then it is checked
                  // else if the value array is empty and isAllSelectedWithEmptyOption is true meaning that when no options are selected, all options are selected, then it is checked
                  checked={
                    (value as (string | number | boolean)[]).includes(
                      optionValue as string | number | boolean,
                    ) ||
                    (value?.toString().length === 0 && isAllSelectedWithEmptyOption)
                  }
                  trackingLabel={optionValue?.toString() || "__empty__"}
                  key={`${value?.toString() || ""}-${optionValue?.toString() || ""}`}
                />
              )}
              {/* if the option value is not "", which represents the empty option, render Item, else return just the label */}
              {optionRenderComponent && optionValue !== ""
                ? optionRenderComponent(itemToRender)
                : optionLabel}
            </MenuItem>
          );
        })}
        {(items.length === 0 || (items.length === 1 && items[0]?.value === value)) && (
          <Box sx={{ display: "flex", padding: "8px 12px", cursor: "default" }}>
            <Typography variant="body2">{noValueText || "No values to select"}</Typography>
          </Box>
        )}
      </Select>
      {errorMessage && <FormHelperText sx={{ color: redAlert }}>{errorMessage}</FormHelperText>}
    </FormControl>
  );
};

const closeDropdownButtonStaticProps = {
  className: "close-dropdown-button",
  trackingLabel: null,
  iconOnly: true,
  startIcon: <Close />,
  sx: {
    position: "absolute",
    right: "10px",
    p: "5px",
    backgroundColor: moderateGray,
    zIndex: 10,
  },
};
