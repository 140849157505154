import { useAppConfigQuery } from "#/features/User/queries";

import { useCurrentFacilityId } from "./useCurrentFacilityId";
import { useDeepMemo } from "./useDeepMemo";

export const useCurrentFacilityUnits = () => {
  const currentFacilityId = useCurrentFacilityId();
  const units = useAppConfigQuery().data?.accessibleUnits;

  return useDeepMemo(
    () => units?.filter((unit) => unit.facilityId === currentFacilityId),
    [units, currentFacilityId],
  );
};
