import { useCallback, useMemo } from "react";

import { map, sortBy, values } from "lodash";

import { CheckBoxOutlineBlank } from "@mui/icons-material";
import CheckBox from "@mui/icons-material/CheckBox";
import { SxProps, TextField } from "@mui/material";

import { IStaffDetails } from "@/api";
import { black, darkGray } from "@/common/theming";

import { Autocomplete, Checkbox } from "../TrackedComponents";

const allStaffOptionId = "all-staff-id";

/**
 * AutocompleteStaffSelector Component
 *
 * This component renders an autocomplete input for selecting staff members.
 * It supports displaying selected staff as text or removable chips and showing an option to select all staff
 *
 *
 * Props:
 * - trackingLabel: A string or null used for tracking purposes.
 * - displaySelectedAsText: A boolean indicating whether to display selected staff as text or removable chips.
 * - staffOptions: An array of IStaffDetails objects representing the available staff options.
 * - selectedStaffIds: An array of staff ids representing the currently selected staff.
 * - setSelectedStaffIds: A function to update the selected staff ids.
 * - showAllStaffOption: A boolean indicating whether to show an option to select all staff. All staff selected will be treated as an empty selected staff array.
 * - label: An optional string to label the input field.
 * - sx: An optional SxProps object to customize the styles.
 */

export const AutocompleteStaffSelector = ({
  trackingLabel = null,
  displaySelectedAsText = false,
  staffOptions,
  selectedStaffIds,
  setSelectedStaffIds,
  showAllStaffOption = false,
  label,
  sx,
  limitTags,
}: {
  limitTags?: number;
  trackingLabel: string | null;
  displaySelectedAsText?: boolean;
  staffOptions: IStaffDetails[];
  selectedStaffIds: string[];
  setSelectedStaffIds: (staffIds: string[]) => void;
  showAllStaffOption?: boolean;
  label?: string;
  sx?: SxProps;
}) => {
  const autoCompleteLabelByStaffId = useMemo(() => {
    // Iterates through staff and creates a unique label for each staff member
    // If a duplicate first and last name is found then email is added to the label
    const staffByUniqueLabel = staffOptions.reduce(
      (acc, staff) => {
        const baseLabel =
          `${staff.user.firstName} ${staff.user.lastName}` +
          (staff.user.isSoftDeleted ? " (suspended)" : "");
        const foundValue = acc[baseLabel];
        if (!foundValue) {
          acc[baseLabel] = {
            ...staff,
            autocompleteLabel: baseLabel,
          };
        } else {
          acc[baseLabel] = {
            ...foundValue,
            autocompleteLabel: `${baseLabel} (${foundValue.user.email})`,
          };
          acc[`${baseLabel} (${staff.user.email})`] = {
            ...staff,
            autocompleteLabel: `${baseLabel} (${staff.user.email})`,
          };
        }
        return acc;
      },
      {} as Record<string, IStaffDetails & { autocompleteLabel: string }>,
    );

    const staffWithAutocompleteLabel = values(staffByUniqueLabel);
    // Returns each unique autocomplete label keyed by userId
    return staffWithAutocompleteLabel.reduce(
      (acc, staff) => {
        acc[staff.userId] = staff.autocompleteLabel;
        return acc;
      },
      {} as Record<string, string>,
    );
  }, [staffOptions]);

  // Returns every selected staff user as a string of text separated by commas
  const selectedNamesAsText = useCallback(
    (selectedIds: string[]) => {
      return selectedIds.map((staffId) => autoCompleteLabelByStaffId[staffId]).join(", ");
    },
    [autoCompleteLabelByStaffId],
  );

  // Appends all staff option to the top of the autocomplete options if showAllStaffOption is true
  const autocompleteOptions = useMemo(() => {
    return [
      ...(showAllStaffOption ? [allStaffOptionId] : []),
      ...map(sortBy(staffOptions, ["user.firstName", "user.lastName"]), "userId"),
    ];
  }, [staffOptions, showAllStaffOption]);

  return (
    <Autocomplete
      limitTags={limitTags}
      trackingLabel={trackingLabel}
      multiple
      options={autocompleteOptions}
      value={selectedStaffIds}
      disableCloseOnSelect
      getOptionLabel={(staffId) => autoCompleteLabelByStaffId[staffId] || ""}
      renderOption={(props, staffId, { selected }) => {
        const isAllStaffOptionSelected = staffId === allStaffOptionId && !selectedStaffIds.length;
        return (
          <li {...props} role="option" aria-selected={isAllStaffOptionSelected}>
            <Checkbox
              trackingLabel="attribute-picker"
              icon={<CheckBoxOutlineBlank fontSize="small" style={{ color: darkGray }} />}
              checkedIcon={<CheckBox fontSize="small" style={{ color: black }} />}
              style={{ marginRight: 8 }}
              checked={selected || isAllStaffOptionSelected}
              disabled
            />
            {staffId === allStaffOptionId ? "All Staff" : autoCompleteLabelByStaffId[staffId]}
          </li>
        );
      }}
      renderTags={displaySelectedAsText ? selectedNamesAsText : undefined}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder={showAllStaffOption && !selectedStaffIds.length ? "All Staff" : undefined}
          focused={showAllStaffOption || undefined}
          label={label}
        />
      )}
      onChange={(event, value) => {
        if (showAllStaffOption && (event.target as HTMLElement).innerText === "All Staff") {
          // If All staff option is selected then sets selected staff to empty array
          setSelectedStaffIds([]);
        } else if (showAllStaffOption && value.length === staffOptions.length - 1) {
          // If all staff are individually selected then sets selected staff to empty array
          setSelectedStaffIds([]);
        } else {
          // Defaults to select and unselect specific staff
          setSelectedStaffIds(value);
        }
      }}
      sx={sx}
    />
  );
};
