import { BULK_DELETE_KEY, LAvailableMuiIconsClassNames } from "~/common/constants";
import { TimeString, Uuid, brandedAlias } from "~/common/types";
import { IUnitBasic } from "~/routes/api/types";

import { APIListResponse, SDTO } from "../_shared/types";
import { ISchedule } from "../schedule";
import { IUnit } from "../unit";

export type IScheduleShiftType = ShiftType.Schedule.DTO;
export type IUnitShiftType = ShiftType.Unit.DTO;
export type IShiftType = ShiftType.DTO;

type ShiftTypeKey = brandedAlias<string>;

export namespace ShiftType {
  export enum EDisplayableFor {
    schedulerGrid = "schedulerGrid",
    dailyReport = "dailyReport",
    schedulerGridQuickAction = "schedulerGridQuickAction",
  }

  // TODO: see why we have duplicates with _shared shift type
  export type DTO = SDTO.withTimestamps &
    SDTO.softDeletable & {
      key: ShiftTypeKey;
      name: string;
      unitId?: Uuid;
      sortPosition?: number;

      // Shift time and duration
      startTime: TimeString;
      durationSeconds: number;

      // --> APPEARANCE
      displayableInDropdowns: EDisplayableFor[];
      printAbbreviation?: string;
      muiIconClassName?: LAvailableMuiIconsClassNames;
      iconColor?: string;
      // Scheduler POV
      scheduleViewDisplayName: string;
      scheduleViewColor: string;
      schedulerAssignableShift: boolean;
      // Staff POV
      staffViewDisplayName: string;
      staffViewColor: string;
      staffPreferableShift: boolean;
      // <--- APPEARANCE

      // Shift description
      isHouseSupervisorShift: boolean;
      isPatientCareShift: boolean;
      isWorkingShift: boolean;
      isPaidShift: boolean;
      isCountedForRequirements: boolean;
      isCountedForRealTimeStaffingTarget: boolean;

      // Fairness rules
      violationIfReplacingNull: boolean;
      violationIfReplacedByNull: boolean;
      violationIfReplacedByPatientNonWorking: boolean;
      violationIfReplacedByNonPatientNonWorking: boolean;
      violationIfReplacedByPatientWorking: boolean;
      violationIfReplacedByNonPatientWorking: boolean;
    };

  export namespace Schedule {
    export interface DTO extends ShiftType.DTO {
      id: Uuid;
      scheduleId: ISchedule["id"];
    }

    export namespace API {
      const PATH_ROOT = "/schedule-shift-type";
      export namespace List {
        export const PATH = PATH_ROOT;
        export interface QueryParams {
          scheduleIds?: ISchedule["id"][];
        }

        export type Response = APIListResponse<DTO>;
      }
    }
  }

  export namespace Unit {
    export interface DTO extends ShiftType.DTO {
      id: Uuid;
      unitId: IUnit["id"];
    }

    export namespace API {
      const PATH_ROOT = "/unit-shift-type";
      export namespace List {
        export const PATH = PATH_ROOT;
        export interface QueryParams {
          ids?: DTO["id"];
          unitIds?: IUnitBasic["id"][];
        }

        export type Response = APIListResponse<DTO>;
      }

      export namespace BulkSave {
        export const PATH = PATH_ROOT + "/bulk-save";
        export interface QueryParams {
          unitShiftTypes: (
            | TUnitShiftTypeToUpdate
            | TUnitShiftTypeToCreate
            | TUnitShiftTypeToDelete
          )[];
          propagateToSchedules: boolean;
        }

        export type Response = APIListResponse<DTO>;
      }
    }
  }
}

type TUnitShiftTypeToDelete = {
  id: IUnitShiftType["id"];
  [BULK_DELETE_KEY]: true;
};

type TUnitShiftTypeToUpdate = {
  id: IUnitShiftType["id"];
} & Partial<UpdatableAttributes>;

type TUnitShiftTypeToCreate = UpdatableAttributes;

type UpdatableAttributes = Pick<
  ShiftType.Unit.DTO,
  | "name"
  | "startTime"
  | "muiIconClassName"
  | "displayableInDropdowns"
  | "durationSeconds"
  | "iconColor"
  | "sortPosition"
  | "isCountedForRealTimeStaffingTarget"
  | "isCountedForRequirements"
  | "isPaidShift"
  | "isPatientCareShift"
  | "isWorkingShift"
  | "scheduleViewColor"
  | "scheduleViewDisplayName"
  | "schedulerAssignableShift"
  | "violationIfReplacedByNonPatientNonWorking"
  | "violationIfReplacedByNonPatientWorking"
  | "violationIfReplacedByNull"
  | "violationIfReplacedByPatientNonWorking"
  | "violationIfReplacedByPatientWorking"
  | "violationIfReplacingNull"
  | "staffPreferableShift"
  | "staffViewColor"
  | "staffViewDisplayName"
>;
