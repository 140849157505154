import styled from "styled-components";

import { Box, Grid } from "@mui/material";

import { darkGray, lines } from "~/common/theming/colors";

type TShiftTypeWrapperProps = {
  $displayRightBorder?: boolean;
  $displayLeftBorder?: boolean;
};

export const HeaderCellContainer = styled(Grid)`
  height: 100%;
`;

export const ShiftTypeWrapper = styled(Box)<TShiftTypeWrapperProps>`
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  height: 26px;
  ${({ $displayRightBorder }: TShiftTypeWrapperProps) =>
    $displayRightBorder &&
    `
    border-right: 1px solid ${lines};
    `}

  ${({ $displayLeftBorder }: TShiftTypeWrapperProps) =>
    $displayLeftBorder &&
    `
    border-left: 1px solid ${lines};
    `}


  svg {
    width: 0.688rem;
    color: ${darkGray};
  }
`;
