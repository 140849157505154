import { THexColor } from "@m7-health/shared-utils";
import { PayloadAction } from "@reduxjs/toolkit";

import { IShiftIncentiveLevel, IUnit } from "@/api";
import { KeyBy } from "@/common/types";

import { THouseViewState } from ".";

export type TShiftIncentiveLevelState = {
  toUpdate: Record<IShiftIncentiveLevel["key"], IShiftIncentiveLevel>;
  toCreate: Record<IShiftIncentiveLevel["key"], IShiftIncentiveLevel>;
  toDelete: Record<IShiftIncentiveLevel["key"], IShiftIncentiveLevel>;
  recordsInDb: Record<IShiftIncentiveLevel["key"], IShiftIncentiveLevel>;
};

export const ShiftIncentiveLevelActionsInitState: TShiftIncentiveLevelState = {
  toUpdate: {},
  toCreate: {},
  toDelete: {},
  recordsInDb: {},
};

export const ShiftIncentiveLevelActions = {
  updateShiftIncentiveLevel: (
    { shiftIncentiveLevels }: THouseViewState,
    action: PayloadAction<IShiftIncentiveLevel>,
  ) => {
    if (shiftIncentiveLevels.toCreate[action.payload.key]) {
      shiftIncentiveLevels.toCreate[action.payload.key] = action.payload;
    } else {
      shiftIncentiveLevels.toUpdate[action.payload.key] = action.payload;
    }
  },
  updateShiftIncentiveLevelKey: (
    { shiftIncentiveLevels }: THouseViewState,
    action: PayloadAction<{
      current: IShiftIncentiveLevel["key"];
      new: IShiftIncentiveLevel["key"];
    }>,
  ) => {
    const currentShiftIncentiveLevel = shiftIncentiveLevels.toCreate[action.payload.current];
    if (!currentShiftIncentiveLevel) return;

    delete shiftIncentiveLevels.toCreate[action.payload.current];
    shiftIncentiveLevels.toCreate[action.payload.new] = {
      ...currentShiftIncentiveLevel,
      key: action.payload.new,
    };
  },
  duplicateShiftIncentiveLevel: (
    { shiftIncentiveLevels }: THouseViewState,
    action: PayloadAction<IShiftIncentiveLevel>,
  ) => {
    const key = `${action.payload.key} copy`;
    shiftIncentiveLevels.toCreate[key] = {
      ...action.payload,
      key,
      id: window.crypto.randomUUID(),
    };
  },
  createShiftIncentiveLevel: (
    { shiftIncentiveLevels }: THouseViewState,
    { payload }: PayloadAction<Partial<IShiftIncentiveLevel> & { unitId: IUnit["id"] }>,
  ) => {
    shiftIncentiveLevels.toCreate["newKeyToBeUpdated"] = {
      key: "newKeyToBeUpdated",
      label: "",
      color: "#000000" as THexColor,
      unitId: payload.unitId,
      id: window.crypto.randomUUID(),
    } as IShiftIncentiveLevel;
  },
  restoreShiftIncentiveLevel: (
    { shiftIncentiveLevels }: THouseViewState,
    action: PayloadAction<IShiftIncentiveLevel>,
  ) => {
    delete shiftIncentiveLevels.toUpdate[action.payload.key];
  },
  deleteShiftIncentiveLevel: (
    { shiftIncentiveLevels }: THouseViewState,
    action: PayloadAction<IShiftIncentiveLevel>,
  ) => {
    if (shiftIncentiveLevels.toCreate[action.payload.key]) {
      delete shiftIncentiveLevels.toCreate[action.payload.key];
      return;
    } else {
      shiftIncentiveLevels.toDelete[action.payload.key] = action.payload;
    }
  },

  setDbShiftIncentiveLevels: (
    { shiftIncentiveLevels }: THouseViewState,
    action: PayloadAction<KeyBy<IShiftIncentiveLevel, "key">>,
  ) => {
    shiftIncentiveLevels.recordsInDb = action.payload;
  },

  cancelAllShiftIncentiveLevelChanges: ({ shiftIncentiveLevels }: THouseViewState) => {
    shiftIncentiveLevels.toCreate = {};
    shiftIncentiveLevels.toUpdate = {};
    shiftIncentiveLevels.toDelete = {};
  },
};
