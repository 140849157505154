import { YyyyMmDd } from "@m7-health/shared-utils";
import { PayloadAction } from "@reduxjs/toolkit";

import { TStoreSlice } from "~/store";

import { IStaffShift, IUser } from "@/api";
import { KeyBy } from "@/common/types";

import { THolidayScheduleState } from ".";

export type TStaffShiftsState = {
  // Anything related to state updates (store to save, undo, redo, etc.)
  updates: {
    cleanOnNextRefetch?: boolean;

    redoHistory: { from: IStaffShift | null; to: IStaffShift | null }[];
    undoHistory: { from: IStaffShift | null; to: IStaffShift | null }[];

    // Latest data about an updated staff shift. That's the object we use when we save the data
    byStaffShiftId: { [staffShiftId in IStaffShift["id"]]: IStaffShift | null };
  };

  // StaffShifts, per user per day
  stateStaffShifts: {
    [staffId in IUser["id"]]: {
      [date in YyyyMmDd]: IStaffShift[];
    };
  };

  // Copy of staff shifts that we receive from the server, so we can compare which one is dirty
  //  and which one is not, to know if it's update, delete or create
  shiftsInDb: KeyBy<IStaffShift, "id">;
};

export const StaffShiftsInitState: TStaffShiftsState = {
  updates: {
    redoHistory: [],
    undoHistory: [],
    byStaffShiftId: {},
  },
  shiftsInDb: {},
  stateStaffShifts: {},
};

export const StaffShiftsActions = {
  // update staff shift by staff shift id
  // should work with multiple shifts per day
  // from means the current value of the shift
  // to means the new value of the shift
  updateStaffShift: (
    { staffShifts: { updates } },
    {
      payload,
    }: PayloadAction<
      // "delete"
      | { from: IStaffShift; to: null }
      // update
      | { from: IStaffShift; to: Partial<IStaffShift> }
      // create
      | { from: null; to: IStaffShift }
    >,
  ) => {
    updates.redoHistory = [];

    const updatesHistory = updates.undoHistory;
    const newValue =
      payload.to === null
        ? null
        : payload.from === null
          ? payload.to
          : { ...payload.from, ...payload.to };

    updatesHistory.push({
      from: payload.from,
      to: newValue,
    });

    const staffShiftId = (payload.from?.id || payload.to?.id)!;

    updates.byStaffShiftId[staffShiftId] = newValue;
  },

  updateStaffShifts: (
    { staffShifts: { updates } },
    {
      payload,
    }: PayloadAction<
      (
        | { from: IStaffShift; to: null } // "delete"
        | { from: IStaffShift; to: Partial<IStaffShift> } // update
        | { from: null; to: IStaffShift } // create
      )[]
    >,
  ) => {
    updates.redoHistory = [];

    const updatesHistory = updates.undoHistory;
    payload.forEach((update) => {
      const newValue =
        update.to === null
          ? null
          : update.from === null
            ? update.to
            : { ...update.from, ...update.to };

      updatesHistory.push({
        from: update.from,
        to: newValue,
      });

      const staffShiftId = (update.from?.id || update.to?.id)!;

      updates.byStaffShiftId[staffShiftId] = newValue;
    });
  },

  undoLastMove: ({ staffShifts: { updates } }) => {
    const updatesHistory = updates.undoHistory;
    const lastUpdate = updatesHistory.pop();
    if (!lastUpdate) return;

    updates.redoHistory.push(lastUpdate);

    const { from, to } = lastUpdate;
    if (from === null && to?.id) {
      delete updates.byStaffShiftId[to.id];
      return;
    }

    if (to === null && from?.id) {
      delete updates.byStaffShiftId[from.id];
      return;
    }

    const staffShiftId = (lastUpdate.from?.id || lastUpdate.to?.id)!;
    updates.byStaffShiftId[staffShiftId] = lastUpdate.from;
  },

  redoLastMove: ({ staffShifts: { updates } }) => {
    const redoHistory = updates.redoHistory;
    const lastUpdate = redoHistory.pop();
    if (!lastUpdate) return;

    updates.undoHistory.push(lastUpdate);

    const { from, to } = lastUpdate;
    if (from === null && to?.id) {
      updates.byStaffShiftId[to.id] = to;
      return;
    }

    if (to === null && from?.id) {
      updates.byStaffShiftId[from.id] = null;
      return;
    }

    const staffShiftId = (lastUpdate.from?.id || lastUpdate.to?.id)!;
    updates.byStaffShiftId[staffShiftId] = lastUpdate.to;
  },

  cancelChanges: ({ staffShifts: { updates } }) => {
    updates.undoHistory = [];
    updates.redoHistory = [];
    updates.byStaffShiftId = {};
  },

  updateShiftsInDb: (
    { staffShifts },
    { payload }: PayloadAction<TStaffShiftsState["shiftsInDb"]>,
  ) => {
    staffShifts.shiftsInDb = payload;
  },

  updateStateStaffShifts: (
    { staffShifts },
    { payload }: PayloadAction<TStaffShiftsState["stateStaffShifts"]>,
  ) => {
    staffShifts.stateStaffShifts = payload;
  },
} satisfies TStoreSlice<THolidayScheduleState>;
