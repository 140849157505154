import { createSlice } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { createFilter } from "redux-persist-transform-filter";

import { Dayjs, localDayJs } from "~/common/packages/dayjs";

import { ISchedule, StaffCategory } from "@/api";

import { IOpenShiftsMetadataPerSchedule, TOpenShiftsShiftTypeFilter } from "../types";

import { initialState } from "./initialState";

import type { PayloadAction } from "@reduxjs/toolkit";

export const openShifts = createSlice({
  name: "openShifts",
  initialState,
  reducers: {
    resetOpenShiftState: () => initialState,
    setShiftTypeFilter: (state, action: PayloadAction<TOpenShiftsShiftTypeFilter>) => {
      state.shiftTypeFilter = action.payload;
    },
    setDate: (state, action: PayloadAction<Dayjs>) => {
      state.date = localDayJs(action.payload);
    },
    setSelectedStaffCategory: (state, action: PayloadAction<StaffCategory.EKey>) => {
      state.selectedStaffCategory = action.payload;
    },
    setSelectedDayKey: (state, action: PayloadAction<string | null>) => {
      state.selectedDayKey = action.payload;
    },
    setIsCreateOpenShiftModalOpen: (state, action: PayloadAction<boolean>) => {
      state.createOpenShiftsModal.isOpen = action.payload;
    },
    setIsCreateBulkOpenShiftModalOpen: (state, action: PayloadAction<boolean>) => {
      state.createBulkOpenShiftsModal.isOpen = action.payload;
    },
    setIsDeleteOpenShiftModalOpen: (state, action: PayloadAction<boolean>) => {
      state.deleteOpenShiftModal.isOpen = action.payload;
    },
    setAvailableSchedules: (state, action: PayloadAction<ISchedule[]>) => {
      state.availableSchedules = action.payload;
    },
    setSelectedScheduleId: (state, action: PayloadAction<string>) => {
      state.selectedScheduleId = action.payload;
    },
    setIsApproveOpenShiftModalOpen: (state, action: PayloadAction<boolean>) => {
      state.approveOpenShiftModal.isOpen = action.payload;
    },
    setIsRejectOpenShiftModalOpen: (state, action: PayloadAction<boolean>) => {
      state.rejectOpenShiftModal.isOpen = action.payload;
    },
    setMetadataOfAllOpenShifts: (state, action: PayloadAction<IOpenShiftsMetadataPerSchedule>) => {
      state.metadataOfAllOpenShifts = action.payload;
    },
  },
});

export const {
  resetOpenShiftState,
  setShiftTypeFilter,
  setDate,
  setSelectedStaffCategory,
  setSelectedDayKey,
  setIsCreateOpenShiftModalOpen,
  setIsCreateBulkOpenShiftModalOpen,
  setAvailableSchedules,
  setSelectedScheduleId,
  setIsDeleteOpenShiftModalOpen,
  setIsApproveOpenShiftModalOpen,
  setIsRejectOpenShiftModalOpen,
  setMetadataOfAllOpenShifts,
} = openShifts.actions;

const openShiftsPersistFilter = createFilter("openShifts", [
  "selectedDayKey",
  "shiftTypeFilter",
  "selectedStaffCategory",
]);

const persistConfig = {
  key: "openShifts",
  storage,
  transform: [openShiftsPersistFilter],
};

export default persistReducer(persistConfig, openShifts.reducer);
