import { useAuth0 } from "@auth0/auth0-react";
import { datadogRum } from "@datadog/browser-rum";
import * as Sentry from "@sentry/react";

// eslint-disable-next-line deprecate/import -- It's ok to use it here
import { useAnalytics } from "~/common/utils/analytics";

export const useLogout = () => {
  const { logout: auth0logout } = useAuth0();
  const analytics = useAnalytics();

  const logout = () => {
    analytics.logout();
    datadogRum.stopSession();
    datadogRum.clearUser();
    Sentry.setUser(null);
    return auth0logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });
  };

  return {
    logout,
  };
};
