import { useCallback } from "react";

import { Box } from "@mui/material";

import { white } from "~/common/theming/colors";
import { useAppTracking } from "~/modules/mixpanel/Context";
import { Mxp } from "~/modules/mixpanel/types";

import { TabStyled, TabsContainerStyled, TabsStyled } from "./Tabs.styled";
import { ICustomTabsProps } from "./types";

const defaultSx = {};

export const CustomTabs = <TabsEnum,>({
  children,
  onChange,
  onTabClick,
  pillTabs = false,
  grey = false,
  tabs,
  value,
  width = "100%",
  sx = defaultSx,
  fullColor = "unset",
  variant = undefined,
  height,
  stickyTop = false,
  className,
  tracking = false,
}: ICustomTabsProps<TabsEnum>) => {
  const track = useAppTracking();

  const onChangeWrapper = useCallback(
    (event: React.SyntheticEvent, newVal: TabsEnum) => {
      event.stopPropagation();
      if (tracking) {
        track(Mxp.Event.elementClicked, {
          [Mxp.Property.element.type]: "Tab",
          [Mxp.Property.element.label]: String(newVal),
        });
      }
      onChange?.(newVal);
    },
    [onChange, track, tracking],
  );

  return (
    <Box sx={{ width, ...sx }} className={className}>
      <TabsContainerStyled
        $pillTabs={pillTabs}
        $grey={grey}
        {...(stickyTop
          ? { sx: { backgroundColor: white, position: "sticky", top: 0, zIndex: 1 } }
          : {})}
      >
        <TabsStyled
          indicatorColor={pillTabs ? ("" as any) : "primary"}
          onChange={onChangeWrapper}
          value={value}
          variant={variant}
          sx={{
            ...(height
              ? {
                  height,
                  minHeight: height,
                }
              : {}),
          }}
          $pillTabs={pillTabs}
        >
          {tabs.map(
            ({ label, value: tabValue, sx: tabSx, className: tabClassName = "" }, index) => (
              <TabStyled
                onClick={(_event) => onTabClick?.(tabValue)}
                className={["custom-tab", value === tabValue ? "selected" : "", tabClassName].join(
                  " ",
                )}
                label={label}
                value={tabValue}
                key={index}
                $pillTabs={pillTabs}
                $fullColor={
                  ((tabSx && "backgroundColor" in tabSx && tabSx.backgroundColor) as string) ||
                  fullColor
                }
                sx={{
                  ...(height
                    ? {
                        height,
                        minHeight: height,
                      }
                    : {}),
                  ...(tabSx || {}),
                }}
              />
            ),
          )}
        </TabsStyled>
      </TabsContainerStyled>
      {stickyTop ? <Box sx={{ overflowY: "auto" }}>{children}</Box> : <>{children}</>}
    </Box>
  );
};
