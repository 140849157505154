import { FC } from "react";

import { Paid } from "@mui/icons-material";
import { SvgIconProps, Tooltip } from "@mui/material";

import { IShiftIncentiveLevel } from "@/api";
import { NOT_EXISTING_UUID } from "@/common/constants";
import { useUnitShiftIncentiveLevels } from "@/common/hooks";
import { black } from "@/common/theming";

export const ShiftIncentiveLevelIcon: FC<
  SvgIconProps & {
    shiftIncentiveLevel?: IShiftIncentiveLevel;
    shiftIncentiveLevelId?: IShiftIncentiveLevel["id"];
    unitId?: IShiftIncentiveLevel["unitId"];
    labelOnHover?: boolean;
  }
> = ({
  labelOnHover,
  unitId,
  shiftIncentiveLevelId,
  shiftIncentiveLevel,
  sx,
  className,
  ...otherProps
}) => {
  const storeShiftIncentiveLevel =
    useUnitShiftIncentiveLevels(unitId).byId[shiftIncentiveLevelId || NOT_EXISTING_UUID];
  shiftIncentiveLevel ||= storeShiftIncentiveLevel;

  if (!shiftIncentiveLevel) return null;

  const label = shiftIncentiveLevel.label;

  const icon = (
    <Paid
      sx={{
        ...(sx || {}),
        color: black,
        ":hover": { color: shiftIncentiveLevel.color },
        transition: "color 0.1s ease-in-out",
      }}
      className={(className || "") + " m7-shift-incentive-level"}
      {...otherProps}
    />
  );

  if (labelOnHover)
    return <Tooltip placement="top" arrow title={`Incentive: ${label}`} children={icon} />;

  return icon;
};
