import { Unit as UnitSharedUtils } from "@m7-health/shared-utils";

import { Timezone } from "~/common/types";
import { IFacility } from "~/routes/api/types";

import { APIElementResponse, APIListResponse, SDTO } from "../_shared/types";
import { IAttribute } from "../attribute";
import { IUser, User } from "../user";

export type IUnit = Unit.DTO;

export namespace Unit {
  export interface DTO extends SDTO.base, SDTO.withSortPosition, SDTO.withTimestamps {
    name: string;
    scheduleDurationInWeeks: number;
    timezone: Timezone;
    facility: IFacility;
    facilityId: IFacility["id"];
    weekendDays: number[];
    configuration: UnitSharedUtils.TConfiguration;
    staffingLevelMatrix: UnitSharedUtils.TStaffingLevelMatrix;
    userIds?: IUser["id"][];
    attributes?: IAttribute[];
  }

  export interface ISchedulerDto {
    email: string;
    emailVerified: boolean;
    firstName: string;
    id: string;
    lastName: string;
    userType: User.ERole.scheduler;
    phoneNumber: string;
  }

  export namespace API {
    export const ROOT_PATH = "/units";
    export namespace List {
      export const PATH = ROOT_PATH;
      export interface QueryParams {
        facilityId?: IFacility["id"];
      }

      export type Response = APIListResponse<DTO>;
    }

    export namespace Update {
      export const PATH = (params: QueryParams) => `${ROOT_PATH}/${params.id}`;

      export type QueryParams = {
        id: DTO["id"];
        configuration?: DTO["configuration"];
        staffingLevelMatrix?: DTO["staffingLevelMatrix"];
        // Keep adding below...
      };

      export type Response = APIElementResponse<DTO>;
    }

    export namespace ListSchedulers {
      export const PATH = (params: QueryParams) =>
        `${ROOT_PATH}/${params.facilityId}/roster/schedulers`;

      export type QueryParams = {
        facilityId: IFacility["id"];
        skip?: number;
        take?: number;
        suspended?: boolean[];
      };
    }
  }
}
