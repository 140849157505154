import { useCallback, useEffect, useMemo } from "react";
import { useSearchParams } from "react-router-dom";

import { YyyyMmDd } from "@m7-health/shared-utils";

import { useAppDispatch, useAppSelector } from "~/common/hooks/useRedux";
import { localDayJs } from "~/common/packages/dayjs";
import { getDateInIsoFormat } from "~/common/utils/dates";

import { houseViewStore } from "../store";

/**
 * This hook manages the selected day for the House View.
 * It handles interactions between query params, redux store, and provides a setter function.
 *
 * The hook deals with two concepts of selected date:
 * 1. selectedDateForData: The date for which data is being displayed.
 * 2. selectedDay (from query params): The date selected in the date picker.
 *
 * In regular day view, these will always be the same.
 * In multi-week view, they may differ.
 *
 * The date from the query params takes precedence for the UI (date picker),
 * while the redux store (selectedDateForData) is authoritative for data display.
 *
 * When no date is provided, it defaults to today.
 *
 * @returns {Object} An object containing:
 *   - dataValue: {YyyyMmDd} The selected date for data display (from redux store)
 *   - setSelectedDay: {Function} Function to update the selected day
 *   - datePickerValue: {YyyyMmDd | undefined} The date shown in the date picker (from query params)
 */
const useSelectedDay = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useAppDispatch();

  const today = getDateInIsoFormat(localDayJs()) as YyyyMmDd;
  const selectedDayFromQuery =
    (searchParams.get("selectedDay") as YyyyMmDd | undefined) || undefined;
  const selectedDayInState = useAppSelector(
    (state) => state.houseView.pageFilters.selectedDateForData,
  );

  const setSelectedDay = useCallback(
    (day: YyyyMmDd) => {
      // if the day is different from the one in the query params, update the query params
      if (selectedDayFromQuery !== day)
        setSearchParams((otherParams) => {
          otherParams.set("selectedDay", day);
          return otherParams;
        });
      // if the day is different from the one in the redux store, update the redux store (will update both selectedDate and selectedDateForData)
      if (selectedDayInState !== day) dispatch(houseViewStore.state.selectDateForData(day));
    },
    [selectedDayFromQuery, setSearchParams, selectedDayInState, dispatch],
  );

  // if one is missing, use the other, or today
  const retrieveSelectedDay = (setIfNull = false) => {
    let result: YyyyMmDd;

    if (!selectedDayInState || !selectedDayFromQuery) {
      if (setIfNull) {
        if (selectedDayInState) setSelectedDay(selectedDayInState);
        else if (selectedDayFromQuery) setSelectedDay(selectedDayFromQuery);
        else setSelectedDay(today);
      }

      result = selectedDayInState || selectedDayFromQuery || today;
    } else if (selectedDayInState !== selectedDayFromQuery) {
      // if they are different, use the one from the STATE
      setIfNull && setSelectedDay(selectedDayInState);
      result = selectedDayInState;
    } else {
      // All good!
      result = selectedDayFromQuery;
    }

    return result;
  };

  const selectedDateValue = retrieveSelectedDay();
  // Set the selected day on mount, no dependencies needed
  useEffect(() => {
    retrieveSelectedDay(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return useMemo(
    () => ({
      dataValue: selectedDateValue,
      setSelectedDay,
      datePickerValue: selectedDayFromQuery || selectedDateValue || today,
    }),
    [selectedDateValue, setSelectedDay, selectedDayFromQuery, today],
  );
};

export const HouseView_hooks_useSelectedDay = useSelectedDay;
