/* eslint-disable no-console */

import { getEnvironment } from "./getEnvironment";

export const cl = (data: any, { stringify = false, clearBefore = false } = {}): void => {
  if (getEnvironment() === "production") {
    return;
  }

  if (clearBefore) {
    console.clear();
  }
  const dataToLog = stringify ? JSON.stringify(data, undefined, 2) : data;
  console.log("----------");
  console.log(dataToLog);
  console.log("----------");
};

export const css = (strings: TemplateStringsArray, ...values: any[]): string => {
  return strings.raw.reduce((acc, str, i) => acc + str + (values[i] || ""), "");
};
