import { useCallback, useMemo } from "react";

import { createColumnHelper } from "@tanstack/react-table";
import sortBy from "lodash/sortBy";

import { localDayJs } from "~/common/packages/dayjs";
import { cellsMetadata, nameCellsMetadata } from "~/features/Fairness/constants";
import { TScheduleDetails, TStaffDetails } from "~/features/Fairness/PreferencesHonored/types";

import HeaderCell from "../../components/HeaderCells/HeaderCell";
import HeaderNameCell from "../../components/HeaderCells/HeaderNameCell";
import Cell from "../Cells/Cell";
import NameCell from "../Cells/NameCell";

const columnHelper = createColumnHelper<TStaffDetails>();

export const useColumns = (schedules: TScheduleDetails[]) => {
  const sortedSchedules = sortBy(schedules, ["startDay"]);
  const { startDay: overallStartDay } = sortedSchedules.at(0) || {};
  const { endDay: overallEndDay } = sortedSchedules.at(-1) || {};

  const getDynamicColumns = useCallback(() => {
    const columns = schedules.map((schedule: TScheduleDetails) => {
      const { averageScore, startDay, endDay, id, staffs } = schedule;

      return columnHelper.accessor(id as any, {
        header: () => (
          <HeaderCell startDay={startDay} endDay={endDay} averageScore={`${averageScore}%`} />
        ),
        cell: (info) => {
          const { id: staffId } = info.row.original;
          const staff = staffs.find(({ id: aStaffId }) => aStaffId === staffId);
          const { honoredPreferences, allNonEmptyDays, allPreferences, score } =
            (staff as TStaffDetails) || {};
          const useFairnessForCell = allNonEmptyDays !== undefined;
          const shiftCount = useFairnessForCell ? allNonEmptyDays : allPreferences;
          // We don't store the number of fair shifts in the database,
          // so we have to calculate it back
          const fairShifts = useFairnessForCell
            ? Math.round((score / 100) * shiftCount)
            : honoredPreferences;

          if (!staff) {
            return;
          }

          return <Cell preferencesHonored={fairShifts} allPreferences={shiftCount} score={score} />;
        },
        meta: cellsMetadata,
      });
    });

    return columns || [];
  }, [schedules]);

  const columns = useMemo(
    () =>
      [
        columnHelper.accessor("id", {
          header: () => (
            <HeaderNameCell
              startDate={localDayJs(overallStartDay)}
              endDate={localDayJs(overallEndDay)}
            />
          ),
          cell: (info) => {
            const { averageScore, firstName, lastName } = info.row.original;

            return <NameCell firstName={firstName} lastName={lastName} score={averageScore!} />;
          },
          meta: nameCellsMetadata,
        }),
      ].concat(getDynamicColumns()),
    [getDynamicColumns, overallStartDay, overallEndDay],
  );

  return { columns };
};
