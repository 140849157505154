import { useMutation, useQueryClient } from "@tanstack/react-query";

import CustomModal from "~/common/components/Modal";
import { useErrors } from "~/common/hooks/useErrors";
import { useAppDispatch, useAppSelector } from "~/common/hooks/useRedux";
import { useToast } from "~/common/hooks/useToast";

import { restoreAccount } from "../api";
import { setIsRestoredModalOpen } from "../store";

const RestoreUserModal = () => {
  const dispatch = useAppDispatch();
  const { handleErrors } = useErrors();
  const queryClient = useQueryClient();
  const { showSuccess } = useToast();

  const { id, name } = useAppSelector((state) => state.roster.restoreAccountModal.data);
  const { isOpen } = useAppSelector((state) => state.roster.restoreAccountModal);

  const onError = (error: any) => handleErrors(error);

  const onSettled = () => {
    queryClient.invalidateQueries({ queryKey: ["roster"] });
  };

  const { mutate, isPending: isLoading } = useMutation({
    mutationFn: restoreAccount,
    onSuccess: () => {
      dispatch(setIsRestoredModalOpen(false));
      showSuccess("Restored user successfully");
    },
    onError,
    onSettled,
  });

  const confirmSuspension = () => {
    mutate({ id });
  };

  const handleSecondaryBtnClick = () => {
    dispatch(setIsRestoredModalOpen(false));
  };

  const variantText = (
    <>
      This user will be added back to the roster with all related data. Their status will <br /> be
      inactive. Please change the status manually to active.
    </>
  );

  const variantTitle = (
    <>
      Are you sure you want to restore <strong>{name}</strong>?
    </>
  );

  return (
    <CustomModal
      isOpen={isOpen}
      primaryBtnText="Restore"
      modalHeaderText="Restore account"
      onSecondaryBtnClick={handleSecondaryBtnClick}
      onSubmit={confirmSuspension}
      primaryDisabled={isLoading}
      variant="warning"
      variantText={variantText}
      variantTitle={variantTitle}
    />
  );
};

export default RestoreUserModal;
