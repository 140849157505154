import { INote } from "~/api/note";

// All versions of event payloads should be defined here
type TPayloadV1 = {
  note: Pick<INote, "id" | "scheduleId" | "content" | "userId" | "date">;
  updatedFields: {
    content: {
      from: string;
      to: string;
    };
  };
};

export namespace NoteUpdated {
  export enum EVersions {
    "v1" = "1",
  }

  export type Payloads = TPayloadV1;
  export type PayloadByVersion = {
    [EVersions.v1]: TPayloadV1;
  };
}
