import React, { FC } from "react";

import { Cancel, Check, CheckCircle, Help, PriorityHigh, QuestionMark } from "@mui/icons-material";
import { SvgIconProps } from "@mui/material";

import { darkGray, darkGreen, darkRed } from "@/common/theming";
import "./CheckChip.scss";

const OkChip: FC<
  SvgIconProps & {
    inverted?: boolean;
  }
> = React.forwardRef((props, ref) => {
  const { className, sx, ...otherProps } = props;
  const Component = props.inverted ? Check : CheckCircle;
  return (
    <Component
      ref={ref}
      sx={{ color: darkGreen, ...(sx || {}) }}
      {...otherProps}
      className={"ok " + (className || " ")}
    />
  );
});

const KoChip: FC<
  SvgIconProps & {
    inverted?: boolean;
  }
> = React.forwardRef((props, ref) => {
  const { className, sx, ...otherProps } = props;
  const Component = props.inverted ? PriorityHigh : Cancel;
  return (
    <Component
      ref={ref}
      sx={{ color: darkRed, ...(sx || {}) }}
      {...otherProps}
      className={"ko " + (className || "")}
    />
  );
});

const UndefinedChip: FC<
  SvgIconProps & {
    inverted?: boolean;
  }
> = React.forwardRef((props, ref) => {
  const { className, sx, ...otherProps } = props;
  const Component = props.inverted ? QuestionMark : Help;
  return (
    <Component
      ref={ref}
      sx={{ color: darkGray, ...(sx || {}) }}
      {...otherProps}
      className={"undefined " + (className || "")}
    />
  );
});

export const CheckChip: FC<
  SvgIconProps & {
    status: boolean | undefined;
    inverted?: boolean;
  }
> = React.forwardRef((props, ref) => {
  const { status, ...otherProps } = props;
  otherProps["className"] ||= "";
  otherProps["className"] += " m7-check-chip ";
  if (props.inverted) otherProps["className"] += " inverted ";
  if (props.onClick) otherProps["className"] += " clickable ";

  if (status === true) return <OkChip {...otherProps} ref={ref} />;
  else if (status === false) return <KoChip {...otherProps} ref={ref} />;
  else return <UndefinedChip {...otherProps} ref={ref} />;
});
