import { buildApiMutationHook } from "../_shared/helpers";

import { PreferencesTemplate } from "./types";

export const useCreatePreferencesTemplateMutation = buildApiMutationHook<
  PreferencesTemplate.API.Create.QueryParams,
  PreferencesTemplate.API.Create.Response
>(PreferencesTemplate.API.Create.PATH, "post");

export const useUpdatePreferencesTemplateMutation = buildApiMutationHook<
  PreferencesTemplate.API.Update.QueryParams,
  PreferencesTemplate.API.Update.Response
>(PreferencesTemplate.API.Update.PATH, "patch");

export const useDeletePreferencesTemplateMutation = buildApiMutationHook<
  PreferencesTemplate.API.Delete.QueryParams,
  PreferencesTemplate.API.Delete.Response
>(PreferencesTemplate.API.Delete.PATH, "delete");

export const useApplyPreferencesTemplateMutation = buildApiMutationHook<
  PreferencesTemplate.API.Apply.QueryParams,
  PreferencesTemplate.API.Apply.Response
>(PreferencesTemplate.API.Apply.PATH, "post");
