import { memo } from "react";

import { Tooltip, Typography } from "@mui/material";

import { OVERTIME_IN_HOURS } from "~/common/constants";
import { black, redAlert, white } from "~/common/theming/colors";
import { TSx, emptySx } from "~/common/types";

const emptyPopOverOptions = {} as const;

export const WorkingHoursTag = memo(
  ({
    workingHours,
    sx = emptySx,
    popOverOptions = emptyPopOverOptions,
    overtimeInHours = OVERTIME_IN_HOURS,
    className = "",
  }: {
    workingHours: number;
    sx?: TSx;
    overtimeInHours?: number;
    popOverOptions?: { offset?: [number, number] };
    className?: string;
  }) => {
    return (
      <Tooltip
        placement="bottom"
        arrow
        slotProps={{
          popper: {
            modifiers: [
              {
                name: "offset",
                options: popOverOptions,
              },
            ],
          },
        }}
        title={
          <Typography sx={{ fontSize: "15px", color: white }}>
            Working time this week: {workingHours}H<br />
            {workingHours - overtimeInHours > 0 ? (
              <>Overtime: {workingHours - overtimeInHours}H</>
            ) : (
              <>Available Time: {overtimeInHours - workingHours}H</>
            )}
          </Typography>
        }
      >
        <Typography
          className={`${className} m7-working-hours-tag`}
          sx={{
            color: workingHours >= overtimeInHours ? redAlert : black,
            fontSize: "12px",
            fontWeight: 500,
            position: "relative",
            ...sx,
          }}
        >
          {workingHours}H
        </Typography>
      </Tooltip>
    );
  },
);
