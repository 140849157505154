import { useToast } from "~/common/hooks/useToast";
import { isCustomError } from "~/common/types";

export const useErrors = () => {
  const { showError } = useToast();

  const handleErrors = (error: any, setError?: any) => {
    if (isCustomError(error)) {
      const { statusCode, message } = error;

      if (statusCode === 400 && Array.isArray(message) && setError) {
        message.forEach(({ field, error }) => {
          setError(field, { message: error });
          return;
        });
      }
      if (typeof message === "string") {
        showError(message);
        return;
      }
    } else if (typeof error?.message === "string") {
      showError(error.message);
    }
  };

  return { handleErrors };
};
