import { buildApiMutationHook } from "../_shared/helpers";

import { PreferenceRequirementRuleSet } from "./types";

export const useCreatePreferenceRequirementRuleSetMutation = buildApiMutationHook<
  PreferenceRequirementRuleSet.API.Create.QueryParams,
  PreferenceRequirementRuleSet.API.Create.Response
>(PreferenceRequirementRuleSet.API.Create.PATH, "post");

export const useUpdatePreferenceRequirementRuleSetMutation = buildApiMutationHook<
  PreferenceRequirementRuleSet.API.Update.QueryParams,
  PreferenceRequirementRuleSet.API.Update.Response
>(PreferenceRequirementRuleSet.API.Update.PATH, "patch");

export const useDeletePreferenceRequirementRuleSetMutation = buildApiMutationHook<
  PreferenceRequirementRuleSet.API.Delete.QueryParams,
  PreferenceRequirementRuleSet.API.Delete.Response
>(PreferenceRequirementRuleSet.API.Delete.PATH, "delete");
