import { memo, useEffect, useMemo } from "react";

import { YyyyMmDd } from "@m7-health/shared-utils";
import { isEqual, round } from "lodash";

import { Box, Grid, Tooltip, Typography } from "@mui/material";

import { Schedule, StaffCategory } from "~/api";

import { returnStaffedColorForTarget } from "#/features/HouseView/hooks/useStaffingTabs";
import { useAppSelector, useCurrentFacility } from "@/common/hooks";
import { Dayjs } from "@/common/packages/dayjs";
import { black, lightGray, lines, seaShell, white } from "@/common/theming";
import { TimeStringToStandardTime, trimMs } from "@/common/utils/dates";

import { CELL_DIMENSIONS } from "../constants";
import { useSynchronizedHorizontalScroll } from "../hooks/useSynchronizedHorizontalScroll";
import { TableStyleContainer } from "../style";
import { ITablesData } from "../types";

import { HeaderCellContainer, ShiftTypeWrapper } from "./HeaderCell.styled";

export const TotalCountHeader = memo(
  ({
    datesRows,
    formattedDateRows,
    schedules,
    tablesData,
  }: {
    datesRows: Dayjs[];
    formattedDateRows: YyyyMmDd[];
    schedules: Schedule.DTO[];
    tablesData: ITablesData;
  }) => {
    /** HIGH LEVEL STATE */
    const {
      unitIdsFilter,
      selectedShowTargetLevels,
      selectedStaffCategory,
      selectedDate,
      selectedCustomTimeRange,
    } = useAppSelector(
      (state) => ({
        unitIdsFilter: state.houseView.pageFilters.unitIds,
        selectedShowTargetLevels: state.houseView.pageFilters.showTargetLevels,
        selectedStaffCategory: state.houseView.pageFilters.selectedStaffCategory,
        selectedDate: state.houseView.pageFilters.selectedDateForData,
        selectedCustomTimeRange: state.houseView.pageFilters.customTimeRange,
      }),
      isEqual,
    );
    const facilityConfig = useCurrentFacility()?.configuration;

    // only show the total shift counts for the 6 week view if the facility setting is enabled
    const showSixWeekTotalShiftCount = facilityConfig?.settings?.showSixWeekViewTotalCount;
    const sixWeekTargetLevelsForStaffCategory =
      facilityConfig?.data?.facilityTimeTargetLevelsByStaff?.[
        selectedStaffCategory || StaffCategory.EKey.nurse
      ];

    const timeTargetLevelsToShow = useMemo(() => {
      // Filter time ranges to only include those that match customTimeRange exactly if it's not "All"
      // Else, show all six week target levels
      const filteredRanges =
        selectedCustomTimeRange && selectedCustomTimeRange.customAbbreviation !== "All"
          ? sixWeekTargetLevelsForStaffCategory?.filter(
              (range) =>
                trimMs(range.startTime) === trimMs(selectedCustomTimeRange.startTime) &&
                trimMs(range.endTime) === trimMs(selectedCustomTimeRange.endTime),
            )
          : sixWeekTargetLevelsForStaffCategory;
      return filteredRanges || [];
    }, [sixWeekTargetLevelsForStaffCategory, selectedCustomTimeRange]);

    const { setupSyncScrolls, cleanupSyncScrolls } = useSynchronizedHorizontalScroll();

    useEffect(() => {
      setupSyncScrolls();
      return () => cleanupSyncScrolls();
    }, [setupSyncScrolls, cleanupSyncScrolls]);

    return (
      <Box
        mt={3}
        boxSizing="border-box"
        marginBottom="-30px"
        display="flex"
        flexDirection="column"
        sx={{ position: "sticky", top: 0, zIndex: 1000, mb: 2 }}
        className="six-week-schedules-header"
      >
        <TableStyleContainer className="multi-weeks-sync-scroll header">
          <table
            className={`scheduler-table mini-view v2`}
            style={{ tableLayout: "fixed", borderCollapse: "separate", borderSpacing: 0 }}
          >
            <tbody>
              <tr>
                {/* Corner cell */}
                <th className="sticky-col sticky-corner">
                  <Grid container flexDirection="column">
                    <Grid
                      container
                      alignItems="center"
                      wrap="nowrap"
                      sx={{
                        width: "300px",
                        height: "55px",
                      }}
                      item
                      justifyContent="flex-end"
                    ></Grid>
                    <Grid
                      container
                      item
                      flexDirection="column"
                      flexWrap="nowrap"
                      sx={{
                        borderTop: `1px solid ${lines}`,
                      }}
                    >
                      {showSixWeekTotalShiftCount &&
                        timeTargetLevelsToShow.map((aRange) => (
                          <Grid
                            sx={{
                              height: CELL_DIMENSIONS.height,
                              background: lightGray,
                              borderTop: `${1}px solid ${lines}`,
                              borderBottom: `0px solid ${lines}`,
                            }}
                            container
                            alignItems="center"
                          >
                            <Typography pl={2} fontSize="0.75rem" fontWeight="400">
                              {aRange?.customAbbreviation ||
                                TimeStringToStandardTime(aRange.startTime)}
                            </Typography>
                          </Grid>
                        ))}
                    </Grid>
                  </Grid>
                </th>

                {/* Add a column for each date */}
                {datesRows.map((cellDate, index) => {
                  const formattedDate = formattedDateRows[index] || cellDate.format("YYYY-MM-DD");

                  const classNames = [];
                  if (cellDate.day() === 6) classNames.push("sunday");
                  classNames.push(formattedDate);

                  return (
                    <th key={formattedDate} className={classNames.join(" ")}>
                      <HeaderCellContainer
                        flexDirection="column"
                        alignItems="flex-start"
                        sx={{
                          height: "55px",
                          opacity: 1,
                          width: CELL_DIMENSIONS.width,
                          background: selectedDate === formattedDate ? seaShell : white,
                          color: black,
                        }}
                      >
                        <Box
                          sx={{
                            paddingTop: "3px",
                            borderTop: "1px solid white",
                            fontWeight: 500,
                            lineHeight: "24px",
                            flexDirection: "column",
                          }}
                        >
                          <Typography
                            align="center"
                            variant="small"
                            display="block"
                            sx={{
                              marginBottom: "-7px",
                              marginTop: "-7px",
                              fontSize: "13px",
                              color: "black",
                            }}
                          >
                            {/* use no-break-space character \u00A0 to make sure display="block" is still applied */}
                            {cellDate.format("DD") === "01" ? cellDate.format("MMM") : "\u00A0"}
                          </Typography>
                          <Typography
                            align="center"
                            variant="small"
                            display="block"
                            sx={{
                              marginBottom: "-7px",
                              color: "black",
                            }}
                          >
                            {cellDate.format("DD")}
                          </Typography>
                          <Typography
                            align="center"
                            variant="small"
                            display="block"
                            sx={{
                              color: "black",
                            }}
                          >
                            {cellDate.format("dd")}
                          </Typography>
                        </Box>
                        {showSixWeekTotalShiftCount && (
                          <Grid>
                            {timeTargetLevelsToShow.map((aRange, idx) => {
                              // find all schedules with this daykey that is between startDate and endDate
                              const dayKey = cellDate.format("YYYY-MM-DD");
                              const filteredSchedules = schedules.filter(
                                ({ startDay, endDay, unitId }) =>
                                  startDay <= dayKey &&
                                  endDay >= dayKey &&
                                  (unitIdsFilter.length === 0 || unitIdsFilter.includes(unitId)),
                              );

                              //add up the total number of shifts across all schedules for this day and time range
                              const total = filteredSchedules.reduce((acc, schedule) => {
                                const shiftsByDayByRange = tablesData?.[schedule?.id || ""];
                                const rangeKey = `${aRange.startTime}-${aRange.endTime}`;
                                return acc + (shiftsByDayByRange?.[dayKey]?.[rangeKey] || 0);
                              }, 0);
                              const count = round(total, 1);
                              const minCount = timeTargetLevelsToShow?.[idx]?.min;
                              const backgroundColor = minCount
                                ? returnStaffedColorForTarget(count, minCount)
                                : "white";

                              return (
                                <Grid
                                  key={`${dayKey}-${aRange.startTime + aRange.endTime}`}
                                  container
                                  alignItems="center"
                                  justifyContent="space-around"
                                  sx={{
                                    borderTop: `1px solid ${lines}`,
                                    height: CELL_DIMENSIONS.height,
                                    background: backgroundColor,
                                    borderBottom: `0px solid ${lines}`,
                                    borderRight: `1px solid ${lines}`,
                                  }}
                                >
                                  <ShiftTypeWrapper>
                                    <Tooltip
                                      title={`Staffed: ${count}${selectedShowTargetLevels && minCount ? ` / Target: ${minCount}` : ""}`}
                                    >
                                      <Typography
                                        sx={{
                                          fontSize: "0.7rem",
                                          color: black,
                                          px: 1,
                                        }}
                                      >
                                        {count.toFixed(0)}
                                      </Typography>
                                    </Tooltip>
                                  </ShiftTypeWrapper>
                                </Grid>
                              );
                            })}
                          </Grid>
                        )}
                      </HeaderCellContainer>
                    </th>
                  );
                })}
              </tr>
            </tbody>
          </table>
        </TableStyleContainer>
      </Box>
    );
  },
);
