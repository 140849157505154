import { ISODateString } from "@m7-health/shared-utils";

import { IStaffShift } from "~/api/staffShift";

import { Uuid } from "@/common/types";

// All versions of event payloads should be defined here
type TPayloadV1 = {
  staffShift: Pick<
    IStaffShift,
    | "id"
    | "scheduleId"
    | "attributes"
    | "customDuration"
    | "customStartTime"
    | "isWorkingAway"
    | "shiftTypeKey"
    | "staffId"
    | "scheduleType"
    | "status"
  > & {
    date: ISODateString;
  };
  origin: TStaffShiftOrigin;
};

type TStaffShiftOrigin =
  | {
      type: "bulk-save-action";
      id?: never;
    }
  | {
      type: "approved-drop-shift-request";
      id: Uuid;
    }
  | {
      type: "approved-time-off-request";
      id: Uuid;
    }
  | {
      type: "deactivated-staff";
      id?: never;
    }
  | {
      type: "home-unit-change";
      id?: never;
    };

export namespace StaffShiftDeleted {
  export enum EVersions {
    "v1" = "1",
  }

  export type StaffShiftOrigin = TStaffShiftOrigin;
  export type Payloads = TPayloadV1;
  export type PayloadByVersion = {
    [EVersions.v1]: TPayloadV1;
  };
}
