import { useMemo } from "react";

import { useCurrentFacilityId } from "~/common/hooks";
import { useCustomSelectShiftsForUnit } from "~/common/hooks/useShiftsForUnit";
import { Uuid } from "~/common/types";
import { IStaffAttributeCustomSelect, IUnitCustomSelect } from "~/routes/api/types";
import { useAllStaffAttributesQuery, useUnitQuery } from "~/routes/queries";

import { useAppConfigQuery } from "#/features/User/queries";
import {
  useListPreferenceRequirementRuleSetsQuery,
  useListPreferencesTemplatesQuery,
  useListSchedulersQuery,
} from "@/api";
import { NOT_EXISTING_UUID } from "@/common/constants";

const emptyUnits: IUnitCustomSelect[] = [];
const emptyStaffAttributes: IStaffAttributeCustomSelect[] = [];

/**
 * Custom hook to fetch and memoize data required for the Staff Data Form.
 *
 * @param {Uuid | undefined} homeUnitId - The ID of the home unit, or undefined if not available.
 * @returns {object} An object containing:
 * - units: Array of units fetched from the useUnitQuery hook.
 * - schedulerData: Data of schedulers fetched from the useListSchedulersQuery hook.
 * - allStaffAttributes: Array of all staff attributes fetched from the useAllStaffAttributesQuery hook.
 * - shiftOptions: Options for shifts fetched from the useCustomSelectShiftsForUnit hook.
 * - ruleSets: Rule sets fetched from the useListPreferenceRequirementRuleSetsQuery hook.
 * - preferencesTemplates: Preferences templates fetched from the useListPreferencesTemplatesQuery hook.
 * - staffTypes: Staff types fetched from the useAppConfigQuery hook.
 */
export const useStaffDataFormQueries = (homeUnitId: Uuid | undefined) => {
  const { data: units = emptyUnits } = useUnitQuery();

  const facilityId = useCurrentFacilityId();

  const { data: schedulerData } = useListSchedulersQuery(
    {
      facilityId: facilityId || NOT_EXISTING_UUID,
    },
    { skip: !facilityId },
  );
  const { data: allStaffAttributes = emptyStaffAttributes } = useAllStaffAttributesQuery();

  const shiftOptions = useCustomSelectShiftsForUnit();

  const { data: ruleSets } = useListPreferenceRequirementRuleSetsQuery(
    { unitIds: [homeUnitId || NOT_EXISTING_UUID] },
    { skip: !homeUnitId },
  );

  const { data: preferencesTemplates } = useListPreferencesTemplatesQuery(
    { unitIds: [homeUnitId || NOT_EXISTING_UUID] },
    { skip: !homeUnitId },
  );

  const staffTypes = useAppConfigQuery().data?.staffTypes;

  return useMemo(() => {
    return {
      units,
      schedulerData,
      allStaffAttributes,
      shiftOptions,
      ruleSets,
      preferencesTemplates,
      staffTypes,
    };
  }, [
    units,
    schedulerData,
    allStaffAttributes,
    shiftOptions,
    ruleSets,
    preferencesTemplates,
    staffTypes,
  ]);
};
