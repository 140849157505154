import { Grid, Typography } from "@mui/material";

import Checkbox from "~/common/components/TrackedComponents/Checkbox";
import { useAppDispatch, useAppSelector } from "~/common/hooks/useRedux";
import { IUnitBasic } from "~/routes/api/types";

import {
  setEditSettingsModalSelectedEmailRosterIds,
  setEditSettingsModalSelectedRosterIdsByEntityOption,
  setEditSettingsModalSelectedSMSRosterIds,
} from "#/features/Roster/store";
import { Roster } from "@/api";
import { lightGray } from "@/common/theming/colors";

export const NotificationsUnitRow = ({
  unit,
  rosterEntry,
}: {
  unit: IUnitBasic;
  rosterEntry: Roster.DTO;
}) => {
  const dispatch = useAppDispatch();
  const selectedSMSRosterIds = useAppSelector(
    (state) => state.roster.editSettingsModal.selectedSMSRosterIds,
  );
  const selectedEmailRosterIds = useAppSelector(
    (state) => state.roster.editSettingsModal.selectedEmailRosterIds,
  );
  const selectedRosterIdsByEntityOption = useAppSelector(
    (state) => state.roster.editSettingsModal.selectedRosterIdsByEntityOption,
  );
  const emailSelected = selectedEmailRosterIds.includes(rosterEntry.id);
  const smsSelected = selectedSMSRosterIds.includes(rosterEntry.id);

  return (
    <tr key={"row-" + unit.id}>
      <td key={`unitName for unitId ${unit.id}`}>
        <Grid container minWidth={"200px"} p={1}>
          <Typography display="flex" alignContent={"center"} justifyContent={"center"} noWrap>
            {unit.name}
          </Typography>
        </Grid>
      </td>

      <td key={`email settings for unitId ${unit.id}`}>
        <Grid
          container
          display={"flex"}
          justifyContent={"center"}
          sx={{ backgroundColor: lightGray }}
        >
          <Checkbox
            trackingLabel="emailCheckbox"
            checked={emailSelected}
            onChange={() => {
              !emailSelected
                ? dispatch(
                    setEditSettingsModalSelectedEmailRosterIds([
                      ...selectedEmailRosterIds,
                      rosterEntry.id,
                    ]),
                  )
                : dispatch(
                    setEditSettingsModalSelectedEmailRosterIds(
                      selectedEmailRosterIds.filter((id) => id !== rosterEntry.id),
                    ),
                  );
            }}
          />
        </Grid>
      </td>
      <td key={`sms settings for unitId ${unit.id}`} className={"thick"}>
        <Grid
          container
          display={"flex"}
          justifyContent={"center"}
          sx={{ backgroundColor: lightGray }}
        >
          <Checkbox
            trackingLabel="smsCheckbox"
            checked={smsSelected}
            onChange={() => {
              !smsSelected
                ? dispatch(
                    setEditSettingsModalSelectedSMSRosterIds([
                      ...selectedSMSRosterIds,
                      rosterEntry.id,
                    ]),
                  )
                : dispatch(
                    setEditSettingsModalSelectedSMSRosterIds(
                      selectedSMSRosterIds.filter((id) => id !== rosterEntry.id),
                    ),
                  );
            }}
          />
        </Grid>
      </td>
      {Object.values(Roster.ENotificationEntityOption).map((entityOption) => {
        const rosterIdsOfEntitySelected = selectedRosterIdsByEntityOption[entityOption];
        if (!rosterIdsOfEntitySelected) {
          dispatch(
            setEditSettingsModalSelectedRosterIdsByEntityOption({
              ...selectedRosterIdsByEntityOption,
              [entityOption]: [],
            }),
          );
          return <></>;
        }
        return (
          <td key={`entityOption ${entityOption} for unitId ${unit.id} with`}>
            <Grid container display={"flex"} justifyContent={"center"}>
              <Checkbox
                trackingLabel="entityOptionCheckbox"
                checked={rosterIdsOfEntitySelected?.includes(rosterEntry.id)}
                onChange={() => {
                  dispatch(
                    setEditSettingsModalSelectedRosterIdsByEntityOption({
                      ...selectedRosterIdsByEntityOption,
                      [entityOption]: !rosterIdsOfEntitySelected.includes(rosterEntry.id)
                        ? [...rosterIdsOfEntitySelected, rosterEntry.id]
                        : rosterIdsOfEntitySelected.filter((id) => id !== rosterEntry.id),
                    }),
                  );
                }}
                key={`entityOption ${entityOption} for unitId ${unit.id} with`}
                disabled={!emailSelected && !smsSelected}
              />
            </Grid>
          </td>
        );
      })}
    </tr>
  );
};
