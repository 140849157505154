import { useMemo } from "react";

import { List, map, sortBy, uniq } from "lodash";

/** This hook is a memoized wrapper around lodash's map function that allows you to map an array of objects by a key.
 * It also allows you to sort the results and remove duplicates.
 *
 * @example results = useMapBy(users, "id", { uniq: true, sortBy: ["firstName", "lastName"] });
 */
export const useMapBy = <T, K extends keyof T>(
  data: List<T> | null | undefined,
  key: K,
  options: {
    uniq?: boolean;
    sortBy?: Parameters<typeof sortBy>[1];
  } = {
    uniq: false,
    sortBy: undefined,
  },
) =>
  useMemo(() => {
    let results = map(data, key);
    if (options.uniq) results = uniq(results);
    if (options.sortBy) results = sortBy(results, options.sortBy);
    return results;
  }, [data, key, options.sortBy, options.uniq]);
