import { useDrag } from "react-dnd";

import { TableCellProps } from "@mui/material";

export const useCellDrag = (
  cell: TableCellProps,
  type: string,
  canDrag?: (cell: TableCellProps) => boolean,
) => {
  const [, drag, preview] = useDrag(
    () => ({
      type,
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
      item: cell,
      canDrag: canDrag ? () => canDrag(cell) : true,
    }),
    [cell],
  );

  return { drag, preview };
};
