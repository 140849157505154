import { useMemo } from "react";

import { List, groupBy } from "lodash";

/** This hook is a memoized wrapper around lodash's groupBy function.
 *
 * @example results = useGroupBy(scheduleShiftType, "scheduleId");
 */
export const useGroupBy = <T, K extends keyof T>(data: List<T> | null | undefined, key: K) =>
  useMemo(() => groupBy(data, key), [data, key]);
