import { StaffingLevelsHeaderRow } from "./TableParts/_HeaderRow";
import { StaffingLevelsRow } from "./TableParts/_StaffingLevelsRow";
import { StaffingLevelsRows } from "./TableParts/_StaffingLevelsRows";
import { StaffingLevelsNewEntryRow } from "./TableParts/StaffingLevelsNewEntryRow";

export const TableParts = {
  HeaderRow: StaffingLevelsHeaderRow,
  Rows: StaffingLevelsRows,
  Row: StaffingLevelsRow,
  NewEntryRow: StaffingLevelsNewEntryRow,
};
