import { useEffect } from "react";
import { useForm } from "react-hook-form";

import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { object } from "yup";

import { Grid } from "@mui/material";

import { CustomModal } from "~/common/components";
import { CustomInputControlled } from "~/common/components/Input";
import { useAppDispatch, useAppSelector, useToast } from "~/common/hooks";
import { useErrors } from "~/common/hooks/useErrors";
import { black, lightGray } from "~/common/theming/colors";
import { pxToRem } from "~/common/utils/pxToRem";
import { stringShapeRequired } from "~/common/validation/shapes";
import { editStaffNote } from "~/features/Roster/api";

import { setIsNoteModalOpen, setNoteModalData } from "../../store";

interface INoteFormValues {
  note: string;
}

const NoteModal = () => {
  const dispatch = useAppDispatch();
  const { showSuccess } = useToast();
  const { handleErrors } = useErrors();
  const queryClient = useQueryClient();

  const isAddVariant = useAppSelector((state) => state.roster.noteModal.variant === "add");
  const isNoteModalOpen = useAppSelector((state) => state.roster.noteModal.isOpen);
  const { firstName, id, lastName, note } = useAppSelector((state) => state.roster.noteModal.data);

  const {
    control,
    formState: { isValid },
    getValues,
    reset,
    watch,
  } = useForm<INoteFormValues>({
    mode: "onChange",
    resolver: yupResolver(object().shape({ note: stringShapeRequired })),
  });

  useEffect(() => {
    if (!isNoteModalOpen) {
      dispatch(setNoteModalData({}));
      reset();
    }
  }, [dispatch, isNoteModalOpen, reset]);

  useEffect(() => {
    reset({
      note,
    });
  }, [isAddVariant, note, reset]);

  const { mutate, isPending: isLoading } = useMutation({
    mutationFn: editStaffNote,
    onSuccess: () => {
      dispatch(setNoteModalData({}));
      dispatch(setIsNoteModalOpen(false));
      showSuccess(isAddVariant ? "Added note successfully" : "Updated note successfully");
    },
    onError: handleErrors,
    onSettled: () => {
      void queryClient.invalidateQueries({ queryKey: ["roster"] });
    },
  });

  const onSecondaryBtnClick = () => {
    dispatch(setIsNoteModalOpen(false));
    dispatch(setNoteModalData({}));
  };

  const onSubmit = () => {
    const { note: formNote } = getValues();

    // trim the note to remove any extra spaces
    const args = { id, note: formNote.trim() };

    mutate(args);
  };

  const currentNote = watch("note");
  const hasNoteChanged = currentNote !== note;

  const modalContent = (
    <Grid container display="block">
      <Grid item>
        <CustomInputControlled
          control={control}
          label="Note"
          name="note"
          multiline
          rows={2}
          maxLength={500}
          sx={[
            {
              "& .Mui-disabled": {
                "-webkit-text-fill-color": black,
                backgroundColor: lightGray,
              },
            },
            {
              "& .MuiInputBase-inputMultiline": { fontSize: pxToRem(14), lineHeight: pxToRem(17) },
            },
          ]}
        />
      </Grid>
    </Grid>
  );

  return (
    <CustomModal
      modalContent={modalContent}
      modalHeaderText={`${firstName || ""} ${lastName || ""} note`}
      onSecondaryBtnClick={onSecondaryBtnClick}
      onSubmit={onSubmit}
      primaryBtnText={isAddVariant ? "Add Note" : "Save Changes"}
      primaryDisabled={(!isValid && isAddVariant) || isLoading || !hasNoteChanged}
      secondaryBtnText={"Cancel"}
      isOpen={isNoteModalOpen}
    />
  );
};

export default NoteModal;
