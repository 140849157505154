import { useEffect, useMemo } from "react";

import { ISODateString, TimeString, Timezone } from "@m7-health/shared-utils";
import { groupBy, isEqual, reduce } from "lodash";

import {
  RealTimeStaffingTarget,
  TRealTimeStaffingTargetToCreate,
  TRealTimeStaffingTargetToUpdate,
  useListRealTimeStaffingTargetsQuery,
} from "~/api/realTimeStaffingTargets";

import { useAppConfigQuery } from "#/features/User/queries";
import { IUnit, useInvalidateQuery } from "@/api";
import {
  useAppDispatch,
  useAppSelector,
  useCurrentTimezone,
  useDeepMemo,
  useMapBy,
} from "@/common/hooks";
import { localDayJs } from "@/common/packages/dayjs";
import { add24Hours, dateStringInTimeRangeGivenTimezone, getTzDayjs } from "@/common/utils/dates";

import { houseViewStore } from "../store";
import { DEFAULT_CUSTOM_TIME_RANGE } from "../store/pageFiltersActions";

export const emptyStaffingTargetsArray: RealTimeStaffingTarget.DTO[] = [];

/** Set staffing targets data for the whole house view page
 * Fetches and set data in the store:
 * - staffingTargets
 */
export const useSetStaffingTargets = () => {
  // High -level
  const dispatch = useAppDispatch();
  const invalidateQuery = useInvalidateQuery();

  // States
  const {
    selectedUnitId,
    selectedFacilityId,
    selectedDate,
    selectedCustomTimeRangeState,
    isStaffingTargetLevelModalOpen,
  } = useAppSelector(
    ({ houseView: { pageFilters, staffingLevels } }) => ({
      selectedUnitId: pageFilters.selectedUnitId,
      selectedFacilityId: pageFilters.selectedFacilityId!,
      selectedDate: pageFilters.selectedDateForData!,
      selectedCustomTimeRangeState: pageFilters.customTimeRange,
      isStaffingTargetLevelModalOpen: staffingLevels.modalIsOpen!,
    }),
    isEqual,
  );
  const selectedCustomTimeRange = selectedCustomTimeRangeState || DEFAULT_CUSTOM_TIME_RANGE;
  const currentTimezone = useCurrentTimezone(selectedUnitId);

  const appConfig = useAppConfigQuery()?.data;
  const units = useMemo(
    () => appConfig?.accessibleUnits.filter((unit) => unit.facilityId === selectedFacilityId),
    [appConfig, selectedFacilityId],
  );
  const timezoneByUnitsId = useDeepMemo(
    () =>
      reduce(
        units,
        (acc, unit) => ({ ...acc, [unit.id]: unit.timezone as Timezone }),
        {} as Record<IUnit["id"], Timezone>,
      ),
    [units],
  );
  const unitIds = useMapBy(units, "id");

  // get the time range
  const startTimeDateString =
    selectedDate &&
    getTzDayjs(selectedDate, currentTimezone)
      .add(parseInt(selectedCustomTimeRange.startTime.split(":")[0] || ""), "hours")
      .add(parseInt(selectedCustomTimeRange.startTime.split(":")[1] || ""), "minutes")
      .add(parseInt(selectedCustomTimeRange.startTime.split(":")[2] || ""), "seconds")
      .toISOString();

  // Adjust end time if it's before start time (e.g., for overnight shifts)
  // so that when we add the time to the date, it's the next day
  const adjustedEndTime =
    selectedCustomTimeRange.endTime < selectedCustomTimeRange.startTime
      ? add24Hours(selectedCustomTimeRange.endTime)
      : selectedCustomTimeRange.endTime;

  const endTimeDateString =
    selectedDate &&
    selectedCustomTimeRange &&
    getTzDayjs(selectedDate, currentTimezone)
      .add(parseInt(adjustedEndTime.split(":")[0] || ""), "hours")
      .add(parseInt(adjustedEndTime.split(":")[1] || ""), "minutes")
      .add(parseInt(adjustedEndTime.split(":")[2] || ""), "seconds")
      .toISOString();

  /** All queries */
  const { data: staffingTargets } = useListRealTimeStaffingTargetsQuery(
    {
      unitIds: unitIds,
      latest: false,
      date: [
        { value: startTimeDateString, operator: "gte" },
        { value: endTimeDateString, operator: "lt" },
      ],
    },
    { skip: !startTimeDateString || !endTimeDateString },
  );

  // invalidate the query when the modal is closed
  useEffect(() => {
    invalidateQuery(useListRealTimeStaffingTargetsQuery);
  }, [invalidateQuery, isStaffingTargetLevelModalOpen]);

  // Dispatch staffing targets to the store
  // Do it whenever staffing targets change OR whenever the modal is open or closed OR whenever the time range changes
  useEffect(() => {
    // Aggregate staffing targets by unit
    const aggregatedTargets: Record<
      string,
      (TRealTimeStaffingTargetToCreate | TRealTimeStaffingTargetToUpdate)[]
    > = groupBy(staffingTargets, "unitId");
    // if the modal is open, also create a new default staffing target for each unit that doesn't have one
    // at the default time
    if (isStaffingTargetLevelModalOpen) {
      unitIds?.forEach((unitId) => {
        if (!aggregatedTargets[unitId] || aggregatedTargets[unitId]?.length === 0) {
          const unitTimezone = timezoneByUnitsId[unitId];
          if (!unitTimezone) return;

          // Create a dayjs object for the selected date in the unit's timezone
          const selectedDateInUnitTz = getTzDayjs(selectedDate, unitTimezone);

          // Get the current time in the unit's timezone
          const currentDateTimeInUnitTz = getTzDayjs(localDayJs(), unitTimezone);
          const currentTimeInUnitTz = currentDateTimeInUnitTz.format("HH:mm:ss") as TimeString;

          // Check if the current time is within the selected time range
          const isWithinRange = dateStringInTimeRangeGivenTimezone(
            selectedCustomTimeRange,
            currentDateTimeInUnitTz.toISOString() as ISODateString,
            unitTimezone,
          );

          // Use current time if within range, otherwise use selected start time
          let dateTimeToUse;
          if (isWithinRange) {
            const [hours, minutes, seconds]: [number, number, number] = currentTimeInUnitTz
              .split(":")
              .map(Number) as [number, number, number];
            dateTimeToUse = selectedDateInUnitTz.hour(hours).minute(minutes).second(seconds);
          } else {
            const [hours, minutes, seconds]: [number, number, number] =
              selectedCustomTimeRange.startTime.split(":").map(Number) as [number, number, number];
            dateTimeToUse = selectedDateInUnitTz.hour(hours).minute(minutes).second(seconds);
          }

          // Convert the final date and time to UTC
          const date = dateTimeToUse.utc().toISOString() as ISODateString;

          aggregatedTargets[unitId] = [
            {
              unitId: unitId,
              patientCount: -1,
              date,
            } as TRealTimeStaffingTargetToCreate,
          ];
        }
      });
    }

    dispatch(houseViewStore.state.setAllStaffingTargets(aggregatedTargets));
    dispatch(houseViewStore.state.setData({ staffingTargets: staffingTargets || [] }));
  }, [
    dispatch,
    isStaffingTargetLevelModalOpen,
    unitIds,
    selectedCustomTimeRangeState,
    staffingTargets,
    selectedCustomTimeRange,
    selectedDate,
    timezoneByUnitsId,
    currentTimezone,
  ]);
};
