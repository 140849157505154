import { TDateFilter, Uuid, dateString } from "~/common/types";
import { IUnitBasic } from "~/routes/api/types";

import { TPositionAsTab } from "#/features/HouseView/hooks/useStaffingTabs";
import { BULK_DELETE_KEY, BULK_UPDATE_KEY } from "@/common/constants";

import { APIListResponse } from "../_shared/types";
import { StaffCategory } from "../staffCategory";
import { User } from "../user";

export type IRealTimeStaffingTarget = RealTimeStaffingTarget.DTO;

export namespace RealTimeStaffingTarget {
  export interface DTO {
    id: Uuid;
    createdAt: dateString;
    createdBy: User.DTO;
    updatedAt: dateString;
    updatedBy: User.DTO;
    unitId: IUnitBasic["id"];
    patientCount: number;
    date: dateString;
    note?: string;
    staffingTarget?: TStaffingTarget;
  }

  // enum possible as key and then make partial to not require all enum values to be present
  export type TStaffingTarget = Partial<{
    [key in StaffCategory.EKey | TPositionAsTab]: number;
  }>;

  export namespace API {
    export const ROOT_PATH = "/real-time-staffing-target";
    export namespace List {
      export const PATH = ROOT_PATH;
      export interface QueryParams {
        unitIds?: IUnitBasic["id"][];
        latest?: boolean;
        date?: TDateFilter[];
      }

      export type Response = APIListResponse<DTO>;
    }

    export namespace Create {
      export const PATH = ROOT_PATH;

      export type QueryParams = {
        unitId: Uuid;
        patientCount?: number;
        note?: string;
        staffingTarget?: TStaffingTarget;
        date: dateString;
      };

      export interface Response {
        data: DTO;
      }
    }

    export namespace Update {
      export const PATH = (params: QueryParams) => `${ROOT_PATH}/${params.id}`;

      export type QueryParams = {
        id: DTO["id"];
        patientCount?: number;
        note?: string;
        date?: dateString;
      };

      export interface Response {
        data: DTO;
      }
    }

    export namespace Delete {
      export const PATH = (params: QueryParams) => `${ROOT_PATH}/${params.id}`;

      export type QueryParams = {
        id: DTO["id"];
      };
      export interface Response {
        data: DTO;
      }
    }
  }
}

// of this type means to create
export type TRealTimeStaffingTargetToCreate = Pick<
  RealTimeStaffingTarget.DTO,
  "unitId" | "patientCount" | "note" | "staffingTarget" | "date"
>;

// of this type means:
// if have _update true, to update;
// if _update not provided or false, then to not update
export type TRealTimeStaffingTargetToUpdate = RealTimeStaffingTarget.DTO & {
  [BULK_UPDATE_KEY]?: boolean;
};

// of this type means to delete
export type TRealTimeStaffingTargetToDelete = RealTimeStaffingTarget.DTO & {
  [BULK_DELETE_KEY]: boolean;
};
