export enum ESelectedFileVariant {
  Default = "default",
  Highlighted = "highlighted",
}

export interface SelectedFileProps {
  fileName: string;
  path: string;
  variant?: ESelectedFileVariant;
}
