import { find, inRange } from "lodash";

import {
  bad,
  blockShift,
  dayShift,
  equal,
  excellent,
  green,
  lightGreen,
  lightOrange,
  satisfactory,
} from "~/common/theming/colors";

export const fairnessScores = {
  great: {
    min: 90,
    max: 100.00001,
    color: green,
  },
  well: {
    min: 81,
    max: 90,
    color: lightGreen,
  },
  average: {
    min: 72,
    max: 81,
    color: dayShift,
  },
  poor: {
    min: 63,
    max: 72,
    color: lightOrange,
  },
  bad: {
    min: 0,
    max: 63,
    color: blockShift,
  },
};

export const getFairnessColor = (fairness: number): string =>
  find(fairnessScores, (fairnessScore) => inRange(fairness, fairnessScore.min, fairnessScore.max))
    ?.color || "transparent";

export const getFairnessToAverageColor = (count: number, average: number) => {
  if (count === average) {
    return equal;
  } else if (count < average) {
    return bad;
  } else {
    return excellent;
  }
};

export const getFairnessToRequiredColor = (count: number, required: number) => {
  if (count === required) {
    return excellent;
  } else if (count < required) {
    return bad;
  } else {
    return satisfactory;
  }
};

// checks how a count of beginner staff relates to a schedule average and returns the appropriate color
export const getFairnessToBeginnerColor = (count: number, average: number) => {
  if (count === average) {
    return equal;
  } else if (count < average) {
    return excellent;
  } else {
    return bad;
  }
};
