import { _HouseView_FacilityPicker } from "./FacilityPicker";
import { _HouseView_Headers } from "./Headers";
import { _HouseView_Modals } from "./modals";
import { HouseViewMultiWeekTable } from "./MultiWeekHouseViewTable/Table";
import { _HouseView_SideBar } from "./SideBar/SideBar";
import { _HouseView_UnitCard } from "./UnitCard";
import { _HouseView_UnitCardStaff } from "./UnitCardStaff";

const HouseView = () => <></>;

HouseView.UnitCard = _HouseView_UnitCard;
HouseView.UnitCardStaff = _HouseView_UnitCardStaff;
HouseView.SideBar = _HouseView_SideBar;
HouseView.Modals = _HouseView_Modals;
HouseView.Headers = _HouseView_Headers;
HouseView.FacilityPicker = _HouseView_FacilityPicker;
HouseView.MultiWeekTable = HouseViewMultiWeekTable;

HouseView.SIDEBAR_WIDTH = "400px";

export { HouseView };

export { StaffItem } from "./StaffItem";
