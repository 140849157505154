/* eslint-disable @typescript-eslint/no-namespace */
import { BrandedAlias } from "@m7-health/shared-utils";

import { APIListResponse } from "../_shared/types";
import { IStaffCategory } from "../staffCategory";

export type IStaffType = StaffType.DTO;

export namespace StaffType {
  export type Key = BrandedAlias<string, "StaffTypeKey">;

  export interface DTO {
    key: Key;
    name: string;
    staffCategoryKey: IStaffCategory["key"];
    description?: string;
    usedForTargetLevel: boolean;
  }

  export namespace API {
    export const ROOT_PATH = "/staff-type";
    export namespace List {
      export const PATH = ROOT_PATH;
      export interface QueryParams {}

      export type Response = APIListResponse<DTO>;
    }

    export namespace BulkSave {
      export const PATH = ROOT_PATH + "/bulk-save";
      export interface QueryParams {
        staffTypes: (TStaffTypeToUpdate | TStaffTypeToCreate)[];
      }

      export type Response = APIListResponse<DTO>;
    }
  }
}

type TStaffTypeToUpdate = {
  key: IStaffType["key"];
} & Partial<UpdatableAttributes>;

type TStaffTypeToCreate = UpdatableAttributes;

type UpdatableAttributes = Pick<
  StaffType.DTO,
  "name" | "usedForTargetLevel" | "staffCategoryKey" | "description"
>;
