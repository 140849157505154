import { styled, Typography } from "@mui/material";

export const TooltipContainer = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  padding: theme.spacing(1),
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: theme.shape.borderRadius,
}));

export const TooltipText = styled(Typography)(({ theme }) => ({
  margin: theme.spacing(0.5, 0),
}));
