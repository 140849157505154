import { useEffect, useState } from "react";

import { ISODateString } from "@m7-health/shared-utils";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { isEqual } from "lodash";

import { Stack, Typography } from "@mui/material";

import CustomModal from "~/common/components/Modal";
import { useErrors } from "~/common/hooks/useErrors";
import { useAppDispatch, useAppSelector } from "~/common/hooks/useRedux";
import { useToast } from "~/common/hooks/useToast";
import { Dayjs } from "~/common/packages/dayjs";

import { CustomDatePicker, CustomSwitch } from "@/common/components";
import { useCurrentTimezone } from "@/common/hooks";
import { localDayJs } from "@/common/packages/dayjs";
import { getTzDayjs, getTzUSFormattedDate } from "@/common/utils/dates";

import { changeStaffStatus } from "../api";
import { closeUpdateStatusDateModal } from "../store";
import { EStaffStatus } from "../types";

const UpdateStatusDateModal = () => {
  const dispatch = useAppDispatch();
  const { handleErrors } = useErrors();
  const queryClient = useQueryClient();
  const { showSuccess } = useToast();
  const timezone = useCurrentTimezone();

  const { statusChangeModalState, modalData } = useAppSelector(
    (state) => ({
      statusChangeModalState: state.roster.updateStatusDateModal.isOpen,
      modalData: state.roster.updateStatusDateModal.data,
    }),
    isEqual,
  );
  const [timeline, setTimeline] = useState({
    newDate: true,
    cancel: false,
  });

  const [statusChangeDate, setStatusChangeDate] = useState<Dayjs | undefined>(undefined);
  useEffect(() => {
    if (!timezone || statusChangeDate || !modalData) return;
    setStatusChangeDate(getTzDayjs(modalData?.date, timezone));
  }, [timezone, statusChangeDate, modalData]);

  const today = localDayJs().tz(timezone).format("YYYY-MM-DD");
  const formattedDate = statusChangeDate?.format("YYYY-MM-DD") || "";

  const resetState = () => {
    setStatusChangeDate(undefined);
    setTimeline({ newDate: true, cancel: false });
  };
  const onSettled = () => {
    void queryClient.invalidateQueries({ queryKey: ["roster"] });
  };

  const { mutate: changeStaffStatusMutation, isPending: isChangeStaffStatusApiLoading } =
    useMutation({
      mutationFn: changeStaffStatus,
      onSuccess: () => {
        dispatch(closeUpdateStatusDateModal());
        showSuccess("Planned inactivity date updated successfully");
        resetState();
      },
      onError: handleErrors,
      onSettled,
    });

  const confirmStatusChange = () => {
    if (!modalData || !statusChangeDate) return;
    let params: Omit<Parameters<typeof changeStaffStatusMutation>[0], "staffId"> = {
      postDateStatusUpdateDate: statusChangeDate.toISOString() as ISODateString,
      postDateStatusUpdateType: "inactive",
      status: EStaffStatus.Active,
    };

    if (timeline.cancel)
      params = {
        postDateStatusUpdateDate: null,
        postDateStatusUpdateType: null,
        status: EStaffStatus.Active,
      };
    else if (formattedDate === today)
      params = {
        postDateStatusUpdateDate: null,
        postDateStatusUpdateType: null,
        status: EStaffStatus.Inactive,
      };

    changeStaffStatusMutation({
      staffId: modalData.id,
      ...params,
    });
  };

  const handleSecondaryBtnClick = () => {
    resetState();
    dispatch(closeUpdateStatusDateModal());
  };

  if (!modalData || !statusChangeModalState) return null;

  if (modalData.status === "suspended")
    return (
      <CustomModal
        isOpen={true}
        modalHeaderText="Status change"
        onSecondaryBtnClick={handleSecondaryBtnClick}
        primaryDisabled={true}
        modalContent={
          <Typography>
            Staff is already set to be suspended on{" "}
            <b>{getTzUSFormattedDate(modalData.date, timezone)}</b> and will be de-activated on this
            date too.
            <br />
            If you want to set a different date, please cancel the suspension first.
          </Typography>
        }
      />
    );

  const variantTitle = (
    <>
      <b>{modalData.name}</b> is set to be inactive on{" "}
      <b>{getTzUSFormattedDate(modalData.date, timezone)}</b>. You are able to update the date the
      user will be set to inactive, or cancel the status change below.
      <br />
      <br />
      <Stack direction="row" spacing={2}>
        {statusChangeDate && (
          <CustomDatePicker
            disabled={timeline.cancel}
            className="post-date-update"
            name="statusChangeDate"
            label="Status takes effect on:"
            value={statusChangeDate}
            disablePast
            onChange={(newDate) => {
              if (newDate) {
                setTimeline({ newDate: true, cancel: false });
                setStatusChangeDate(newDate.startOf("day"));
              }
            }}
            timezone={timezone}
          />
        )}
        <CustomSwitch
          name="toggle-cancel-status-change"
          label="Cancel status change"
          checked={timeline.cancel}
          switchVariant="checkbox"
          onChange={(value) => setTimeline({ newDate: !value, cancel: value })}
        />
      </Stack>
    </>
  );

  return (
    <CustomModal
      isOpen={true}
      primaryBtnText="Confirm"
      modalHeaderText="Status Change"
      onSecondaryBtnClick={handleSecondaryBtnClick}
      onSubmit={confirmStatusChange}
      primaryDisabled={isChangeStaffStatusApiLoading}
      variant="warning"
      variantText={
        timeline.cancel ? (
          "You are about to Cancel this status change."
        ) : formattedDate === today ? (
          <>
            Selected date is <b>today</b>. The status change will take effect immediately and will
            remove all of the staff's future shifts and preferences from current and future
            schedules.
          </>
        ) : undefined
      }
      variantTitle={variantTitle}
    />
  );
};

export default UpdateStatusDateModal;
