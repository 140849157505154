import { useEffect } from "react";

import { filter, isEqual, map, orderBy } from "lodash";

import { Add } from "@mui/icons-material";
import { Box, Divider, Typography } from "@mui/material";

import {
  TRealTimeStaffingTargetToCreate,
  TRealTimeStaffingTargetToUpdate,
  useCreateRealTimeStaffingTargetMutation,
  useDeleteRealTimeStaffingTargetMutation,
  useListRealTimeStaffingTargetsQuery,
  useUpdateRealTimeStaffingTargetMutation,
} from "~/api/realTimeStaffingTargets";
import CustomModal from "~/common/components/Modal";
import { useAppDispatch, useAppSelector } from "~/common/hooks/useRedux";
import { useToast } from "~/common/hooks/useToast";
import { localDayJs } from "~/common/packages/dayjs";
import {
  dateStringInTimeRangeGivenTimezone,
  getReadableDate,
  getTzDayjs,
  isSpecificHourInTimeString,
  TimeStringToStandardTime,
} from "~/common/utils/dates";
import { MixpanelProvider } from "~/modules/mixpanel/Provider";
import { Mxp } from "~/modules/mixpanel/types";
import { IUnitBasic } from "~/routes/api/types";

import { emptyStaffingTargetsArray } from "#/features/HouseView/hooks/useSetStaffingTargets";
import { DEFAULT_CUSTOM_TIME_RANGE } from "#/features/HouseView/store/pageFiltersActions";
import { useAppConfigQuery } from "#/features/User/queries";
import { IUnitConfig } from "#/features/User/types";
import { IStaffShift, useInvalidateQuery } from "@/api";
import { CustomButton } from "@/common/components";
import CustomSelect from "@/common/components/TrackedComponents/Select";
import { BULK_DELETE_KEY, BULK_UPDATE_KEY } from "@/common/constants";
import { useAppFlags, useCurrentTimezone, useKeyBy } from "@/common/hooks";
import { isOnMobile } from "@/common/utils/isOnMobile";

import { houseViewStore } from "../../../store";

import { getFinalTargetWithCorrectDate } from "./helpers";
import { UnitTable } from "./UnitTable/Table";

const emptyUnits: IUnitConfig[] = [];

const StaffingLevelModal = ({
  shiftsByUnit,
  numberOfUnitsWithNoStaffingTargets,
}: {
  shiftsByUnit: { [unitId: IUnitBasic["id"]]: IStaffShift[] };
  numberOfUnitsWithNoStaffingTargets: number;
}) => {
  /** HIGH LEVEL */
  const dispatch = useAppDispatch();
  const { staffingTargetLevelModalQueuesToInputPatientCount } = useAppFlags();
  const { showSuccess, showError } = useToast();
  const invalidateQuery = useInvalidateQuery();

  /** GENERAL STATE */
  const { selectedCustomTimeRangeState, selectedUnitId, selectedDate, allStaffingTargets } =
    useAppSelector(
      (state) => ({
        selectedCustomTimeRangeState: state.houseView.pageFilters.customTimeRange,
        selectedUnitId: state.houseView.pageFilters.selectedUnitId!,
        selectedDate: state.houseView.pageFilters.selectedDateForData!,
        allStaffingTargets: state.houseView.staffingLevels.allStaffingTargets,
      }),
      isEqual,
    );
  const selectedCustomTimeRange = selectedCustomTimeRangeState || DEFAULT_CUSTOM_TIME_RANGE;

  const selectedStaffingTargetsArray =
    allStaffingTargets[selectedUnitId] || emptyStaffingTargetsArray;
  const newCreatedStaffingTarget = selectedStaffingTargetsArray.find((target) => !("id" in target));
  const isOpen = useAppSelector((state) => Boolean(state.houseView.staffingLevels.modalIsOpen));

  /** QUERIES */
  const appConfigData = useAppConfigQuery().data;
  const units = appConfigData?.accessibleUnits || emptyUnits;
  const selectedUnit = units.find((unit) => unit.id === selectedUnitId);
  const selectedUnitName = selectedUnit?.name;

  // Time Range State
  // Get facility configuration
  const facility = selectedUnit?.facility;
  const facilityTimeRanges = facility?.configuration?.settings?.houseViewTimeRanges;

  // if on mobile, then always use the start time formatted label and never the custom abbreviation
  // else (on desktop), use the custom abbreviation and default to the start time formatted label if needed
  // filter out the time ranges that are not don't include 7am or 7pm (include if 7:30am / 7:30pm etc., that is ok)
  const facilityTimeRangesCustomSelect =
    facilityTimeRanges
      ?.filter((timeRange) => {
        return (
          (isSpecificHourInTimeString(timeRange.startTime, "7") ||
            isSpecificHourInTimeString(timeRange.endTime, "19") ||
            isSpecificHourInTimeString(timeRange.endTime, "7") ||
            isSpecificHourInTimeString(timeRange.startTime, "19")) &&
          timeRange.customAbbreviation !== "All"
        );
      })
      ?.map((timeRange) => ({
        label: isOnMobile()
          ? TimeStringToStandardTime(timeRange.startTime)
          : timeRange.customAbbreviation || TimeStringToStandardTime(timeRange.startTime),
        value: timeRange.startTime + "-" + timeRange.endTime,
        item: timeRange.startTime + "-" + timeRange.endTime,
      })) || [];

  /** STAFF CATEGORY QUERY */
  const unitStaffCategoryKeys = selectedUnit?.staffCategoryKeys;
  const staffCategories = appConfigData?.staffCategories;
  // memoize to prevent re-renders
  const categoryByKey = useKeyBy(staffCategories, "key");

  // set categories of unit once loaded
  useEffect(() => {
    const selectableStaffCategories = map(unitStaffCategoryKeys, (key) => ({
      key,
      name: categoryByKey[key]?.name || "",
    }));
    // Filter out the house supervisor category and order the rest by name
    const categories = orderBy(
      selectableStaffCategories.filter(({ key }) => key !== "houseSupervisor"),
      "name",
    );
    dispatch(houseViewStore.state.setSelectedUnitCategories(categories));
  }, [categoryByKey, dispatch, unitStaffCategoryKeys]);

  // once loaded... if the selectedCustomTimeRange is not in the facility time ranges, then set it to the first one
  useEffect(() => {
    if (isOpen) {
      if (
        !facilityTimeRangesCustomSelect?.find(
          ({ value: timeRangeString }) =>
            timeRangeString ===
            selectedCustomTimeRange.startTime + "-" + selectedCustomTimeRange.endTime,
        )
      ) {
        const firstTimeRange = facilityTimeRanges?.[0];
        if (firstTimeRange) {
          dispatch(houseViewStore.state.selectCustomTimeRange(firstTimeRange));
        }
      }
    }
    // don't want exhaustive deps, don't care to run this effect again if facilityTimeRanges changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [facilityTimeRangesCustomSelect, selectedCustomTimeRange, dispatch, isOpen]);

  // useEffect to check if it is midnight and open the modal
  useEffect(() => {
    const checkTimeAndOpenModal = () => {
      if (
        staffingTargetLevelModalQueuesToInputPatientCount &&
        numberOfUnitsWithNoStaffingTargets > 0
      ) {
        dispatch(houseViewStore.state.setStaffingLevelModalIsOpen(true));
      }
    };

    const scheduleNextMidnight = () => {
      const now = localDayJs();
      const nextMidnight = now.endOf("day").add(1, "second");
      const msUntilMidnight = nextMidnight.diff(now);

      return setTimeout(() => {
        checkTimeAndOpenModal();
        scheduleNextMidnight();
      }, msUntilMidnight);
    };

    const timeoutId = scheduleNextMidnight();

    // Clean up timeout on component unmount or when dependencies change
    return () => clearTimeout(timeoutId);
  }, [
    dispatch,
    staffingTargetLevelModalQueuesToInputPatientCount,
    numberOfUnitsWithNoStaffingTargets,
  ]);

  const currentTimezone = useCurrentTimezone(selectedUnitId);

  /** HELPERS / MODALS */
  //helper
  const closeModalAndDiscardChanges = () => {
    dispatch(houseViewStore.state.setStaffingLevelModalIsOpen(false));
    // discarding changes is done as part of the useSetStaffingTargets hook that is called on page load
  };

  const { mutateAsync: createRealTimeStaffingTarget, isPending: isCreatingRealTimeStaffingTarget } =
    useCreateRealTimeStaffingTargetMutation({});
  const { mutateAsync: updateRealTimeStaffingTarget, isPending: isUpdatingRealTimeStaffingTarget } =
    useUpdateRealTimeStaffingTargetMutation({});
  const { mutateAsync: deleteRealTimeStaffingTarget, isPending: isDeletingRealTimeStaffingTarget } =
    useDeleteRealTimeStaffingTargetMutation({});

  const submitStaffing = () => {
    // first: do synchronous checks of times
    // check if any staffing levels are not in the selected time range
    let numberOfStaffingTargetsNotInSelectedTimeRange = 0;
    selectedStaffingTargetsArray.forEach((staffingTargetPossiblyChanged) => {
      // if the staffing target is new (no id in the object), then check if it is in the selected time range
      if (!("id" in staffingTargetPossiblyChanged)) {
        if (
          !dateStringInTimeRangeGivenTimezone(
            selectedCustomTimeRange,
            staffingTargetPossiblyChanged.date,
            currentTimezone,
          )
        ) {
          numberOfStaffingTargetsNotInSelectedTimeRange++;
        }
      } else {
        // Check if we are deleting or updating (check deleting first, since could
        // have updated a target and then deleted it, should still delete it)
        if (
          BULK_DELETE_KEY in staffingTargetPossiblyChanged &&
          !!staffingTargetPossiblyChanged?.[BULK_DELETE_KEY]
        ) {
          // if the staffing target is being deleted, then do nothing since it is being deleted
        } else if (
          BULK_UPDATE_KEY in staffingTargetPossiblyChanged &&
          !!staffingTargetPossiblyChanged?.[BULK_UPDATE_KEY]
        ) {
          // if the staffing target is being updated, then check if it is in the selected time range
          if (
            !dateStringInTimeRangeGivenTimezone(
              selectedCustomTimeRange,
              staffingTargetPossiblyChanged.date,
              currentTimezone,
            )
          ) {
            numberOfStaffingTargetsNotInSelectedTimeRange++;
          }
        }
      }
    });
    // if any staffing levels are not in the selected time range, show an error
    // else if a new staffing level is being created and the patient count is not inputted, show an error
    // else, send all staffing levels to the API

    if (numberOfStaffingTargetsNotInSelectedTimeRange > 0) {
      showError(
        `${numberOfStaffingTargetsNotInSelectedTimeRange} staffing level(s) are not in the selected time range`,
      );
    } else if (newCreatedStaffingTarget && newCreatedStaffingTarget.patientCount < 0) {
      showError("Must input a patient count");
    } else {
      void (async () => {
        try {
          await Promise.all(
            selectedStaffingTargetsArray.map((staffingTargetPossiblyChanged) => {
              // if the staffing target is new (no id in the object), then create it
              if (!("id" in staffingTargetPossiblyChanged)) {
                return createRealTimeStaffingTarget(
                  getFinalTargetWithCorrectDate<TRealTimeStaffingTargetToCreate>(
                    staffingTargetPossiblyChanged,
                    selectedDate,
                    currentTimezone,
                  ),
                );
              } else {
                // Check if we are deleting or updating (check deleting first, since could
                // have updated a target and then deleted it, should still delete it)
                if (
                  BULK_DELETE_KEY in staffingTargetPossiblyChanged &&
                  !!staffingTargetPossiblyChanged?.[BULK_DELETE_KEY]
                ) {
                  // if the staffing target is deleted, then delete it
                  return deleteRealTimeStaffingTarget({
                    id: staffingTargetPossiblyChanged.id,
                  });
                } else if (
                  BULK_UPDATE_KEY in staffingTargetPossiblyChanged &&
                  !!staffingTargetPossiblyChanged?.[BULK_UPDATE_KEY]
                ) {
                  // if the staffing target is updated, then update it
                  return updateRealTimeStaffingTarget(
                    getFinalTargetWithCorrectDate<TRealTimeStaffingTargetToUpdate>(
                      staffingTargetPossiblyChanged,
                      selectedDate,
                      currentTimezone,
                    ),
                  );
                }
              }
              return Promise.resolve(true);
            }),
          );
          showSuccess("The staffing levels were updated successfully");
          invalidateQuery(useListRealTimeStaffingTargetsQuery);
          closeModalAndDiscardChanges();
        } catch (error) {
          // if error, show the error message
          showError("There was a problem updating the staffing levels. Please try again");
        }
      })();
    }
  };

  // general modal content
  const modalContent = (
    <Box
      sx={{
        overflow: "auto",
        maxHeight: "60vh",
      }}
    >
      <Box sx={{ mt: isOnMobile() ? 0 : 1 }}>
        <Typography variant="h6" mr={2} mb={2}>
          Date: {getReadableDate(getTzDayjs(selectedDate, currentTimezone))}
          <Box
            ml={isOnMobile() ? 0 : 2}
            mt={isOnMobile() ? 3 : 0}
            display={isOnMobile() ? "" : "inline"}
          >
            {/* Range picker */}
            <CustomSelect<string>
              className="time-range-select"
              label="Time range"
              width={"130px"}
              items={facilityTimeRangesCustomSelect}
              value={selectedCustomTimeRange.startTime + "-" + selectedCustomTimeRange.endTime}
              onChange={(event) => {
                const customTimeRangeBasedOnValue = facilityTimeRanges?.find(
                  (timeRange) =>
                    timeRange.startTime + "-" + timeRange.endTime === event.target.value,
                );
                if (!customTimeRangeBasedOnValue) return;
                dispatch(houseViewStore.state.selectCustomTimeRange(customTimeRangeBasedOnValue));
              }}
            />
          </Box>
        </Typography>
        <Divider />
        <Box mt={isOnMobile() ? 3 : 5} display="flex" alignItems="center">
          <Typography variant="small" fontWeight={600} fontSize={23} mr={2}>
            Unit: {selectedUnitName}
          </Typography>
          {/* if there is no staffing level being created, then show the button to add one if desired*/}
          {!newCreatedStaffingTarget && (
            <CustomButton
              onClick={() => {
                dispatch(houseViewStore.state.createStaffingLevel(currentTimezone));
              }}
              startIcon={<Add />}
              label="Add New Patient Count"
            />
          )}
        </Box>

        <UnitTable
          unitStaffingLevels={selectedStaffingTargetsArray || []}
          hasNewEntry
          shiftsByUnit={shiftsByUnit}
        />
      </Box>
      {filter(
        selectedStaffingTargetsArray.map((staffingTargetPossiblyChanged) => {
          // if the staffing target is new (no id in the object)
          if (!("id" in staffingTargetPossiblyChanged)) {
            return (
              <Typography key="new created staffing target">
                Creating new log entry for <b>{selectedUnitName || ""}</b> at{" "}
                <b>
                  {getTzDayjs(
                    localDayJs(staffingTargetPossiblyChanged?.date),
                    currentTimezone,
                  ).format("hh:mm A")}
                </b>
              </Typography>
            );
          } else {
            // Check if we are deleting or creating (check deleting first, since could
            // have updated a target and then deleted it, should still delete it)
            if (
              BULK_DELETE_KEY in staffingTargetPossiblyChanged &&
              !!staffingTargetPossiblyChanged?.[BULK_DELETE_KEY]
            ) {
              // if the staffing target is deleted
              return (
                <Typography key={`deleted staffing target id ${staffingTargetPossiblyChanged.id}`}>
                  Deleting log entry for <b>{selectedUnitName || ""}</b> at{" "}
                  <b>
                    {getTzDayjs(
                      localDayJs(staffingTargetPossiblyChanged?.date),
                      currentTimezone,
                    ).format("hh:mm A")}
                  </b>{" "}
                </Typography>
              );
            } else if (
              BULK_UPDATE_KEY in staffingTargetPossiblyChanged &&
              !!staffingTargetPossiblyChanged?.[BULK_UPDATE_KEY]
            ) {
              // if the staffing target is updated
              return (
                <Typography key={`updated staffing target id ${staffingTargetPossiblyChanged.id}`}>
                  Editing log entry for <b>{selectedUnitName || ""}</b> at{" "}
                  <b>
                    {getTzDayjs(
                      localDayJs(staffingTargetPossiblyChanged?.date),
                      currentTimezone,
                    ).format("hh:mm A")}
                  </b>{" "}
                </Typography>
              );
            }
          }
          return null;
        }),
      )}
    </Box>
  );

  // when hit Save... send all staffing levels to API, not just updated ones
  return (
    <MixpanelProvider properties={{ [Mxp.Property.layout.section]: "edit-staffing-level-modal" }}>
      <CustomModal
        classes={{ root: "house-view-modal" }}
        isOpen={isOpen}
        primaryBtnText="Save logs"
        modalContent={modalContent}
        modalHeaderText={`Input Patient Count`}
        onSecondaryBtnClick={closeModalAndDiscardChanges}
        onSubmit={submitStaffing}
        primaryDisabled={
          isCreatingRealTimeStaffingTarget ||
          isUpdatingRealTimeStaffingTarget ||
          isDeletingRealTimeStaffingTarget ||
          (newCreatedStaffingTarget && newCreatedStaffingTarget.patientCount < 0)
        }
        customWidth="1200px"
      />
    </MixpanelProvider>
  );
};

export const HouseViewStaffingLevelModal = StaffingLevelModal;
