import { useMemo } from "react";

export const useSynchronizedHorizontalScroll = () => {
  return useMemo(() => {
    const handleScroll = (event: Event) => {
      const scrolledElement = event.target as HTMLElement;
      const scrollLeft = scrolledElement.scrollLeft;

      // Find all scrollable elements with this class
      const scrollableElements = document.querySelectorAll(".multi-weeks-sync-scroll");

      // Update all other elements' scroll position
      scrollableElements.forEach((element) => {
        if (element !== scrolledElement) {
          (element as HTMLElement).scrollLeft = scrollLeft;
        }
      });
    };

    const setupSyncScrolls = () => {
      const scrollableElements = document.querySelectorAll(".multi-weeks-sync-scroll");

      // Hide scrollbars for all elements except the first one
      scrollableElements.forEach((element, index) => {
        element.addEventListener("scroll", handleScroll);

        if (index > 0) {
          const elementAsHTMLElement = element as HTMLElement;
          // Use valid CSS properties
          elementAsHTMLElement.style.setProperty("scrollbar-width", "none"); // Firefox
          elementAsHTMLElement.style.setProperty("-ms-overflow-style", "none"); // IE/Edge
          elementAsHTMLElement.style.setProperty("::-webkit-scrollbar", "none"); // Chrome/Safari
        }
      });
    };

    const cleanupSyncScrolls = () => {
      const scrollableElementsToCleanup = document.querySelectorAll(".multi-weeks-sync-scroll");
      scrollableElementsToCleanup.forEach((element) => {
        element.removeEventListener("scroll", handleScroll);
      });
    };

    return {
      setupSyncScrolls,
      cleanupSyncScrolls,
    };
  }, []);
};
