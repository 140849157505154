import AssignmentLateIcon from "@mui/icons-material/AssignmentLate";
import DirectionsRunIcon from "@mui/icons-material/DirectionsRun";
import NotificationsIcon from "@mui/icons-material/Notifications";

import { FLOATING_ICON } from "~/common/constants";
import {
  blockShift,
  darkBlue,
  darkRed,
  dayShift,
  dayShiftDark,
  floatedStaffColor,
  lightBlue,
  mediumGray,
  white,
  darkOrange,
} from "~/common/theming/colors";
import { EShiftStatus } from "~/features/DailyReports/types";
import { IUnitBasic } from "~/routes/api/types";

import { Note, StaffShift } from "@/api";

export const backgroundColorForUpdateOnSchedulerGrid = (update: string) => {
  switch (update) {
    case StaffShift.EStatus.onCall:
      return dayShiftDark;
    case StaffShift.EStatus.calledIn:
      return darkBlue;
    case StaffShift.EStatus.floated:
      return floatedStaffColor;
    case StaffShift.EStatus.flexed:
      return mediumGray;
    case StaffShift.EStatus.requestOnCall:
      return darkOrange;
    case StaffShift.EStatus.onCall4h:
      return mediumGray;
    default:
      return darkRed;
  }
};

export const backgroundColorForUpdateOnStaffCalendar = (update: string) => {
  switch (update) {
    case StaffShift.EStatus.onCall:
      return dayShiftDark;
    case StaffShift.EStatus.calledIn:
      return darkRed;
    case StaffShift.EStatus.floated:
      return floatedStaffColor;
    case StaffShift.EStatus.flexed:
      return mediumGray;
    case StaffShift.EStatus.requestOnCall:
      return darkOrange;
    case StaffShift.EStatus.onCall4h:
      return mediumGray;
    default:
      return darkRed;
  }
};

export const iconForNoteUpdate = (update: EShiftStatus | Note.EUpdate | StaffShift.EStatus) => {
  switch (update) {
    case StaffShift.EStatus.onCall4h:
    case StaffShift.EStatus.requestOnCall:
    case StaffShift.EStatus.onCall:
      return NotificationsIcon;
    case StaffShift.EStatus.floated:
      return FLOATING_ICON;
    case StaffShift.EStatus.flexed:
      return DirectionsRunIcon;
    default:
      return AssignmentLateIcon;
  }
};

export const backgroundColorUpdateFieldDailyReport = (update: string) => {
  switch (update) {
    case StaffShift.EStatus.onCall:
      return dayShift;
    case StaffShift.EStatus.calledIn:
      return lightBlue;
    case StaffShift.EStatus.cancelled:
      return blockShift;
    case StaffShift.EStatus.floated:
      return floatedStaffColor;
    case StaffShift.EStatus.flexed:
      return mediumGray;
    case StaffShift.EStatus.requestOnCall:
      return darkOrange;
    case StaffShift.EStatus.onCall4h:
      return mediumGray;
    default:
      return white;
  }
};

/**
 * @deprecated use showStatusForUnit instead
 * Determines whether to show the flexed update based on the provided update string and unit configuration.
 * @param {string} update - The update string.
 * @param {IUnitBasic | undefined} unit - The unit object containing configuration settings.
 * @returns {boolean} - Returns true if the flexed update should be shown, otherwise false.
 */
export const showFlexedUpdate = (update: string, unit: IUnitBasic | undefined) => {
  // If the update is a flexed update and the unit configuration settings do not allow flexed updates, do not show flexed update.
  // return not(flexed and useFlexedUpdate is false)
  return !(
    update === StaffShift.EStatus.flexed &&
    !unit?.configuration.settings?.schedulerApp?.useFlexedUpdate
  );
};
