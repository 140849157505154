import Captions from "~/common/components/Captions";
import { ECaptionsVariant } from "~/common/components/Captions/types";

const data = [
  { variant: ECaptionsVariant.Bad, text: "Bad: <63%" },
  { variant: ECaptionsVariant.Poor, text: "Poor: 63%–72%" },
  { variant: ECaptionsVariant.Satisfactory, text: "Satisfactory: 72%–81%" },
  { variant: ECaptionsVariant.Good, text: "Good: 81%–90%" },
  { variant: ECaptionsVariant.Excellent, text: "Excellent: >90%" },
];

export const CaptionsForHonoredPreferences = () => <Captions data={data} />;
