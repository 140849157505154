import { ScheduleTableHeaderRow } from "./TableParts/HeaderRow";
import { ScheduleTableStaffNameHeaderCell } from "./TableParts/ScheduleTableStaffNameHeaderCell";
import { ScheduleTableStaffRows } from "./TableParts/ScheduleTableStaffRows";
import { ScheduleTableStaffShiftCell } from "./TableParts/ScheduleTableStaffShiftCell";
import { TotalCountHeader } from "./TableParts/TotalCountHeader";

export const TableParts = {
  HeaderRow: ScheduleTableHeaderRow,
  StaffRows: ScheduleTableStaffRows,
  StaffNameHeaderCell: ScheduleTableStaffNameHeaderCell,
  StaffShiftCell: ScheduleTableStaffShiftCell,
  TotalCountHeader: TotalCountHeader,
};
