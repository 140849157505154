import { useMemo } from "react";

import { createSlice } from "@reduxjs/toolkit";
import { keys } from "lodash";

import { useAppDispatch } from "@/common/hooks";

import { FiltersActions, FiltersInitState, TFiltersState } from "./filtersActions";
import { GeneralActions, GeneralInitState, TGeneralState } from "./generalActions";
import { GridActions, GridInitState, TGridState } from "./gridActions";
import { ModalsActions, ModalsInitState, TModalsState } from "./modalsActions";
import { SideBarActions, SideBarInitState, TSideBarState } from "./sidebarActions";
import { StaffShiftsActions, StaffShiftsInitState, TStaffShiftsState } from "./staffShiftsActions";

export type THolidayScheduleState = {
  filters: TFiltersState;
  general: TGeneralState;
  grid: TGridState;
  modals: TModalsState;
  sideBar: TSideBarState;
  staffShifts: TStaffShiftsState;
};

const initialState: THolidayScheduleState = {
  filters: FiltersInitState,
  general: GeneralInitState,
  grid: GridInitState,
  modals: ModalsInitState,
  sideBar: SideBarInitState,
  staffShifts: StaffShiftsInitState,
};

export const HolidayScheduleStore = createSlice({
  name: "holidaySchedule",
  initialState,
  reducers: {
    resetState: () => initialState,
    ...FiltersActions,
    ...GeneralActions,
    ...GridActions,
    ...ModalsActions,
    ...SideBarActions,
    ...StaffShiftsActions,
  },
});

type TActions = typeof HolidayScheduleStore.actions;
type TWrappedFunctions = {
  [key in keyof TActions]: (...args: Parameters<TActions[key]>) => void;
};
export const useDispatch = () => {
  const dispatch = useAppDispatch();

  return useMemo(
    () =>
      keys(HolidayScheduleStore.actions).reduce(
        <K extends keyof TActions>(acc: TWrappedFunctions, fnName: K) => {
          const fn = HolidayScheduleStore.actions[fnName];
          if (fn)
            acc[fnName] = (...args: unknown[]) => {
              // @ts-ignore can pass its own args
              dispatch(fn(...args));
            };

          return acc;
        },
        {} as TWrappedFunctions,
      ),
    [dispatch],
  );
};

export default HolidayScheduleStore.reducer;
