import { configureStore, PayloadAction } from "@reduxjs/toolkit";
import { persistStore } from "redux-persist";

import commonReducer from "../common/store";
import adminPanelReducer from "../features/AdminPanel/store";
import calendarV2Reducer from "../features/CalendarV2/store";
import cxDashboardReducer from "../features/CxDashboard/store";
import dailyReportReducer from "../features/DailyReports/store";
import fairnessReducer from "../features/Fairness/store";
import HolidayScheduleStore from "../features/HolidaySchedule/store";
import houseViewReducer from "../features/HouseView/store";
import openShiftsReducer from "../features/OpenShifts/store";
import rosterReducer from "../features/Roster/store";
import schedulerGridReducer from "../features/SchedulerGrid/store";
import shiftSwapRequestReducer from "../features/ShiftSwapRequest/store";
import statisticsReducer from "../features/Statistics/store";
import userReducer from "../features/User/store";

export type TStoreSlice<StateType> = Record<
  string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (state: StateType, action: PayloadAction<any>) => void
>;

export const store = configureStore({
  reducer: {
    calendarV2: calendarV2Reducer,
    common: commonReducer,
    dailyReport: dailyReportReducer,
    fairness: fairnessReducer,
    roster: rosterReducer,
    schedulerGrid: schedulerGridReducer,
    holidaySchedule: HolidayScheduleStore,
    statistics: statisticsReducer,
    user: userReducer,
    openShifts: openShiftsReducer,
    shiftSwapRequest: shiftSwapRequestReducer,
    houseView: houseViewReducer,
    adminPanel: adminPanelReducer,
    cxDashboard: cxDashboardReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      // ignoredActions: [FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE],
      // temporary solution
      serializableCheck: false,
    }),
});
export const persistor = persistStore(store);

export type TRootState = ReturnType<typeof store.getState>;
export type TAppDispatch = typeof store.dispatch;
