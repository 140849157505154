export const NUMBER_OF_MONTHS = 12;

export type TimeFrame = "weekCounts" | "monthCounts" | "yearCounts";

export const timeFrameLabels: Record<TimeFrame, string> = {
  weekCounts: "Past Week",
  monthCounts: "Past Month",
  yearCounts: "Past Year",
};

export type TPulseCheckData = {
  date: string;
  unitAverage: number;
  personalScore?: number;
  answer?: string;
};
