import { Grid, Typography } from "@mui/material";

import FairnessBox from "~/common/components/Fairness";
import { darkGray } from "~/common/theming/colors";
import { pxToRem } from "~/common/utils/pxToRem";

export const Cell = ({
  preferencesHonored,
  allPreferences,
  score,
}: {
  preferencesHonored: number;
  allPreferences: number;
  score: number;
}) => {
  return (
    <Grid container justifyContent="center" alignItems="center" gap="12px">
      <Typography
        sx={{
          fontSize: pxToRem(12),
          fontWeight: 500,
          color: darkGray,
          width: "40px",
          textAlign: "right",
        }}
      >
        {preferencesHonored}/{allPreferences}
      </Typography>
      <FairnessBox fairness={score} />
    </Grid>
  );
};
