import React, { FC, memo } from "react";

import { Chip, ChipProps, Tooltip } from "@mui/material";

import { IPreferencesTemplate, IUnit, useListPreferencesTemplatesQuery } from "@/api";
import { NOT_EXISTING_UUID } from "@/common/constants";
import { theme } from "@/common/theming";
import { emptySx } from "@/common/types";

export const PreferencesTemplateLabel: FC<
  ChipProps & {
    withTooltip?: boolean;
  } & (
      | {
          preferencesTemplate?: IPreferencesTemplate;
          preferencesTemplateId?: never;
          unitId?: never;
        }
      | {
          preferencesTemplate?: never;
          preferencesTemplateId?: IPreferencesTemplate["id"];
          unitId?: IUnit["id"];
        }
    )
> = memo(
  React.forwardRef(
    (
      { withTooltip, preferencesTemplate, preferencesTemplateId, unitId, sx, ...otherProps },
      ref,
    ) => {
      const { data: unitPreferencesTemplate } = useListPreferencesTemplatesQuery(
        { unitIds: [unitId || NOT_EXISTING_UUID] },
        { skip: !unitId || !preferencesTemplateId },
      );
      preferencesTemplate ||= unitPreferencesTemplate?.find(
        ({ id }) => id === preferencesTemplateId,
      );
      if (!preferencesTemplate) return null;

      const chip = (
        <Chip
          ref={ref}
          label={preferencesTemplate.label}
          size={"small"}
          sx={{
            mr: 1,
            backgroundColor: preferencesTemplate.color,
            color: theme.palette.getContrastText(preferencesTemplate.color),
            borderRadius: "5px",
            ...(sx || emptySx),
          }}
          {...otherProps}
        />
      );

      if (!withTooltip) return chip;

      return (
        <Tooltip
          title={
            <>
              Preference Template: {preferencesTemplate.name}
              <br />
              <br />
              <i>{preferencesTemplate.description}</i>
            </>
          }
          placement="top"
          arrow
          children={chip}
        />
      );
    },
  ),
);
