import { useMemo } from "react";

import { staleFor } from "~/api/_shared/types";

import { useListSchedulesQuery } from "@/api";

export const useUnitIdByScheduleId = () => {
  const { data: schedules } = useListSchedulesQuery({}, { staleTime: staleFor(10).minutes });

  return useMemo(() => {
    return schedules?.reduce(
      (acc, schedule) => {
        acc[schedule.id] = schedule.unitId;
        return acc;
      },
      {} as Record<string, string>,
    );
  }, [schedules]);
};
