import { useDrop } from "react-dnd";

import { TableCellProps } from "@mui/material";

import { ICellDropData } from "../types";

export const useCellDrop = (
  cell: TableCellProps,
  accept: string | string[],
  onCellDrop?: (dropData: ICellDropData) => void,
  canDrop?: (cell: TableCellProps) => boolean,
) => {
  const [{ canDropItem }, drop] = useDrop(
    () => ({
      accept,
      drop: (item: TableCellProps) => {
        onCellDrop?.({
          dragItem: item,
          dropItem: cell,
        });
      },
      collect: (monitor) => ({
        isOver: monitor.isOver(),
        canDropItem: !!monitor.canDrop(),
      }),
      canDrop: canDrop ? (dragItem) => canDrop(dragItem) : undefined,
    }),
    [cell],
  );

  return { drop, canDropItem };
};
