import React from "react";

import { isEqual } from "lodash";

import { Box, Grid, Typography } from "@mui/material";

import { Unit } from "~/api";
import { ExpandMore } from "~/common/components/ExpandMore/ExpandMore";
import { lightGray, lightPurple, lines } from "~/common/theming/colors";

import { useAppSelector } from "@/common/hooks";
import { TimeStringToStandardTime } from "@/common/utils/dates";

import { CELL_DIMENSIONS } from "../constants";

export const HeaderNameCell = ({
  unit,
  expanded,
  setExpandedUnits,
}: {
  unit: Unit.DTO;
  expanded: boolean;
  setExpandedUnits: (value: React.SetStateAction<Record<string, boolean>>) => void;
}) => {
  const unitName = unit.name;
  const unitId = unit.id;

  const { customTimeRange: selectedCustomTimeRange } = useAppSelector(
    (state) => ({
      customTimeRange: state.houseView.pageFilters.customTimeRange,
    }),
    isEqual,
  );

  const allFacilityTimeRanges = unit?.facility?.configuration.settings?.houseViewTimeRanges || [];
  if (!selectedCustomTimeRange) {
    return null;
  }
  const timeRangesToShow =
    selectedCustomTimeRange.customAbbreviation === "All"
      ? allFacilityTimeRanges
      : [selectedCustomTimeRange];

  return (
    <Grid container flexDirection="column">
      <Grid
        container
        alignItems="center"
        justifyContent="space-between"
        wrap="nowrap"
        sx={{
          minWidth: "300px",
          maxWidth: "300px",
          height: "40px",
          background: expanded ? `${lightPurple}60` : lines, // 80 in hex = 50% opacity
          borderRadius: "10px 10px 0 0",
        }}
        item
      >
        <Grid
          container
          item
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
          sx={{
            mt: 1,
          }}
        >
          <Typography
            variant="small"
            fontWeight={600}
            sx={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              maxWidth: "280px", // Leaving space for the expand arrow
            }}
          >
            <Box
              onClick={() =>
                setExpandedUnits((previous) => ({
                  ...previous,
                  [unitId]: !expanded,
                }))
              }
              display={"inline"}
            >
              <Typography variant="small" fontSize={13} fontWeight={300} flexWrap={"nowrap"}>
                <ExpandMore direction="right" expand={expanded} />
              </Typography>
            </Box>
            {unitName}
          </Typography>
        </Grid>
      </Grid>
      <Grid
        container
        item
        flexDirection="column"
        flexWrap="nowrap"
        sx={{
          borderTop: `1px solid ${lines}`,
        }}
      >
        {timeRangesToShow.map((aRange) => (
          <Grid
            key={`${aRange.startTime}-${aRange.endTime}`}
            sx={{
              height: CELL_DIMENSIONS.height,
              background: lightGray,
              borderTop: `${1}px solid ${lines}`,
              borderBottom: `0px solid ${lines}`,
            }}
            container
            alignItems="center"
          >
            <Typography pl={2} fontSize="0.75rem" fontWeight="400">
              {aRange.customAbbreviation || TimeStringToStandardTime(aRange.startTime)}
            </Typography>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};
