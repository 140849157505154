import styled from "styled-components";

import { Box, Tab, TabProps, Tabs, TabsProps } from "@mui/material";

import { black, lightGray, lines, white } from "~/common/theming/colors";

type TCustomProps = {
  $pillTabs: boolean;
  $grey?: boolean;
  $fullColor?: string;
};

export const TabsContainerStyled = styled(Box as any)<TCustomProps>`
  ${({ $pillTabs, $grey }: TCustomProps & TabsProps) =>
    $pillTabs
      ? `border-bottom: 'none;`
      : $grey
        ? `background-color: ${lightGray}`
        : `border-bottom: 1px solid ${lines};`}
`;

export const TabsStyled = styled(Tabs)<TCustomProps>`
  ${({ $pillTabs }: TCustomProps & TabsProps) =>
    $pillTabs &&
    `
      background: ${white};
      border: 1px solid ${lines};
      border-radius: 3.75rem;
      color: white;
      height: 2.75rem;
      min-height: auto;
    `}
`;

export const TabStyled = styled(Tab)<TCustomProps>`
  ${({ $pillTabs, $fullColor }: TCustomProps & TabProps) =>
    $pillTabs
      ? `
      border: none;
      border-radius: 3.75rem;
      height: 2rem;
      margin: 6px;
      height: 2rem;
      min-height: auto;

      &.Mui-selected {
        background: ${black};
        color: ${white};
        border-bottom: none;
      }
    `
      : `&.Mui-selected {
        background: ${$fullColor};
        border-bottom: none;
        border-top-left-radius: 0.5rem;
        border-top-right-radius: 0.5rem;
      }`}
`;
