import { m7DayJs } from "@m7-health/shared-utils";

import { StaffDetails } from "~/api/staffDetails/types";
import { EStaffShiftType, EStaffStatus, IEditUser } from "~/features/Roster/types";

import { User } from "@/api";

export const initialState: IEditUser = {
  chargeEligibility: false,
  contractEndDate: m7DayJs(Date.now()).toISOString(),
  email: "",
  employmentStartDate: m7DayJs(Date.now()).toISOString(),
  employmentType: StaffDetails.EEmploymentType.fullTime,
  firstName: "",
  id: "",
  lastName: "",
  maxConsecutiveShifts: 1,
  maxRequestsOffPerSchedule: 1,
  minShiftsPerWeek: 1,
  maxShiftsPerWeek: 1,
  moreThanOneDayBetweenShifts: false,
  orientationEndDate: undefined,
  onOrientation: false,
  preceptor: false,
  phoneNumber: "",
  roles: [User.ERole.staff],
  rosters: [],
  shiftType: EStaffShiftType.Day,
  status: EStaffStatus.Inactive,
  totalShiftsRequiredPerSchedule: 1,
  totalWeekendShiftsRequiredPerSchedule: 1,
  units: [],
  staffUnitAssignment: [],
  preferenceRequirementRuleSetIds: [],
  schedulerUnitAssignment: [],
  staffTypeName: "RN",
};
