import { Typography } from "@mui/material";

import { black, white } from "~/common/theming/colors";

import { IStatusTagProps } from "./types";

export const StatusTag = ({
  children,
  backgroundColor,
  color,
  reverse = false,
  sx,
}: IStatusTagProps) => {
  if (!backgroundColor && !color) {
    if (!reverse) {
      backgroundColor = white;
      color = black;
    } else {
      backgroundColor = black;
      color = white;
    }
  }
  return (
    <Typography
      variant="small"
      style={{ backgroundColor, borderRadius: "4px", color, padding: "4px 8px" }}
      sx={sx}
    >
      {children}
    </Typography>
  );
};
