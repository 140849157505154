import { Delete, Edit, EditNote, Loupe, QuestionMark } from "@mui/icons-material";

import { EventLog, IEventLog } from "@/api";

export const IconForEvent = ({ eventLog }: { eventLog: IEventLog }) => {
  switch (eventLog.eventType) {
    case EventLog.EEventType.staffShiftCreated:
      return <Loupe />;
    case EventLog.EEventType.staffShiftUpdated:
      return <Edit />;
    case EventLog.EEventType.staffShiftDeleted:
      return <Delete />;
    case EventLog.EEventType.noteUpdated:
      return <EditNote />;
    default:
      return <QuestionMark />;
  }
};
