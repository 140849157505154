import { reduce } from "lodash";

import {
  IRoster,
  IUser,
  PreferenceRequirementRuleSet,
  useListPreferenceRequirementRuleSetsQuery,
  useListRosterQuery,
} from "@/api";
import { NOT_EXISTING_UUID } from "@/common/constants";
import { useCurrentUnitId, useDeepMemo } from "@/common/hooks";

/**
 * @return object containing user ids as keys and their rule sets as values
 */
type TUserRuleSetsByUserId = Record<IUser["id"], PreferenceRequirementRuleSet.DTO[]>;
export const useUsersRuleSets = (): TUserRuleSetsByUserId => {
  const unitId = useCurrentUnitId();

  const { data: ruleSets } = useListPreferenceRequirementRuleSetsQuery(
    { unitIds: [unitId || NOT_EXISTING_UUID] },
    { skip: !unitId },
  );
  const { data: rosters } = useListRosterQuery(
    { unitIds: [unitId || NOT_EXISTING_UUID] },
    { skip: !unitId },
  );

  return useDeepMemo(() => {
    const userIdByRosterId = reduce(
      rosters,
      (acc, roster) => ({
        ...acc,
        [roster.id]: roster.userId,
      }),
      {} as Record<IRoster["id"], IUser["id"]>,
    );

    return reduce(
      ruleSets,
      (acc, ruleSet) => {
        ruleSet.rosterRuleSets.forEach((rosterRuleSet) => {
          const userId = userIdByRosterId[rosterRuleSet.rosterId];
          if (!userId) return;

          (acc[userId] ||= []).push(ruleSet);
        });

        return acc;
      },
      {} as TUserRuleSetsByUserId,
    );
  }, [ruleSets, rosters]);
};
