import { MemoExoticComponent } from "react";

import { NoteUpdated, StaffShiftCreated, StaffShiftUpdated } from "~/api/eventLog/events";
import { StaffShiftDeleted } from "~/api/eventLog/events/staffShifts/deleted";

import { EventLog, IEventLog, IScheduleShiftType, IStaffDetails, IUser } from "@/api";
import { KeyBy } from "@/common/types";

import { NoteUpdatedV1 } from "./note/updated/v1";
import { StaffShiftCreatedV1 } from "./staffShift/created/v1";
import { StaffShiftDeletedV1 } from "./staffShift/deleted/v1";
import { StaffShiftUpdatedV1 } from "./staffShift/updated/v1";

export interface IEventLogComponent<TEvent extends EventLog.DTO = EventLog.DTO> {
  indexedShiftTypes?: Record<IScheduleShiftType["scheduleId"], KeyBy<IScheduleShiftType, "key">>;
  usersById?: KeyBy<IUser, "id">;
  staffDetailsById?: KeyBy<IStaffDetails, "userId">;
  unitIdByScheduleId?: Record<string, string>;
  event: TEvent;
}

type TEventComponent = (params: IEventLogComponent) => JSX.Element;

const componentByEventType: {
  [eventType in EventLog.EEventType]: {
    [version in string]: TEventComponent | MemoExoticComponent<TEventComponent>;
  };
} = {
  [EventLog.EEventType.staffShiftCreated]: {
    [StaffShiftCreated.EVersions.v1]: StaffShiftCreatedV1,
  },
  [EventLog.EEventType.staffShiftUpdated]: {
    [StaffShiftUpdated.EVersions.v1]: StaffShiftUpdatedV1,
  },
  [EventLog.EEventType.staffShiftDeleted]: {
    [StaffShiftDeleted.EVersions.v1]: StaffShiftDeletedV1,
  },
  [EventLog.EEventType.noteUpdated]: {
    [NoteUpdated.EVersions.v1]: NoteUpdatedV1,
  },
};

export const componentForEvent = (eventLog: IEventLog) => {
  const componentByVersion = componentByEventType[eventLog.eventType];
  const component = componentByVersion?.[eventLog.eventVersion];

  return component;
};
