import { useState } from "react";

import { datadogRum } from "@datadog/browser-rum";
import * as Sentry from "@sentry/react";
import { useRegisterSW } from "virtual:pwa-register/react";

import { useAppTracking } from "~/modules/mixpanel/Context";
import { Mxp } from "~/modules/mixpanel/types";

const intervalMS =
  1000 * 60 * ((import.meta.env.VITE_SERVICE_WORKER_UPDATE_INTERVAL_MINUTES || 5) as number);

export const useRegisterServiceWorker = () => {
  const [serviceWorkerRegistration, setServiceWorkerRegistration] = useState<
    ServiceWorkerRegistration | undefined
  >(undefined);
  const [didInitialServiceWorkerUpdate, setDidInitialServiceWorkerUpdate] = useState(false);

  const track = useAppTracking();

  const {
    offlineReady: [_offlineReady, _setOfflineReady],
    needRefresh: [needRefresh, _setNeedRefresh],
    updateServiceWorker,
  } = useRegisterSW({
    onRegisteredSW(swScriptUrl, registration) {
      datadogRum.addAction("service-worker-registered", { swScriptUrl });
      Sentry.addBreadcrumb({
        message: "Service worker has been registered",
        data: { swScriptUrl },
      });
      track(Mxp.Event.system, {
        [Mxp.Property.system.area]: "Service Worker",
        [Mxp.Property.system.action]: "Register",
      });

      setServiceWorkerRegistration(registration);

      // Immediately check for updates after registration
      if (!didInitialServiceWorkerUpdate) {
        datadogRum.addAction("service-worker-registration-update");
        void registration?.update();
        setDidInitialServiceWorkerUpdate(true);
      }

      // Check for updates every X minutes
      registration &&
        setInterval(() => {
          datadogRum.addAction("service-worker-registration-update", { intervalMS });
          Sentry.addBreadcrumb({
            message: "Service worker registration.update()",
            data: { intervalMS, swScriptUrl },
          });
          track(Mxp.Event.system, {
            [Mxp.Property.system.area]: "Service Worker",
            [Mxp.Property.system.action]: "Update",
          });

          void registration.update();
        }, intervalMS);
    },
    onRegisterError(error: unknown) {
      Sentry.captureException(error, { tags: { area: "useReloadPrompt" } });
    },
  });

  return { needRefresh, updateServiceWorker, serviceWorkerRegistration };
};
