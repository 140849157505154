import { memo } from "react";

import Tag from "~/common/components/Tag";
import { iconComponentForShift } from "~/common/constants";
import { black, theme } from "~/common/theming";
import { TSx } from "~/common/types";
import { timeAdd, TimeStringToStandardTime } from "~/common/utils/dates";

import { IShiftType, IStaffShift } from "@/api";

import { ITagProps } from "../Tag/types";

// This component was created while refactoring scheduler grid.
// If used elsewhere, you might need to introduce a few new extra options.
type IShiftProps = {
  iconOnly?: boolean;
  hideIcon?: boolean;
  suffix?: string;
  prefix?: string;

  hoursVariant?: "short" | "medium" | "long";
  hoursInLabel?: boolean;
  hoursAsLabel?: boolean;
  customLabel?: string;

  variant?: "small" | "medium" | "big";
  observer?: "scheduler" | "staff";

  sx?: TSx;

  onClick?: () => void;

  shiftType: Partial<IShiftType>;
  staffShift?: IStaffShift;
  showPartialShiftBrackets?: boolean;
};

const emptySx = {} as TSx;

export const ShiftV2 = memo(
  ({
    iconOnly = false,
    hideIcon = false,
    showPartialShiftBrackets = true,
    suffix = "",
    prefix = "",
    hoursInLabel = undefined,
    hoursAsLabel = undefined,
    customLabel = "",
    hoursVariant = undefined,
    variant = undefined,
    sx = emptySx,
    observer = "scheduler",
    shiftType,
    staffShift,
    ...rest
  }: IShiftProps & ITagProps) => {
    let color, defaultLabel;

    // Setup main attributes - color, label, icon
    const { scheduleViewColor, scheduleViewDisplayName, staffViewColor, staffViewDisplayName } =
      shiftType;

    if (observer === "staff") {
      color = staffViewColor;
      defaultLabel = staffViewDisplayName || "";
    } else {
      color = scheduleViewColor;
      defaultLabel = scheduleViewDisplayName || "";
    }

    if (suffix) defaultLabel = `${defaultLabel} ${suffix}`;
    if (prefix) defaultLabel = `${prefix} ${defaultLabel}`;

    const { customDuration, customStartTime } = staffShift || {};

    let { printAbbreviation } = shiftType;
    // If we have an abbreviation and partial shift, wrap in brackets
    if (showPartialShiftBrackets && printAbbreviation && (customDuration || customStartTime)) {
      printAbbreviation = `[${printAbbreviation}]`;
    }

    hoursAsLabel ??= !!(customDuration || customStartTime);

    const startTime = customStartTime || shiftType.startTime;
    const duration = customDuration || shiftType.durationSeconds;
    if (startTime && duration && (hoursAsLabel || hoursInLabel)) {
      const endTime = timeAdd(startTime, duration);

      hoursAsLabel ??= !!(customDuration || customStartTime);

      const labelTime = `${TimeStringToStandardTime(
        startTime,
        hoursVariant,
      )}-${TimeStringToStandardTime(endTime, hoursVariant)}`;

      if (hoursAsLabel) defaultLabel = labelTime;
      else if (hoursInLabel) defaultLabel = `${defaultLabel} (${labelTime})`;
    }

    let icon;
    if (!hideIcon) {
      // allow to hide icon if we want by setting muiIconClassName to None
      // if muiIconClassName is not set or null or unknown, will fallback on default icon
      if (shiftType.muiIconClassName === "None") {
        icon = undefined;
      } else {
        const Icon = iconComponentForShift(shiftType.muiIconClassName);
        icon = <Icon sx={{ color: shiftType.iconColor }} />;
      }
    }

    return (
      <Tag
        sx={{
          // If color is dark, use white text
          color: color ? theme.palette.getContrastText(color) : black,
          p: { "-webkit-text-fill-color": "initial" },
          ...sx,
        }}
        backgroundColor={color}
        className={["shift-tile", `shift-type-${shiftType.key || "_unknown_"}`, variant].join(" ")}
        icon={icon}
        text={customLabel || (iconOnly ? null : defaultLabel)}
        variant={variant}
        miniViewLabel={customLabel || printAbbreviation}
        {...rest}
      />
    );
  },
);
