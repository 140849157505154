import { PayloadAction } from "@reduxjs/toolkit";

import { IAttribute } from "@/api";
import { KeyBy } from "@/common/types";

import { THouseViewState } from ".";

export type TAttributeState = {
  toUpdate: Record<IAttribute["key"], IAttribute>;
  toDelete: Record<IAttribute["key"], IAttribute>;
  toCreate: Record<IAttribute["key"], IAttribute>;
  recordsInDb: Record<IAttribute["key"], IAttribute>;
};

export const AttributeActionsInitState: TAttributeState = {
  toUpdate: {},
  toDelete: {},
  toCreate: {},
  recordsInDb: {},
};

export const AttributeActions = {
  updateAttribute: ({ attributes }: THouseViewState, action: PayloadAction<IAttribute>) => {
    if (attributes.toCreate[action.payload.key]) {
      attributes.toCreate[action.payload.key] = action.payload;
    } else if (attributes.toUpdate[action.payload.key]) {
      attributes.toUpdate[action.payload.key] = action.payload;
    } else if (attributes.toDelete[action.payload.key]) {
      attributes.toDelete[action.payload.key] = action.payload;
    } else {
      attributes.toUpdate[action.payload.key] = action.payload;
    }
  },
  deleteAttribute: ({ attributes }: THouseViewState, action: PayloadAction<IAttribute>) => {
    if (attributes.toCreate[action.payload.key]) {
      delete attributes.toCreate[action.payload.key];
      return;
    } else if (attributes.toUpdate[action.payload.key]) {
      delete attributes.toUpdate[action.payload.key];
    }
    attributes.toDelete[action.payload.key] = action.payload;
  },
  restoreAttribute: ({ attributes }: THouseViewState, action: PayloadAction<IAttribute>) => {
    delete attributes.toDelete[action.payload.key];
    delete attributes.toUpdate[action.payload.key];
    delete attributes.toCreate[action.payload.key];
  },
  addNewAttribute: ({ attributes }: THouseViewState, action: PayloadAction<IAttribute>) => {
    const key = action.payload.key;
    attributes.toCreate[key] = { ...action.payload, key };
  },

  setDbAttributes: (
    { attributes }: THouseViewState,
    action: PayloadAction<KeyBy<IAttribute, "key">>,
  ) => {
    attributes.recordsInDb = action.payload;
  },

  cancelAllAttributeChanges: ({ attributes }: THouseViewState) => {
    attributes.toCreate = {};
    attributes.toDelete = {};
    attributes.toUpdate = {};
  },
};
