import { YyyyMmDd } from "@m7-health/shared-utils";
import { PayloadAction } from "@reduxjs/toolkit";

import { IStaffShift } from "~/api";

import { initialState, THouseViewState } from ".";

// Make sure anything else than 'Other' matches StaffDetails.EmploymentType
export enum EHVEmploymentTypeFilter {
  perDiem = "Per diem",
  travelStaff = "Travel staff",
  other = "Other",
}
export type EditShifts = {
  staffShift?: Partial<
    Pick<IStaffShift, "shiftTypeKey" | "staffId" | "customStartTime" | "customDuration" | "id">
  > | null;
  staffShifts?: IStaffShift[];
  autoSave?: boolean;
  staffId?: IStaffShift["staffId"];
  fromDate?: YyyyMmDd;
  toDate?: YyyyMmDd;
};

export const EditShiftsActions = {
  startEditShifts: (
    { sidebarCurrentAction, editShifts }: THouseViewState,
    action: PayloadAction<{
      autoSave?: EditShifts["autoSave"];
      shifts?: EditShifts["staffShifts"];
      staffId: IStaffShift["staffId"];
      fromDate?: YyyyMmDd;
      toDate?: YyyyMmDd;
    }>,
  ) => {
    sidebarCurrentAction.inProgress = "editShifts";
    editShifts.autoSave = action.payload.autoSave;
    editShifts.staffShifts = action.payload.shifts;
    editShifts.staffId = action.payload.staffId;
    editShifts.fromDate = action.payload.fromDate;
    editShifts.toDate = action.payload.toDate;
  },
  endEditShifts: (state: THouseViewState) => {
    state.editShifts = initialState.editShifts;
    state.sidebarCurrentAction = initialState.sidebarCurrentAction;
  },
};
