import { useMemo } from "react";

import { List, keyBy } from "lodash";

/** This hook is a memoized wrapper around lodash's keyBy function that allows you to key an array of objects by a key.
 *
 * @example results = useKeyBy(users, "id");
 */
export const useKeyBy = <T, K extends keyof T>(data: List<T> | null | undefined, key: K) =>
  useMemo(() => keyBy(data || [], key), [data, key]);
