import { buildApiMutationHook, buildApiMutationHookForCsv } from "../_shared/helpers";

import { StaffShift } from "./types";

export const useBulkSaveStaffShifts = buildApiMutationHook<
  StaffShift.API.BulkSave.QueryParams,
  StaffShift.API.BulkSave.Response
>(StaffShift.API.BulkSave.PATH, "post");

export const useDownloadUpdateReportMutation = buildApiMutationHookForCsv<
  StaffShift.API.DownloadUpdateReport.QueryParams,
  Blob
>(StaffShift.API.DownloadUpdateReport.PATH, "get");

export const useDownloadShiftReportMutation = buildApiMutationHookForCsv<
  StaffShift.API.DownloadShiftReport.QueryParams,
  Blob
>(StaffShift.API.DownloadShiftReport.PATH, "post");
