import { useMemo } from "react";

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { keys } from "lodash";

import { useAppDispatch } from "@/common/hooks/useRedux";

import { AttributeActions, AttributeActionsInitState, TAttributeState } from "./attributeActions";
import {
  ShiftIncentiveLevelActions,
  ShiftIncentiveLevelActionsInitState,
  TShiftIncentiveLevelState,
} from "./shiftIncentiveLevelActions";
import {
  ShiftTypeActions,
  ShiftTypeActionsInitState,
  ShiftTypeModalsInitState,
  TShiftTypeModals,
  TShiftTypeState,
} from "./shiftTypeActions";
import { StaffTypeActions, StaffTypeActionsInitState, TStaffTypeState } from "./staffTypeActions";
import {
  TUnitTargetLevelState,
  UnitTargetLevelActions,
  UnitTargetLevelActionsInitState,
} from "./unitTargetLevelActions";

// {} is desired, we don't want the default to be record<never>.
// eslint-disable-next-line @typescript-eslint/ban-types
export type TModalState<T extends object = {}> = T & {
  isOpen: boolean;
};

type TAvailableModals = TShiftTypeModals;

export type THouseViewState = {
  shiftTypes: TShiftTypeState;
  staffTypes: TStaffTypeState;
  attributes: TAttributeState;
  unitTargetLevels: TUnitTargetLevelState;
  shiftIncentiveLevels: TShiftIncentiveLevelState;
  modals: TAvailableModals;
  canManage: boolean;
};
const initialModalsState = ShiftTypeModalsInitState;
const initialState = {
  shiftTypes: ShiftTypeActionsInitState,
  staffTypes: StaffTypeActionsInitState,
  attributes: AttributeActionsInitState,
  unitTargetLevels: UnitTargetLevelActionsInitState,
  shiftIncentiveLevels: ShiftIncentiveLevelActionsInitState,
  modals: initialModalsState,
  canManage: false,
};

const AdminPanelStore = createSlice({
  name: "adminPanel",
  initialState,
  reducers: {
    resetState: () => initialState,
    ...ShiftTypeActions,
    ...StaffTypeActions,
    ...AttributeActions,
    ...UnitTargetLevelActions,
    ...ShiftIncentiveLevelActions,

    // General shared action
    setCanManage: (state, { payload }: PayloadAction<boolean>) => {
      state.canManage = payload;
    },
    openModal: ({ modals }, { payload }: PayloadAction<keyof TAvailableModals>) => {
      modals[payload].isOpen = true;
    },
    closeModal: ({ modals }, { payload }: PayloadAction<keyof TAvailableModals>) => {
      modals[payload].isOpen = false;
    },
  },
});

type TActions = typeof AdminPanelStore.actions;
type TWrappedFunctions = {
  [key in keyof TActions]: (...args: Parameters<TActions[key]>) => void;
};
export const useAdminPanelDispatch = () => {
  const dispatch = useAppDispatch();

  return useMemo(
    () =>
      keys(AdminPanelStore.actions).reduce(
        <K extends keyof TActions>(acc: TWrappedFunctions, fnName: K) => {
          const fn = AdminPanelStore.actions[fnName];
          if (fn)
            acc[fnName] = (...args: unknown[]) => {
              // @ts-ignore can pass its own args
              dispatch(fn(...args));
            };

          return acc;
        },
        {} as TWrappedFunctions,
      ),
    [dispatch],
  );
};
/** @deprecated use useAdminPanelDispatch instead */
export const useDispatch = useAdminPanelDispatch;

export default AdminPanelStore.reducer;
