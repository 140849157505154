import { LeafValues } from "~/common/types";

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace Mxp {
  export const Event = {
    elementClicked: "element:clicked",
    pageViewed: "page:viewed",
    system: "system",
  } as const;
  export type TEvent = (typeof Event)[keyof typeof Event];

  export const Property = {
    layout: {
      page: "layout:page",
      section: "layout:section",
      subSection: "layout:subSection",
      component: "layout:component",
      element: "layout:element",
    },
    element: {
      type: "element:type",
      label: "element:label",
    },
    navigation: {
      path: "navigation:path",
    },
    system: {
      area: "system:area",
      action: "system:action",
    },
    m7Context: {
      currentRole: "m7Context:currentRole",
      currentUnitId: "m7Context:currentUnitId",
      currentUnit: "m7Context:currentUnit",
      currentFacilityId: "m7Context:currentFacilityId",
      currentFacility: "m7Context:currentFacility",
    },
  } as const;

  export type TProperty = LeafValues<typeof Property>;
}
