import { PayloadAction } from "@reduxjs/toolkit";

import { IStaffShift } from "~/api";

import { THouseViewState, initialState } from ".";

export type Floating = {
  inProgress?: boolean;
  isDirty?: boolean;
  staffShift?: Partial<
    Pick<IStaffShift, "shiftTypeKey" | "staffId" | "customStartTime" | "customDuration" | "id">
  > | null;
  confirmModalIsOpen?: boolean;
};

export const FloatingActions = {
  startFloating: ({ sidebarCurrentAction }: THouseViewState) => {
    sidebarCurrentAction.inProgress = "floating";
  },
  setFloatShift: (
    { floating, sidebarCurrentAction }: THouseViewState,
    action: PayloadAction<Partial<IStaffShift> | null>,
  ) => {
    if (action.payload) {
      floating.staffShift = {
        ...(floating.staffShift || {}),
        ...action.payload,
      };
      sidebarCurrentAction.isDirty = true;
    } else {
      sidebarCurrentAction.isDirty = false;
      floating.staffShift = null;
    }
  },
  setFloatingDirtiness: (
    { sidebarCurrentAction }: THouseViewState,
    action: PayloadAction<Floating["isDirty"]>,
  ) => {
    sidebarCurrentAction.isDirty = action.payload;
  },
  displayConfirmFloatModal: ({ floating }: THouseViewState) => {
    floating.confirmModalIsOpen = true;
  },
  hideConfirmFloatModal: ({ floating }: THouseViewState) => {
    floating.confirmModalIsOpen = false;
  },
  endFloating: (state: THouseViewState) => {
    state.floating = initialState.floating;
    state.sidebarCurrentAction = initialState.sidebarCurrentAction;
  },
};
