import { Grid, Typography } from "@mui/material";

import { localDayJs } from "~/common/packages/dayjs";
import { black } from "~/common/theming/colors";

export const Dates = ({ startDay, endDay }: { startDay: string; endDay: string }) => {
  return (
    <Grid container alignItems="center" justifyContent="center" height="40px">
      <Typography sx={{ fontSize: "0.75rem", fontWeight: 500 }} color={black}>
        {localDayJs(startDay).format("L")} - {localDayJs(endDay).format("L")}
      </Typography>
    </Grid>
  );
};
