import { Tooltip, Typography } from "@mui/material";

import {
  backgroundColorForUpdateOnSchedulerGrid,
  iconForNoteUpdate,
} from "~/common/components/NoteUpdateTag/helpers";
import { TSx, emptySx } from "~/common/types";
import { EShiftStatus } from "~/features/DailyReports/types";

import { IUnit, Note, StaffShift } from "@/api";
import { useStatusLabel } from "@/common/utils/shifts";

const tagBaseSx = { fontSize: 13, mr: "0.5px" };
export const NoteUpdateTag = ({
  variant = "regular",
  sx = emptySx,
  update,
  unitId,
}: {
  sx?: TSx;
  update: EShiftStatus | Note.EUpdate | StaffShift.EStatus;
  variant?: "compact" | "regular";
  unitId?: IUnit["id"];
}) => {
  const getStatusLabel = useStatusLabel(unitId);

  const label = getStatusLabel(update as StaffShift.EStatus);
  const Icon = iconForNoteUpdate(update);
  const content = (
    <Typography
      display={"flex"}
      borderRadius={1}
      variant="small"
      fontSize={13}
      className="note-update-tag"
      sx={{
        backgroundColor: backgroundColorForUpdateOnSchedulerGrid(update),
        display: "flex", // Make it a flex container
        alignItems: "center", // Vertically center items (for icons and update to be inline)
        padding: "1px",
        ...sx,
      }}
    >
      <Icon sx={{ ...tagBaseSx }} />
      {variant === "regular" && label}
    </Typography>
  );
  return variant === "compact" ? (
    <Tooltip placement="top" title={label}>
      {content}
    </Tooltip>
  ) : (
    content
  );
};
