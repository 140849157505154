import { m7DayJs } from "@m7-health/shared-utils";
import { TooltipProps } from "recharts";

import { lineChartPersonalScore, lineChartUnitAverage } from "@/common/theming";

import { ChartDataPoint } from "../types";

import { TooltipContainer, TooltipText } from "./styles";

export const CustomTooltip = ({ active, payload, label }: TooltipProps<number, string>) => {
  if (active && payload && payload.length) {
    const data = payload[0]?.payload as ChartDataPoint | undefined;
    if (!data) {
      return null;
    }
    const date = m7DayJs()
      .year(data.year)
      .month(Math.floor(label as number))
      .date(Math.round(((label as number) % 1) * 30) + 1);
    return (
      <TooltipContainer>
        <TooltipText variant="body2">{`Date: ${date.format("MMM D, YYYY")}`}</TooltipText>
        {/* fix personal score to 0 decimals because score is integer only */}
        <TooltipText
          variant="body2"
          sx={{ color: lineChartPersonalScore }}
        >{`Personal Score: ${data?.personalScore?.toFixed(0) || "None"}`}</TooltipText>
        <TooltipText
          variant="body2"
          sx={{ color: lineChartUnitAverage }}
        >{`Unit Average: ${data.unitAverage.toFixed(2)}`}</TooltipText>
        <TooltipText variant="body2">{`Answer: ${data?.answer || "None"}`}</TooltipText>
      </TooltipContainer>
    );
  }
  return null;
};
