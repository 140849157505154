import { ChangeEvent, useEffect, useMemo, useRef, useState } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import withScrolling, {
  createHorizontalStrength,
  createVerticalStrength,
} from "react-dnd-scrolling";
import { useVirtual } from "react-virtual";

import { HeaderGroup, flexRender, getCoreRowModel, useReactTable } from "@tanstack/react-table";
import { get } from "lodash";
import isNil from "lodash/isNil";

import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
} from "@mui/material";
import TablePagination from "@mui/material/TablePagination";

import { lines, white } from "~/common/theming/colors";

import { ScrollableTableContainerStyled, TableContainerWithSticky } from "./Table.styled";
import CustomTableRow from "./TableRow";
import { ITableProps } from "./types";

const ScrollingTableContainer = withScrolling(ScrollableTableContainerStyled);

export const CustomTable = <T,>({
  data = [],
  columns = [],
  draggableCells = false,
  onPageChange,
  onRowsPerPageChange,
  pageIndex,
  pageSize = 20,
  rowsPerPageOptions = [10, 20, 50, 100],
  serverSidePagination = true,
  showPagination = false,
  total = data.length,
  onCellDrop,
  withPadding = true,
  width = "unset",
  stickyTableHead = false,
  stretchVertically = false,
  wrapHeaders = false,
}: ITableProps<T>) => {
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    manualPagination: serverSidePagination,
  });

  const [pageSizeOptions, setPageSizeOptions] = useState<number[]>(rowsPerPageOptions);
  const [isDraggingItem, setIsDraggingItem] = useState<boolean>(false);

  useEffect(() => {
    if (isNil(pageIndex)) {
      return;
    }
    table.setPageIndex(pageIndex);
  }, [pageIndex, table]);

  useEffect(() => {
    if (!pageSize) {
      return;
    }
    setPageSizeOptions((options) => {
      if (options.includes(pageSize)) {
        return options;
      } else {
        const updatedOptions = [...options, pageSize];
        return updatedOptions.sort((a, b) => a - b);
      }
    });
    table.setPageSize(pageSize);
  }, [pageSize, pageSizeOptions, table]);

  useEffect(() => {
    if (serverSidePagination && total) {
      const pageCount = Math.ceil(total / pageSize);
      table.setPageCount(pageCount);
    }
  }, [pageSize, serverSidePagination, table, total]);

  const hasFooter = useMemo(() => columns?.some((col) => col.footer), [columns]);

  const ScrollingTableContainerToRender = draggableCells ? ScrollingTableContainer : TableContainer;
  const scrollingTableContainerProps = draggableCells
    ? {
        verticalStrength: createVerticalStrength(0),
        horizontalStrength: createHorizontalStrength(100),
      }
    : {};
  const handlePageChange = (
    _event: React.MouseEvent<HTMLButtonElement> | null,
    newPageIndex: number,
  ) => {
    table.setPageIndex(newPageIndex);
    onPageChange?.(newPageIndex);
  };

  const handleRowsPerPageChange = ({
    target: { value },
  }: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const rowsPerPage = Number(value);
    if (isNaN(rowsPerPage)) {
      return;
    }
    table.setPageSize(rowsPerPage);
    table.setPageIndex(0);
    onRowsPerPageChange?.(rowsPerPage);
  };

  const renderTableHeaderCell = (headerGroup: HeaderGroup<T>) => {
    return headerGroup.headers?.map((header) => (
      <TableCell
        key={header.id}
        colSpan={header.colSpan}
        sx={{
          // only don't wrap headers if wrapHeaders is false, which it is by default
          whiteSpace: !wrapHeaders ? "nowrap" : "unset",
          ...get(header, "column.columnDef.meta.headerCellStyles", {}),
        }}
        className={header?.column?.columnDef?.meta?.pinned ? "sticky" : ""}
      >
        {header.isPlaceholder
          ? null
          : flexRender(header.column.columnDef.header, header.getContext())}
      </TableCell>
    ));
  };

  const tableContainerRef = useRef<typeof Box>(null);

  const { rows } = table.getRowModel();
  const rowVirtualizer = useVirtual({
    parentRef: tableContainerRef,
    size: rows.length,
    overscan: 15,
  });
  const { virtualItems: virtualRows, totalSize } = rowVirtualizer;

  const paddingTop = virtualRows.length > 0 ? virtualRows[0]?.start || 0 : 0;
  const paddingBottom =
    virtualRows.length > 0 ? totalSize - (virtualRows[virtualRows.length - 1]?.end || 0) : 0;

  const tableToRender = (
    <TableContainerWithSticky $withPadding={withPadding} $stretchVertically={stretchVertically}>
      <Box
        pt={3}
        width="100%"
        maxHeight="100%"
        boxSizing="border-box"
        display="flex"
        flexDirection="column"
      >
        <ScrollingTableContainerToRender {...scrollingTableContainerProps}>
          <Box ref={tableContainerRef}>
            <Table size="small" sx={{ width }}>
              <TableHead
                sx={
                  stickyTableHead
                    ? {
                        position: "sticky",
                        top: "0",
                        background: white,
                        zIndex: 100,
                        outline: `1px solid  ${lines}`,
                      }
                    : {}
                }
              >
                {table
                  .getHeaderGroups()
                  ?.map((headerGroup) => (
                    <TableRow key={headerGroup.id}>{renderTableHeaderCell(headerGroup)}</TableRow>
                  ))}
              </TableHead>
              <TableBody>
                {paddingTop > 0 && (
                  <tr>
                    <td style={{ height: `${paddingTop}px` }} />
                  </tr>
                )}
                {virtualRows.map((virtualRow) => {
                  const row = rows[virtualRow.index];
                  if (!row) return <></>;

                  return (
                    <CustomTableRow<T>
                      onCellDrop={onCellDrop}
                      key={row.id}
                      row={row}
                      draggableCells={draggableCells}
                      isDraggingItem={isDraggingItem}
                      setIsDraggingItem={setIsDraggingItem}
                    />
                  );
                })}
                {paddingBottom > 0 && (
                  <tr>
                    <td style={{ height: `${paddingBottom}px` }} />
                  </tr>
                )}
              </TableBody>
              {hasFooter && (
                <TableFooter>
                  {table.getFooterGroups().map((footerGroup) => (
                    <TableRow key={footerGroup.id}>
                      {footerGroup.headers?.map((footer) => (
                        <TableCell key={footer.id} colSpan={footer.colSpan}>
                          {footer.isPlaceholder
                            ? null
                            : flexRender(footer.column.columnDef.footer, footer.getContext())}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableFooter>
              )}
            </Table>
          </Box>
        </ScrollingTableContainerToRender>
        {showPagination && onPageChange && (
          <TablePagination
            rowsPerPageOptions={pageSizeOptions}
            component="div"
            count={total}
            rowsPerPage={table.getState().pagination.pageSize}
            page={table.getState().pagination.pageIndex}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleRowsPerPageChange}
            sx={{ flexShrink: 0 }}
          />
        )}
      </Box>
    </TableContainerWithSticky>
  );

  return draggableCells ? (
    <DndProvider backend={HTML5Backend}>{tableToRender}</DndProvider>
  ) : (
    tableToRender
  );
};
