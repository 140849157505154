import { Info } from "@mui/icons-material";
import { Box, Tooltip, Typography } from "@mui/material";

import { black, mediumGray } from "@/common/theming/colors";
import { isOnMobile } from "@/common/utils/isOnMobile";
import { pxToRem } from "@/common/utils/pxToRem";

import { HeaderCellContainer } from "./HeaderCell.styled";

interface IHeaderCellProps {
  title: string;
  hint?: string | null;
}

export const HeaderCell = ({ title, hint }: IHeaderCellProps) => {
  return (
    <HeaderCellContainer
      flexDirection="column"
      alignItems="flex-start"
      sx={{ height: "52px", opacity: 1 }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          height: "35px",
        }}
      >
        <Typography
          sx={{
            fontSize: isOnMobile() ? pxToRem(12) : pxToRem(17),
          }}
        >
          {title}
          {hint && !isOnMobile() && (
            <Typography
              sx={{
                display: "inline",
                position: "relative",
                top: "5px",
                left: "5px",
              }}
            >
              <Tooltip
                placement="top"
                title={hint}
                sx={{ color: mediumGray, "&:hover": { color: black } }}
              >
                <Info fontSize="small" />
              </Tooltip>
            </Typography>
          )}
        </Typography>
      </Box>
    </HeaderCellContainer>
  );
};
