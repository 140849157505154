import { useMemo } from "react";

import { User } from "@/api";

import { useAppSelector } from "./useRedux";

export const useIsStaffApp = (): boolean => {
  const currentRole = useAppSelector((state) => state.user.currentRole);

  return useMemo(() => {
    return currentRole === User.ERole.staff;
  }, [currentRole]);
};
