import { Uuid } from "@m7-health/shared-utils";

import { Grid, Typography } from "@mui/material";

import { useAppDispatch, useAppSelector } from "~/common/hooks/useRedux";
import PreferencesHonored from "~/features/Fairness/PreferencesHonored";
import { selectFairnessType, selectSchedulesCount } from "~/features/Fairness/store";
import { EFairnessTypes, ESchedulesCount } from "~/features/Fairness/types";
import WeekendShifts from "~/features/Fairness/WeekendShifts";

import CustomTabs from "@/common/components/TrackedComponents/Tabs";
import CustomTabPanel from "@/common/components/TrackedComponents/Tabs/TabPanel";

const fairnessTypesTabs = [
  { label: "Fair Shifts", value: EFairnessTypes.PreferencesHonored },
  { label: "Weekend Shifts", value: EFairnessTypes.WeekendShifts },
  { label: "Blocked Days Honored", value: EFairnessTypes.BlockedDaysHonored },
];

const schedulesCountTabs = [
  { label: "Last 12 schedules", value: ESchedulesCount.All },
  { label: "Last 6 schedules", value: ESchedulesCount.Last6 },
  { label: "Last 3 schedules", value: ESchedulesCount.Last3 },
];

const FairnessPage = ({ currentStaffId }: { currentStaffId?: Uuid }) => {
  const dispatch = useAppDispatch();

  const selectedFairnessType = useAppSelector((state) => state.fairness.fairnessType);
  const selectedSchedulesCount = useAppSelector((state) => state.fairness.schedulesCount);

  return (
    <Grid container flexDirection="column">
      <Grid item container alignItems="center" justifyContent="center" mt={5}>
        <Grid item container pl={3} pr={3} mb={3} alignItems="center">
          <Typography variant="h5" mr={4} fontWeight={"bold"}>
            Schedule Fairness
          </Typography>
          <CustomTabs<ESchedulesCount>
            onChange={(newTab) => dispatch(selectSchedulesCount(newTab))}
            tabs={schedulesCountTabs}
            value={selectedSchedulesCount}
            pillTabs
            width="auto"
          ></CustomTabs>
        </Grid>
        <Grid item container pl={3} pr={3}>
          <CustomTabs<EFairnessTypes>
            onChange={(newTab) => dispatch(selectFairnessType(newTab))}
            tabs={fairnessTypesTabs}
            value={selectedFairnessType}
            grey={true}
          >
            <CustomTabPanel<EFairnessTypes>
              tabKey={EFairnessTypes.PreferencesHonored}
              value={selectedFairnessType}
            >
              <PreferencesHonored
                type={EFairnessTypes.PreferencesHonored}
                currentStaffId={currentStaffId}
              />
            </CustomTabPanel>
            <CustomTabPanel<EFairnessTypes>
              tabKey={EFairnessTypes.WeekendShifts}
              value={selectedFairnessType}
            >
              <WeekendShifts currentStaffId={currentStaffId} />
            </CustomTabPanel>
            <CustomTabPanel<EFairnessTypes>
              tabKey={EFairnessTypes.BlockedDaysHonored}
              value={selectedFairnessType}
            >
              <PreferencesHonored
                type={EFairnessTypes.BlockedDaysHonored}
                currentStaffId={currentStaffId}
              />
            </CustomTabPanel>
          </CustomTabs>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default FairnessPage;
