import { FieldErrors, FieldValues } from "react-hook-form";

import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";

import { TSx } from "~/common/types";
import { useAppTracking } from "~/modules/mixpanel/Context";
import { Mxp } from "~/modules/mixpanel/types";

import Checkbox from "../Checkbox";

const defaultSx = {};

interface ICustomMultiSelectControlledProps {
  label: string;
  name: string;
  fullWidth: boolean;
  errors?: FieldErrors<FieldValues>;
  items: Array<{ label: string; value: string }>;
  value: string[];
  renderValue?: (selected: string[]) => string;
  onChange: (name: string, selectedValues: string[]) => void;
  keyToNameObject: Record<string, string>;
  readonly?: boolean;
  disabled?: boolean;
  sx?: TSx;
}

const CustomMultiSelectControlled = ({
  label,
  name,
  fullWidth,
  errors,
  items,
  value,
  renderValue,
  onChange,
  keyToNameObject,
  readonly = false,
  disabled = false,
  sx = defaultSx,
}: ICustomMultiSelectControlledProps) => {
  renderValue ||= (selected: string[]) =>
    selected
      .filter((attributeKey) => keyToNameObject[attributeKey]) //Don't display keys that you have no name for
      .map((attributeKey) => keyToNameObject[attributeKey])
      .join(", ");

  const track = useAppTracking();

  const onClickWrapper = () => {
    track(Mxp.Event.elementClicked, {
      [Mxp.Property.element.type]: "Select",
      [Mxp.Property.element.label]: label,
    });
    // onClick is not exposed by the custom Switch component
    //  so we don't need to wrap it for now;
  };

  const handleChange = (event: SelectChangeEvent<typeof value>) => {
    const {
      target: { value: selectedValues },
    } = event;
    if (onChange) {
      onChange(name, selectedValues as string[]);
    }
  };

  const classNames = [];
  if (disabled) {
    classNames.push("disabled");
  }
  if (readonly) {
    classNames.push("readonly");
  }

  return (
    <FormControl fullWidth={fullWidth} className={classNames.join(" ")}>
      <InputLabel>{label}</InputLabel>
      <Select
        onClick={onClickWrapper}
        multiple
        readOnly={readonly}
        disabled={disabled}
        // this value contains all the selected values as a string[]
        value={value}
        onChange={handleChange}
        inputProps={{
          name: name,
          id: name,
        }}
        renderValue={renderValue}
        error={!!errors?.[name]}
        label={label}
        sx={sx}
      >
        {items.map((item) => (
          <MenuItem key={item.value + item.label} value={item.value}>
            <Checkbox checked={value?.includes(item.value)} trackingLabel={item.value} />
            {item.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default CustomMultiSelectControlled;
