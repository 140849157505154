import { ISchedule } from "@/api";

import { EApprovableEntityTab, EApprovableEntityType, ITimeOffRequest } from "../types";

export type IShiftSwapRequestState = {
  displayedRequestStatusTab: EApprovableEntityTab | null;
  newShiftSwapRequestCount: number;
  selectedScheduleId?: ISchedule["id"];
  selectedRequestType?: EApprovableEntityType;
  timeOffRequestModal: {
    isOpen: boolean;
    entity: ITimeOffRequest | undefined;
    readonly: boolean;
  };
};

export const initialState: IShiftSwapRequestState = {
  displayedRequestStatusTab: EApprovableEntityTab.new,
  newShiftSwapRequestCount: 0,
  selectedScheduleId: undefined,
  selectedRequestType: undefined,
  timeOffRequestModal: {
    isOpen: false,
    entity: undefined,
    readonly: false,
  },
};
