import { boolean, date, number, string } from "yup";

import {
  dateMsg,
  emailMsg,
  getMinNumber,
  integerMsg,
  numberMsg,
  requiredMsg,
  phoneNumberMsg,
} from "./messages";

const phoneNumberReg =
  /^(\+?\d{1,4}[-.\s]?)?((\(\d{1,4}\))|\d{1,4})[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,4}$/;

export const emailShape = string().email(emailMsg);
export const phoneNumberShape = string().matches(phoneNumberReg, phoneNumberMsg);
export const integerShape = number()
  .typeError(numberMsg)
  .min(0, getMinNumber(0))
  .integer(integerMsg);
export const dateShape = date().typeError(dateMsg);

export const emailShapeRequired = emailShape.required(requiredMsg);
export const stringShapeRequired = string().required(requiredMsg);
export const booleanShape = boolean();
export const booleanShapeRequired = boolean().required(requiredMsg);
export const dateShapeRequired = dateShape.required();
export const integerShapeRequired = integerShape.required(requiredMsg);
export const phoneNumberShapeRequired = phoneNumberShape.required(requiredMsg);
