import { Fragment, useCallback, useMemo } from "react";

import { filter, includes, keyBy } from "lodash";

import { Typography } from "@mui/material";

import { IShift } from "~/routes/api/types";

import { IShiftType } from "@/api";

import { Shift } from "../Shift/Shift";
import CustomSelect from "../TrackedComponents/Select";
import { ICustomSelectItem } from "../TrackedComponents/Select/types";

import { sortingFunction } from "./sorting";
import { IShiftTypeFilterDropdownProps } from "./types";

const defaultSx = {};
const multipleDropDownStyle = {
  ".MuiMenuItem-root": {
    display: "flex",
    ".shift-tile": {
      display: "flex",
      "flex-grow": 1,
    },
  },
};

export const ShiftTypeFilterDropdown = <T extends IShift | IShiftType>({
  selectedOption,
  shiftTypes,
  selectOption,
  excludeOptions,
  hoursInLabel = true,
  width,
  emptyOptionLabel = undefined,
  emptyOptionLabelDisplayWhenSelected = undefined,
  excludeNonPatientCare = false,
  excludeNonWorking = false,
  dropdownTypeOptionsToInclude,
  isMultiSelect,
  includePTO = false,
  open,
  variant,
  size,
  label,
  onClose,
  readonly = false,
  isAllSelectedWithEmptyOption = false,
  sx = defaultSx,
  className = "",
  limit,
  error,
  withCloseButton,
  dropdownSx,
  shrink,
}: IShiftTypeFilterDropdownProps<T>) => {
  const shiftTypesByKey = keyBy(shiftTypes, "key");
  withCloseButton = withCloseButton ?? isMultiSelect;

  const items = useMemo(() => {
    let filterItemsToDisplay = shiftTypes;
    // exclude custom options
    if (excludeOptions) {
      filterItemsToDisplay = filterItemsToDisplay.filter(
        ({ key }) => !excludeOptions.includes(key),
      );
    }

    // include non patient care options specifically

    if (excludeNonPatientCare) {
      let selectedKeys: string[];
      if (isMultiSelect) {
        selectedKeys = selectedOption ? selectedOption.map(({ key }) => key) : [];
      } else {
        selectedKeys = selectedOption?.key ? [selectedOption?.key] : [];
      }

      filterItemsToDisplay = filterItemsToDisplay.filter(
        ({ isPatientCareShift, key }) =>
          isPatientCareShift || selectedKeys?.includes(key) || (includePTO && key === "pto"),
      );
    }

    if (excludeNonWorking) {
      let selectedKeys: string[];
      if (isMultiSelect) {
        selectedKeys = selectedOption ? selectedOption.map(({ key }) => key) : [];
      } else {
        selectedKeys = selectedOption?.key ? [selectedOption?.key] : [];
      }

      filterItemsToDisplay = filterItemsToDisplay.filter(
        ({ isWorkingShift, key }) =>
          isWorkingShift || selectedKeys?.includes(key) || (includePTO && key === "pto"),
      );
    }

    // include custom options
    // if also use exclude non patient care, then will do both... will exclude non patient care and include custom options
    // in the case that both are selected, a custom option that is a non patient care shift will NOT be included
    if (dropdownTypeOptionsToInclude) {
      filterItemsToDisplay = dropdownTypeOptionsToInclude
        ? filterItemsToDisplay?.filter(({ displayableInDropdowns }) =>
            includes(displayableInDropdowns, dropdownTypeOptionsToInclude),
          )
        : filterItemsToDisplay;
    }

    const resultItems: ICustomSelectItem<T>[] = filterItemsToDisplay.map((shiftType) => {
      const { key, name } = shiftType;
      return {
        value: key,
        label: name,
        item: shiftType,
      };
    });
    if (emptyOptionLabel) {
      resultItems.push({
        value: "",
        label: emptyOptionLabel,
        item: undefined,
      });
    }
    return resultItems;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(shiftTypes.map(({ key }) => key)), emptyOptionLabel]);

  const sortedItems = useMemo(
    () => items.sort((a, b) => sortingFunction(a.item, b.item)),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [items],
  );

  const renderingShiftType = useCallback(
    (short = false) =>
      (optionToRender: ICustomSelectItem<T> | undefined) => {
        const shiftType = optionToRender?.item;

        // Empty Option
        if (!shiftType) {
          // if emptyOptionLabelDisplayWhenSelected is defined, display it when the empty option is selected
          if (emptyOptionLabelDisplayWhenSelected) {
            return <Typography>{emptyOptionLabelDisplayWhenSelected}</Typography>;
          }
          return <></>;
        }

        let shiftHoursInLabel = hoursInLabel && shiftType.isPaidShift && !short;
        if (hoursInLabel === "skipInput") {
          const options = [selectedOption].flat();
          if (options.find((option) => option?.key === shiftType.key)) {
            shiftHoursInLabel = false;
          }
        }

        return (
          <Fragment key={shiftType.key}>
            <Shift
              fullWidth={!width && !short}
              sx={{ justifyContent: "flex-start" }}
              shiftType={shiftType}
              variant={"medium"}
              hoursInLabel={shiftHoursInLabel}
            />{" "}
          </Fragment>
        );
      },
    [hoursInLabel, width, emptyOptionLabelDisplayWhenSelected, selectedOption],
  );

  const optionsForDropdown = useMemo(() => renderingShiftType(), [renderingShiftType]);
  const optionsForSelection = useMemo(() => renderingShiftType(true), [renderingShiftType]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const style = useMemo(() => ({ ...defaultSx, ...sx }), [JSON.stringify(sx)]);

  return (
    <CustomSelect<T>
      // TODO: forward with ...rest rather than hardcoding props we need to just forward
      readOnly={readonly}
      disabled={readonly}
      sx={style}
      shrink={shrink}
      style={dropdownSx}
      className={`${className} shift-type-filter-dropdown ${isMultiSelect ? "multi-select" : ""}`}
      MenuProps={{
        className: `shift-type-filter-dropdown-menu ${isMultiSelect ? "multi-select" : ""}`,
        sx: isMultiSelect ? multipleDropDownStyle : {},
      }}
      variant={variant}
      key={`shift-type-filter`}
      open={open}
      onClose={onClose}
      items={sortedItems}
      width={width}
      label={label ?? "Select Shift Type"}
      size={size}
      limit={limit}
      error={error}
      withCloseButton={withCloseButton}
      // Handle the onChange event of the dropdown
      onChange={(event) => {
        if (!isMultiSelect) {
          // If it's not a multi-select dropdown, select the option based on the selected value
          const value = event.target.value as T["key"];
          const optionToSelect = shiftTypesByKey[value];
          selectOption(optionToSelect);
        } else {
          // If it's a multi-select dropdown
          const values = (
            Array.isArray(event.target.value) ? event.target.value : [event.target.value]
          ) as string[];
          if (values.includes("")) {
            // If the empty string is selected, select all options
            selectOption([]);
            return;
          } else {
            // Else elect the options based on the selected values
            const optionsToSelect = values?.map((key: T["key"]) => shiftTypesByKey[key]) || [];
            selectOption(filter(optionsToSelect));
          }
        }
      }}
      multiple={isMultiSelect}
      displayEmpty={!!emptyOptionLabel}
      optionRenderComponent={optionsForDropdown}
      renderMultiSelectedValue={optionsForSelection}
      value={
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        isMultiSelect
          ? (selectedOption || []).map((option) => option.key)
          : selectedOption?.key || ""
      }
      checked={isMultiSelect}
      isAllSelectedWithEmptyOption={isAllSelectedWithEmptyOption}
    />
  );
};
