import { m7DayJs } from "@m7-health/shared-utils";
import { isNil } from "lodash";

import { IShiftType } from "~/api";
import { IShift } from "~/routes/api/types";

type TComparableShiftType = IShift | IShiftType | undefined;
export const sortingFunction = (a: TComparableShiftType, b: TComparableShiftType): number => {
  if (a?.key === b?.key) return 0;

  // 0. Sort the empty option
  // Actions such as this (Clear Filters etc.) should be the first option, always
  if (!a || !b) {
    if (!a && b) return -1;
    if (a && !b) return 1;
    return 0;
  }

  // 1. Sort by position if any
  if (!isNil(a?.sortPosition) && !isNil(b?.sortPosition)) {
    if (a.sortPosition < b.sortPosition) return -1;
    if (a.sortPosition > b.sortPosition) return 1;
  } else {
    if (!isNil(a?.sortPosition)) return -1;
    if (!isNil(b?.sortPosition)) return 1;
  }

  // 2. Sort by isPatientCareShift (true first)
  if (a?.isPatientCareShift && !b?.isPatientCareShift) return -1;
  if (!a?.isPatientCareShift && b?.isPatientCareShift) return 1;

  // 3. Sort specific keys
  const specificKeysOrder = [
    "administrative",
    "education",
    "orientationDay",
    "orientationNight",
    "pto",
  ];
  if (a && b) {
    const aIndex = specificKeysOrder.indexOf(a?.key);
    const bIndex = specificKeysOrder.indexOf(b?.key);
    if (aIndex !== -1 && bIndex === -1) return -1;
    if (aIndex === -1 && bIndex !== -1) return 1;
    if (aIndex !== -1 && bIndex !== -1) return aIndex - bIndex;
  }
  // 4.1 Sort by startTime
  if (a?.startTime && b?.startTime) {
    if (a?.startTime !== b?.startTime) {
      const startDate = m7DayJs(`2022-04-17T${a.startTime}`);
      const endDay = m7DayJs(`2022-04-17T${b.startTime}`);
      if (startDate < endDay) return -1;
      if (startDate > endDay) return 1;
    }

    // 4.2 Sort by EndTime
    else if (
      a?.durationSeconds &&
      b?.durationSeconds &&
      a?.durationSeconds !== b?.durationSeconds
    ) {
      if (a.durationSeconds < b.durationSeconds) return -1;
      if (a.durationSeconds > b.durationSeconds) return 1;
    }
  }

  // 5. Sort by scheduleViewColor
  if (a?.scheduleViewColor && b?.scheduleViewColor) {
    const colorComparison = a.scheduleViewColor.localeCompare(b.scheduleViewColor);
    if (colorComparison !== 0) return colorComparison;
  } else if (a?.scheduleViewColor) {
    return -1;
  } else if (b?.scheduleViewColor) {
    return 1;
  }

  // 6. For all other items and items with equal criteria, sort by name
  return a.name.localeCompare(b.name);
};
