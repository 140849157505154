import { useCallback, useMemo } from "react";

import { orderBy } from "lodash";

import { Delete, Edit, Person } from "@mui/icons-material";
import { Box, Chip, Divider, List, ListItem, Stack, Typography } from "@mui/material";

import {
  IPreferencesTemplate,
  useDeletePreferencesTemplateMutation,
  useInvalidateQuery,
  useListPreferencesTemplatesQuery,
} from "@/api";
import { CustomButton, CustomModal, PreferencesTemplateLabel } from "@/common/components";
import { NOT_EXISTING_UUID } from "@/common/constants";
import { useAppDispatch, useCurrentUnitId, useErrors, useToast } from "@/common/hooks";

import {
  closeModal,
  openModal,
  setPreferencesTemplateToUpdate,
  setPreferencesTemplateToUpdateUsers,
} from "../../store";

export const UpdatePreferencesTemplatesModal = ({ withApply }: { withApply?: boolean }) => {
  const currentUnitId = useCurrentUnitId();
  const dispatch = useAppDispatch();
  const { handleErrors } = useErrors();
  const { showSuccess } = useToast();
  const invalidateQuery = useInvalidateQuery();

  const { data: preferencesTemplates } = useListPreferencesTemplatesQuery(
    { unitIds: [currentUnitId || NOT_EXISTING_UUID] },
    { skip: !currentUnitId },
  );
  const { mutateAsync: deletePreferencesTemplate } = useDeletePreferencesTemplateMutation({});
  const orderedPreferencesTemplates = useMemo(
    () => orderBy(preferencesTemplates, ["createdAt", "label"]),
    [preferencesTemplates],
  );

  // Actions
  const closeModalAction = useCallback(() => {
    dispatch(closeModal("updatePreferencesTemplatesModal"));
  }, [dispatch]);
  const OpenUpdatePreferencesTemplateModal = useCallback(
    (preferencesTemplate: IPreferencesTemplate | null) => {
      return () => {
        dispatch(setPreferencesTemplateToUpdate(preferencesTemplate));
        dispatch(openModal("updatePreferencesTemplateModal"));
        dispatch(closeModal("updatePreferencesTemplatesModal"));
      };
    },
    [dispatch],
  );
  const deletePreferencesTemplateAction = useCallback(
    (preferencesTemplateId: IPreferencesTemplate["id"]) => {
      void (async () => {
        try {
          await deletePreferencesTemplate({ id: preferencesTemplateId });
          showSuccess("Preference Template deleted successfully");
          invalidateQuery(useListPreferencesTemplatesQuery);
        } catch (error) {
          handleErrors(error);
        }
      })();
    },
    [deletePreferencesTemplate, handleErrors, invalidateQuery, showSuccess],
  );
  const openApplyPreferencesTemplatesModal = useCallback(() => {
    dispatch(openModal("applyPreferencesTemplatesModal"));
    dispatch(closeModal("updatePreferencesTemplatesModal"));
  }, [dispatch]);
  const editPreferencesTemplateUsers = useCallback(
    (preferencesTemplate: IPreferencesTemplate) => () => {
      dispatch(openModal("updatePreferencesTemplateUsersModal"));
      dispatch(closeModal("updatePreferencesTemplatesModal"));
      dispatch(setPreferencesTemplateToUpdateUsers(preferencesTemplate));
    },
    [dispatch],
  );

  const modalContent = useMemo(() => {
    return (
      <List>
        {withApply && (
          <>
            <Stack
              direction="row"
              justifyContent="space-between"
              sx={{ mt: 2, alignItems: "center" }}
            >
              <Typography children="Apply templates for selected staff on current schedule" />
              <CustomButton
                trackingLabel="apply-preferences-templates"
                label="Apply"
                onClick={openApplyPreferencesTemplatesModal}
              />
            </Stack>
            <Divider sx={{ my: 3 }} />
            <Typography variant="h6" children="Edit Templates" sx={{ mb: 2 }} />
          </>
        )}

        {orderedPreferencesTemplates.map((preferencesTemplate) => (
          <ListItem key={preferencesTemplate.id} sx={{ p: 0 }}>
            <PreferencesTemplateLabel
              preferencesTemplate={preferencesTemplate}
              sx={{ width: "80px" }}
            />
            <Typography children={preferencesTemplate.name} width={"200px"} noWrap />
            <Chip
              deleteIcon={<Edit />}
              onClick={editPreferencesTemplateUsers(preferencesTemplate)}
              onDelete={editPreferencesTemplateUsers(preferencesTemplate)}
              sx={{
                width: "80px",
                borderRadius: "5px",
                svg: { fontSize: "18px !important" },
              }}
              label={
                <>
                  <Stack direction="row" spacing={1} alignItems="center">
                    <Typography children={preferencesTemplate.userIds.length} />
                    <Person />
                  </Stack>
                </>
              }
            />
            <Box sx={{ width: "100px" }} />
            <CustomButton
              onClick={() => {
                dispatch(openModal("updatePreferencesTemplateModal"));
                dispatch(setPreferencesTemplateToUpdate(preferencesTemplate));
              }}
              iconOnly
              startIcon={<Edit />}
              label={"Edit Preference Template"}
            />
            <CustomButton
              trackingLabel="delete-rule-set"
              onClick={() => deletePreferencesTemplateAction(preferencesTemplate.id)}
              iconOnly
              startIcon={<Delete />}
              confirmation="Are you sure you want to delete this preference template? It will also remove it from all users."
              label="Delete Preference Template"
            />
          </ListItem>
        ))}
      </List>
    );
  }, [
    deletePreferencesTemplateAction,
    dispatch,
    editPreferencesTemplateUsers,
    openApplyPreferencesTemplatesModal,
    orderedPreferencesTemplates,
    withApply,
  ]);

  return (
    <CustomModal
      trackingLabel="manage-all-preferences-templates"
      isOpen={true}
      modalHeaderText="Manage Preference Templates"
      primaryBtnText="Create Preference Template"
      secondaryBtnText="Close"
      modalContent={modalContent}
      onClose={closeModalAction}
      onSecondaryBtnClick={closeModalAction}
      onSubmit={OpenUpdatePreferencesTemplateModal(null)}
    />
  );
};
