import { buildApiListQueryHook } from "../_shared/helpers";
import { staleFor } from "../_shared/types";

import { HolidaySchedule } from "./types";

export const useListHolidaySchedulesQuery = buildApiListQueryHook<
  HolidaySchedule.API.List.QueryParams,
  HolidaySchedule.API.List.Response
>(HolidaySchedule.API.List.PATH, {
  staleTime: staleFor(30).seconds,
});
