import { Typography } from "@mui/material";

import FairnessToAverage from "~/common/components/FairnessToAverage";
import { NameCellStyled } from "~/features/Fairness/components/Cells/NameCell.styled";

export const NameCell = ({
  averageScore,
  firstName,
  lastName,
}: {
  averageScore: number;
  firstName: string;
  lastName: string;
}) => (
  <NameCellStyled>
    <FairnessToAverage variant="small" count={averageScore} average={averageScore} />
    <Typography variant="small">
      {lastName} {firstName}
    </Typography>
  </NameCellStyled>
);
