import { useCallback, useEffect, useMemo, useState } from "react";

import { Timezone } from "@m7-health/shared-utils";

import { Info } from "@mui/icons-material";
import { Box, Stack, Tooltip, Typography } from "@mui/material";
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import { Dayjs } from "~/common/packages/dayjs";

import { IShiftType, IStaffShift } from "@/api";
import { localDayJs } from "@/common/packages/dayjs";
import { TimeString, seconds } from "@/common/types";
import { dateToTimeString, msToMilitary, timeStringToDate, trimMs } from "@/common/utils/dates";

import { ShiftEditorHelpers } from "./helpers";

const { adjustToMax24Hours, isShiftInsideBoundaries } = ShiftEditorHelpers;

export const ShiftEditorTimePickers = ({
  timezone,
  staffShift,
  updateStaffShift,
  shiftType,
  readonly,
  setShiftValidity,
}: {
  timezone: Timezone;
  staffShift: IStaffShift;
  updateStaffShift: (staffShift: IStaffShift) => void;
  shiftType: IShiftType | undefined;
  readonly: boolean;
  setShiftValidity: (valid: boolean) => void;
}) => {
  /* State variables */

  // Buffer that always represents what the user input is,
  //  but only bubbles up the values to the update action if they are valid
  const [timesBuffer, setTimesBuffer] = useState<{
    startTime: Dayjs | null;
    endTime: Dayjs | null;
  }>({ startTime: null, endTime: null });
  const [duration, setDuration] = useState(staffShift.customDuration || shiftType?.durationSeconds);
  // Error management
  const [timeError, __setTimeError] = useState<string | null>(null);
  const setTimeError = useCallback(
    (error: string | null) => {
      __setTimeError(error);
      setShiftValidity(!error);
    },
    [setShiftValidity],
  );

  // Translate incoming shift start + duration to start + end
  const [startTime, endTime]: [Dayjs, Dayjs] | [null, null] = useMemo(() => {
    let startTimeString: TimeString | null = null;

    startTimeString ||= staffShift.customStartTime;
    startTimeString ||= shiftType?.startTime || null;

    const shiftDuration = staffShift.customDuration || shiftType?.durationSeconds;
    if (startTimeString && shiftDuration) {
      const startDayjs = timeStringToDate(startTimeString, timezone);
      const end = startDayjs.add(shiftDuration, "seconds").toDate();

      return [startDayjs, localDayJs.tz(end, timezone)];
    }

    return [null, null];
  }, [
    timezone,
    staffShift.customDuration,
    staffShift.customStartTime,
    shiftType?.startTime,
    shiftType?.durationSeconds,
  ]);

  // Update buffer every time incoming actual shift start/end changes
  useEffect(() => {
    if (startTime?.toISOString() !== timesBuffer.startTime?.toISOString())
      setTimesBuffer((times) => ({
        ...times,
        startTime,
      }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startTime]);

  useEffect(() => {
    if (endTime?.toISOString() !== timesBuffer.endTime?.toISOString())
      setTimesBuffer((times) => ({
        ...times,
        endTime,
      }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [endTime]);

  // Set duration when buffer is updated
  useEffect(() => {
    if (timesBuffer.startTime && timesBuffer.endTime)
      setDuration(timesBuffer.endTime.diff(timesBuffer.startTime, "seconds") as seconds);
  }, [timesBuffer.startTime, timesBuffer.endTime]);

  // Reset error when shift type changes
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => setTimeError(null), [shiftType]);

  // Update function
  // 1 - adjust the given value to make that start<>end are both in a 24hours boundaries from each other
  // 2 - set the buffer
  // 3 - validate and: propagate or set error
  const updateBufferAndPropagate = (key: "startTime" | "endTime", value: Dayjs | null) => {
    if (!value) return;

    // 1. ADJUST TO MAX 24 HOURS
    value = adjustToMax24Hours({ value, timezone, timesBuffer, key });

    setTimesBuffer((times) => ({
      ...times,
      [key]: value,
    }));

    const buffer = { ...timesBuffer, [key]: value };

    // 2. SET BUFFER
    const bufferStartTime = buffer.startTime;
    const bufferEndTime = buffer.endTime;

    // Nothing to validate or propagate
    if (!bufferStartTime || !bufferEndTime || !shiftType) return;

    // 3. VALIDATION HERE
    const startIsBeforeEnd = bufferStartTime.isBefore(bufferEndTime);
    const shiftIsInsideBoundaries = isShiftInsideBoundaries({
      shiftType,
      bufferStartTime,
      bufferEndTime,
      timezone,
    });

    // If valid, propagate
    if (startIsBeforeEnd && shiftIsInsideBoundaries) {
      const newStartTime = dateToTimeString(bufferStartTime);
      const newDuration = bufferEndTime.diff(bufferStartTime, "seconds") as seconds;

      // If same as shift type, remove custom values
      const sameAsShiftType =
        trimMs(newStartTime) === trimMs(shiftType.startTime) &&
        newDuration === shiftType.durationSeconds;
      updateStaffShift({
        ...staffShift,
        ...(sameAsShiftType
          ? { customDuration: null, customStartTime: null }
          : { customStartTime: newStartTime, customDuration: newDuration }),
      });
      setTimeError(null);
      return;
    }

    // Error
    if (!startIsBeforeEnd) setTimeError("Start time must be before end time");
    else setTimeError("Shift must be within original shift type boundaries");
  };

  return (
    <Box className="shift-editor-shift-time-pickers">
      <Stack direction={"row"} className="time-pickers-inputs">
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          {(["startTime", "endTime"] as const).map((key) => (
            <TimePicker
              key={key + staffShift.id + (shiftType?.key || "") + (staffShift.status || "")}
              closeOnSelect={false}
              value={timesBuffer[key]}
              disabled={readonly}
              onChange={(value) => updateBufferAndPropagate(key, value)}
            />
          ))}
        </LocalizationProvider>
      </Stack>
      <Stack direction={"row"} className="time-pickers-duration-and-error">
        {timeError && (
          <Stack direction={"row"}>
            <Typography className="time-pickers-error">Invalid time</Typography>
            <Tooltip
              children={<Info color="error" sx={{ ml: 0.5 }} fontSize="small" />}
              title={timeError}
            />
          </Stack>
        )}
        <Box flexGrow={1} />
        {duration !== undefined && (
          <Typography className="time-pickers-duration">
            {msToMilitary(duration * 1000)
              .slice(0, -7)
              .replace(/:/, "h ")
              .replace(/^0/, "")}
            m
          </Typography>
        )}
      </Stack>
    </Box>
  );
};
