import { useEffect, useMemo } from "react";
import { useLocation, useSearchParams } from "react-router-dom";

import { filter, map, uniqBy } from "lodash";

import { TextField } from "@mui/material";

import { useAppDispatch, useAppSelector } from "~/common/hooks/useRedux";
import { useAppConfigQuery } from "~/features/User/queries";

import { IUnitConfig } from "#/features/User/types";
import { Autocomplete } from "@/common/components";
import { black } from "@/common/theming";

import { houseViewStore } from "../store";

const emptyUnits = [] as IUnitConfig[];

const FacilityPicker = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const isRosterPage = location.pathname.includes("/roster");
  const selectedFacilityQueryParam = searchParams.get("facility");

  const units = useAppConfigQuery().data?.units || emptyUnits;
  const facilities = useMemo(() => filter(uniqBy(map(units || [], "facility"), "id")), [units]);
  const dispatch = useAppDispatch();
  const selectedFacilityId = useAppSelector(
    (state) => state.houseView.pageFilters.selectedFacilityId,
  );

  const selectedFacilityFromQuery = facilities.find(
    (facility) => facility.name === selectedFacilityQueryParam,
  );
  const selectedFacilityFromStore = useMemo(() => {
    return facilities.find((facility) => facility.id === selectedFacilityId);
  }, [selectedFacilityId, facilities]);

  const selectedFacility = useMemo(() => selectedFacilityFromStore, [selectedFacilityFromStore]);

  useEffect(() => {
    if (!selectedFacility && facilities.length >= 1) {
      dispatch(houseViewStore.state.selectFacility(facilities[0]!.id));
    }
  }, [dispatch, facilities, selectedFacility]);

  useEffect(() => {
    if (selectedFacilityFromQuery && selectedFacilityFromQuery !== selectedFacilityFromStore) {
      // Set facility in params if there are more than one facility
      if (facilities.length <= 1) return;

      setSearchParams((otherParams) => {
        otherParams.set("facility", selectedFacilityFromQuery.name);
        return otherParams;
      });

      dispatch(houseViewStore.state.selectFacility(selectedFacilityFromQuery.id));
    }
  }, [
    dispatch,
    facilities.length,
    selectedFacilityFromQuery,
    selectedFacilityFromStore,
    setSearchParams,
  ]);

  if (selectedFacility) {
    const disabled = facilities.length === 1;

    return (
      <Autocomplete
        trackingLabel="Facility Picker"
        sx={{
          width: "200px",
          ".MuiInputBase-root": { padding: "0 10px" },
          ...(disabled
            ? {
                ".MuiAutocomplete-endAdornment": { display: "none" },
                "input.Mui-disabled": { "-webkit-text-fill-color": black },
              }
            : {}),
        }}
        options={facilities}
        getOptionLabel={(option) => option.name}
        value={selectedFacility}
        disableClearable={true}
        renderInput={(params) => <TextField {...params} label="Facility" />}
        disabled={disabled}
        onChange={(_event, facility) => {
          if (facility) {
            dispatch(houseViewStore.state.selectFacility(facility.id));

            // Set facility in params if there are more than one facility
            if (facilities.length > 1 && !isRosterPage)
              setSearchParams((otherParams) => {
                otherParams.set("facility", facility.name);
                return otherParams;
              });
          }
        }}
      />
    );
  }

  return <></>;
};

export const _HouseView_FacilityPicker = FacilityPicker;
