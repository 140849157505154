import { useMemo } from "react";

import { filter, List } from "lodash";

/** This hook is a memoized wrapper around lodash's filter function that allows you to key an array of objects by a key.
 *
 * @example results = useFilterBy(users, ({status}) => status === "active");
 */
export const useFilterBy = <T>(
  data: List<T> | null | undefined,
  filterFn: (item: T) => boolean,
  deps: React.DependencyList,
) =>
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useMemo(() => filter(data || [], filterFn), [data, filterFn.toString(), ...deps]);
