import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { ISchedule } from "@/api";

import { EApprovableEntityTab, EApprovableEntityType } from "../types";

import { IShiftSwapRequestState, initialState } from "./initialState";

export const shiftSwapRequestSlice = createSlice({
  name: "shiftSwapRequest",
  initialState,
  reducers: {
    resetRequestState: () => initialState,
    setDisplayedRequestStatusTab: (state, action: PayloadAction<EApprovableEntityTab | null>) => {
      state.displayedRequestStatusTab = action.payload;
    },

    setNewShiftSwapRequestCount: (state, action: PayloadAction<number>) => {
      state.newShiftSwapRequestCount = action.payload;
    },

    setSelectedScheduleId: (state, action: PayloadAction<ISchedule["id"] | undefined>) => {
      state.selectedScheduleId = action.payload;
    },

    setSelectedRequestType: (state, action: PayloadAction<EApprovableEntityType | undefined>) => {
      state.selectedRequestType = action.payload;
    },

    setTimeOffRequestModalProperties: (
      state,
      action: PayloadAction<IShiftSwapRequestState["timeOffRequestModal"]>,
    ) => {
      state.timeOffRequestModal = action.payload;
    },
  },
});

export const {
  resetRequestState,
  setDisplayedRequestStatusTab,
  setNewShiftSwapRequestCount,
  setSelectedRequestType,
  setSelectedScheduleId,
  setTimeOffRequestModalProperties,
} = shiftSwapRequestSlice.actions;
export default shiftSwapRequestSlice.reducer;
