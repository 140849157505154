import { THouseViewState } from "../../store";
import { StaffItemProps } from "../StaffItem";

import { _HouseView_SideBar_BottomActions } from "./helpers/BottomActions";
import { _HouseView_SideBar_TopActions } from "./helpers/TopActions";
import { HouseView_SideBar_UpdateNoteModal } from "./helpers/UpdateNoteModal";
import { _HouseView_SideBar_AssignAttributes } from "./states/AssignAttributes";
import { _HouseView_SideBar_FindStaffToWork } from "./states/FindStaffToWork";
import { _HouseView_SideBar_SelectedUnit } from "./states/SelectedUnit";

const HouseViewSideBar = () => <></>;

type SideBarActions = NonNullable<THouseViewState["sidebarCurrentAction"]["inProgress"]>;
const validateStaffItemsLayouts = <
  TParam extends Partial<{
    [key in SideBarActions]: StaffItemProps["layout"];
  }>,
>(
  param: TParam,
) => param;

export const STAFF_ITEMS_LAYOUTS = validateStaffItemsLayouts({
  selectedUnit: [
    "shift",
    "staffName",
    "note",
    "staffType",
    "positions",
    "employmentType",
    "shiftTime",
    "status",
    "shiftIncentiveLevel",
  ],
  floating: [
    "shift",
    "shiftTime",
    "staffName",
    "note",
    "employmentType",
    "miniCalendar",
    "lastStatusChange",
    "unitName",
    "workingHoursLine1",
    "shiftIncentiveLevel",
  ],
  updateStatus: [
    "shift",
    "shiftTime",
    "staffName",
    "status",
    "staffType",
    "employmentType",
    "positions",
    "lastStatusChange",
    "miniCalendar",
    "workingHours",
    "shiftIncentiveLevel",
  ],
  assignAttributes: [
    "shift",
    "shiftTime",
    "staffName",
    "staffType",
    "employmentType",
    "miniCalendar",
    "workingHours",
    "shiftIncentiveLevel",
  ],
  findStaffToWork: [
    "floatedUnitTag",
    "staffName",
    "staffType",
    "employmentType",
    "miniCalendar",
    "workingHours",
    "phoneNumber",
  ],
} as const);

// eslint-disable-next-line @typescript-eslint/naming-convention
const _check_layout_type: Partial<{
  [key in SideBarActions]: StaffItemProps["layout"];
}> = STAFF_ITEMS_LAYOUTS;

HouseViewSideBar.States = {
  SelectedUnit: _HouseView_SideBar_SelectedUnit,
  // Actions
  AssignAttributes: _HouseView_SideBar_AssignAttributes,
  FindStaffToWork: _HouseView_SideBar_FindStaffToWork,
};
// Helpers
HouseViewSideBar.Helpers = {
  TopActions: _HouseView_SideBar_TopActions,
  BottomActions: _HouseView_SideBar_BottomActions,
  UpdateNoteModal: HouseView_SideBar_UpdateNoteModal,
};

export { HouseViewSideBar };
