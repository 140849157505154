import { useCallback, useState } from "react";

import { m7DayJs, PreferencesTemplate } from "@m7-health/shared-utils";
import { clone } from "lodash";

import { Grid, Typography } from "@mui/material";

import { ShiftType } from "@/api";
import CustomInput from "@/common/components/Input";
import { iconComponentForShift } from "@/common/constants";
import { useAppSelector, useCurrentUnit } from "@/common/hooks";
import { black, darkPurple, white } from "@/common/theming";
import { pxToRem } from "@/common/utils/pxToRem";

import { TSelectedDaysObject } from "./TemplateChanger";

export const TemplateCalendar = ({
  template,
  setSelectedDays,
  selectedDays,
  shiftsByKey,
  setTemplate,
}: {
  template: PreferencesTemplate.TTemplatedWeek[];
  shiftsByKey: Record<string, ShiftType.DTO>;
  selectedDays: TSelectedDaysObject;
  setSelectedDays: React.Dispatch<React.SetStateAction<TSelectedDaysObject>>;
  setTemplate: React.Dispatch<React.SetStateAction<PreferencesTemplate.TTemplatedWeek[]>>;
}) => {
  const weekLengthMax = useCurrentUnit()?.scheduleDurationInWeeks;

  const templateLengthFromState = useAppSelector(
    (state) =>
      state.roster.updatePreferencesTemplateModal.preferencesTemplateToUpdate
        ?.templatedPreferencesWeeks.length,
  );
  const [weekLength, setWeekLength] = useState(templateLengthFromState || 1);

  //Makes sure that the length of the template always matches the template weeks value
  const weekLengthChanged = useCallback(
    (newWeekLength: number) => {
      if (weekLengthMax && newWeekLength > weekLengthMax) return;
      if (newWeekLength < 1) return;
      setWeekLength(newWeekLength);
      setTemplate((currentTemplate) => {
        const newTemplate = [...currentTemplate];
        while (newTemplate.length !== newWeekLength) {
          if (newTemplate.length < newWeekLength) {
            // Push duplicate last available week
            const toPush = clone(
              newTemplate[newTemplate.length - 1] || [null, null, null, null, null, null, null],
            ) as PreferencesTemplate.TTemplatedWeek;
            newTemplate.push(toPush);
          } else {
            newTemplate.pop();
          }
        }
        return newTemplate;
      });
    },
    [setTemplate, weekLengthMax],
  );

  const handleDayClick = useCallback(
    (weekIndex: number, dayIndex: number) => {
      setSelectedDays((currentSelectedDays) => {
        const currentSelectedValue = currentSelectedDays[`${weekIndex}-${dayIndex}`]?.selected;
        return {
          ...currentSelectedDays,
          [`${weekIndex}-${dayIndex}`]: {
            selected: !currentSelectedValue,
            weekIndex,
            dayIndex,
          },
        };
      });
    },
    [setSelectedDays],
  );
  return (
    <>
      <Grid
        container
        sx={{
          alignItems: "center",
          justifyContent: "space-between",
          flexWrap: "nowrap",
          pb: 1,
        }}
      >
        <Grid item>
          <Typography sx={{ fontSize: pxToRem(16) }}>Template Creation</Typography>
        </Grid>
        <Grid
          container
          item
          sx={{
            flexWrap: "nowrap",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-end",
            width: "40%",
          }}
        >
          <CustomInput
            label=""
            name="Weeks"
            type="number"
            counter
            maxNumber={weekLengthMax}
            onChange={(event) => weekLengthChanged(Number(event.target.value))}
            field={{
              value: weekLength,
              onChange: (newValue: string) => weekLengthChanged(Number(newValue)),
            }}
            sx={{
              mr: 1,
              ...(weekLengthMax === weekLength
                ? { 'svg[data-testid="AddIcon"]': { opacity: 0.2, cursor: "not-allowed" } }
                : {}),
              ...(weekLength === 1
                ? { 'svg[data-testid="RemoveIcon"]': { opacity: 0.2, cursor: "not-allowed" } }
                : {}),
            }}
          />
          <Typography sx={{ fontSize: pxToRem(14), width: "fit-content" }}>Week(s)</Typography>
        </Grid>
      </Grid>
      <Grid container item sx={{ flexDirection: "column" }} gap={1}>
        {template.map((week, weekIndex) => (
          <Grid
            container
            item
            key={weekIndex}
            sx={{
              backgroundColor: white,
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            {week.map((dayShiftTypeKey, dayIndex) => {
              const dayIsSelected = selectedDays[`${weekIndex}-${dayIndex}`]?.selected;
              const dayShiftType = dayShiftTypeKey ? shiftsByKey[dayShiftTypeKey] : undefined;
              const Icon = dayShiftType
                ? iconComponentForShift(dayShiftType.muiIconClassName)
                : undefined;
              return (
                <Grid
                  container
                  item
                  key={`${weekIndex}-${dayIndex}`}
                  sx={{
                    height: 40,
                    backgroundColor: dayIsSelected
                      ? black
                      : dayShiftType
                        ? dayShiftType.scheduleViewColor
                        : "none",
                    border: `1px dashed ${darkPurple}`,
                    alignItems: "center",
                    flexDirection: "column",
                    flexWrap: "nowrap",
                  }}
                  xs={1.6}
                  onClick={() => handleDayClick(weekIndex, dayIndex)}
                >
                  <Grid item>
                    <Typography
                      sx={{
                        fontSize: pxToRem(12),
                        width: "fit-content",
                        color: dayIsSelected ? white : black,
                      }}
                    >
                      {m7DayJs().isoWeekday(dayIndex).format("ddd")}
                    </Typography>
                  </Grid>
                  <Grid item>
                    {Icon && (
                      <Icon
                        sx={{
                          color: dayIsSelected ? white : dayShiftType?.iconColor,
                          fontSize: pxToRem(14),
                        }}
                      />
                    )}
                  </Grid>
                </Grid>
              );
            })}
          </Grid>
        ))}
      </Grid>
    </>
  );
};
