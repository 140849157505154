import { Grid, Typography } from "@mui/material";

import { StaffDetails, Unit } from "~/api";

import { CompactFloatedTag } from "@/common/components/CompactFloatedTag/CompactFloatedTag";
import { lines } from "@/common/theming";

interface NameCellProps {
  staffDetails: StaffDetails.DTO | undefined;
  unitId: Unit.DTO["id"];
}

export const NameCell = ({ staffDetails, unitId }: NameCellProps) => {
  const isWorkingAwayFromOutside = staffDetails?.homeUnitId && staffDetails.homeUnitId !== unitId;

  return (
    <Grid
      container
      flexWrap="nowrap"
      alignItems="center"
      sx={{
        paddingLeft: "14px",
        height: "100%",
        paddingRight: "8px",
        background: "transparent",
        borderRight: `1px solid ${lines}`,
      }}
      className={`header-cell-name ${isWorkingAwayFromOutside ? "floated-from-another-unit" : ""}`}
    >
      <Typography ml={1} mr={1} variant="small" className="header-cell-name-attribute">
        {staffDetails?.user?.lastName.trim()} {staffDetails?.user?.firstName.trim()},{" "}
        {staffDetails?.staffType.name}{" "}
      </Typography>
      {isWorkingAwayFromOutside && <CompactFloatedTag unitId={staffDetails.homeUnitId} />}
    </Grid>
  );
};
