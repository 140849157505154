import { useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";

import { isInteger } from "lodash";

import { Box, CircularProgress, Grid, Typography } from "@mui/material";

import CustomTable from "~/common/components/Table";
import { useAppDispatch, useAppSelector } from "~/common/hooks/useRedux";
import { setRosterTablePageSize } from "~/features/Roster/store";
import {
  IKioskDto,
  IRosterItem,
  ISchedulerDto,
  IStaffWithDetailsDto,
} from "~/features/Roster/types";

import { User } from "@/api";
import { ITable } from "@/common/types";

import { useColumns } from "./useColumns";

interface IRosterTableProps {
  // is showing suspended users
  isSuspended: boolean;
  rosterData: ITable<IStaffWithDetailsDto | ISchedulerDto | IKioskDto> | undefined;
  isRosterDataLoading: boolean;
}

export const StaffRosterTable = ({
  isSuspended,
  rosterData,
  isRosterDataLoading,
}: IRosterTableProps) => {
  const dispatch = useAppDispatch();

  const [searchParams, setSearchParams] = useSearchParams();
  const [pageIndex, setPageIndex] = useState<number>();

  useEffect(() => {
    const pageParam = Number(searchParams.get("page"));
    const index = !isNaN(pageParam) && isInteger(pageParam) && pageParam >= 1 ? pageParam - 1 : 0;
    setPageIndex(index);
  }, [searchParams]);

  const pageSize = useAppSelector((state) => state.roster.rosterTable.pageSize);

  const [staffItems, total] = useMemo(() => {
    const { data: items = [], total: totalStaff } = rosterData || {};
    return [
      items.filter(
        (item) => item.userType === User.ERole.staff || item.userType === User.ERole.kiosk,
      ),
      totalStaff,
    ];
  }, [rosterData]);

  // same columns if home unit or not, the only differentiation is with suspended users
  const { columns } = useColumns(isSuspended);

  if (!rosterData || isRosterDataLoading) {
    return <CircularProgress />;
  }

  if (!isRosterDataLoading && staffItems.length === 0) {
    return (
      <Grid flexDirection="column" alignItems="center" container mt="15%">
        {isSuspended ? (
          <Typography mt={2} variant="shy">
            No suspended users
          </Typography>
        ) : (
          <>
            <Typography mt={2} variant="shy">
              This unit is empty.
            </Typography>
            <Typography variant="shy">Use “Add User” to start adding personnel.</Typography>
          </>
        )}
      </Grid>
    );
  }

  const handlePageChange = (index: number) => {
    setSearchParams({ page: (index + 1).toString() });
  };

  const handleRowsPerPageChange = (rowsPerPage: number) => {
    dispatch(setRosterTablePageSize(rowsPerPage));
    setSearchParams({ page: "1" });
  };

  return (
    <Box width="100%" height="100%" minHeight={0} maxHeight="100%">
      <CustomTable<IRosterItem>
        columns={columns}
        data={staffItems}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleRowsPerPageChange}
        pageIndex={pageIndex}
        pageSize={pageSize}
        showPagination
        total={total}
        stickyTableHead
        stretchVertically
        wrapHeaders
      />
    </Box>
  );
};
