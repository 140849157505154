import { useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { yupResolver } from "@hookform/resolvers/yup";
import { Timezone, US_MAJOR_CITY_TIMEZONES } from "@m7-health/shared-utils";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { object, string } from "yup";

import { Box, Grid, TextField, Typography } from "@mui/material";

import { CustomInputControlled } from "~/common/components/Input";
import CustomModal from "~/common/components/Modal";
import { CustomSelectControlled } from "~/common/components/TrackedComponents/Select";
import { useErrors } from "~/common/hooks/useErrors";
import { useAppDispatch, useAppSelector } from "~/common/hooks/useRedux";
import { useToast } from "~/common/hooks/useToast";
import { timezones } from "~/common/packages/dayjs";
import { axiosInstance } from "~/common/packages/httpClient";
import { setSelectedUnit, setUnitModal } from "~/common/store";
import { getMaxMsg, getMaxNumber, requiredMsg } from "~/common/validation/messages";
import { integerShapeRequired } from "~/common/validation/shapes";
import { resetOpenShiftState } from "~/features/OpenShifts/store";
import { routes } from "~/routes";
import { createUnitApi, editUnitApi } from "~/routes/api";
import { useFacilitiesQuery } from "~/routes/queries";

import { Autocomplete } from "@/common/components";
import { ICommonState } from "@/common/store/types";

const UnitModal = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { showSuccess } = useToast();
  const { handleErrors } = useErrors();
  const queryClient = useQueryClient();

  const selectedUnit = useAppSelector((state) => state.common.currentUnit);
  const unitModalState = useAppSelector((state) => state.common.unitModalType);

  const isUnitModalOpen = Boolean(unitModalState);
  const isCreateUnitModalOpen = unitModalState === "create";
  const isEditUnitModalOpen = unitModalState === "edit";

  const unitId = selectedUnit?.id;

  const { data: facilities = [] } = useFacilitiesQuery();
  const facilitiesOptions = [
    ...facilities.map((facility) => ({
      label: facility.name,
      value: facility.id,
    })),
  ];

  const unitFormSchema = {
    scheduleDurationInWeeks: integerShapeRequired.max(12, getMaxNumber(12)),
    unitName: string().max(50, getMaxMsg(50)).required(requiredMsg),
    unitTimezone: string().required(),
  };

  const unitFormSchemaWithFacilities = {
    ...unitFormSchema,
    unitFacility: string().required(),
  };

  const {
    control,
    reset,
    formState: { errors, isValid, isDirty },
    getValues,
    watch,
    setValue,
    setError,
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(object().shape(unitFormSchemaWithFacilities).required()),
  });

  useEffect(() => {
    if (selectedUnit && isEditUnitModalOpen) {
      reset({
        scheduleDurationInWeeks: selectedUnit?.scheduleDurationInWeeks,
        unitName: selectedUnit?.name,
        unitTimezone: selectedUnit?.timezone,
        unitFacility: selectedUnit?.facility?.id,
      });
    } else {
      reset({
        scheduleDurationInWeeks: 1,
        unitName: "",
        unitTimezone: timezones[0]?.value,
        unitFacility: "",
      });
    }
  }, [selectedUnit, reset, isEditUnitModalOpen]);

  const onError = (error: unknown) => handleErrors(error, setError);

  const onSettled = () => {
    void queryClient.invalidateQueries({ queryKey: ["units"] });
  };

  const { mutate: createUnitMutation, isPending: createUnitApiLoading } = useMutation({
    mutationFn: createUnitApi,

    onSuccess: async ({ id }) => {
      reset();

      dispatch(setUnitModal(""));

      const { data: newUnitDetails } = await axiosInstance.get(`/units/${id}`);

      if (newUnitDetails?.id && newUnitDetails?.name) {
        dispatch(setSelectedUnit(newUnitDetails));
        let newRoute = routes.staffRosterUnit.children[0]?.path || "";
        newRoute = newRoute.replace(":unitId", id);
        navigate(newRoute);
      }

      dispatch(resetOpenShiftState());

      showSuccess("New unit created successfully");
    },
    onError,
    onSettled,
  });

  const { mutate: editUnitMutation, isPending: editUnitApiLoading } = useMutation({
    mutationFn: editUnitApi,
    onSuccess: () => {
      if (unitId) {
        const values: {
          scheduleDurationInWeeks: number;
          unitName: string;
          unitTimezone: string;
          unitFacility: string;
        } = getValues() as {
          scheduleDurationInWeeks: number;
          unitName: string;
          unitTimezone: string;
          unitFacility: string;
        };
        const {
          scheduleDurationInWeeks,
          unitName,
          unitTimezone,
          unitFacility: unitFacilityId,
        } = values;
        const unitFacility = facilities.find((facility) => facility.id === unitFacilityId);
        const unitData: ICommonState["selectedUnit"] = {
          ...selectedUnit,
          label: unitName,
          name: unitName,
          scheduleDurationInWeeks: scheduleDurationInWeeks,
          timezone: unitTimezone,
          id: unitId,
          value: unitId,
          facility: {
            id: unitFacilityId,
            name: unitFacility?.name || "",
            configuration: unitFacility?.configuration || {},
          },
        };
        dispatch(setSelectedUnit(unitData));

        dispatch(setUnitModal(""));

        showSuccess("Unit updated successfully");
      }
    },
    onError,
    onSettled,
  });

  const currentUnitTimezone = watch("unitTimezone") as Timezone | undefined;
  const currentUnitTimezoneOption = useMemo(
    () =>
      currentUnitTimezone
        ? US_MAJOR_CITY_TIMEZONES.find((option) => option.timezone === currentUnitTimezone)
        : undefined,
    [currentUnitTimezone],
  );

  const handleEditUnit = () => {
    const { scheduleDurationInWeeks, unitName, unitTimezone, unitFacility } = getValues();

    const body = {
      scheduleDurationInWeeks,
      unitTimezone,
      unitName,
      facilityId: unitFacility as string,
    };

    if (isEditUnitModalOpen && unitId) {
      editUnitMutation({
        body,
        unitId,
      });
    } else {
      createUnitMutation(body);
    }
  };

  const onSecondaryBtnClick = () => {
    if (isCreateUnitModalOpen) {
      dispatch(setUnitModal(""));
      reset();
    } else {
      dispatch(setUnitModal(""));
      reset();
    }
  };

  const isLoading = editUnitApiLoading || createUnitApiLoading;

  const modalContent = (
    <Grid container flexDirection="column">
      <Grid item mb={2}>
        <Typography fontSize="0.875rem" sx={{ opacity: 0.6 }}>
          * Required information
        </Typography>
      </Grid>
      <Grid item>
        <CustomInputControlled
          label="Unit name*"
          name="unitName"
          control={control}
          errors={errors}
          fullWidth
        />
      </Grid>
      {
        <Grid item mt={2}>
          <CustomSelectControlled
            items={facilitiesOptions}
            label="Select unit facility*"
            name="unitFacility"
            control={control}
            fullWidth
          />
        </Grid>
      }
      <Grid item mt={2}>
        <Autocomplete<(typeof US_MAJOR_CITY_TIMEZONES)[number], true>
          multiple
          trackingLabel="unitTimezone"
          options={US_MAJOR_CITY_TIMEZONES}
          groupBy={(option) => option.state}
          getOptionLabel={(option) => `${option.city} (${option.state}) - ${option.timezone}`}
          disabled={!isCreateUnitModalOpen}
          size="small"
          value={currentUnitTimezoneOption ? [currentUnitTimezoneOption] : undefined}
          onChange={(_event, [_oldOption, newOption]) => {
            if (!newOption) return;
            setValue("unitTimezone", newOption.timezone);
          }}
          renderTags={([option]) => <Box ml={1}>{option?.timezone}</Box>}
          renderInput={(params) => <TextField {...params} label="Timezone" />}
          noOptionsText="City is not listed. Please check the correct timezone on Google and select it from the dropdown."
        />
      </Grid>
      <Grid item mt={2}>
        <CustomInputControlled
          control={control}
          disabled={!isCreateUnitModalOpen}
          errors={errors}
          label="Unit schedule duration (weeks)*"
          name="scheduleDurationInWeeks"
          type="number"
          counter
          maxNumber={12}
        />
      </Grid>
    </Grid>
  );

  return (
    <CustomModal
      isOpen={isUnitModalOpen}
      modalContent={modalContent}
      modalHeaderText={isEditUnitModalOpen ? "Edit Unit" : "Create a new unit"}
      primaryBtnText={isEditUnitModalOpen ? "Save changes" : "Create"}
      onSecondaryBtnClick={onSecondaryBtnClick}
      onSubmit={handleEditUnit}
      primaryDisabled={!isValid || isLoading || !isDirty}
    />
  );
};

export default UnitModal;
