import { Grid, Typography } from "@mui/material";

import { black, lightGray, lines } from "~/common/theming/colors";

export const SmallHeader = ({ text = "Schedule avg score" }: { text?: string }) => (
  <Grid
    container
    alignItems="center"
    height="25px"
    sx={{
      borderTop: `1px solid ${lines}`,
      backgroundColor: lightGray,
      width: "100%",
    }}
    pl={2}
  >
    <Typography sx={{ fontSize: "0.75rem", fontWeight: 400 }} color={black}>
      {text}
    </Typography>
  </Grid>
);
