import { useCallback } from "react";

import { YyyyMmDd } from "@m7-health/shared-utils";
import { get, isEqual, round } from "lodash";

import { Box, Grid, Tooltip, Typography } from "@mui/material";

import { Schedule, StaffCategory, Unit } from "~/api";
import { useAppDispatch, useAppSelector } from "~/common/hooks/useRedux";
import { Dayjs } from "~/common/packages/dayjs";
import { black, danger, lines, paleAlert } from "~/common/theming/colors";
import { returnStaffedColorForTarget } from "~/features/HouseView/hooks/useStaffingTabs";
import { useAppConfigQuery } from "~/features/User/queries";

import { houseViewStore } from "#/features/HouseView/store";
import { useAppFlags } from "@/common/hooks";
import { stripMilliseconds } from "@/common/utils/dates";

import { CELL_DIMENSIONS } from "../constants";
import { ITablesData } from "../types";

import { HeaderCellContainer, ShiftTypeWrapper } from "./HeaderCell.styled";

interface IHeaderCellProps {
  cellDate: Dayjs;
  columnKey: string;
  schedules: Schedule.DTO[];
  tablesData: ITablesData;
  unitId: Unit.DTO["id"];
}

export const HeaderCell = ({
  cellDate,
  columnKey: _columnKey,
  schedules,
  tablesData,
  unitId,
}: IHeaderCellProps) => {
  /** HIGH LEVEL STATE */
  const dispatch = useAppDispatch();
  const { balancingTheHouseSidebar } = useAppFlags();
  const { data: config } = useAppConfigQuery();
  const unitFromConfig = config?.units.find((unit) => unit.id === unitId);
  const dateNeedsAttentionFlag = false;
  const {
    selectedStaffCategory,
    showTargetLevels: selectedShowTargetLevels,
    customTimeRange: selectedCustomTimeRange,
  } = useAppSelector(
    (state) => ({
      selectedStaffCategory: state.houseView.pageFilters.selectedStaffCategory,
      showTargetLevels: state.houseView.pageFilters.showTargetLevels,
      customTimeRange: state.houseView.pageFilters.customTimeRange,
    }),
    isEqual,
  );
  const resetSidebarAndSelectDateAndUnit = useCallback(
    (formattedDate: YyyyMmDd) => {
      if (balancingTheHouseSidebar) {
        dispatch(houseViewStore.state.selectUnit(unitId));
        dispatch(houseViewStore.state.endFloating());
        dispatch(houseViewStore.state.endAssignAttributes());
        dispatch(houseViewStore.state.endFindStaffToWork());
        dispatch(houseViewStore.state.endEditShifts());
        dispatch(houseViewStore.state.selectDateForData(formattedDate));
      }
    },
    [dispatch, balancingTheHouseSidebar, unitId],
  );

  // facility time ranges
  const allFacilityTimeRanges =
    unitFromConfig?.facility?.configuration.settings?.houseViewTimeRanges;

  if (!selectedCustomTimeRange) {
    return null;
  }

  const timeRangesToShow =
    selectedCustomTimeRange.customAbbreviation === "All"
      ? allFacilityTimeRanges
      : [selectedCustomTimeRange];

  // unit time target levels
  const unitTimeTargetLevels = unitFromConfig?.configuration?.data?.timeTargetLevels;
  const unitTimeTargetLevelsByCategory =
    unitTimeTargetLevels?.[
      // StaffCategory could be position (position tab), so if not category found for selected category, use the nurse key as default
      (get(StaffCategory.EName, selectedStaffCategory || StaffCategory.EKey.nurse) as
        | StaffCategory.EName
        | undefined) || StaffCategory.EName[StaffCategory.EKey.nurse]
    ];

  return (
    <HeaderCellContainer
      flexDirection="column"
      alignItems="flex-start"
      sx={{
        opacity: 1,
        width: CELL_DIMENSIONS.width,
      }}
    >
      <Box
        sx={{
          backgroundColor: dateNeedsAttentionFlag ? paleAlert : "transparent",
          paddingTop: "0px",
          borderTop: dateNeedsAttentionFlag ? `1px solid ${danger}` : "1px solid transparent",
          marginTop: "-7px",
          marginBottom: "-7px",
          height: "54px",
        }}
      ></Box>
      <Grid>
        {timeRangesToShow?.map((aRange) => {
          const dayKey: YyyyMmDd = cellDate.format("YYYY-MM-DD") as YyyyMmDd;
          const schedule = schedules.find(
            ({ startDay, endDay, unitId: possibleUnitId }) =>
              startDay <= dayKey && endDay >= dayKey && possibleUnitId === unitId,
          );
          const shiftsByDayByRange = tablesData?.[schedule?.id || ""];
          const timeRangeKey = `${aRange.startTime}-${aRange.endTime}`;
          const count = round(shiftsByDayByRange?.[dayKey]?.[timeRangeKey] || 0, 1);

          const matchingTimeTargetLevel = unitTimeTargetLevelsByCategory?.find(
            (aLevel) =>
              stripMilliseconds(aLevel.startTime) === aRange.startTime &&
              stripMilliseconds(aLevel.endTime) === aRange.endTime,
          );
          const minCount = matchingTimeTargetLevel?.min;

          const backgroundColor = minCount ? returnStaffedColorForTarget(count, minCount) : "white";

          return balancingTheHouseSidebar ? (
            <Tooltip title={`View ${cellDate.format("MMM D, YYYY")}`} arrow>
              <Grid
                key={`${aRange.startTime}-${aRange.endTime}`}
                container
                alignItems="center"
                justifyContent="space-around"
                sx={{
                  borderTop: `1px solid ${lines}`,
                  height: CELL_DIMENSIONS.height,
                  background: backgroundColor,
                  borderBottom: `0px solid ${lines}`,
                  borderRight: `1px solid ${lines}`,
                  cursor: "pointer",
                }}
                onClick={() => {
                  resetSidebarAndSelectDateAndUnit(dayKey);
                }}
              >
                <ShiftTypeWrapper>
                  <Typography sx={{ fontSize: "0.7rem", color: black }}>
                    {count}
                    {selectedShowTargetLevels && minCount && `/${minCount}`}
                  </Typography>
                </ShiftTypeWrapper>
              </Grid>
            </Tooltip>
          ) : (
            <Grid
              key={`${aRange.startTime}-${aRange.endTime}`}
              container
              alignItems="center"
              justifyContent="space-around"
              sx={{
                borderTop: `1px solid ${lines}`,
                height: "27px",
                background: backgroundColor,
                borderBottom: `0px solid ${lines}`,
                borderRight: `1px solid ${lines}`,
              }}
            >
              <ShiftTypeWrapper>
                <Typography sx={{ fontSize: "0.7rem", color: black }}>
                  {count}
                  {selectedShowTargetLevels && minCount && `/${minCount}`}
                </Typography>
              </ShiftTypeWrapper>
            </Grid>
          );
        })}
      </Grid>
    </HeaderCellContainer>
  );
};
