import { buildApiListQueryHook } from "../_shared/helpers";

import { TargetLevel } from "./types";

export const useListTargetLevelsQuery = buildApiListQueryHook<
  TargetLevel.API.List.QueryParams,
  TargetLevel.API.List.Response
>(TargetLevel.API.List.PATH);

export const useListUnitTargetLevelsQuery = buildApiListQueryHook<
  TargetLevel.Unit.API.List.QueryParams,
  TargetLevel.Unit.API.List.Response
>(TargetLevel.Unit.API.List.PATH);
