import { Box, Stack, Typography } from "@mui/material";

import { bad, equal, excellent, good, poor, satisfactory } from "~/common/theming/colors";
import { pxToRem } from "~/common/utils/pxToRem";

import { ECaptionsVariant, ICaptionsItemProps, ICaptionsProps } from "./types";

const getCaptionsColorForTheVariant = (variant: ECaptionsVariant) => {
  switch (variant) {
    case ECaptionsVariant.Bad:
      return bad;
    case ECaptionsVariant.Poor:
      return poor;
    case ECaptionsVariant.Equal:
      return equal;
    case ECaptionsVariant.Satisfactory:
      return satisfactory;
    case ECaptionsVariant.Good:
      return good;
    case ECaptionsVariant.Excellent:
      return excellent;
  }
};

const CaptionsItem = ({ variant, text }: ICaptionsItemProps) => {
  return (
    <Stack direction="row" gap={1}>
      <Box
        width={14}
        height={14}
        minWidth={14}
        minHeight={14}
        borderRadius="2px"
        sx={{ backgroundColor: getCaptionsColorForTheVariant(variant) }}
      />
      <Typography fontSize={pxToRem(12)} lineHeight={pxToRem(15)}>
        {text}
      </Typography>
    </Stack>
  );
};

export const Captions = ({ data, sx }: ICaptionsProps) => {
  return (
    <Stack direction="row" gap={3} sx={sx}>
      {data.map(({ variant, text }, idx) => (
        <CaptionsItem key={idx} variant={variant} text={text} />
      ))}
    </Stack>
  );
};
