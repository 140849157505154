import { useEffect, useRef, useState } from "react";

import { CircularProgress } from "@mui/material";

import { seconds } from "~/common/types";

export const AppLoader = ({
  fullScreen = false,
  open,
  threshold = 0 as seconds,
}: {
  fullScreen?: boolean;
  open: boolean;
  threshold?: seconds;
}) => {
  const now = useRef(Date.now());
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (open) {
      now.current = Date.now();
      setIsOpen(true);
    } else {
      const rightNow = now.current;
      setTimeout(
        () => setIsOpen(false),
        Date.now() - rightNow > threshold ? 0 : threshold - (Date.now() - rightNow),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);
  if (!isOpen) {
    return <></>;
  }

  return (
    <div
      style={{
        ...(fullScreen
          ? {
              position: "fixed",
              width: "100vw",
              height: "100vh",
              zIndex: 9999,
              top: 0,
              left: 0,
              backdropFilter: "blur(1px)",
            }
          : {
              width: "100%",
              height: "100%",
            }),
        display: "flex", // Added for flexbox layout
        justifyContent: "center", // Centers horizontally
        alignItems: "center", // Centers vertically
      }}
    >
      <CircularProgress />
    </div>
  );
};
