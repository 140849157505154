import { useMemo, useRef } from "react";

import { isEqual } from "lodash";

// useMemo wrapper that ensure the same object is return even if dependencies changed,
//  but "internally" the object is the same.
export const useDeepMemo = <T>(factory: () => T, dependencies: unknown[]): T => {
  const previousValueRef = useRef<T>();
  const memoizedValue = useMemo(() => {
    const newValue = factory();

    // Check if the previous value is equal to the new one
    if (previousValueRef.current && isEqual(previousValueRef.current, newValue)) {
      return previousValueRef.current;
    }

    // Otherwise, update the reference to the new value
    previousValueRef.current = newValue;
    return newValue;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencies);

  return memoizedValue;
};
