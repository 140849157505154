import { memo } from "react";

import { StaffShiftCreated } from "~/api/eventLog/events";

import { EventLog } from "@/api";
import { ShiftV2 } from "@/common/components/Shift/ShiftV2";

import { IEventLogComponent } from "../..";
import { ShiftDetails } from "../helpers";

export const StaffShiftDeletedV1 = memo(
  ({
    event,
    indexedShiftTypes,
    usersById,
  }: IEventLogComponent<
    EventLog.DTO<StaffShiftCreated.PayloadByVersion[StaffShiftCreated.EVersions.v1]>
  >) => {
    const staffShift = event.eventPayload.staffShift;
    const shiftType = indexedShiftTypes?.[staffShift.scheduleId]?.[staffShift.shiftTypeKey];
    const author = usersById?.[event.authorId] || { firstName: "An M7 Admin", lastName: "" };

    return (
      <>
        <b>
          {author.firstName} {author.lastName}
        </b>{" "}
        deleted the {shiftType ? <ShiftV2 variant="small" shiftType={shiftType} /> : ""} shift.
        <ShiftDetails origin={event.eventPayload.origin} />{" "}
      </>
    );
  },
);
