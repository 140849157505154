import { Timezone } from "@m7-health/shared-utils";
import { Action, createSlice } from "@reduxjs/toolkit";
import { persistReducer, REHYDRATE } from "redux-persist";
import storage from "redux-persist/lib/storage";

import { ICommonState } from "./types";

import type { PayloadAction } from "@reduxjs/toolkit";

const initialState: ICommonState = {
  m7Context: {
    currentUserId: undefined,
    currentUser: null,
    currentRole: null,
    currentUnitId: undefined,
    currentUnit: null,
    currentFacilityId: undefined,
    currentFacility: null,
    currentTimezone: "America/New_York" as Timezone,
  },
  selectedUnit: null,
  currentUnit: null,
  unitModalType: "",
};

interface RehydrateAction extends Action<typeof REHYDRATE> {
  payload: ICommonState;
}

export const commonSlice = createSlice({
  name: "common",
  initialState,
  reducers: {
    setSelectedUnit: (state, action: PayloadAction<ICommonState["selectedUnit"]>) => {
      state.selectedUnit = action.payload;
    },
    setCurrentUnit: (state, action: PayloadAction<ICommonState["currentUnit"]>) => {
      state.currentUnit = action.payload;
    },
    setCurrentContext: (state, action: PayloadAction<ICommonState["m7Context"]>) => {
      state.m7Context = action.payload;
    },
    setUnitModal: (state, action: PayloadAction<ICommonState["unitModalType"]>) => {
      state.unitModalType = action.payload;
    },
  },
  extraReducers: (builder) => {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    builder.addCase(REHYDRATE, (state, action: RehydrateAction) => {
      state = { ...action.payload };
    });
  },
});

export const { setCurrentContext, setSelectedUnit, setUnitModal, setCurrentUnit } =
  commonSlice.actions;

const persistConfig = {
  key: "common",
  storage,
};

export default persistReducer(persistConfig, commonSlice.reducer);
