import { useMemo } from "react";

import { keyBy } from "lodash";

import { useAppConfigQuery } from "#/features/User/queries";
import { IShiftIncentiveLevel, IUnit } from "@/api";

import { useCurrentUnitId } from "./useCurrentUnitId";
import { useDeepMemo } from "./useDeepMemo";

export const useUnitShiftIncentiveLevels = (
  unitId?: IUnit["id"],
): {
  array: IShiftIncentiveLevel[];
  byId: Record<IShiftIncentiveLevel["id"], IShiftIncentiveLevel>;
} => {
  const storeUnitId = useCurrentUnitId();
  unitId ||= storeUnitId;

  const unitConfig = useAppConfigQuery().data?.accessibleUnits?.find((unit) => unit.id === unitId);

  const shiftIncentiveLevels = useDeepMemo(() => unitConfig?.shiftIncentiveLevels, [unitId]);

  return useMemo(
    () => ({
      array: shiftIncentiveLevels || [],
      byId: keyBy(shiftIncentiveLevels, "id"),
    }),
    [shiftIncentiveLevels],
  );
};
