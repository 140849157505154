import { KeyBy } from "@m7-health/shared-utils";
import { keyBy, reduce } from "lodash";

import { useAppConfigQuery } from "#/features/User/queries";
import { IAttribute } from "@/api";
import { useDeepMemo } from "@/common/hooks";

export const useAllAttributesByKey = () => {
  const units = useAppConfigQuery().data?.accessibleUnits;

  return useDeepMemo(
    () =>
      reduce(
        units,
        (acc, aUnit) => ({
          ...acc,
          ...keyBy(aUnit.attributes, "key"),
        }),
        {} as KeyBy<IAttribute, "key">,
      ),
    [units],
  );
};
