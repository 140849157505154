/* eslint-disable no-console */

import { memo } from "react";

import { Box } from "@mui/material";

import { IUnitBasic } from "~/routes/api/types";

import { Roster } from "@/api";

import { TableStyleContainer } from "./style";

import { TableParts } from ".";

export const NotificationsTable = memo(
  ({ units, rosterEntries }: { units: IUnitBasic[]; rosterEntries: Roster.DTO[] }) => {
    return (
      <Box
        mt={3}
        maxHeight="60vh"
        marginBottom="60px"
        boxSizing="border-box"
        display="flex"
        overflow={"auto"}
        flexDirection="column"
      >
        <TableStyleContainer>
          <table
            className={`scheduler-table regular-view v2`}
            style={{ tableLayout: "fixed", borderCollapse: "separate", borderSpacing: 0 }}
          >
            <tbody>
              <TableParts.HeaderRow />
              <TableParts.Rows units={units} rosterEntries={rosterEntries} />
            </tbody>
          </table>
        </TableStyleContainer>
      </Box>
    );
  },
);
