import { buildApiMutationHook } from "../_shared/helpers";

import { Roster } from "./types";

export const useUpdateRosterMutation = buildApiMutationHook<
  Roster.API.Update.QueryParams,
  Roster.API.Update.Response
>(Roster.API.Update.PATH, "patch");

export const useBulkSaveUnitEligibility = buildApiMutationHook<
  Roster.API.BulkSaveUnitEligibility.QueryParams,
  Roster.API.BulkSaveUnitEligibility.Response
>(Roster.API.BulkSaveUnitEligibility.PATH, "post");
