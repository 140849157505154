import { useMemo } from "react";

import { Typography } from "@mui/material";

import { FairnessStyled } from "~/common/components/Fairness/Fairness.styled";
import { getFairnessColor } from "~/common/utils/fairness";

export const Fairness = ({
  fairness,
  variant = "big",
}: {
  fairness: number;
  variant?: "small" | "medium" | "big";
}) => {
  const isSmall = variant === "small";
  const isMedium = variant === "medium";
  const fairnessBackground = useMemo(() => getFairnessColor(fairness), [fairness]);

  return (
    <FairnessStyled $small={isSmall} $medium={isMedium} $background={fairnessBackground}>
      <Typography className="text">{fairness}%</Typography>
    </FairnessStyled>
  );
};
