import { PayloadAction } from "@reduxjs/toolkit";

import { IRealTimeStaffingTarget } from "~/api/realTimeStaffingTargets";

import {
  INote,
  ISchedule,
  IScheduleShiftType,
  IStaffDetails,
  IStaffShift,
  IWorkingHours,
  StaffDetails,
} from "@/api";
import { KeyBy } from "@/common/types";

import { THouseViewState } from ".";

type TData = {
  staffShifts: IStaffShift[];
  shiftTypes: Record<IScheduleShiftType["scheduleId"], KeyBy<IScheduleShiftType, "key">>;
  schedules: ISchedule[];
  notes: INote[];
  staffDetails: KeyBy<IStaffDetails, "userId">;
  staffWorkingHoursByStaffId: KeyBy<IWorkingHours, "staffId">;
  metadataByStaffId: KeyBy<StaffDetails.ShiftMetadataDTO, "staffId">;
  staffingTargets: IRealTimeStaffingTarget[];
};
export type PageData = TData & {
  loaders: { [key in keyof TData]: boolean };
  isDataLoading: boolean;
};

export const pageDataInitState: PageData = {
  staffShifts: [],
  schedules: [],
  notes: [],
  shiftTypes: {},
  staffDetails: {},
  staffWorkingHoursByStaffId: {},
  metadataByStaffId: {},
  staffingTargets: [],
  loaders: {
    staffShifts: false,
    shiftTypes: false,
    schedules: false,
    notes: false,
    staffDetails: false,
    staffWorkingHoursByStaffId: false,
    metadataByStaffId: false,
    staffingTargets: false,
  },
  isDataLoading: false,
};

export const PageDataActions = {
  setData: (state: THouseViewState, action: PayloadAction<Partial<PageData>>) => {
    state.pageData = { ...state.pageData, ...action.payload };
  },

  setShiftTypes: ({ pageData }: THouseViewState, action: PayloadAction<PageData["shiftTypes"]>) => {
    pageData.shiftTypes = action.payload;
  },

  setStaffShifts: (
    { pageData }: THouseViewState,
    action: PayloadAction<PageData["staffShifts"]>,
  ) => {
    pageData.staffShifts = action.payload;
  },

  setDataLoaders: (
    { pageData }: THouseViewState,
    { payload }: PayloadAction<Partial<PageData["loaders"]>>,
  ) => {
    pageData.loaders = { ...pageData.loaders, ...payload };
    pageData.isDataLoading = Object.values(pageData.loaders).some(Boolean);
  },

  setStaffDetails: (
    { pageData }: THouseViewState,
    action: PayloadAction<PageData["staffDetails"]>,
  ) => {
    pageData.staffDetails = action.payload;
  },
};
