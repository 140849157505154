import { useMemo } from "react";

import { map, sortBy, uniq } from "lodash";

/** This hook is a memoized wrapper around lodash's map function that allows you to map an array of objects by a key.
 * It also allows you to sort the results and remove duplicates.
 *
 * @example results = useMapBy(users, "id", { uniq: true, sortBy: ["firstName", "lastName"] });
 */
export const useMap = <T, R>(
  items: T[] | null | undefined,
  mapFunction: (item: T) => R,
  options: {
    uniq?: boolean;
    sortBy?: Parameters<typeof sortBy>[1];
  } = {
    uniq: false,
    sortBy: undefined,
  },
): R[] =>
  useMemo(() => {
    let results = map(items, mapFunction);
    if (options.uniq) results = uniq(results);
    if (options.sortBy) results = sortBy(results, options.sortBy);
    return results;
  }, [items, mapFunction, options.sortBy, options.uniq]);
