import { EventBusy, Phone } from "@mui/icons-material";
import { darken, Typography } from "@mui/material";

import { IShiftType, IStaffDetails, StaffDetails } from "~/api";
import { StatusTag } from "~/common/components/StatusTag";
import { black, darkPurple, mediumGray } from "~/common/theming/colors";
import { formatPhoneNumber } from "~/common/utils/phoneNumber";
import { IUnitBasic } from "~/routes/api/types";

import { ShiftV2 } from "@/common/components";
import { CompactFloatedTag } from "@/common/components/CompactFloatedTag/CompactFloatedTag";

export const miscVisualHelpers = {
  displayShiftIcon(shiftType: IShiftType | undefined) {
    return shiftType ? (
      <ShiftV2 shiftType={shiftType} variant={"small"} sx={{ mr: 1 }} />
    ) : (
      <EventBusy fontSize="small" sx={{ mr: 1, color: darken(mediumGray, 0.2) }} />
    );
  },

  displayStaffDescription({
    user: { firstName, lastName },
    staffType: { name: staffTypeName },
    employmentType,
  }: StaffDetails.DTO) {
    const displayableEmploymentType =
      employmentType === StaffDetails.EEmploymentType.perDiem
        ? "PRN"
        : employmentType === StaffDetails.EEmploymentType.travelStaff
          ? "TVL"
          : null;
    return (
      <>
        <Typography whiteSpace={"nowrap"}>
          {lastName} {firstName}, <span style={{ fontSize: "12px" }}>{staffTypeName}</span>
        </Typography>
        {displayableEmploymentType && (
          <StatusTag
            sx={{ ml: 0.5, padding: "2px !important", fontSize: "10px" }}
            reverse
            children={displayableEmploymentType}
          />
        )}
      </>
    );
  },

  displayUnitIcon({ homeUnitId }: StaffDetails.DTO, selectedUnitId: IUnitBasic["id"]) {
    if (homeUnitId === selectedUnitId) return <></>;

    return <CompactFloatedTag sx={{ ml: 1 }} unitId={homeUnitId} />;
  },

  getPhoneNumber(phoneNumber?: IStaffDetails["user"]["phoneNumber"] | null, readonly = false) {
    return phoneNumber ? (
      <Typography
        sx={{
          position: "relative",
          top: "-8px",
          left: "32px",
          color: darkPurple,
          ...(readonly
            ? {}
            : {
                "&:hover": {
                  color: black,
                  cursor: "pointer",
                },
              }),
        }}
        fontSize={"12px"}
      >
        <Phone
          sx={{
            width: "15px",
            position: "relative",
            top: "5px",
          }}
          fontSize="small"
        />{" "}
        {formatPhoneNumber(phoneNumber)}
      </Typography>
    ) : (
      <></>
    );
  },
};

export const shiftCompactSx = {
  height: "20px",
  maxWidth: "40px",
  padding: 0,
  width: "20px",
  ".MuiButton-startIcon": {
    marginRight: "-3px",
  },
};
