import { buildApiMutationHook } from "../_shared/helpers";

import { RealTimeStaffingTarget } from "./types";

export const useCreateRealTimeStaffingTargetMutation = buildApiMutationHook<
  RealTimeStaffingTarget.API.Create.QueryParams,
  RealTimeStaffingTarget.API.Create.Response
>(RealTimeStaffingTarget.API.Create.PATH, "post");

export const useUpdateRealTimeStaffingTargetMutation = buildApiMutationHook<
  RealTimeStaffingTarget.API.Update.QueryParams,
  RealTimeStaffingTarget.API.Update.Response
>(RealTimeStaffingTarget.API.Update.PATH, "patch");

export const useDeleteRealTimeStaffingTargetMutation = buildApiMutationHook<
  RealTimeStaffingTarget.API.Delete.QueryParams,
  RealTimeStaffingTarget.API.Delete.Response
>(RealTimeStaffingTarget.API.Delete.PATH, "delete");
