import { buildApiMutationHook } from "../_shared/helpers";

import { PreferenceRequest } from "./types";

export const useCreatePreferenceRequestMutation = buildApiMutationHook<
  PreferenceRequest.API.Create.QueryParams,
  PreferenceRequest.API.Create.Response
>(PreferenceRequest.API.Create.PATH, "post");

export const useDeletePreferenceRequestMutation = buildApiMutationHook<
  PreferenceRequest.API.Delete.QueryParams,
  PreferenceRequest.API.Delete.Response
>(PreferenceRequest.API.Delete.PATH, "delete");

export const useUpdatePreferenceRequestMutation = buildApiMutationHook<
  PreferenceRequest.API.Update.QueryParams,
  PreferenceRequest.API.Update.Response
>(PreferenceRequest.API.Update.PATH, "patch");
