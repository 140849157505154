// eslint-disable-next-line deprecate/import
import { Uuid, YyyyMmDd } from "~/common/types";

import { DeprecatedTShift } from "../SchedulerGrid/types";
import { ERoleIds, IUser } from "../User/types";

export const EShiftSwapRequestStatus = {
  accepted: "accepted",
  rejectedByPeer: "rejectedByPeer",
  rejectedByScheduler: "rejectedByScheduler",
  waitingOnScheduler: "waitingOnScheduler",
  waitingOnPeer: "waitingOnPeer",
  none: null,
} as const;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type EShiftSwapRequestStatus =
  (typeof EShiftSwapRequestStatus)[keyof typeof EShiftSwapRequestStatus];

export const EDropShiftRequestStatus = {
  accepted: "accepted",
  rejected: "rejected",
  waitingOnScheduler: "waitingOnScheduler",
  waitingOnPeer: "waitingOnPeer",
  none: null,
} as const;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type EDropShiftRequestStatus =
  (typeof EDropShiftRequestStatus)[keyof typeof EDropShiftRequestStatus];

export const ETimeOffRequestStatus = {
  accepted: "accepted",
  rejectedByScheduler: "rejectedByScheduler",
  waitingOnScheduler: "waitingOnScheduler",
  none: null,
} as const;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type ETimeOffRequestStatus =
  (typeof EShiftSwapRequestStatus)[keyof typeof EShiftSwapRequestStatus];

export const EApprovalRequestStatus = {
  accepted: "accepted",
  rejected: "rejected",
  ignored: "ignored",
  none: null,
} as const;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type EApprovalRequestStatus =
  (typeof EApprovalRequestStatus)[keyof typeof EApprovalRequestStatus];

// cannot set to null
export enum ESetApprovalRequestStatus {
  accepted = "accepted",
  rejected = "rejected",
  ignored = "ignored",
}

export enum EApprovableEntityType {
  shiftSwapRequest = "shift_swap_request",
  dropShiftRequest = "drop_shift_request",
  timeOffRequest = "time_off_request",
}

/** @deprecated use EApprovableEntityTab instead */
export enum EShiftSwapRequestTab {
  new = "new",
  pending = "pending",
  completed = "completed",
}

export enum EApprovableEntityTab {
  new = "new",
  incoming = "incoming",
  pending = "pending",
  completed = "completed",
}

export const EApprovableEntityStatus = {
  ...EShiftSwapRequestStatus,
  ...ETimeOffRequestStatus,
  ...EDropShiftRequestStatus,
} as const;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type EApprovableEntityStatus =
  | EShiftSwapRequestStatus
  | ETimeOffRequestStatus
  | EDropShiftRequestStatus;

export type IApprovableEntityBase = {
  id: Uuid;
  requestType: EApprovableEntityType;
  computedStatus: EApprovableEntityStatus;
  createdAt: Date;
  updatedAt: Date;
  requesterId: IUser["id"];
  computedUpdatedAt: Date;
  approvalRequests: IApprovalRequest[];
};

export type IApprovalRequest = {
  id: Uuid;
  requesterId: IUser["id"];
  deciderId?: IUser["id"];
  deciderRoleId: ERoleIds;
  approvableEntityId: Uuid;
  approvableEntityType: EApprovableEntityType;
  priorityOrder: number;
  decision: EApprovalRequestStatus;
  decisionNote?: string;
  decidedAt?: Date;
  createdAt: Date;
  updatedAt: Date;
};

export type IDropShiftRequest = IApprovableEntityBase & {
  requestType: EApprovableEntityType.dropShiftRequest;
  scheduleId: Uuid;
  dateToDrop: Date;
  requesterId: IUser["id"];
  shiftTypeKey: DeprecatedTShift;
};

export type IShiftSwapRequest = IApprovableEntityBase & {
  requestType: EApprovableEntityType.shiftSwapRequest;
  scheduleId: Uuid;
  requesterShiftDate: YyyyMmDd;
  deciderId: IUser["id"];
  deciderShiftDate: YyyyMmDd;
  shiftTypeKey: DeprecatedTShift;
};

export type ICreateShiftSwapRequest = Pick<
  IShiftSwapRequest,
  | "scheduleId"
  | "requesterId"
  | "requesterShiftDate"
  | "deciderId"
  | "deciderShiftDate"
  | "shiftTypeKey"
>;

export type ICreateDropShiftRequest = Pick<
  IDropShiftRequest,
  "scheduleId" | "dateToDrop" | "shiftTypeKey"
>;

export type ITimeOffRequest = IApprovableEntityBase & {
  requestType: EApprovableEntityType.timeOffRequest;
  fromDate: Date;
  toDate: Date;
  ptoHoursRequested: number;
  requesterNote?: string;
  decisionNote?: string;
};

export type ICreateTimeOffRequest = Pick<
  ITimeOffRequest,
  "fromDate" | "toDate" | "ptoHoursRequested" | "requesterNote"
>;

export type IApprovableEntity = IShiftSwapRequest | ITimeOffRequest | IDropShiftRequest;
