import { PayloadAction } from "@reduxjs/toolkit";

import { IStaffType } from "@/api";
import { KeyBy } from "@/common/types";

import { THouseViewState } from ".";

export type TStaffTypeState = {
  toUpdate: Record<IStaffType["key"], IStaffType>;
  toCreate: Record<IStaffType["key"], IStaffType>;
  recordsInDb: Record<IStaffType["key"], IStaffType>;
};

export const StaffTypeActionsInitState: TStaffTypeState = {
  toUpdate: {},
  toCreate: {},
  recordsInDb: {},
};

export const StaffTypeActions = {
  updateStaffType: ({ staffTypes }: THouseViewState, action: PayloadAction<IStaffType>) => {
    if (staffTypes.toCreate[action.payload.key]) {
      staffTypes.toCreate[action.payload.key] = action.payload;
    } else {
      staffTypes.toUpdate[action.payload.key] = action.payload;
    }
  },
  updateStaffTypeKey: (
    { staffTypes }: THouseViewState,
    action: PayloadAction<{ current: IStaffType["key"]; new: IStaffType["key"] }>,
  ) => {
    const currentStaffType = staffTypes.toCreate[action.payload.current];
    if (!currentStaffType) return;

    delete staffTypes.toCreate[action.payload.current];
    staffTypes.toCreate[action.payload.new] = {
      ...currentStaffType,
      key: action.payload.new,
    };
  },
  duplicateStaffType: ({ staffTypes }: THouseViewState, action: PayloadAction<IStaffType>) => {
    const key = `${action.payload.key} copy` as IStaffType["key"];
    staffTypes.toCreate[key] = {
      ...action.payload,
      key,
    };
  },
  restoreStaffType: ({ staffTypes }: THouseViewState, action: PayloadAction<IStaffType>) => {
    delete staffTypes.toUpdate[action.payload.key];
  },
  deleteStaffType: ({ staffTypes }: THouseViewState, action: PayloadAction<IStaffType>) => {
    if (staffTypes.toCreate[action.payload.key]) {
      delete staffTypes.toCreate[action.payload.key];
      return;
    }
  },

  setDbStaffTypes: (
    { staffTypes }: THouseViewState,
    action: PayloadAction<KeyBy<IStaffType, "key">>,
  ) => {
    staffTypes.recordsInDb = action.payload;
  },

  cancelAllStaffTypeChanges: ({ staffTypes }: THouseViewState) => {
    staffTypes.toCreate = {};
    staffTypes.toUpdate = {};
  },
};
