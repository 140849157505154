import { PayloadAction } from "@reduxjs/toolkit";

import { TStoreSlice } from "~/store";

import { IHolidaySchedule } from "@/api";

import { THolidayScheduleState } from ".";

export type TGeneralState = {
  holidaySchedule: IHolidaySchedule | null;
};

export const GeneralInitState: TGeneralState = {
  holidaySchedule: null,
};

export const GeneralActions = {
  setHolidaySchedule: ({ general }, { payload }: PayloadAction<IHolidaySchedule>) => {
    general.holidaySchedule = payload;
  },
} satisfies TStoreSlice<THolidayScheduleState>;
