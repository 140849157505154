// eslint-disable-next-line deprecate/import
import { AutocompleteProps, ChipTypeMap, Autocomplete as MuiAutocomplete } from "@mui/material";

import { useAppTracking } from "~/modules/mixpanel/Context";
import { Mxp } from "~/modules/mixpanel/types";

// Type is extracted from Autocomplete function type (not the AutocompleteProps as the defaults
//  for the generics are not set in the type definition, but in the function itself)
export const Autocomplete = <
  T,
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false,
  FreeSolo extends boolean | undefined = false,
  ChipComponent extends React.ElementType = ChipTypeMap["defaultComponent"],
>({
  trackingLabel,
  ...props
}: {
  trackingLabel: string | null;
} & AutocompleteProps<T, Multiple, DisableClearable, FreeSolo, ChipComponent>) => {
  const track = useAppTracking();

  // For now we only track on click, no property change
  const onClickWrapper: (typeof props)["onClick"] = (...args) => {
    if (trackingLabel) {
      track(Mxp.Event.elementClicked, {
        [Mxp.Property.element.type]: "Dropdown",
        [Mxp.Property.element.label]: trackingLabel,
      });
    }

    props.onClick?.(...args);
  };

  return <MuiAutocomplete {...props} onClick={onClickWrapper} />;
};
