export const getFullName = (
  firstName: string | undefined,
  lastName: string | undefined,
  isReversed = false,
) => {
  if (firstName && !lastName) {
    return firstName;
  }
  if (!firstName && lastName) {
    return lastName;
  }
  if (!firstName && !lastName) {
    return "";
  }
  return isReversed ? `${lastName} ${firstName}` : `${firstName} ${lastName}`;
};
