import { useQuery } from "@tanstack/react-query";

import { useLogout } from "~/common/hooks/useLogout";
import { getAppConfig, getCurrentUser, getStaffDetails } from "~/features/User/api";

export const useCurrentUserQuery = () => {
  const { logout } = useLogout();

  const query = useQuery({
    queryKey: ["currentUser"],
    queryFn: getCurrentUser,
  });

  if (query.isError) {
    const error = query.error;
    if (error && typeof error === "object") {
      if ("statusCode" in error) {
        if (error.statusCode === 401 || error.statusCode === 403) void logout();
      }

      if ("message" in error) {
        if (error.message === "Unknown or invalid refresh token.") void logout();
      }
    }
  }

  return query;
};

export const useStaffDetailsQuery = (isEnabled = true) => {
  return useQuery({ queryKey: ["staffDetails"], queryFn: getStaffDetails, enabled: isEnabled });
};

export const useAppConfigQuery = () => {
  return useQuery({
    queryKey: ["appConfig"],
    queryFn: getAppConfig,
    staleTime: Infinity,
  });
};
