import { useMemo, useState } from "react";

import { PreferencesTemplate } from "@m7-health/shared-utils";
import { keyBy } from "lodash";

import { Grid } from "@mui/material";

import { ShiftType, useListUnitShiftTypeQuery } from "@/api";
import { NOT_EXISTING_UUID } from "@/common/constants";
import { useCurrentUnitId } from "@/common/hooks";
import { lightGray } from "@/common/theming";

import { ShiftTypeSelector } from "./ShiftTypeSelector";
import { TemplateCalendar } from "./TemplateCalendar";

const defaultShiftTypes: ShiftType.DTO[] = [];

export type TSelectedDaysObject = {
  [key: string]: { selected: boolean; weekIndex: number; dayIndex: number };
};

export const TemplateChanger = ({
  template,
  setTemplate,
}: {
  template: PreferencesTemplate.TTemplatedWeek[];
  setTemplate: React.Dispatch<React.SetStateAction<PreferencesTemplate.TTemplatedWeek[]>>;
}) => {
  const unitId = useCurrentUnitId();
  const [selectedDays, setSelectedDays] = useState<TSelectedDaysObject>({});

  const { data: unitShiftTypes = defaultShiftTypes } = useListUnitShiftTypeQuery(
    { unitIds: [unitId || NOT_EXISTING_UUID] },
    { skip: !unitId },
  );

  const shiftsByKey = useMemo(() => {
    const unfilteredShiftTypes = unitShiftTypes;
    const filteredShiftTypes = unfilteredShiftTypes.filter(
      (shiftType) => shiftType.staffPreferableShift,
    );
    return keyBy(filteredShiftTypes, "key");
  }, [unitShiftTypes]);

  return (
    <Grid
      container
      flexDirection="column"
      gap={2}
      sx={{ backgroundColor: lightGray, py: 2, px: 1 }}
    >
      <TemplateCalendar
        setTemplate={setTemplate}
        template={template}
        selectedDays={selectedDays}
        setSelectedDays={setSelectedDays}
        shiftsByKey={shiftsByKey}
      />
      <ShiftTypeSelector
        selectedDays={selectedDays}
        shiftsByKey={shiftsByKey}
        setTemplate={setTemplate}
        setSelectedDays={setSelectedDays}
      />
    </Grid>
  );
};
