export enum EUserTypes {
  staff = "staff",
  scheduler = "scheduler",
  existingScheduler = "existingScheduler",
}

export enum ESchedulerFormNames {
  firstName = "firstName",
  lastName = "lastName",
  email = "email",
  phoneNumber = "phoneNumber",
  unitAssignment = "unitAssignment",
}
