import { lines } from "~/common/theming/colors";

export const nameCellsMetadata = {
  headerCellStyles: {
    padding: "0px",
    borderRight: `1px solid ${lines}`,
  },
  cellStyles: {
    padding: "0px",
  },
  pinned: true,
};

export const cellsMetadata = {
  headerCellStyles: {
    padding: "0px",
    verticalAlign: "baseline",
    borderRight: `1px solid ${lines}`,
  },
  cellStyles: {
    padding: "0px 3px",
    borderRight: `1px solid ${lines}`,
  },
};
