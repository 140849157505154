import { dateString } from "~/common/types";

import { APIElementResponse, SDTO } from "../_shared/types";
import { IUnit } from "../unit";

export type IQuestionnaire = Questionnaire.DTO;

export namespace Questionnaire {
  type content = Record<string, unknown>;
  export interface DTO extends SDTO.base, SDTO.withTimestamps, SDTO.softDeletable {
    unitId: IUnit["id"];
    content: content;
    history: Record<dateString, content>;
    mandatoryResponse: boolean;
  }

  export namespace API {
    export const ROOT_PATH = "/questionnaire";
    export namespace GetCurrent {
      export const PATH = ROOT_PATH + "/current";
      export interface QueryParams {}

      export type Response = APIElementResponse<DTO>;
    }

    export namespace Update {
      export const PATH = (params: QueryParams) => `${ROOT_PATH}/${params.id}`;
      export type QueryParams = Pick<DTO, "id"> &
        Partial<Pick<DTO, "content" | "mandatoryResponse">>;

      export type Response = APIElementResponse<DTO>;
    }

    export namespace Create {
      export const PATH = ROOT_PATH;
      export type QueryParams = Pick<DTO, "content" | "mandatoryResponse">;

      export type Response = APIElementResponse<DTO>;
    }
  }
}
