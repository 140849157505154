import { buildApiListQueryHook } from "../_shared/helpers";

import { StaffShift } from "./types";

export const useListStaffShiftsQuery = buildApiListQueryHook<
  StaffShift.API.List.QueryParams,
  StaffShift.API.List.Response
>(StaffShift.API.List.PATH);

export const useListWorkingHoursQuery = buildApiListQueryHook<
  StaffShift.API.WorkingHours.QueryParams,
  StaffShift.API.WorkingHours.Response
>(StaffShift.API.WorkingHours.PATH);
