import { useCallback, useMemo } from "react";

import { closeModal } from "#/features/Roster/store";
import {
  useCreatePreferencesTemplateMutation,
  useInvalidateQuery,
  useListPreferencesTemplatesQuery,
  useUpdatePreferencesTemplateMutation,
} from "@/api";
import { useAppDispatch, useToast } from "@/common/hooks";

export const useSaveTemplate = () => {
  const { showSuccess, showError } = useToast();
  const invalidateQuery = useInvalidateQuery();
  const dispatch = useAppDispatch();

  const closeTemplateModal = useCallback(
    (withConfirm = false) => {
      if (
        withConfirm &&
        !window.confirm("Are you sure you want to cancel? you are about to lose all your changes")
      )
        return;

      dispatch(closeModal("updatePreferencesTemplateModal"));
      invalidateQuery(useListPreferencesTemplatesQuery);
    },
    [dispatch, invalidateQuery],
  );

  const { mutate: createTemplate, isPending: isCreatingTemplate } =
    useCreatePreferencesTemplateMutation({
      onSuccess: () => {
        showSuccess("Template created successfully");
        closeTemplateModal();
      },
      onError: () => {
        showError("Failed to create template");
      },
    });
  const { mutate: updateTemplate, isPending: isUpdatingTemplate } =
    useUpdatePreferencesTemplateMutation({
      onSuccess: () => {
        showSuccess("Template updated successfully");
        closeTemplateModal();
      },
      onError: () => {
        showError("Failed to update template");
      },
    });

  return useMemo(
    () => ({
      createTemplate,
      updateTemplate,
      closeTemplateModal,
      isSavePending: isCreatingTemplate || isUpdatingTemplate,
    }),
    [createTemplate, updateTemplate, closeTemplateModal, isCreatingTemplate, isUpdatingTemplate],
  );
};
