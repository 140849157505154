import { generatePath, matchPath, Navigate, useLocation } from "react-router-dom";

import { values } from "lodash";

import { FALLBACK_ROUTE, IBasicRoute, routes } from "~/routes";

import { useM7SimpleContext } from "@/common/hooks";

const CatchAllPage = () => {
  const location = useLocation();
  const pathname = location.pathname;
  const allRoutes = routes;
  const { currentFacilityId: facilityId, currentUnitId: unitId } = useM7SimpleContext();

  let redirectTo: string | undefined = undefined;

  // Check if one of the legacyPaths paths matches the current pathname,
  //  if so, build the new path and redirect
  const checkRoute = (route: IBasicRoute) =>
    !!route.legacyPaths?.find((legacyPath) => {
      const { params } = matchPath(legacyPath, pathname) || {};

      if (params) {
        redirectTo = generatePath(route.path, { ...params, unitId, facilityId });
        return true;
      }

      return false;
    });

  // Recursively check if any of a route children have a legacyPaths
  //  that matches the current pathname, if not check the route itself
  //  and return true if a match is found.
  // It sets the redirectTo variable on the first match
  const checkSelfAndChildrenAndRedirect = (route: IBasicRoute): boolean => {
    let redirectWasFound: boolean;

    if (route.children) {
      redirectWasFound = !!values(route.children).find((child) =>
        checkSelfAndChildrenAndRedirect(child),
      );
    }
    redirectWasFound ||= checkRoute(route);

    return redirectWasFound;
  };

  // For each route, call the recursive function
  values(allRoutes).find((route) => checkSelfAndChildrenAndRedirect(route));

  redirectTo ||= FALLBACK_ROUTE;

  return <Navigate to={redirectTo} />;
};

export default CatchAllPage;
