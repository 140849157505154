import { axiosInstance } from "~/common/packages/httpClient";
import { IGetShifts } from "~/routes/api/types";

import { buildApiListQueryHook } from "../_shared/helpers";
import { staleFor } from "../_shared/types";

import { ShiftType } from "./types";

// TODO: to improve
/** @deprecated use useListScheduleShiftTypeQuery instead */
export const getShiftsByScheduleApi = async (scheduleId: string) => {
  const {
    data: { data },
  } = await axiosInstance.get<IGetShifts>("/schedule-shift-type", {
    params: {
      ...(scheduleId && {
        scheduleIds: [scheduleId],
      }),
    },
  });
  return data;
};

export const useListScheduleShiftTypeQuery = buildApiListQueryHook<
  ShiftType.Schedule.API.List.QueryParams,
  ShiftType.Schedule.API.List.Response
>(ShiftType.Schedule.API.List.PATH, {
  staleTime: staleFor(1).hour,
});

export const useListUnitShiftTypeQuery = buildApiListQueryHook<
  ShiftType.Unit.API.List.QueryParams,
  ShiftType.Unit.API.List.Response
>(ShiftType.Unit.API.List.PATH, {
  staleTime: staleFor(1).hour,
});
