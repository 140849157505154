import { memo, useMemo } from "react";

import DiffMatchPatch from "diff-match-patch";

import { Typography } from "@mui/material";

import { NoteUpdated } from "~/api/eventLog/events";

import { EventLog } from "@/api";
import { darkGray, darkGreen, lightGreen, lightRed, redAlert } from "@/common/theming/colors";

import { IEventLogComponent } from "../..";

const diffMaker = new DiffMatchPatch();

export const NoteUpdatedV1 = memo(
  ({
    event,
    usersById,
  }: IEventLogComponent<EventLog.DTO<NoteUpdated.PayloadByVersion[NoteUpdated.EVersions.v1]>>) => {
    const author = usersById?.[event.authorId] || { firstName: "An M7 Admin", lastName: "" };

    const { from, to } = event.eventPayload.updatedFields.content;

    const diff = useMemo(() => {
      const rawDiff = diffMaker.diff_main(from, to);
      diffMaker.diff_cleanupSemantic(rawDiff);
      return rawDiff;
    }, [from, to]);

    return (
      <>
        <b>
          {author.firstName} {author.lastName}
        </b>{" "}
        updated the note:
        <br />
        <Typography>
          {diff.map((part) => {
            const [direction, value] = part;
            // green for additions, red for deletions
            // grey for common parts
            const [color, backgroundColor] =
              direction === DiffMatchPatch.DIFF_INSERT
                ? [darkGreen, lightGreen]
                : direction === DiffMatchPatch.DIFF_DELETE
                  ? [redAlert, lightRed]
                  : [darkGray, "transparent"];
            const crossed = direction === DiffMatchPatch.DIFF_DELETE;

            return (
              <span
                key={value}
                style={{
                  color,
                  backgroundColor,
                  ...(crossed ? { textDecoration: "line-through" } : {}),
                }}
              >
                {value}
              </span>
            );
          })}
        </Typography>
      </>
    );
  },
);
