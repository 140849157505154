import { useMemo } from "react";

import { useListSchedulesQuery } from "~/api";
import { useAppSelector } from "~/common/hooks/useRedux";

export const useSelectedSchedule = () => {
  const selectedUnitId = useAppSelector((state) => state.houseView.pageFilters.selectedUnitId);
  const selectedDateForData = useAppSelector(
    (state) => state.houseView.pageFilters.selectedDateForData,
  );
  const { data: schedules } = useListSchedulesQuery({});

  return useMemo(() => {
    if (!selectedUnitId || !selectedDateForData) return undefined;

    const unitSchedules = schedules?.filter((schedule) => schedule.unitId === selectedUnitId);
    return unitSchedules?.find(
      (schedule) =>
        schedule.startDay <= selectedDateForData && selectedDateForData <= schedule.endDay,
    );
  }, [schedules, selectedUnitId, selectedDateForData]);
};
