import { Box } from "@mui/material";

import { ICustomTabPanelProps } from "./types";

export const CustomTabPanel = <TabsEnum,>({
  children,
  value,
  tabKey,
  ...other
}: ICustomTabPanelProps<TabsEnum>) => {
  return (
    <Box
      role="tabpanel"
      hidden={value !== tabKey}
      id={`simple-tabpanel-${tabKey}`}
      aria-labelledby={`tab-panel-${tabKey}`}
      {...other}
    >
      {value === tabKey && children}
    </Box>
  );
};
