import { OmitOptional } from "~/common/types";

import { ISchedulerGridState } from "./store/initialState";

// Type that selects keys from InitialType where the value has only isOpen attribute
export type ModalsWithIsOpenOnly = NonNullable<
  {
    [K in keyof ISchedulerGridState["modals"]]: {
      isOpen: boolean;
    } extends OmitOptional<ISchedulerGridState["modals"][K]>
      ? K
      : never;
  }[keyof ISchedulerGridState["modals"]]
>;

export enum ESchedulePageTabs {
  past = "past",
  current = "current",
  planning = "planning",
}

export enum EScheduleTypes {
  draft = "draft",
  m7 = "m7",
}

/** @deprecated use ShiftType["key"] instead */
export type DeprecatedTShift = string;
