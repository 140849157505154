import { buildApiListQueryHook } from "../_shared/helpers";
import { staleFor } from "../_shared/types";

import { User } from "./types";

// includes all users from roster (not just home unit users)
export const useListUsersQuery = buildApiListQueryHook<
  User.API.List.QueryParams,
  User.API.List.Response
>(User.API.List.PATH, {
  staleTime: staleFor(5).minutes,
});

export const useGetNotificationsCountQuery = buildApiListQueryHook<
  User.API.GetNotificationsCount.QueryParams,
  User.API.GetNotificationsCount.Response
>(User.API.GetNotificationsCount.PATH);
