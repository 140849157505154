import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { PreferenceRequirementRuleSet } from "@m7-health/shared-utils";
import { isEqual } from "lodash";

import {
  IPreferenceRequirementRule,
  IPreferenceRequirementRuleSet,
  useCreatePreferenceRequirementRuleSetMutation,
  useListPreferenceRequirementRuleSetsQuery,
  useUpdatePreferenceRequirementRuleSetMutation,
} from "~/api/preferenceRequirementRuleSet";

import { IShiftType, IUnit, useInvalidateQuery } from "@/api";
import { BULK_DELETE_KEY } from "@/common/constants";
import { useAppDispatch, useAppSelector, useErrors, useToast } from "@/common/hooks";

import { closeModal, openModal } from "../../store";

export const useActions = (unitId?: IUnit["id"]) => {
  const ruleSetToEdit = useAppSelector(
    (state) => state.roster.updateRuleSetModal.ruleSetToUpdate,
    isEqual,
  );

  const [ruleSet, setRuleSet] = useState<IPreferenceRequirementRuleSet>(
    ruleSetToEdit || ({ unitId, description: "" } as IPreferenceRequirementRuleSet),
  );

  const createNewRule = useCallback(
    (partialRuleSet: Partial<IPreferenceRequirementRule> = {}) =>
      setRuleSet((oldValue) => ({
        ...oldValue,
        preferenceRequirementRules: [
          ...(oldValue.preferenceRequirementRules || []),
          {
            id: crypto.randomUUID(),
            targetType: PreferenceRequirementRuleSet.ETargetType.shiftCount,
            frequencyStep: PreferenceRequirementRuleSet.EFrequencyStep.schedule,
            targetIsWeekend: false,
            ...partialRuleSet,
          } as IPreferenceRequirementRule,
        ],
      })),
    [],
  );

  const initRules = useCallback(() => {
    createNewRule();
    createNewRule({ targetIsWeekend: true });
    createNewRule({
      targetShiftTypeKeys: ["block" as IShiftType["key"]],
    });
  }, [createNewRule]);

  const deleteRule = useCallback(
    (rule: IPreferenceRequirementRule) => {
      const otherRuleSets = ruleSet.preferenceRequirementRules.filter(
        (currentRule) => currentRule.id !== rule.id,
      );
      const deletedRuleSet = rule.createdAt ? [{ ...rule, [BULK_DELETE_KEY]: true }] : [];

      setRuleSet({
        ...ruleSet,
        preferenceRequirementRules: [...otherRuleSets, ...deletedRuleSet],
      });
    },
    [ruleSet],
  );

  const updateRule = useCallback(
    (rule: IPreferenceRequirementRule, params: Partial<IPreferenceRequirementRule>) => {
      setRuleSet({
        ...ruleSet,
        preferenceRequirementRules: ruleSet.preferenceRequirementRules.map((currentRule) =>
          currentRule.id === rule.id
            ? {
                ...currentRule,
                ...params,
              }
            : currentRule,
        ),
      });
    },
    [ruleSet],
  );

  // Save/cancel actions
  const dispatch = useAppDispatch();
  const { handleErrors } = useErrors();
  const { showSuccess } = useToast();
  const invalidateQueries = useInvalidateQuery();
  const useMutationFn = ruleSet?.id
    ? useUpdatePreferenceRequirementRuleSetMutation
    : useCreatePreferenceRequirementRuleSetMutation;
  const { mutateAsync } = useMutationFn({});

  const save = useCallback(async () => {
    try {
      // Deep dup
      const toSave = JSON.parse(JSON.stringify(ruleSet)) as typeof ruleSet;
      // Remove id from new rules
      toSave.preferenceRequirementRules.forEach((rule: IPreferenceRequirementRule) => {
        if (!rule.createdAt) delete (rule as Partial<IPreferenceRequirementRule>).id;
      });

      await mutateAsync(toSave);
      showSuccess(`Requirement ${toSave.id ? "updated" : "created"} successfully`);
      void invalidateQueries(useListPreferenceRequirementRuleSetsQuery);
      dispatch(closeModal("updateRuleSetModal"));
      dispatch(openModal("updateRuleSetsModal"));
    } catch (error) {
      handleErrors(error);
      return;
    }
  }, [dispatch, handleErrors, invalidateQueries, mutateAsync, ruleSet, showSuccess]);

  const cancel = useCallback(() => {
    if (window.confirm("Are you sure you want to cancel? you are about to lose all your changes")) {
      dispatch(closeModal("updateRuleSetModal"));
      dispatch(openModal("updateRuleSetsModal"));
    }
  }, [dispatch]);

  // EFFECTS ON RENDER
  // Ensure always at least one rule
  const initiated = useRef(false);
  useEffect(() => {
    if (initiated.current) return;

    if (!ruleSet?.preferenceRequirementRules?.length) {
      initiated.current = true;
      initRules();
    }
  }, [ruleSet?.preferenceRequirementRules?.length, initRules]);

  return useMemo(() => {
    return {
      actions: { createNewRule, initRules, deleteRule, updateRule, save, cancel },
      ruleState: [ruleSet, setRuleSet] as const,
    };
  }, [createNewRule, initRules, deleteRule, updateRule, save, cancel, ruleSet]);
};
