import { useEffect, useRef } from "react";
import { useSearchParams } from "react-router-dom";

import { filter, flatMap, keyBy } from "lodash";

import { IUnit } from "@/api";

export const useHandleQueryParams = ({
  onChange,
  valueIds,
  withQueryParams = false,
  fullySelectedGroups,
  groups,
  options,
  isLoading,
  availableUnits,
}: {
  onChange: (selectedUnits: IUnit[]) => void;
  valueIds: IUnit["id"][];
  withQueryParams?: boolean;
  fullySelectedGroups: string[];
  groups: Record<string, { available: IUnit["id"][] }>;
  options: { value: IUnit["id"]; label: string }[];
  isLoading: boolean;
  availableUnits: IUnit[];
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const selectedUnitsAndGroupsFromParams = searchParams.get("units");
  const valuesSetFromParams = useRef(false);

  // call onChange from values from query params
  useEffect(() => {
    const optionsByLabel = keyBy(options, "label");

    if (!withQueryParams) return;
    if (valuesSetFromParams.current) return;
    if (isLoading || !availableUnits.length) return;

    valuesSetFromParams.current = true;
    if (selectedUnitsAndGroupsFromParams) {
      const idsFromQuery = flatMap(
        (selectedUnitsAndGroupsFromParams || "").split(",").map((unitOrGroup) => {
          return filter([
            ...(groups[unitOrGroup]?.available || []),
            optionsByLabel[unitOrGroup]?.value,
          ]);
        }),
      );
      onChange(availableUnits.filter((unit) => idsFromQuery.includes(unit.id)));
    }
  }, [
    isLoading,
    withQueryParams,
    availableUnits,
    groups,
    onChange,
    options,
    selectedUnitsAndGroupsFromParams,
    valueIds.length,
  ]);

  // Change query params when values change
  useEffect(() => {
    if (!withQueryParams) return;
    if (!valuesSetFromParams.current) return;

    const unitIdsAlreadyInGroups = fullySelectedGroups.reduce(
      (acc, groupName) => [...acc, ...(groups[groupName]?.available || [])],
      [] as IUnit["id"][],
    );
    const unitNamesToKeep: string[] = [];
    options.forEach(
      ({ value, label }) =>
        valueIds.includes(value) &&
        !unitIdsAlreadyInGroups.includes(value) &&
        unitNamesToKeep.push(label),
    );

    const unitsQueryValue = filter([...unitNamesToKeep, ...fullySelectedGroups]).join(",");

    setSearchParams((otherParams) => {
      unitsQueryValue ? otherParams.set("units", unitsQueryValue) : otherParams.delete("units");

      return otherParams;
    });
  }, [fullySelectedGroups, groups, options, setSearchParams, valueIds, withQueryParams]);

  return valuesSetFromParams;
};
