/* eslint-disable no-console */

import { memo } from "react";

import { keyBy } from "lodash";

import { IUnitBasic } from "~/routes/api/types";

import { Roster } from "@/api";

import { TableParts } from "..";

export const NotificationsRows = memo(function MemoizedTableRows({
  units,
  rosterEntries,
}: {
  units: IUnitBasic[];
  rosterEntries: Roster.DTO[];
}) {
  const unitsById = keyBy(units, "id");
  return (
    <>
      {rosterEntries
        .sort((a, b) =>
          (unitsById[a.unitId]?.name || "").localeCompare(unitsById[b.unitId]?.name || ""),
        )
        .map((rosterEntry) => {
          const unit = unitsById[rosterEntry.unitId];
          return unit && <TableParts.Row unit={unit} rosterEntry={rosterEntry} />;
        })}
    </>
  );
});
