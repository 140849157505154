import { useEffect, useState } from "react";

import { ISODateString } from "@m7-health/shared-utils";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { startCase } from "lodash";

import { Typography } from "@mui/material";

import CustomModal from "~/common/components/Modal";
import { useErrors } from "~/common/hooks/useErrors";
import { useAppDispatch, useAppSelector } from "~/common/hooks/useRedux";
import { useToast } from "~/common/hooks/useToast";
import { Dayjs } from "~/common/packages/dayjs";

import { CustomDatePicker } from "@/common/components";
import { useCurrentTimezone } from "@/common/hooks";
import { localDayJs } from "@/common/packages/dayjs";
import { getTzUSFormattedDate } from "@/common/utils/dates";

import { changeStaffStatus } from "../api";
import { setIsStatusChangeModalOpen } from "../store";
import { EStaffStatus } from "../types";

const StatusChangeModal = () => {
  const dispatch = useAppDispatch();
  const { handleErrors } = useErrors();
  const queryClient = useQueryClient();
  const { showSuccess } = useToast();
  const timezone = useCurrentTimezone();

  const {
    id: statusChangeModalUserId,
    name: statusChangeModalUserName,
    status: statusChangeModalStatus,
    postDatedExistingStatus,
    postDatedExistingDate,
  } = useAppSelector((state) => state.roster.statusChangeModal.data);
  const statusChangeModalState = useAppSelector((state) => state.roster.statusChangeModal.isOpen);

  const [statusChangeDate, setStatusChangeDate] = useState<Dayjs | undefined>(undefined);
  useEffect(() => {
    if (!timezone || statusChangeDate) return;
    setStatusChangeDate(localDayJs().tz(timezone));
  }, [timezone, statusChangeDate]);

  const onSettled = () => void queryClient.invalidateQueries({ queryKey: ["roster"] });

  const { mutate: changeStaffStatusMutation, isPending: isChangeStaffStatusApiLoading } =
    useMutation({
      mutationFn: changeStaffStatus,
      onSuccess: () => {
        dispatch(setIsStatusChangeModalOpen(false));
        showSuccess("Status changed successfully");
      },
      onError: handleErrors,
      onSettled,
    });

  const confirmStatusChange = () => {
    if (!statusChangeDate) return;

    const changeInFuture =
      statusChangeModalStatus === EStaffStatus.Inactive &&
      statusChangeDate.isAfter(localDayJs().tz(timezone), "day");

    changeStaffStatusMutation({
      staffId: statusChangeModalUserId,
      status: changeInFuture ? EStaffStatus.Active : statusChangeModalStatus,
      postDateStatusUpdateDate: changeInFuture
        ? (statusChangeDate.toISOString() as ISODateString)
        : undefined,
      postDateStatusUpdateType: changeInFuture ? "inactive" : undefined,
    });
  };

  const resetState = () => {
    setStatusChangeDate(localDayJs().tz(timezone));
  };

  const handleSecondaryBtnClick = () => {
    resetState();
    dispatch(setIsStatusChangeModalOpen(false));
  };

  if (postDatedExistingStatus === "suspended")
    return (
      <CustomModal
        isOpen={statusChangeModalState}
        modalHeaderText="Status Change"
        onSecondaryBtnClick={handleSecondaryBtnClick}
        primaryDisabled={true}
        secondaryBtnText="Close"
        modalContent={
          <Typography>
            <b>{statusChangeModalUserName}</b> is already set to be suspended on{" "}
            <b>{getTzUSFormattedDate(postDatedExistingDate, timezone)}</b> and will automatically be
            updated to <b>Inactive</b> status at this time.
          </Typography>
        }
      />
    );

  const getWarningTextForStaffStatus = (staffStatus: EStaffStatus) => {
    switch (staffStatus) {
      case EStaffStatus.Active:
        return "This change will include selected person in future schedules in this unit.";
      case EStaffStatus.Inactive:
        return "This change in status will remove all of the staff's future shifts and preferences after the specified date from current and future schedules and will exclude them from future schedules in this unit.";
    }
  };

  const variantTitle = (
    <>
      Do you want to change status to <strong>{startCase(statusChangeModalStatus)}</strong> for{" "}
      <strong>{statusChangeModalUserName}</strong>?
      {statusChangeDate && statusChangeModalStatus === EStaffStatus.Inactive && (
        <>
          <br />
          <br />

          <CustomDatePicker
            disablePast
            name="statusChangeDate"
            label="Status takes effect on:"
            value={statusChangeDate}
            onChange={(newDate) => newDate && setStatusChangeDate(newDate)}
            timezone={timezone}
          />
        </>
      )}
    </>
  );

  return (
    <CustomModal
      isOpen={statusChangeModalState}
      primaryBtnText="Confirm"
      modalHeaderText="Status Change"
      onSecondaryBtnClick={handleSecondaryBtnClick}
      onSubmit={confirmStatusChange}
      primaryDisabled={isChangeStaffStatusApiLoading}
      variant="warning"
      variantText={getWarningTextForStaffStatus(statusChangeModalStatus)}
      variantTitle={variantTitle}
    />
  );
};

export default StatusChangeModal;
