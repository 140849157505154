import { ISODateString, Millisecond } from "@m7-health/shared-utils";

import { AccessTime } from "@mui/icons-material";
import { Tooltip, Typography } from "@mui/material";

import { useCurrentTimezone } from "@/common/hooks";
import { localDayJs } from "@/common/packages/dayjs";
import { darkGray } from "@/common/theming/colors";

const oneYear = localDayJs.duration(1, "year").asMilliseconds() as Millisecond;
const oneWeek = localDayJs.duration(1, "week").asMilliseconds() as Millisecond;

/**
 * Displays a humanized timestamp with a tooltip showing the full date and time.
 *
 * If the date is before the provided threshold (default is one week),
 *  the timestamp will be displayed as a 'time ago' string.
 * Otherwise, a
 */
export const HumanizedTimestamp = ({
  date,
  timeAgoThreshold = oneWeek,
}: {
  date: ISODateString;
  timeAgoThreshold?: Millisecond;
}) => {
  const timezone = useCurrentTimezone();

  const diff = localDayJs(date).diff() as Millisecond;
  let humanizedTime: string;

  const formattedTime =
    diff > oneYear
      ? localDayJs(date).tz(timezone).format("MMM D, YYYY [at] h:mm A Z")
      : localDayJs(date).tz(timezone).format("MMM D [at] h:mm:ss A Z");
  if (diff > timeAgoThreshold) {
    humanizedTime = localDayJs(date).tz(timezone).format("MM-DD-YYYY");
  } else {
    humanizedTime = localDayJs(date).fromNow();
  }

  return (
    <Typography
      color={darkGray}
      fontSize="14px"
      alignItems="center"
      display="flex"
      position={"relative"}
    >
      {humanizedTime}
      <Tooltip title={formattedTime}>
        <AccessTime fontSize="small" sx={{ ml: 1, "&:hover": { color: "black !important" } }} />
      </Tooltip>
    </Typography>
  );
};
