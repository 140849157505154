import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { m7DayJs, Uuid } from "@m7-health/shared-utils";

import { ArrowForward, Build, Home, Work } from "@mui/icons-material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  Avatar,
  Card,
  CardContent,
  Grid,
  Typography,
  Box,
  Divider,
  FormControl,
  InputLabel,
  Select,
  SelectChangeEvent,
} from "@mui/material";

import { useGetStaffMetadataQuery } from "~/api/staffMetadata";

import FairnessPage from "#/features/Fairness";
import { useAppConfigQuery } from "#/features/User/queries";
import { User } from "@/api";
import { MenuItem, CustomButton } from "@/common/components";
import { CrossIcon } from "@/common/components/Icons";
import { SendSingleSms } from "@/common/components/SendSingleSms/SendSingleSms";
import StaffCalendar from "@/common/components/StaffCalendar/StaffCalendar";
import { NOT_EXISTING_UUID } from "@/common/constants";
import { useAppDispatch, useAppFlags, useCurrentUnit } from "@/common/hooks";
import { avatarBackground, cardBackground, darkPurple } from "@/common/theming";
import { ITable } from "@/common/types";

import { setIsSuspendedModalOpen, setSuspendedModalData } from "../../store";
import SuspendUserModal from "../../SuspendUserModal";
import { EStaffStatus, IKioskDto, ISchedulerDto, IStaffWithDetailsDto } from "../../types";

import ShiftStatusTable from "./ShiftStatusTable";
import SimpleLineChart from "./SimpleLineChart";
import { StyledChip, StyledPaper } from "./styles";
import { TimeFrame, timeFrameLabels, TPulseCheckData } from "./types";

export const StaffProfilePage = ({
  rosterData,
}: {
  rosterData: ITable<IStaffWithDetailsDto | IKioskDto | ISchedulerDto>;
}) => {
  /** HIGH LEVEL STATE */
  const { schedulerStaffProfileExtraFeatures } = useAppFlags();
  const dispatch = useAppDispatch();
  const configData = useAppConfigQuery()?.data;
  const units = configData?.accessibleUnits || [];
  const currentUnit = useCurrentUnit();
  const facilities = configData?.facilities || [];
  const { staffId } = useParams();
  const navigate = useNavigate();

  const possibleStaffDetails = rosterData?.data.find((item) => item.id === staffId);

  const handleBack = () => {
    navigate(-1);
  };
  /** QUERIES */
  const { data: staffMetadata } = useGetStaffMetadataQuery(
    {
      staffId: (staffId || NOT_EXISTING_UUID) as Uuid,
    },
    {
      skip: !staffId,
    },
  );
  /** LOCAL STATE */
  // Pulse checks of this year
  const [selectedYearPulseCheck, setSelectedYearPulseCheck] = useState(m7DayJs().year());
  // Time frame for shift status counts
  const [selectedTimeFrameStatus, setSelectedTimeFrameStatus] = useState<TimeFrame>("weekCounts");

  const handleTimeFrameChange = (event: SelectChangeEvent<TimeFrame>) => {
    setSelectedTimeFrameStatus(event.target.value as TimeFrame);
  };

  if (possibleStaffDetails?.userType !== User.ERole.staff || !staffId) {
    return <Typography variant="h6">This user is not a staff member</Typography>;
  }

  const staffDetails = possibleStaffDetails;
  const homeUnit = units.find((unit) => unit.id === staffDetails.homeUnitId);
  const homeFacility = facilities?.find((facility) => facility.id === homeUnit?.facilityId);
  const isOnOrientation =
    staffDetails.onOrientation &&
    staffDetails?.orientationEndDate &&
    staffDetails.orientationEndDate < m7DayJs().toISOString();
  const isInactive = staffDetails.status === EStaffStatus.Inactive;

  const pulseCheckData: TPulseCheckData[] =
    staffMetadata?.pulseCheckSubmissions
      ?.map((submission) => ({
        date: submission.statusSetAt || submission.createdAt,
        personalScore: submission.content["satisfaction-question"],
        unitAverage: submission.averageSatisfactionScore || 0,
        answer: submission.content["open-question"],
      }))
      ?.sort((a, b) => m7DayJs(a.date).valueOf() - m7DayJs(b.date).valueOf()) || [];

  const years = [...new Set(pulseCheckData.map((data) => m7DayJs(data.date).year()))];
  const filteredPulseCheckData = pulseCheckData.filter(
    (data) => m7DayJs(data.date).year() === selectedYearPulseCheck,
  );
  const latestPulseCheckResponse =
    filteredPulseCheckData[filteredPulseCheckData.length - 1]?.answer;

  return (
    <>
      <CustomButton
        startIcon={<ArrowBackIcon />}
        onClick={handleBack}
        sx={{ marginBottom: 2 }}
        trackingLabel={"Go back from staff profile page"}
      >
        Back
      </CustomButton>
      <Box sx={{ p: 3 }}>
        <Grid container spacing={3}>
          {/* Staff Information Section */}
          <Grid item xs={12}>
            <StyledPaper elevation={3}>
              <Box sx={{ display: "flex", alignItems: "center", mb: 3 }}>
                <Avatar sx={{ width: 80, height: 80, mr: 2, bgcolor: avatarBackground }}>
                  {staffDetails.firstName[0]}
                  {staffDetails.lastName[0]}
                </Avatar>
                <Box>
                  <Typography variant="h4" fontWeight="bold">
                    {staffDetails.firstName} {staffDetails.lastName}
                  </Typography>
                  <Typography variant="h6" color="text.secondary">
                    {staffDetails.staffTypeName}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {staffDetails.email} | {staffDetails.phoneNumber}
                  </Typography>
                </Box>
                {schedulerStaffProfileExtraFeatures && (
                  <Box sx={{ ml: "auto" }}>
                    <Typography variant="h6" fontWeight="bold" color="success.main">
                      Staff Score: 100/100 (N/A)
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      No Risk Assessment
                    </Typography>
                  </Box>
                )}
              </Box>
              <Divider sx={{ my: 2 }} />
              <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                  <Typography variant="body1" sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                    <Build fontSize="small" sx={{ mr: 1, color: "primary.main" }} />
                    Facility: {homeFacility?.name}
                  </Typography>
                  <Typography variant="body1" sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                    <Work fontSize="small" sx={{ mr: 1, color: "primary.main" }} />
                    Employment Type: {staffDetails.employmentType}
                  </Typography>
                  <Typography variant="body1" sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                    <Home fontSize="small" sx={{ mr: 1, color: "primary.main" }} />
                    Home Unit: {homeUnit?.name}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Card variant="outlined" sx={{ bgcolor: cardBackground }}>
                    <CardContent>
                      <Typography variant="h6" sx={{ mb: 1 }}>
                        🎉 Work Anniversary:{" "}
                        {m7DayJs(staffDetails.employmentStartDate).format("MM/DD/YYYY")}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </StyledPaper>
          </Grid>

          {/* Left Column */}
          <Grid item xs={12} md={6}>
            <Grid container spacing={3}>
              {/* Staff Calendar Section */}
              <Grid item xs={12}>
                <StyledPaper elevation={3}>
                  <Typography variant="h6" fontWeight="bold" sx={{ mb: 2 }}>
                    Staff Calendar
                  </Typography>
                  <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <StaffCalendar
                      staffId={staffId as Uuid}
                      selectedDate={m7DayJs()}
                      weeksAround={3}
                    />
                  </Box>
                </StyledPaper>
              </Grid>
              {/* Schedule History Section */}
              <Grid item xs={12}>
                <StyledPaper elevation={3}>
                  <Typography variant="h6" fontWeight="bold" sx={{ mb: 2 }}>
                    Schedule History
                  </Typography>
                  <FormControl fullWidth sx={{ mb: 2 }}>
                    <InputLabel id="time-frame-select-label">Time Frame</InputLabel>
                    <Select
                      labelId="time-frame-select-label"
                      id="time-frame-select"
                      value={selectedTimeFrameStatus}
                      label="Time Frame"
                      onChange={handleTimeFrameChange}
                    >
                      {Object.entries(timeFrameLabels).map(([value, label]) => (
                        <MenuItem
                          key={value}
                          value={value}
                          trackingLabel={"schedule history time range"}
                        >
                          {label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  {staffMetadata?.shiftStatusCounts && currentUnit && (
                    <ShiftStatusTable
                      staffCounts={staffMetadata.shiftStatusCounts.staff[selectedTimeFrameStatus]}
                      unitCounts={staffMetadata.shiftStatusCounts.unit[selectedTimeFrameStatus]}
                      unitConfig={currentUnit.configuration}
                    />
                  )}
                </StyledPaper>
              </Grid>
            </Grid>
          </Grid>

          {/* Right Column */}
          <Grid item xs={12} md={6}>
            <Grid container spacing={3}>
              {/* Tasks Completed Section */}
              <Grid item xs={12}>
                <StyledPaper elevation={3}>
                  <Typography variant="h6" fontWeight="bold" sx={{ mb: 2 }}>
                    Tasks Completed
                  </Typography>
                  <Box sx={{ display: "flex", alignItems: "center", flexWrap: "wrap" }}>
                    <StyledChip
                      label="Orientation"
                      color={isOnOrientation && !isInactive ? "success" : "secondary"}
                    />
                    <ArrowForward sx={{ mx: 1 }} />
                    <StyledChip
                      label="Employment"
                      color={!isOnOrientation && !isInactive ? "success" : "secondary"}
                    />
                    <ArrowForward sx={{ mx: 1 }} />
                    <StyledChip
                      color={isInactive ? "success" : "danger"}
                      label={
                        <Box
                          sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
                        >
                          <CrossIcon />
                          Exit
                        </Box>
                      }
                      onClick={() => {
                        if (!isInactive) {
                          dispatch(setIsSuspendedModalOpen(true));
                          dispatch(
                            setSuspendedModalData({
                              id: staffId,
                              name: `${staffDetails.lastName} ${staffDetails.firstName}`,
                            }),
                          );
                        }
                      }}
                    />
                  </Box>
                  {isOnOrientation && (
                    <Typography variant="body2" sx={{ mt: 1 }}>
                      Orientation ends{" "}
                      {m7DayJs(staffDetails.orientationEndDate).format("MM/DD/YYYY")}
                    </Typography>
                  )}
                </StyledPaper>
              </Grid>
              {/* Pulse Check Analysis Section */}
              {schedulerStaffProfileExtraFeatures && (
                <Grid item xs={12}>
                  <StyledPaper elevation={3}>
                    <Typography
                      variant="h6"
                      fontWeight="bold"
                      sx={{
                        mb: 2,
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      Pulse Check Analysis
                      <SendSingleSms.Button
                        data={{
                          firstName: staffDetails.firstName,
                          lastName: staffDetails.lastName,
                          id: staffDetails.id,
                          label: `Text ${staffDetails.firstName}`,
                        }}
                        sx={{ fontSize: "16px", backgroundColor: darkPurple }}
                      />
                    </Typography>
                    {pulseCheckData.length > 0 && (
                      <>
                        <Box sx={{ backgroundColor: cardBackground, p: 2, borderRadius: 1, mb: 2 }}>
                          {latestPulseCheckResponse ? (
                            <Typography variant="body2" sx={{ fontStyle: "italic" }}>
                              "{latestPulseCheckResponse}"
                            </Typography>
                          ) : (
                            <Typography variant="body2" sx={{ fontStyle: "italic" }}>
                              No response available in last pulse check.
                            </Typography>
                          )}
                        </Box>
                        <FormControl fullWidth sx={{ mt: 2 }}>
                          <InputLabel id="year-select-label">Select Year</InputLabel>
                          <Select
                            labelId="year-select-label"
                            value={selectedYearPulseCheck}
                            label="Select Year"
                            onChange={(event) =>
                              setSelectedYearPulseCheck(event.target.value as number)
                            }
                          >
                            {years.map((year) => (
                              <MenuItem
                                key={year}
                                value={year}
                                trackingLabel={"year selector staff profile"}
                              >
                                {year}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        <SimpleLineChart
                          data={filteredPulseCheckData}
                          year={selectedYearPulseCheck}
                        />
                      </>
                    )}
                    {pulseCheckData.length === 0 && (
                      <Typography variant="body2">No pulse check data available.</Typography>
                    )}
                  </StyledPaper>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
        {/* Schedule Fairness - spans full width */}
        <Grid item xs={12}>
          <StyledPaper elevation={3}>
            <FairnessPage currentStaffId={staffId as Uuid} />
          </StyledPaper>
        </Grid>
      </Box>
      <SuspendUserModal />
    </>
  );
};
