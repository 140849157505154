import { ESchedulePageTabs } from "#/features/SchedulerGrid/types";
import { User } from "@/api";
import { isOnMobile } from "@/common/utils/isOnMobile";

import { TDefaultRoleRoute } from "./types";

// Default route for each role on desktop
export const defaultDesktopRoleRoute: TDefaultRoleRoute = {
  [User.ERole.admin]: "/schedule",
  [User.ERole.scheduler]: "/schedule",
  [User.ERole.staff]: "/calendar",
  [User.ERole.kiosk]: `/schedule/${ESchedulePageTabs.planning}`,
};

// Default route for each role on mobile
export const defaultMobileRoleRoute: TDefaultRoleRoute = {
  [User.ERole.admin]: "/schedule",
  [User.ERole.scheduler]: "/house-view",
  [User.ERole.staff]: "/calendar",
  [User.ERole.kiosk]: `/schedule/${ESchedulePageTabs.planning}`,
};

export const defaultRoleRoute = (role: User.ERole): string => {
  return isOnMobile() ? defaultMobileRoleRoute[role] : defaultDesktopRoleRoute[role];
};
