import React from "react";

import { PreferenceRequirementRuleSet, THexColor } from "@m7-health/shared-utils";
import { isEqual, isNumber, map, values } from "lodash";

import { Delete, Edit } from "@mui/icons-material";
// eslint-disable-next-line deprecate/import
import {
  Autocomplete,
  Box,
  Button,
  Card,
  Grid,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from "@mui/material";

import { IPreferenceRequirementRule, IPreferenceRequirementRuleSet, IUnitShiftType } from "@/api";
import { ColorSelector } from "@/common/components";
import ShiftTypeFilterDropdown from "@/common/components/ShiftTypeFilterDropdown";
import { useFilterBy } from "@/common/hooks";
import { black, lightGray, mediumGray, white } from "@/common/theming";

const frequencyLabels = {
  [PreferenceRequirementRuleSet.EFrequencyStep.schedule]: "per schedule",
  [PreferenceRequirementRuleSet.EFrequencyStep.weekly]: "per week",
  [PreferenceRequirementRuleSet.EFrequencyStep.payPeriod]: "per pay period",
} as const;
const targetTypesLabels = {
  [PreferenceRequirementRuleSet.ETargetType.hours]: "Hours",
  [PreferenceRequirementRuleSet.ETargetType.shiftCount]: "Shifts",
};

const DeleteRuleButton = ({
  deleteRule,
  disabled,
  rule,
}: {
  deleteRule: (rule: IPreferenceRequirementRule) => void;
  disabled?: boolean;
  rule: IPreferenceRequirementRule;
}) => {
  return (
    <IconButton
      disabled={disabled}
      sx={{ ":disabled": { color: mediumGray, cursor: "not-allowed" } }}
      onClick={() => deleteRule(rule)}
      children={<Delete />}
    />
  );
};

export const RuleLabel = ({
  rule,
  showError,
  updateRule,
}: {
  rule: IPreferenceRequirementRule;
  showError: boolean;
  updateRule: (
    rule: IPreferenceRequirementRule,
    params: Partial<IPreferenceRequirementRule>,
  ) => void;
}) => (
  <TextField
    error={showError && !rule.label}
    variant="standard"
    label={
      <>
        <span className="title">Requirement Label</span>
        <span className="helper">(Max 8 characters)</span>
      </>
    }
    inputProps={{ maxLength: 8 }}
    InputProps={{
      endAdornment: <InputAdornment position="end" children={<Edit fontSize="small" />} />,
    }}
    sx={{
      ".MuiFormLabel-root .helper": { display: "none" },
      ".MuiFormLabel-root .title": { display: "initial" },
      ".MuiFormLabel-root.Mui-focused .helper": { display: "initial" },
      ".MuiFormLabel-root.Mui-focused .title": { display: "none" },
    }}
    size="small"
    value={rule.label}
    onChange={(input) =>
      updateRule(rule, {
        label: input.target.value,
      })
    }
  />
);

export const Phrase = ({ content, width }: { content: string; width: string }) => (
  <Typography children={content} sx={{ width }} />
);

export const MinMaxFields = ({
  showError,
  rule,
  updateRule,
}: {
  showError: boolean;
  rule: IPreferenceRequirementRule;
  updateRule: (
    rule: IPreferenceRequirementRule,
    params: Partial<IPreferenceRequirementRule>,
  ) => void;
}) => (
  <>
    <TextField
      placeholder="1"
      size="small"
      sx={{ width: "60px" }}
      type="number"
      label="Min"
      inputProps={{ min: 0, step: 1 }}
      value={rule.frequencyValueMin}
      onChange={(input) =>
        updateRule(rule, {
          frequencyValueMin: parseInt(input.target.value),
        })
      }
      error={
        showError &&
        (!isNumber(rule.frequencyValueMin) ||
          rule.frequencyValueMin < 0 ||
          rule.frequencyValueMin.toFixed(0) !== rule.frequencyValueMin.toString())
      }
    />
    <Phrase content={"and"} width={"30px"} />
    <TextField
      placeholder="1"
      size="small"
      sx={{ width: "60px" }}
      type="number"
      label="Max"
      inputProps={{ min: 0, step: 1 }}
      value={rule.frequencyValueMax}
      onChange={(input) =>
        updateRule(rule, {
          frequencyValueMax: parseInt(input.target.value),
        })
      }
      error={
        showError &&
        (!isNumber(rule.frequencyValueMax) ||
          rule.frequencyValueMax < 0 ||
          rule.frequencyValueMax.toFixed(0) !== rule.frequencyValueMax.toString() ||
          rule.frequencyValueMax < rule.frequencyValueMin)
      }
    />
  </>
);

export const ShiftTypePicker = ({
  rule,
  shiftTypes,
  updateRule,
  showError,
  readonly,
}: {
  rule: IPreferenceRequirementRule;
  shiftTypes: IUnitShiftType[];
  updateRule: (
    rule: IPreferenceRequirementRule,
    params: Partial<IPreferenceRequirementRule>,
  ) => void;
  showError: boolean;
  readonly?: boolean;
}) => {
  const selectedShiftTypes = useFilterBy(
    shiftTypes,
    ({ key }) => rule.targetShiftTypeKeys?.includes(key),
    [rule.targetShiftTypeKeys],
  );

  return (
    <ShiftTypeFilterDropdown<IUnitShiftType>
      readonly={readonly}
      shiftTypes={shiftTypes}
      isMultiSelect
      label=""
      limit={4}
      width={"400px"}
      sx={{
        ".MuiInputBase-inputSizeSmall": { p: "5px", minHeight: "30px" },
      }}
      error={showError && !selectedShiftTypes.length}
      selectOption={(selectedOptions) => {
        const newKeys = map(selectedOptions, "key");

        // Specific use case for the block requirement section. Cannot select block only
        if (isEqual(newKeys, ["block"])) return;

        updateRule(rule, { targetShiftTypeKeys: newKeys });
      }}
      selectedOption={selectedShiftTypes}
    />
  );
};

const TargetTypePicker = ({
  rule,
  updateRule,
  disabled,
}: {
  rule: IPreferenceRequirementRule;
  updateRule: (
    rule: IPreferenceRequirementRule,
    params: Partial<IPreferenceRequirementRule>,
  ) => void;
  disabled?: boolean;
}) => (
  <Autocomplete
    size="small"
    id="frequency"
    options={values(PreferenceRequirementRuleSet.ETargetType)}
    sx={{ minWidth: "100px" }}
    value={rule.targetType}
    onChange={(_, newValue) =>
      updateRule(rule, {
        targetType: newValue,
      })
    }
    disabled={disabled}
    disableClearable
    getOptionLabel={(option) => targetTypesLabels[option]}
    renderInput={(params) => (
      <TextField
        {...{ ...params, size: "small" }}
        sx={{ ".MuiOutlinedInput-root": { paddingRight: "30px !important" } }}
      />
    )}
  />
);

const availableFrequencies = values(PreferenceRequirementRuleSet.EFrequencyStep);
const FrequencyPicker = ({
  rule,
  updateRule,
}: {
  rule: IPreferenceRequirementRule;
  updateRule: (
    rule: IPreferenceRequirementRule,
    params: Partial<IPreferenceRequirementRule>,
  ) => void;
}) => (
  <Autocomplete
    size="small"
    id="frequency"
    options={availableFrequencies}
    disabled={availableFrequencies.length === 1}
    sx={{
      minWidth: "180px",
      ...(availableFrequencies.length === 1
        ? {
            input: { "-webkit-text-fill-color": black + " !important" },
            ".MuiAutocomplete-popupIndicator": { color: mediumGray },
          }
        : {}),
    }}
    value={rule.frequencyStep}
    onChange={(_, newValue) =>
      updateRule(rule, {
        frequencyStep: newValue,
      })
    }
    disableClearable
    getOptionLabel={(option) => frequencyLabels[option]}
    renderInput={(params) => <TextField {...{ ...params, size: "small" }} />}
  />
);

export const RuleUiWrapper = ({
  children,
  index,
}: {
  children: React.ReactNode;
  index: number;
}) => (
  <Card sx={{ p: 1, m: 1, background: index % 2 === 0 ? white : lightGray }} children={children} />
);

export const AddRuleButton = ({
  label,
  createNewRule,
}: {
  label: string;
  createNewRule: () => void;
}) => (
  <Stack direction="row" spacing={2} m={1}>
    <Box flexGrow={1} />
    <Button
      sx={{ display: "block", mt: 2, width: "250px" }}
      variant="contained"
      onClick={() => createNewRule()}
      children={label}
    />
  </Stack>
);

export const RuleSetFields = ({
  showError,
  ruleSet,
  setRuleSet,
}: {
  showError: boolean;
  ruleSet: IPreferenceRequirementRuleSet;
  setRuleSet: (ruleSet: IPreferenceRequirementRuleSet) => void;
}) => (
  <Stack direction="row" spacing={2} m={1}>
    <Typography variant="h6">Requirement Set Details</Typography>
    <Box flexGrow={1} />
    <TextField
      size="small"
      label="Requirement Set Name"
      variant="standard"
      sx={{ minWidth: "200px" }}
      error={showError && !ruleSet.name}
      onChange={(input) =>
        setRuleSet({
          ...ruleSet,
          name: input.target.value,
        })
      }
      value={ruleSet.name}
      helperText=""
    />
    <TextField
      size="small"
      label="Requirement Set Label"
      variant="standard"
      sx={{ width: "150px" }}
      error={showError && !ruleSet.label}
      onChange={(input) =>
        setRuleSet({
          ...ruleSet,
          label: input.target.value,
        })
      }
      value={ruleSet.label}
      helperText="Max 10 characters"
      inputProps={{ maxLength: 10 }}
    />
    <Grid item sx={{ width: "100px" }}>
      <ColorSelector
        selectedColor={ruleSet.color}
        onChange={(event) =>
          setRuleSet({
            ...ruleSet,
            color: event.target.value as THexColor,
          })
        }
        label="Color"
        variant="standard"
        error={showError && !ruleSet.color}
      />
    </Grid>
  </Stack>
);

export const RuleSetDescription = ({
  ruleSet,
  setRuleSet,
}: {
  ruleSet: IPreferenceRequirementRuleSet;
  setRuleSet: (ruleSet: IPreferenceRequirementRuleSet) => void;
}) => (
  <TextField
    size="small"
    label="Requirement Set description"
    variant="filled"
    sx={{ width: "100%", my: 2 }}
    className="rule-set-description"
    multiline
    rows={3}
    onChange={(input) =>
      setRuleSet({
        ...ruleSet,
        description: input.target.value,
      })
    }
    placeholder="Describe the purpose and content of this requirement set here ..."
    value={ruleSet.description}
  />
);

export const RuleSetElements = () => <></>;
RuleSetElements.RuleSetFields = RuleSetFields;
RuleSetElements.RuleSetDescription = RuleSetDescription;

export const RuleElements = () => <></>;
RuleElements.DeleteRule = DeleteRuleButton;
RuleElements.RuleLabel = RuleLabel;
RuleElements.Phrase = Phrase;
RuleElements.MinMax = MinMaxFields;
RuleElements.TargetTypePicker = TargetTypePicker;
RuleElements.ShiftTypePicker = ShiftTypePicker;
RuleElements.FrequencyPicker = FrequencyPicker;
RuleElements.RuleWrapper = RuleUiWrapper;
RuleElements.AddRule = AddRuleButton;
