import { EUnitPermissionAreas, ISODateString } from "@m7-health/shared-utils";
import { createSlice } from "@reduxjs/toolkit";

import { User } from "@/api";

import {
  EStaffStatus,
  INoteModalData,
  IRosterState,
  ISingleSendSMSModalData,
  ISuspendModalData,
  RosterIdsByEntityOption,
} from "../types";

import type { PayloadAction } from "@reduxjs/toolkit";

const initialState: IRosterState = {
  addUserModal: {
    isOpen: false,
  },
  massSendSmsModal: {
    isOpen: false,
  },
  singleSendSmsModal: {
    isOpen: false,
    preSelectedUserToSms: undefined,
  },
  editSettingsModal: {
    isOpen: false,
    selectedSMSRosterIds: [],
    selectedEmailRosterIds: [],
    selectedRosterIdsByEntityOption: {},
  },
  editUserModal: {
    id: "",
    isOpen: false,
    userType: null,
  },
  noteModal: {
    data: {
      firstName: "",
      id: "",
      lastName: "",
      note: "",
    },
    isOpen: false,
    variant: "add",
  },
  statusChangeModal: {
    isOpen: false,
    data: {
      id: "",
      name: "",
      status: EStaffStatus.Inactive,
      postDatedExistingStatus: null,
      postDatedExistingDate: null,
    },
  },
  suspendAccountModal: {
    isOpen: false,
    data: {
      id: "",
      name: "",
    },
  },
  restoreAccountModal: {
    isOpen: false,
    data: {
      id: "",
      name: "",
    },
  },
  updateRuleSetsModal: {
    isOpen: false,
  },
  updateRuleSetModal: {
    isOpen: false,
  },
  updateRuleSetUsersModal: {
    isOpen: false,
  },
  updatePreferencesTemplatesModal: {
    isOpen: false,
  },
  updatePreferencesTemplateModal: {
    isOpen: false,
  },
  updatePreferencesTemplateUsersModal: {
    isOpen: false,
  },
  applyPreferencesTemplatesModal: {
    isOpen: false,
  },
  updateStatusDateModal: {
    isOpen: false,
  },
  updateSuspensionDateModal: {
    isOpen: false,
  },
  bulkUsersUploadModal: {
    isOpen: false,
  },
  editFloatEligibilityModal: {
    isOpen: false,
  },
  editAttributeEligibilityModal: {
    isOpen: false,
  },
  rosterTable: {
    pageSize: 100,
  },
  permissions: {
    permissionsReadyToSubmit: false,
    permissionsByUnit: {},
    houseViewPermission: "none",
    schedulerRosterPermission: "none",
    shiftReportsPermission: "none",
  },
};

export const rosterModalSlice = createSlice({
  name: "roster",
  initialState,
  reducers: {
    openUpdateStatusDateModal: (
      state,
      action: PayloadAction<NonNullable<IRosterState["updateStatusDateModal"]["data"]>>,
    ) => {
      state.updateStatusDateModal = {
        isOpen: true,
        data: action.payload,
      };
    },
    closeUpdateStatusDateModal: (state) => {
      state.updateStatusDateModal = { isOpen: false };
    },
    openUpdateSuspensionDateModal: (
      state,
      action: PayloadAction<NonNullable<IRosterState["updateSuspensionDateModal"]["data"]>>,
    ) => {
      state.updateSuspensionDateModal = {
        isOpen: true,
        data: action.payload,
      };
    },
    closeUpdateSuspensionDate: (state) => {
      state.updateSuspensionDateModal = { isOpen: false };
    },
    setIsAddUserModalOpen: (state, action: PayloadAction<boolean>) => {
      state.addUserModal.isOpen = action.payload;
    },
    setMassSendSmsModalOpen: (state, action: PayloadAction<boolean>) => {
      state.massSendSmsModal.isOpen = action.payload;
    },
    setSingleSendSmsModalOpen: (state, action: PayloadAction<boolean>) => {
      state.singleSendSmsModal.isOpen = action.payload;
    },
    setSingleSendSmsModalUser: (state, action: PayloadAction<ISingleSendSMSModalData>) => {
      state.singleSendSmsModal.preSelectedUserToSms = action.payload;
    },
    setEditSettingsModalOpen: (state, action: PayloadAction<boolean>) => {
      state.editSettingsModal.isOpen = action.payload;
    },
    openModal: (
      state,
      action: PayloadAction<
        | "updateRuleSetsModal"
        | "updateRuleSetModal"
        | "updateRuleSetUsersModal"
        | "updatePreferencesTemplateModal"
        | "updatePreferencesTemplatesModal"
        | "updatePreferencesTemplateUsersModal"
        | "applyPreferencesTemplatesModal"
      >,
    ) => {
      state[action.payload].isOpen = true;
    },
    setRuleSetToUpdate: (
      state,
      action: PayloadAction<IRosterState["updateRuleSetModal"]["ruleSetToUpdate"]>,
    ) => {
      state.updateRuleSetModal.ruleSetToUpdate = action.payload;
    },
    setRuleSetToUpdateUsers: (
      state,
      action: PayloadAction<IRosterState["updateRuleSetUsersModal"]["ruleSetToUpdate"]>,
    ) => {
      state.updateRuleSetUsersModal.ruleSetToUpdate = action.payload;
    },
    setPreferencesTemplateToUpdate: (
      state,
      action: PayloadAction<
        IRosterState["updatePreferencesTemplateModal"]["preferencesTemplateToUpdate"]
      >,
    ) => {
      state.updatePreferencesTemplateModal.preferencesTemplateToUpdate = action.payload;
    },
    setPreferencesTemplateToUpdateUsers: (
      state,
      action: PayloadAction<
        IRosterState["updatePreferencesTemplateUsersModal"]["preferencesTemplateToUpdate"]
      >,
    ) => {
      state.updatePreferencesTemplateUsersModal.preferencesTemplateToUpdate = action.payload;
    },
    closeModal: (
      state,
      action: PayloadAction<
        | "updateRuleSetsModal"
        | "updateRuleSetModal"
        | "updateRuleSetUsersModal"
        | "updatePreferencesTemplateModal"
        | "updatePreferencesTemplatesModal"
        | "updatePreferencesTemplateUsersModal"
        | "applyPreferencesTemplatesModal"
      >,
    ) => {
      state[action.payload].isOpen = false;
    },
    setEditSettingsModalSelectedSMSRosterIds: (state, action: PayloadAction<number[]>) => {
      state.editSettingsModal.selectedSMSRosterIds = action.payload;
    },
    setEditSettingsModalSelectedEmailRosterIds: (state, action: PayloadAction<number[]>) => {
      state.editSettingsModal.selectedEmailRosterIds = action.payload;
    },
    setEditSettingsModalSelectedRosterIdsByEntityOption: (
      state,
      action: PayloadAction<RosterIdsByEntityOption>,
    ) => {
      state.editSettingsModal.selectedRosterIdsByEntityOption = action.payload;
    },
    setIsEditUserModalOpen: (state, action: PayloadAction<boolean>) => {
      state.editUserModal.isOpen = action.payload;
    },
    setEditUserModalId: (state, action: PayloadAction<string>) => {
      state.editUserModal.id = action.payload;
    },
    setEditUserModalUserType: (
      state,
      action: PayloadAction<User.ERole.staff | User.ERole.scheduler | null>,
    ) => {
      state.editUserModal.userType = action.payload;
    },
    setIsNoteModalOpen: (state, action: PayloadAction<boolean>) => {
      state.noteModal.isOpen = action.payload;
    },
    setNoteModalData: (state, action: PayloadAction<INoteModalData>) => {
      state.noteModal.data = action.payload;
    },
    setNoteModalVariant: (state, action: PayloadAction<"add" | "edit">) => {
      state.noteModal.variant = action.payload;
    },
    setIsStatusChangeModalOpen: (state, action: PayloadAction<boolean>) => {
      state.statusChangeModal.isOpen = action.payload;
    },
    setIsBulkUsersUploadModalOpen: (state, action: PayloadAction<boolean>) => {
      state.bulkUsersUploadModal.isOpen = action.payload;
    },
    setIsEditFloatEligibilityModalOpen: (state, action: PayloadAction<boolean>) => {
      state.editFloatEligibilityModal.isOpen = action.payload;
    },
    setIsEditAttributeEligibilityModalOpen: (state, action: PayloadAction<boolean>) => {
      state.editAttributeEligibilityModal.isOpen = action.payload;
    },
    setStatusChangeModalData: (
      state,
      action: PayloadAction<{
        name: string;
        id: string;
        status: EStaffStatus;
        postDatedExistingStatus: "inactive" | "suspended" | null;
        postDatedExistingDate: ISODateString | null;
      }>,
    ) => {
      state.statusChangeModal.data = action.payload;
    },
    setIsSuspendedModalOpen: (state, action: PayloadAction<boolean>) => {
      state.suspendAccountModal.isOpen = action.payload;
    },
    setSuspendedModalData: (state, action: PayloadAction<ISuspendModalData>) => {
      state.suspendAccountModal.data = action.payload;
    },
    setRosterTablePageSize: (state, action: PayloadAction<number>) => {
      state.rosterTable.pageSize = action.payload;
    },
    setIsRestoredModalOpen: (state, action: PayloadAction<boolean>) => {
      state.restoreAccountModal.isOpen = action.payload;
    },
    setRestoredModalData: (state, action: PayloadAction<ISuspendModalData>) => {
      state.restoreAccountModal.data = action.payload;
    },
    setPermissionsByUnit: (
      state,
      action: PayloadAction<Record<string, Record<EUnitPermissionAreas, string>>>,
    ) => {
      state.permissions.permissionsByUnit = action.payload;
    },
    setHouseViewPermission: (state, action: PayloadAction<string>) => {
      state.permissions.houseViewPermission = action.payload;
    },
    setSchedulerManagePermission: (state, action: PayloadAction<string>) => {
      state.permissions.schedulerRosterPermission = action.payload;
    },
    setShiftReportsPermission: (state, action: PayloadAction<string>) => {
      state.permissions.shiftReportsPermission = action.payload;
    },
    setPermissionsReadyToSubmit: (state, action: PayloadAction<boolean>) => {
      state.permissions.permissionsReadyToSubmit = action.payload;
    },
  },
});

export const {
  openUpdateStatusDateModal,
  closeUpdateStatusDateModal,
  openUpdateSuspensionDateModal,
  closeUpdateSuspensionDate,
  setEditUserModalId,
  setEditUserModalUserType,
  setIsAddUserModalOpen,
  setMassSendSmsModalOpen,
  setSingleSendSmsModalOpen,
  setSingleSendSmsModalUser,
  setEditSettingsModalOpen,
  setEditSettingsModalSelectedSMSRosterIds,
  setEditSettingsModalSelectedEmailRosterIds,
  setEditSettingsModalSelectedRosterIdsByEntityOption,
  setIsEditUserModalOpen,
  setRosterTablePageSize,
  setIsNoteModalOpen,
  setIsSuspendedModalOpen,
  setPreferencesTemplateToUpdate,
  setPreferencesTemplateToUpdateUsers,
  openModal,
  closeModal,
  setRuleSetToUpdate,
  setRuleSetToUpdateUsers,
  setNoteModalData,
  setNoteModalVariant,
  setIsStatusChangeModalOpen,
  setIsBulkUsersUploadModalOpen,
  setIsEditFloatEligibilityModalOpen,
  setIsEditAttributeEligibilityModalOpen,
  setStatusChangeModalData,
  setSuspendedModalData,
  setIsRestoredModalOpen,
  setRestoredModalData,
  setPermissionsByUnit,
  setHouseViewPermission,
  setSchedulerManagePermission,
  setShiftReportsPermission,
  setPermissionsReadyToSubmit,
} = rosterModalSlice.actions;
export default rosterModalSlice.reducer;
