export enum EFairnessTypes {
  PreferencesHonored = "preferencesHonored",
  WeekendShifts = "weekendShifts",
  ChargeShifts = "chargeShifts",
  BlockedDaysHonored = "blockedDaysHonored",
  ExperiencePerShift = "experiencePerShift",
}

export enum ESchedulesCount {
  All = 12,
  Last6 = 6,
  Last3 = 3,
}

export type ApiParams = {
  schedulesLimit: number;
  unitId: string | undefined;
};
