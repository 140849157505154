import { Grid } from "@mui/material";

import { Dayjs } from "~/common/packages/dayjs";
import { SmallHeader } from "~/features/Fairness/components/HeaderCells/HeaderNameCell/SmallHeader";

import { Dates } from "./Dates";

export const HeaderNameCell = ({ startDate, endDate }: { startDate: Dayjs; endDate: Dayjs }) => {
  return (
    <Grid container flexDirection="column" alignItems="center">
      <Dates startDate={startDate} endDate={endDate} />
      <SmallHeader />
    </Grid>
  );
};
