import { dateString } from "~/common/types";
import { IUser } from "~/features/User/types";

import { APIElementResponse, APIListResponse, SDTO } from "../_shared/types";
import { IQuestionnaire } from "../questionnaire";
import { IUnit } from "../unit";

export type ISubmission = Submission.DTO;

export namespace Submission {
  export enum ESubmissionStatus {
    // Created, but we don't want to show it to the user just yet
    created = "created",
    // We want to show it to the user
    requested = "requested",
    // The user ignored it
    ignored = "ignored",
    // The user submitted it
    completed = "completed",
    // The user hasn't ignored nor submitted, but we don't want to show it to the user
    cancelled = "cancelled",
  }

  export interface DTO extends SDTO.base, SDTO.withTimestamps, SDTO.softDeletable {
    statusSetAt: dateString;
    status: ESubmissionStatus;
    userId: IUser["id"];
    content: Record<string, unknown>;
    questionnaireRaw: IQuestionnaire["content"];
    questionnaireId?: IQuestionnaire["id"];
  }

  export namespace API {
    export const ROOT_PATH = "/submission";
    export namespace GetCurrent {
      export const PATH = ROOT_PATH + "/current";
      export interface QueryParams {}

      export type Response = APIElementResponse<DTO>;
    }

    export namespace Update {
      export const PATH = (params: QueryParams) => `${ROOT_PATH}/${params.id}`;
      export type QueryParams = Pick<
        DTO,
        "id" | "status" | "content" | "questionnaireRaw" | "questionnaireId"
      >;

      export type Response = APIElementResponse<DTO>;
    }

    export namespace List {
      export const PATH = ROOT_PATH;
      export interface QueryParams {
        unitIds?: IUnit["id"][];
        statuses?: ESubmissionStatus[];
      }

      export type Response = APIListResponse<DTO>;
    }
  }
}
