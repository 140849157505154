import { buildApiMutationHook } from "../_shared/helpers";

import { Questionnaire } from "./types";

export const useUpdateQuestionnaire = buildApiMutationHook<
  Questionnaire.API.Update.QueryParams,
  Questionnaire.API.Update.Response
>(Questionnaire.API.Update.PATH, "patch");

export const useCreateQuestionnaire = buildApiMutationHook<
  Questionnaire.API.Create.QueryParams,
  Questionnaire.API.Create.Response
>(Questionnaire.API.Create.PATH, "post");
