import { THexColor } from "@m7-health/shared-utils";

import { BULK_DELETE_KEY } from "@/common/constants";

import { APIListResponse, SDTO } from "../_shared/types";
import { IUnit } from "../unit";

export type IShiftIncentiveLevel = ShiftIncentiveLevel.DTO;

export namespace ShiftIncentiveLevel {
  export interface DTO extends SDTO.base, SDTO.withTimestamps, SDTO.softDeletable {
    label: string;
    level: number;
    color: THexColor;
    unitId: IUnit["id"];
    key: string;
  }

  export namespace API {
    export const ROOT_PATH = "/shift-incentive-level";
    export namespace List {
      export const PATH = ROOT_PATH;

      export type QueryParams = {
        unitIds?: IUnit["id"][];
      };
      export type Response = APIListResponse<DTO>;
    }

    export namespace BulkSave {
      export const PATH = `${ROOT_PATH}/bulk-save`;

      export type QueryParams = {
        shiftIncentiveLevels: TBulkSaveShiftIncentiveLevels;
        unitIds?: IUnit["id"][];
      };
      export type Response = APIListResponse<DTO>;
    }
  }
}

type TShiftIncentiveLevelToDelete = {
  id: IShiftIncentiveLevel["id"];
  [BULK_DELETE_KEY]: true;
};

type TShiftIncentiveLevelToUpdate = {
  id: IShiftIncentiveLevel["id"];
} & Partial<Pick<IShiftIncentiveLevel, "label" | "color">>;

type TStaffShiftIncentiveLevelToCreate = Pick<
  IShiftIncentiveLevel,
  "key" | "label" | "color" | "unitId"
>;

type TBulkSaveShiftIncentiveLevels = (
  | TShiftIncentiveLevelToDelete
  | TShiftIncentiveLevelToUpdate
  | TStaffShiftIncentiveLevelToCreate
)[];
