import { useEffect, useState } from "react";

import { EUnitPermissionAreas } from "@m7-health/shared-utils";
import { cloneDeep, keyBy, map } from "lodash";

import { Box, TextField } from "@mui/material";

import CustomCollapse from "~/common/components/Collapse";
import Autocomplete from "~/common/components/TrackedComponents/Autocomplete";
import Checkbox from "~/common/components/TrackedComponents/Checkbox";
import { NOT_EXISTING_UUID } from "~/common/constants";
import { useAppDispatch, useAppSelector } from "~/common/hooks/useRedux";
import { mediumGray } from "~/common/theming/colors";
import { houseViewStore } from "~/features/HouseView/store";

import { useCheckUserPermission, useIsKiosk, useMapBy } from "@/common/hooks";

import { HouseViewSideBar, STAFF_ITEMS_LAYOUTS } from "..";
import { StaffItem } from "../../StaffItem";
import { HouseViewSideBarHooks } from "../hooks";
import { THouseViewSideBar } from "../types";

const hooks = HouseViewSideBarHooks;

const AssignAttributes = ({ selectedUnit, shifts, staffDetails }: THouseViewSideBar) => {
  const isKioskUser = useIsKiosk();
  const canManage = useCheckUserPermission("manage", EUnitPermissionAreas.houseView);
  const dispatch = useAppDispatch();
  const unitAttributeKeys = useMapBy(selectedUnit.attributes, "key");

  const shiftTypesByKey = hooks.useShiftTypesByKey(
    shifts?.[selectedUnit.id]?.[0]?.scheduleId || NOT_EXISTING_UUID,
  );
  const unitShifts = shifts?.[selectedUnit.id];
  const selectedDateForData = useAppSelector(
    (state) => state.houseView.pageFilters.selectedDateForData,
  );
  const selectedUnitId = useAppSelector((state) => state.houseView.pageFilters.selectedUnitId);

  const [dirtyState, __setDirtyState] = useState(false);
  const setDirtyState = (value: boolean) => {
    __setDirtyState(value);
    dispatch(houseViewStore.state.setAssignAttributesDirtiness(value));
  };

  const initialExpandedCategory = hooks.useSetExpandedCategoryOnMount();
  const displayableSections = hooks.assignAttributes.useBuildCategories(
    selectedUnit,
    shifts,
    shiftTypesByKey,
    staffDetails,
  );

  const [cachedAttributesForUpdates, setCachedAttributesForUpdates] = useState(
    keyBy(cloneDeep(unitShifts) || [], "id"),
  );

  const resetShifts = () => {
    setCachedAttributesForUpdates(keyBy(cloneDeep(unitShifts) || [], "id"));
    setDirtyState(false);
  };
  useEffect(() => {
    setTimeout(() => resetShifts());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDateForData, selectedUnitId, JSON.stringify(unitShifts)]);

  const { isLoading: anAttributeIsSaving, commitChanges } =
    HouseViewSideBarHooks.assignAttributes.useCommitChanges(
      unitShifts,
      cachedAttributesForUpdates,
      resetShifts,
      setDirtyState,
    );

  return (
    <>
      <Box sx={{ overflowY: "auto" }}>
        {initialExpandedCategory &&
          displayableSections.map(([sectionName, sectionShifts, maybeCategory]) => {
            // Try to find at least one shift that has an attribute for the selected unit
            const hasOneShift = sectionShifts.find((shift) => {
              const { staffId } = shift;
              const userDetails = staffDetails?.[staffId];

              return userDetails?.attributeKeys.find((key) => unitAttributeKeys.includes(key));
            });

            if (!hasOneShift) return <></>;

            return (
              <CustomCollapse
                styles={{ mt: 0 }}
                key={"update-notes-" + sectionName}
                label={sectionName}
                isExpandedByDefault={maybeCategory === initialExpandedCategory}
              >
                {sectionShifts.map((shift) => {
                  const { staffId } = shift;
                  const userDetails = staffDetails?.[staffId];
                  const staffAttributes = selectedUnit.attributes?.filter(({ key }) =>
                    userDetails?.attributeKeys.includes(key),
                  );
                  const shiftAttributeKeys = cachedAttributesForUpdates[shift.id]?.attributes;
                  const selectedAttributes = staffAttributes?.filter(({ key }) =>
                    shiftAttributeKeys?.includes(key),
                  );

                  if (!staffAttributes?.[0]) return <></>;

                  const renderedAssignAttribute = (
                    <Box
                      display={"flex"}
                      alignItems={"center"}
                      className="attributes-dropdown-container"
                    >
                      <Autocomplete
                        trackingLabel="Assign Position"
                        multiple
                        disableCloseOnSelect
                        options={staffAttributes || []}
                        getOptionLabel={({ name }) => name}
                        renderOption={(props, option, { selected }) => (
                          <li {...props} style={{ ...props.style, padding: 0 }}>
                            <Checkbox
                              checked={selected}
                              disabled={isKioskUser || !canManage}
                              trackingLabel={option.name}
                            />
                            {option.name}
                          </li>
                        )}
                        size="small"
                        renderTags={(value) => value.map((option) => option.name).join(", ")}
                        sx={sxForDropdown(!selectedAttributes?.[0])}
                        value={selectedAttributes}
                        onChange={(_event, attributes) => {
                          if (isKioskUser || !canManage) return;
                          if (!dirtyState) setDirtyState(true);

                          setCachedAttributesForUpdates((cachedShifts) => {
                            const shiftToUpdate = cachedShifts[shift.id]!;
                            shiftToUpdate.attributes = map(attributes, "key");

                            return { ...cachedShifts, [shift.id]: shiftToUpdate };
                          });
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            sx={{
                              fontSize: "16px",
                              ...(selectedAttributes?.[0]
                                ? { input: { position: "absolute", display: "hidden" } }
                                : {}),
                            }}
                            placeholder={selectedAttributes?.length ? "" : "No Position"}
                            variant="standard"
                            label="Position"
                          />
                        )}
                      />
                    </Box>
                  );

                  return (
                    <StaffItem
                      layout={STAFF_ITEMS_LAYOUTS.updateStatus}
                      unitId={selectedUnit.id}
                      shift={shift}
                      children={renderedAssignAttribute}
                    />
                  );
                })}
              </CustomCollapse>
            );
          })}
      </Box>
      <Box flexGrow={1} />
      {!isKioskUser && canManage && (
        <HouseViewSideBar.Helpers.BottomActions
          title={"Assign Positions"}
          actionButton={{
            disabled: anAttributeIsSaving || !dirtyState,
            label: "Save",
            action: commitChanges,
          }}
          cancelButton={{
            disabled: anAttributeIsSaving || !dirtyState,
            label: "Cancel",
            action: resetShifts,
          }}
        />
      )}
    </>
  );
};

const sxForDropdown = (isEmpty: boolean) => ({
  height: "fit-content",
  ".MuiInputBase-root": { m: 0 },
  minWidth: "150px",
  ".MuiAutocomplete-inputRoot": {
    fontSize: "12px",
  },
  ".MuiAutocomplete-clearIndicator": { display: "none" },
  ".MuiInputLabel-root": {
    top: "-12px",
    color: mediumGray,
    fontSize: "12px",
    ...(isEmpty ? {} : { display: "none" }),
  },
});

export const _HouseView_SideBar_AssignAttributes = AssignAttributes;
