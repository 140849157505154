import { useCallback } from "react";
import { ErrorCode, FileRejection, useDropzone } from "react-dropzone";

import { UploadFile } from "@mui/icons-material";
import { Box, Stack, Typography } from "@mui/material";

import { useToast } from "~/common/hooks/useToast";
import { darkGray, lightGray, lines } from "~/common/theming/colors";

import { ICustomDropzoneProps } from "./types";
import { UploadedFileTile } from "./UploadedFileTile";

const MAX_FILE_SIZE_DROPZONE = 500 * 1024; // 500 kB

const ERROR_MESSAGES: Record<string, string> = {
  [ErrorCode.FileInvalidType]: "File type must be .csv",
  [ErrorCode.FileTooLarge]: "File is larger than 500kB",
  [ErrorCode.TooManyFiles]: "You can upload only 1 file",
};

export const CustomDropzone = ({ uploadedFiles, setUploadedFiles }: ICustomDropzoneProps) => {
  const { showError } = useToast();

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      setUploadedFiles([...uploadedFiles, ...acceptedFiles]);
    },
    [setUploadedFiles, uploadedFiles],
  );

  const onDropRejected = useCallback(
    (errorData: FileRejection[]) => {
      errorData[0]?.errors?.forEach((error) => {
        const error_message = ERROR_MESSAGES[error.code];
        error_message && showError(error_message);
      });
    },
    [showError],
  );

  const removeFile = (file: File) => () => {
    const newFiles = [...uploadedFiles];
    newFiles.splice(newFiles.indexOf(file), 1);
    setUploadedFiles(newFiles);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    onDropRejected,
    accept: { "text/csv": [".csv"] },
    maxFiles: 1,
    maxSize: MAX_FILE_SIZE_DROPZONE,
    multiple: false,
  });

  if (uploadedFiles.length) {
    return <UploadedFileTile uploadedFiles={uploadedFiles} removeFile={removeFile} />;
  }

  return (
    <div {...getRootProps()}>
      <input {...getInputProps()} />
      <Stack
        border={`1px dashed ${lines}`}
        alignItems="center"
        paddingX={2}
        paddingY={3}
        gap={1}
        sx={{ cursor: "pointer", backgroundColor: isDragActive ? lightGray : "inherit" }}
      >
        <Box padding={1}>
          <UploadFile />
        </Box>
        <Typography variant="body2">
          <Typography variant="body2" sx={{ textDecoration: "underline" }} display="inline">
            Click to upload
          </Typography>{" "}
          or drag and drop
        </Typography>
        <Typography variant="body2" color={darkGray}>
          CSV (max. 500 kB)
        </Typography>
      </Stack>
    </div>
  );
};
