import { StaffDetails, Unit } from "~/api";

import { NameCell } from "./NameCell";

export const ScheduleTableStaffNameHeaderCell = ({
  staffDetails,
  unitId,
}: {
  staffDetails: StaffDetails.DTO | undefined;
  unitId: Unit.DTO["id"];
}) => {
  return <NameCell staffDetails={staffDetails} unitId={unitId} />;
};
