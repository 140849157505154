import { IUnitBasic } from "~/routes/api/types";

import { APIElementResponse, APIListResponse, SDTO } from "../_shared/types";
import { IStaffCategory } from "../staffCategory";

export type IUser = User.DTO;

export namespace User {
  export enum ERole {
    admin = "admin",
    scheduler = "scheduler",
    staff = "staff",
    kiosk = "kiosk",
  }

  export interface DTO extends SDTO.base, SDTO.withTimestamps, SDTO.softDeletable {
    unitIds: IUnitBasic["id"][];
    roles: ERole[];
    isSoftDeleted?: boolean;
    softDeletedDate?: string;
    firstName: string;
    lastName: string;
  }

  export namespace API {
    export const ROOT_PATH = "/user";

    export namespace List {
      export const PATH = ROOT_PATH;
      export interface QueryParams {
        unitIds?: IUnitBasic["id"][];
        ids?: IUser["id"][];
        roles?: DTO["roles"];
        staffCategories?: IStaffCategory["key"][];
        suspended?: boolean[];
      }

      export type Response = APIListResponse<DTO>;
    }

    export namespace GetNotificationsCount {
      export const PATH = `${ROOT_PATH}/notifications-count`;
      export interface QueryParams {
        version: "2";
      }

      export type Response = APIElementResponse<{
        // eslint-disable-next-line @typescript-eslint/naming-convention
        OPSRequestCount: number;
        approvalRequestCount: number;
      }>;
    }
  }
}
