import { Grid, Typography } from "@mui/material";

import { darkGray, lines } from "~/common/theming/colors";
import { TScheduleDetails } from "~/features/Fairness/PreferencesHonored/types";

import { Dates } from "../Dates";

export const HeaderCell = ({
  startDay,
  endDay,
  averageScore,
}: {
  startDay: TScheduleDetails["startDay"];
  endDay: TScheduleDetails["endDay"];
  averageScore: string | number;
}) => {
  return (
    <Grid container flexDirection="column" alignItems="center" width="190px">
      <Dates startDay={startDay} endDay={endDay} />
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        height="24px"
        sx={{ borderTop: `1px solid ${lines}` }}
      >
        <Typography sx={{ fontSize: "0.6875rem", fontWeight: 500 }} color={darkGray}>
          {averageScore}
        </Typography>
      </Grid>
    </Grid>
  );
};
