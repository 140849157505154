import React, { useContext, useEffect, useMemo, useState } from "react";

import mixpanel, { Callback } from "mixpanel-browser";

interface AnalyticsContextProps {
  isInitialized: boolean;
  tenantName?: string;
}

interface AnalyticsProviderProps {
  children: React.ReactNode;
  token: string;
  tenantName?: string;
}

interface AnalyticsProperties {
  [key: string]: string | number | boolean | string[];
}

const AnalyticsContext = React.createContext<AnalyticsContextProps | undefined>(undefined);

export const AnalyticsProvider: React.FC<AnalyticsProviderProps> = ({
  children,
  token,
  tenantName,
}) => {
  const [isInitialized, setInitialized] = useState(false);
  useEffect(() => {
    if (!token || isInitialized) return;
    mixpanel.init(token, {
      ignore_dnt: true,
    });
    if (import.meta.env.VITE_ENVIRONMENT === "local") mixpanel.disable();
    setInitialized(true);
  }, [token, isInitialized]);

  return (
    <AnalyticsContext.Provider value={{ isInitialized, tenantName }}>
      {children}
    </AnalyticsContext.Provider>
  );
};

export const useAnalytics = () => {
  const contextValue = useContext(AnalyticsContext);
  if (!contextValue) throw new Error("useAnalytics must be used within an AnalyticsProvider");

  return useMemo(() => {
    const { isInitialized, tenantName } = contextValue;

    const identify = (userId: string, properties?: AnalyticsProperties) => {
      if (isInitialized) {
        mixpanel.identify(userId);
        const fullProperties = { ...properties, tenantName };
        mixpanel.people.set(fullProperties);
      }
    };

    const track = (eventName: string, properties?: AnalyticsProperties, callback?: Callback) => {
      if (isInitialized) {
        const fullProperties = { ...properties, tenantName };
        mixpanel.track(eventName, fullProperties, callback);
      }
    };

    const getDistinctId = () => {
      if (isInitialized) return mixpanel.get_distinct_id() as string;
    };

    const logout = () => {
      if (isInitialized) {
        track("Logout", undefined, () => {
          mixpanel.reset();
        });
      }
    };

    return {
      identify,
      track,
      getDistinctId,
      logout,
    };
  }, [contextValue]);
};
