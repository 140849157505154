import { buildApiListQueryHook } from "../_shared/helpers";
import { staleFor } from "../_shared/types";

import { StaffDetails } from "./types";

export const useListStaffDetailsQuery = buildApiListQueryHook<
  StaffDetails.API.List.QueryParams,
  StaffDetails.API.List.Response
>(StaffDetails.API.List.PATH);

/** Returns per-staff metadata about shifts such as
 * - last floated
 * - last called in
 * etc...
 */
export const useListStaffShiftMetadataQuery = buildApiListQueryHook<
  StaffDetails.API.ListShiftMetadata.QueryParams,
  StaffDetails.API.ListShiftMetadata.Response
>(StaffDetails.API.ListShiftMetadata.PATH, {
  staleTime: staleFor(5).minutes,
});
