import { useCallback, useMemo } from "react";
import { useLocation } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "~/common/hooks/useRedux";
import { routes } from "~/routes";

import { useDispatch } from "#/features/HolidaySchedule/store";

import { SchedulerGridStore } from "../store";

// TODO: refactor
export const useScheduleUnsavedChanges = () => {
  const hasChanges = useAppSelector(
    (state) =>
      state.schedulerGrid.grid.data.updates.undoHistory.length > 0 ||
      state.holidaySchedule.staffShifts.updates.undoHistory.length > 0,
  );

  const location = useLocation();
  const dispatch = useAppDispatch();
  const holidayDispatch = useDispatch();

  const resetChanges = useCallback(() => {
    dispatch(SchedulerGridStore.doEraseHistory());
    holidayDispatch.cancelChanges();
  }, [dispatch, holidayDispatch]);

  const handleUnsavedChanges = useCallback(
    ({
      onActionPrevented,
      onActionSuccess,
    }: {
      onActionSuccess?: () => void;
      onActionPrevented?: () => void;
    }) => {
      const scheduleRoutePath = routes.schedule.path;
      const holidayScheduleRoutePath = routes.holidaySchedule.path;
      if (
        location.pathname.includes(scheduleRoutePath) ||
        location.pathname.includes(holidayScheduleRoutePath)
      ) {
        if (hasChanges) {
          if (onActionPrevented) onActionPrevented();

          dispatch(
            SchedulerGridStore.openModalWithParams({
              key: "unsavedChanges",
              initialValues: {
                handleDiscardChanges: () => {
                  resetChanges();
                  return onActionSuccess?.();
                },
              },
            }),
          );
        } else {
          dispatch(SchedulerGridStore.doEraseHistory());
          holidayDispatch.cancelChanges();
          onActionSuccess?.();
        }

        return;
      }

      if (onActionSuccess) {
        onActionSuccess();
      }
    },
    [dispatch, hasChanges, location.pathname, resetChanges, holidayDispatch],
  );

  return useMemo(
    () => ({ handleUnsavedChanges, hasChanges, resetChanges }),
    [handleUnsavedChanges, hasChanges, resetChanges],
  );
};
