/* eslint-disable no-console */
import { memo, useMemo } from "react";

import { camelCase, isEqual } from "lodash";

import {
  HVSpecificPositionsTabs,
  TPositionAsTab,
} from "#/features/HouseView/hooks/useStaffingTabs";
import { useAppConfigQuery } from "#/features/User/queries";
import { useAppFlags } from "@/common/hooks";
import { useAppSelector } from "@/common/hooks/useRedux";

import { HeaderCell } from "./HeaderCell";

export const GLOBAL_STAFFING_LEVEL_MODAL_COLUMN_TITLES = [
  { title: "Time", hint: null },
  { title: "Patient Count", hint: null },
];

export const StaffingLevelsHeaderRow = memo(function MemoizedStaffingLevelsHeaderRow() {
  const { hvPositionAsTab } = useAppFlags();
  const HeaderCellComponent = HeaderCell;
  const { unitCategories, selectedUnitId } = useAppSelector(
    (state) => ({
      unitCategories: state.houseView.staffingLevels.selectedUnitCategories,
      selectedUnitId: state.houseView.pageFilters.selectedUnitId!,
    }),
    isEqual,
  );
  const unitAttributes = useAppConfigQuery().data?.units.find(
    (unit) => unit.id === selectedUnitId,
  )?.attributes;
  const unitColumnTitles = useMemo(() => {
    const titles = [];

    titles.push(...GLOBAL_STAFFING_LEVEL_MODAL_COLUMN_TITLES);
    titles.push(
      ...(unitCategories?.map((category) => ({
        title: category.name,
        hint: "Current / Target Staffing",
      })) || []),
    );

    if (hvPositionAsTab) {
      unitAttributes?.forEach((attribute) => {
        const attributeName = attribute.name.toLocaleLowerCase() as TPositionAsTab;
        // If attribute doesn't count toward "regular" target, then separate target
        if (HVSpecificPositionsTabs[attributeName]?.countInTarget === false) {
          titles.push({ title: attribute.name, hint: "Current / Target Staffing" });
        }
      });
    }

    titles.push({ title: "Reason for Staffing Discrepancy", hint: null });

    return titles;
  }, [unitCategories, hvPositionAsTab, unitAttributes]);

  return (
    <tr>
      {unitColumnTitles.map((column) => {
        return (
          <th key={column.title} className={camelCase(column.title)}>
            <div>
              <HeaderCellComponent title={column.title} hint={column.hint} />
            </div>
          </th>
        );
      })}
      <th className="delete cell" />
      <th className="info cell" />
    </tr>
  );
});
