import styled from "styled-components";

import { Box } from "@mui/material";

import { black, lightGray } from "~/common/theming/colors";
import { pxToRem } from "~/common/utils/pxToRem";

type CustomProps = {
  $background: string;
  $small: boolean;
  $medium?: boolean;
};

export const FairnessStyled = styled(Box)<CustomProps>`
  height: ${({ $small, $medium }) => ($small ? "16px" : $medium ? "22px" : "28px")};
  width: ${({ $small, $medium }) => ($small ? "34px" : $medium ? "42px" : "52px")};
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ $background }) => $background || lightGray};

  .text {
    font-size: ${({ $small, $medium }) =>
      $small ? pxToRem(10) : $medium ? pxToRem(12) : pxToRem(14)};
    font-weight: 500;
    color: ${black};
  }
`;
