import { ISODateString } from "@m7-health/shared-utils";

import { TDateFilter, Uuid } from "@/common/types";

import { APIListResponse, SDTO } from "../_shared/types";
import { IFacility } from "../facility";
import { ISchedule } from "../schedule";
import { IUnit } from "../unit";
import { IUser } from "../user";

export type IEventLog = EventLog.DTO;

export namespace EventLog {
  export enum EEventType {
    staffShiftCreated = "staff_shift_created",
    staffShiftUpdated = "staff_shift_updated",
    staffShiftDeleted = "staff_shift_deleted",
    noteUpdated = "note_updated",
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  export interface DTO<TPayload = any> extends SDTO.base, SDTO.withTimestamps {
    eventEmittedAt: ISODateString;
    eventVersion: string;
    eventType: EEventType;
    eventPayload: TPayload;
    correlationId: Uuid;
    authorId: IUser["id"];
    authorType: "user";
    targetId: Uuid;
    targetType: "staffShift";
    facilityId: IFacility["id"];
    unitId: IUnit["id"];
    humanReadableSummary: string;
    filterValues: {
      scheduleIds?: ISchedule[];
      dates?: ISODateString[];
      staffIds?: IUser[];
      ids?: Uuid[];
    };
  }

  export namespace API {
    export const ROOT_PATH = "/event-log";
    export namespace List {
      export const PATH = ROOT_PATH;
      export interface QueryParams {
        unitIds?: IUnit["id"][];
        correlationIds?: Uuid[];
        staffIds?: IUser["id"][];
        scheduleIds?: ISchedule["id"][];
        authorIds?: IUser["id"][];
        relatedDates?: TDateFilter[];
        emittedAt?: TDateFilter[];
        ids?: DTO["id"][];
        relatedIds?: Uuid[];
      }

      export type Response = APIListResponse<DTO>;
    }
  }
}
