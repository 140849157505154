import { useLocation } from "react-router-dom";

import { EStaffRosterPageTabs } from "../../types";

export const useSelectedRosterTab = () => {
  const location = useLocation();

  const selectedRosterTab =
    (location.pathname.split(/[//]/).at(4) as EStaffRosterPageTabs) ||
    EStaffRosterPageTabs.homeUnitUsers;

  return {
    selectedRosterTab,
  };
};
