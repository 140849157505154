import { IStaffShift } from "~/api/staffShift";

import { Uuid } from "@/common/types";

const comparableAttributes = [
  "date",
  "scheduleId",
  "attributes",
  "customDuration",
  "customStartTime",
  "isWorkingAway",
  "shiftTypeKey",
  "staffId",
  "status",
  "incentiveLevelId",
] as const;

type TComparableAttributes = (typeof comparableAttributes)[number];
type TDifferences = Partial<{
  [key in TComparableAttributes]: {
    from: IStaffShift[key];
    to: IStaffShift[key];
  };
}>;

// All versions of event payloads should be defined here
type TPayloadV1 = {
  staffShift: Pick<
    IStaffShift,
    | "id"
    | "scheduleId"
    | "attributes"
    | "customDuration"
    | "customStartTime"
    | "isWorkingAway"
    | "shiftTypeKey"
    | "staffId"
    | "scheduleType"
    | "status"
    | "incentiveLevelId"
  >;
  updatedFields: TDifferences;
  origin: TStaffShiftOrigin;
};

type TStaffShiftOrigin =
  | {
      type: "approved-open-shift-request";
      id: Uuid;
    }
  | {
      type: "bulk-save-action";
      id?: never;
    }
  | {
      type: "approved-swap-shift-request";
      id: Uuid;
    };

export namespace StaffShiftUpdated {
  export enum EVersions {
    "v1" = "1",
  }

  export type StaffShiftOrigin = TStaffShiftOrigin;
  export type Differences = TDifferences;
  export type Payloads = TPayloadV1;
  export type PayloadByVersion = {
    [EVersions.v1]: TPayloadV1;
  };
}
