import { useCallback } from "react";

import { resetOpenShiftState } from "#/features/OpenShifts/store";
import { SchedulerGridStore } from "#/features/SchedulerGrid/store";
import { resetRequestState } from "#/features/ShiftSwapRequest/store";

import { useAppDispatch } from "../hooks/useRedux";

//Add any newly created states here
export const useResetAllStates = () => {
  const dispatch = useAppDispatch();
  return useCallback(() => {
    dispatch(SchedulerGridStore.resetSchedulerGridState());
    dispatch(resetOpenShiftState());
    dispatch(resetRequestState());
  }, [dispatch]);
};
