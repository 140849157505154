import { KeyBy } from "~/common/types";

import { TWeightedShift } from "#/features/SchedulerGrid/store/initialState";
import { IScheduleShiftType, StaffShift } from "@/api";
import { iconComponentForShift } from "@/common/constants";

interface IShiftIconProps {
  fullView: boolean;
  firstShift?: TWeightedShift | StaffShift.DTO;
  availableShiftTypes?: KeyBy<IScheduleShiftType, "key">;
  // could be different from firstShift.status because is the first status of the day
  firstStatusOnDay?: StaffShift.EStatus | null;
}

export const ShiftIcon = ({
  fullView,
  firstShift,
  availableShiftTypes,
  firstStatusOnDay,
}: IShiftIconProps) => {
  if (!firstShift) return null;

  const Icon = iconComponentForShift(
    availableShiftTypes?.[firstShift.shiftTypeKey]?.muiIconClassName,
  );
  if (firstShift.customDuration && !firstStatusOnDay) {
    if (!fullView) return null;
    return (
      <div style={{ display: "flex", alignItems: "center", fontSize: "14px" }}>
        [<Icon />]
      </div>
    );
  }
  return <Icon />;
};
