import { HouseView_hooks_useSelectedDay } from "./useSelectedDay";
import { HouseView_hooks_useSortedStaffShifts } from "./useSortStaffShift";
import { HouseView_hooks_useStaffingTabs } from "./useStaffingTabs";

export { useSetShiftTypes } from "./useSetShiftTypes";
export { useSetStaffMetadata } from "./useSetStaffMetadata";
export { useSetWorkingHours } from "./useSetWorkingHours";

export const HouseViewHooks = {
  useSelectedDay: HouseView_hooks_useSelectedDay,
  useStaffingTabs: HouseView_hooks_useStaffingTabs,
  useSortedStaffShifts: HouseView_hooks_useSortedStaffShifts,
};
