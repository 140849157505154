// Extracted from https://mui.com/material-ui/react-card/#complex-interaction
import { ArrowLeft } from "@mui/icons-material";
import { IconButton, IconButtonProps } from "@mui/material";
import { styled } from "@mui/material/styles";

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
  direction?: "left" | "right";
  icon?: React.ReactNode;
}

export const ExpandMore = styled((props: ExpandMoreProps) => {
  const { direction: _maybeDirection, expand: _expand, icon: maybeIcon, ...other } = props;
  const icon = maybeIcon || <ArrowLeft />;

  return <IconButton {...other}>{icon}</IconButton>;
})(({ theme, expand, direction = "left" }) => ({
  transform: !expand
    ? direction === "left"
      ? "rotate(0deg)"
      : "rotate(180deg)"
    : "rotate(-90deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));
