import { IShift } from "~/routes/api/types";

import { DeprecatedTShift } from "#/features/SchedulerGrid/types";

export const getOrderedShiftTiles = (
  shiftTiles: IShift["key"][],
  shiftOptions: Record<
    DeprecatedTShift,
    {
      isPatientCareShift: boolean;
    }
  >,
) => {
  // things with lookup value of currently 16 are last in the list (may change)
  // (for example, houseSupervisor shift tiles should never show up for staff but defaults to last in case looked up elsewhere)
  const orderLookup: Record<IShift["key"], number> = {
    clear: 0,
    day: 1,
    night: 2,
    block: 3,
    pto: 4,
    otDay: 5,
    otNight: 6,
    education: 7,
    administrative: 8,
    orientationDay: 9,
    orientationNight: 10,
    school: 11,
    bereavement: 12,
    juryDuty: 13,
    eib: 14,
    childCare: 15,
    houseSupervisorDay: 16,
    houseSupervisorNight: 16,
  };

  shiftTiles.sort((shift1, shift2) => {
    return shift1 in orderLookup && shift2 in orderLookup
      ? (orderLookup[shift1] || 0) - (orderLookup[shift2] || 0)
      : 16;
  });

  shiftTiles.sort((shift1, shift2) => {
    const isPatientCare1 = shiftOptions[shift1]?.isPatientCareShift || false;
    const isPatientCare2 = shiftOptions[shift2]?.isPatientCareShift || false;

    return Number(isPatientCare2) - Number(isPatientCare1); // This will place patient care shifts first
  });

  return shiftTiles;
};
