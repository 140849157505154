import "./ShiftEditor.scss";

import { useMemo, useState } from "react";

import { Uuid, getTzDayjs, toISO } from "@m7-health/shared-utils";
import { filter, values } from "lodash";

import { MenuBook } from "@mui/icons-material";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import {
  Box,
  darken,
  Grid,
  InputAdornment,
  MenuItem,
  Select,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";

import { IUnitBasic } from "~/routes/api/types";

import { useAppConfigQuery } from "#/features/User/queries";
import {
  IScheduleShiftType,
  IStaffDetails,
  IStaffShift,
  IUnit,
  StaffShift,
  useListSchedulesQuery,
} from "@/api";
import { CustomDatePicker } from "@/common/components";
import { FLOATING_ICON } from "@/common/constants";
import { useAppFlags, useCurrentTimezone, useMapBy, useToast } from "@/common/hooks";
import { black, darkGray, mediumGray, white } from "@/common/theming/colors";
import { getTzFormattedDate } from "@/common/utils/dates";
import { showStatusForUnit } from "@/common/utils/shifts";

import { CompactFloatedTag } from "../CompactFloatedTag/CompactFloatedTag";
import { NoteUpdateTag } from "../NoteUpdateTag/NoteUpdateTag";
import { IncentiveLevelDropdown } from "../ShiftIncentiveLevel";
import ShiftTypeFilterDropdown from "../ShiftTypeFilterDropdown";
import { StaffShiftHistoryModal } from "../StaffShiftHistoryModal";
import { Autocomplete, Checkbox, CustomButton } from "../TrackedComponents";

import { ShiftEditorTimePickers } from "./TimePickers";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export const ShiftEditor = ({
  staffShift,
  updateStaffShift,
  deleteStaffShift,
  unitOfShift,
  readonly,
  availableShiftTypes,
  staffDetails,
  readonlyUnit,
  setShiftsValidity,
  showHistory = true,
}: {
  staffShift: IStaffShift;
  updateStaffShift: (staffShift: IStaffShift) => void;
  deleteStaffShift: (staffShift: IStaffShift) => void;
  availableShiftTypes: IScheduleShiftType[];
  unitOfShift: IUnit | IUnitBasic;
  // readonly in general is used to prevent changing of anything
  // eg. used from scheduler grid when shift is away from unit it is being seen from
  readonly: boolean;
  // readonlyUnit is used to prevent changing the unit of the shift
  // eg. used from scheduler grid
  readonlyUnit: boolean;
  staffDetails: IStaffDetails;
  setShiftsValidity: React.Dispatch<
    React.SetStateAction<{
      [key: IStaffShift["id"]]: boolean;
    }>
  >;
  showHistory?: boolean;
}) => {
  /** HIGH-LEVEL STATE */
  const { incentivizedShifts, balancingTheHouseSidebar } = useAppFlags();
  const toast = useToast();
  // use timezone of unit of shift, NOT facility timezone
  const timezone = useCurrentTimezone(unitOfShift.id);
  const shiftDate = getTzFormattedDate(staffShift.date, timezone);
  const [historyIsOpen, setHistoryIsOpen] = useState(false);

  showHistory &&= !!staffShift.id;

  // shift type selector
  const shiftType = useMemo(
    () =>
      staffShift.shiftTypeKey &&
      availableShiftTypes?.find(({ key }) => key === staffShift.shiftTypeKey),
    [availableShiftTypes, staffShift.shiftTypeKey],
  );

  // Attributes selector
  const availableAttributes = useMemo(() => {
    const unitAttributes = unitOfShift.attributes;
    return filter(unitAttributes, ({ key }) => staffDetails.attributeKeys.includes(key));
  }, [staffDetails.attributeKeys, unitOfShift.attributes]);
  const selectedAttributes = useMemo(
    () => availableAttributes.filter(({ key }) => staffShift.attributes.includes(key)),
    [availableAttributes, staffShift.attributes],
  );

  // Style
  const backgroundColor = shiftType?.scheduleViewColor || white;

  // Unit selector
  const availableUnitsIds = useMapBy(staffDetails.user.rosters, "unitId");
  const allAvailableUnits = useAppConfigQuery().data?.accessibleUnits;
  const staffAvailableUnits = useMemo(
    () => allAvailableUnits?.filter(({ id }) => availableUnitsIds.includes(id)),
    [allAvailableUnits, availableUnitsIds],
  );
  const selectedUnit = allAvailableUnits?.find(({ id }) => id === unitOfShift.id);
  const { data: schedules } = useListSchedulesQuery(
    { unitIds: availableUnitsIds },
    { skip: availableUnitsIds.length === 0 },
  );
  const currentScheduleOfShift = useMemo(
    () =>
      schedules?.find(
        ({ unitId, startDay, endDay }) =>
          unitId === unitOfShift.id && startDay <= shiftDate && shiftDate <= endDay,
      ),
    [schedules, unitOfShift.id, shiftDate],
  );
  const statusesToShow = useMemo(
    () =>
      values(StaffShift.EStatus).filter(
        (status) => status === staffShift.status || showStatusForUnit(status, selectedUnit),
      ),
    [selectedUnit, staffShift.status],
  );

  if (!staffAvailableUnits) return null;

  return (
    <Grid
      container
      className="component-shift-editor"
      sx={{
        border: `1.5px solid ${darken(backgroundColor, 0.2)}`,
      }}
    >
      {showHistory && (
        <>
          <Tooltip title="Browse shift history" arrow placement="top">
            <MenuBook
              className="shift-history-icon"
              sx={{
                color: mediumGray,
                "&:hover": { color: black },
                border: `1.5px solid ${darken(backgroundColor, 0.2)}`,
              }}
              onClick={(event) => {
                setHistoryIsOpen(true);
                event.stopPropagation();
              }}
            />
          </Tooltip>
          {historyIsOpen && (
            <StaffShiftHistoryModal
              staffShiftId={staffShift.id}
              onClose={() => setHistoryIsOpen(false)}
              modalTitle="Shift History"
            />
          )}
        </>
      )}
      <Grid container className="unit">
        <Autocomplete
          className="unit-picker"
          trackingLabel={null}
          disabled={readonly || readonlyUnit}
          readOnly={readonly || readonlyUnit}
          options={staffAvailableUnits}
          value={selectedUnit}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Unit"
              InputProps={{
                ...params.InputProps,
                ...buoyAdornmentIfWorkingAway(unitOfShift.id, staffDetails.homeUnitId),
              }}
            />
          )}
          onChange={(_event, newUnit) => {
            const newlySelectedSchedule = (schedules || [])?.find(
              ({ unitId, startDay, endDay }) =>
                newUnit?.id === unitId && startDay <= shiftDate && shiftDate <= endDay,
            );
            if (!newlySelectedSchedule) {
              toast.showInfo(
                `There is no schedule for ${newUnit.name} on ${getTzDayjs(shiftDate, timezone).format("MM/DD/YYYY")}, so this shift cannot be moved to it.`,
              );
              return;
            }
            updateStaffShift({
              ...staffShift,
              attributes: [],
              incentiveLevelId: null,
              isWorkingAway: newUnit.id !== staffDetails.homeUnitId,
              scheduleId: newlySelectedSchedule.id,
            });
          }}
          disableClearable
          getOptionLabel={(option) => option.name}
          renderOption={(props, option) => (
            <li {...props}>
              {option.name}{" "}
              {option.id === staffDetails.homeUnitId ? (
                ""
              ) : (
                <FLOATING_ICON sx={{ pl: 1 }} fontSize="small" color="disabled" />
              )}{" "}
            </li>
          )}
        />
      </Grid>
      {balancingTheHouseSidebar && (
        <Stack mb={2} className="shift-date-picker">
          <CustomDatePicker
            name="shiftDate"
            label="Date"
            value={getTzDayjs(staffShift.date, timezone)}
            onChange={(newDate) => {
              if (newDate) {
                updateStaffShift({
                  ...staffShift,
                  date: toISO(newDate),
                });
              }
            }}
            timezone={timezone}
            shouldDisableDate={(date) => {
              // if there is no schedule, disable all dates
              if (!currentScheduleOfShift) return true;
              const yyyyMmDdStr = date?.format("YYYY-MM-DD");
              // if date is not a valid date, disable it
              if (!yyyyMmDdStr) return true;
              return (
                yyyyMmDdStr < currentScheduleOfShift.startDay ||
                yyyyMmDdStr > currentScheduleOfShift.endDay
              );
            }}
          />
        </Stack>
      )}
      <Stack className="shift-type-picker" direction={"row"}>
        <ShiftTypeFilterDropdown
          className="shift-type-dropdown"
          hoursInLabel={"skipInput"}
          shiftTypes={availableShiftTypes || []}
          selectedOption={shiftType}
          selectOption={(staffShiftType) =>
            staffShiftType?.key &&
            updateStaffShift({
              ...staffShift,
              shiftTypeKey: staffShiftType.key,
              customDuration: null,
              customStartTime: null,
            })
          }
          isMultiSelect={false}
          readonly={readonly}
          label={"Shift Type"}
          emptyOptionLabel={" \u00A0 \u00A0 None"}
          sx={{
            minHeight: "47px",
          }}
        />
        <ShiftEditorTimePickers
          timezone={timezone}
          staffShift={staffShift}
          updateStaffShift={updateStaffShift}
          shiftType={shiftType}
          readonly={readonly}
          setShiftValidity={(isValid) =>
            setShiftsValidity((prev) => ({ ...prev, [staffShift.id]: isValid }))
          }
        />
      </Stack>
      <Stack direction={"row-reverse"} gap={"10px"} className="shift-other-attributes">
        <Autocomplete
          className="attributes-picker"
          trackingLabel="attributes-picker"
          readOnly={readonly}
          disabled={readonly}
          multiple
          options={availableAttributes}
          value={selectedAttributes}
          disableCloseOnSelect
          getOptionLabel={({ name }) => name}
          renderOption={(props, option, { selected }) => (
            <li {...props}>
              <Checkbox
                trackingLabel="attribute-picker"
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {option.name}
            </li>
          )}
          renderTags={(value) => value.map((option) => option.name).join(", ")}
          renderInput={(params) => <TextField {...params} label="Position" />}
          onChange={(_event, value) => {
            updateStaffShift({
              ...staffShift,
              attributes: value.map((attribute) => attribute.key),
            });
          }}
        />
        <Select<StaffShift.EStatus | "">
          className="status-picker"
          readOnly={readonly}
          disabled={readonly}
          value={staffShift.status || ""}
          displayEmpty
          onChange={(event) => {
            updateStaffShift({
              ...staffShift,
              status: (event.target.value as StaffShift.EStatus) || null,
            });
          }}
        >
          <MenuItem value={""} key="update-notes-update-option-empty">
            <Typography fontSize="13px" color={darkGray} className="no-status">
              <i>No status</i>
            </Typography>
          </MenuItem>
          {Object.values(statusesToShow).map((status) => (
            <MenuItem value={status} key={"update-notes-update-option-" + status}>
              <NoteUpdateTag update={status} sx={{ p: 0.2, px: 0.5 }} unitId={unitOfShift.id} />
            </MenuItem>
          ))}
        </Select>
      </Stack>
      {(!readonly || showHistory) && (
        <Stack direction="row" className="delete-shift-button-container">
          {incentivizedShifts && (
            <IncentiveLevelDropdown
              size="small"
              unitId={unitOfShift.id}
              value={staffShift.incentiveLevelId || ""}
              onChange={(event) => {
                const incentiveLevelId = (event.target.value || null) as Uuid | null;
                updateStaffShift({ ...staffShift, incentiveLevelId });
              }}
            />
          )}
          <Box flexGrow={1} />
          {!readonly && (
            <CustomButton
              className="delete-shift-button"
              label="Delete"
              variant="outlined"
              onClick={() => deleteStaffShift(staffShift)}
            />
          )}
        </Stack>
      )}
    </Grid>
  );
};

const buoyAdornmentIfWorkingAway = (shiftUnitId: IUnit["id"], homeUnitId: IUnit["id"]) =>
  shiftUnitId === homeUnitId
    ? {}
    : {
        endAdornment: (
          <InputAdornment position="end">
            <CompactFloatedTag unitId={homeUnitId} grayIfNotFloatedStatus />
          </InputAdornment>
        ),
      };
