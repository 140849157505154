import { Close, UploadFile } from "@mui/icons-material";
import { Box, Grid, Stack, Typography } from "@mui/material";

import { black, darkGray, lines } from "~/common/theming/colors";

import { IUploadedFileTileProps } from "./types";

export const UploadedFileTile = ({ uploadedFiles, removeFile }: IUploadedFileTileProps) => {
  return (
    <>
      {uploadedFiles.map((file) => (
        <Grid
          key={file.name}
          container
          alignItems="center"
          border={`1px solid ${lines}`}
          borderRadius={1}
          marginTop={3}
        >
          <Grid item xs={2} padding={2}>
            <Box padding={1} display="flex" justifyContent="center" alignItems="center">
              <UploadFile />
            </Box>
          </Grid>
          <Grid item xs={9}>
            <Stack>
              <Typography variant="body1">{file.name}</Typography>

              <Stack direction="row" gap={1}>
                <Typography variant="body2" color={darkGray}>
                  {Math.ceil(file.size / 1024)} kB
                </Typography>
                <Typography variant="body2" color={darkGray}>
                  •
                </Typography>
                <Typography variant="body2" color={darkGray}>
                  File uploaded
                </Typography>
              </Stack>
            </Stack>
          </Grid>
          <Grid
            item
            xs={1}
            sx={[
              {
                cursor: "pointer",
                color: darkGray,
              },
              {
                "&:hover": {
                  color: black,
                },
              },
            ]}
          >
            <Close onClick={removeFile(file)} />
          </Grid>
        </Grid>
      ))}
    </>
  );
};
