import { useLocation } from "react-router-dom";

import { get, isEqual } from "lodash";

import { TRootState } from "~/store";

import { useAppSelector, useDeepMemo } from "@/common/hooks";

const emptyValue = "_empty_";
const contextByPath = (location: string) => {
  if (location.startsWith("/schedule")) return "schedule";
  if (location.startsWith("/holiday-schedule")) return "schedule";

  return null;
};
export const useLocalizedContent = (): {
  location: string;
  context: Record<string, string>;
} => {
  const location = useLocation().pathname;

  const context = useAppSelector(
    (state) => ({
      schedule: scheduleSelector(state.schedulerGrid),
      holidaySchedule: holidayScheduleSelector(state.holidaySchedule),
    }),
    isEqual,
  );

  return useDeepMemo(() => {
    const contextKey = contextByPath(location) || "";
    return {
      location,
      context: get(context, contextKey) || {},
    };
  }, [location, context]);
};

const scheduleSelector = (state: TRootState["schedulerGrid"]) => {
  return {
    scheduleId: state.schedule?.id || emptyValue,
    scheduleStatus: state.schedule?.status || emptyValue,
    scheduleType: state.scheduleType || emptyValue,
  };
};

const holidayScheduleSelector = (state: TRootState["holidaySchedule"]) => {
  return {
    scheduleId: state.general.holidaySchedule?.id || emptyValue,
    scheduleStatus: state.general.holidaySchedule?.status || emptyValue,
  };
};
