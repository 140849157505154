import { ISODateString } from "@m7-health/shared-utils";

import { APIListResponse, SDTO } from "../_shared/types";
import { IUser } from "../user";

export type ITimeOffRequest = TimeOffRequest.DTO;

export namespace TimeOffRequest {
  export const EStatus = {
    accepted: "accepted",
    rejectedByScheduler: "rejectedByScheduler",
    waitingOnScheduler: "waitingOnScheduler",
    none: null,
  } as const;

  export type EStatus = (typeof EStatus)[keyof typeof EStatus];

  export interface DTO extends SDTO.base, SDTO.withTimestamps, SDTO.Request {
    fromDate: ISODateString;
    toDate: ISODateString;
    requesterId: IUser["id"];
    requesterNote?: string;
    computedStatus: EStatus;
    ptoHoursRequested?: number;
  }

  export namespace API {
    export const ROOT_PATH = "/time-off-request";
    export namespace List {
      export const PATH = ROOT_PATH;
      export interface QueryParams {
        unitIds?: IUser["id"][];
      }

      export type Response = APIListResponse<DTO>;
    }
  }
}
