import { PayloadAction } from "@reduxjs/toolkit";

import { TStoreSlice } from "~/store";

import { IShiftType, IStaffCategory } from "@/api";

import { THolidayScheduleState } from ".";

type TModal<T = Record<string, never>> = {
  isOpen: boolean;
  data: T;
};
const defaultModalState = <T>(data: T): TModal<T> => ({
  isOpen: false,
  data,
});

export type TModalsState = {
  restoreToPreferences: TModal;
  updateTargetLevels: TModal<{
    nthWeek: number;
    staffCategoryKeyFilter: IStaffCategory["key"] | null;
    shiftTypeKeyFilter: IShiftType["key"] | null;
  }>;
};

export const ModalsInitState: TModalsState = {
  restoreToPreferences: defaultModalState({}),
  updateTargetLevels: defaultModalState({
    nthWeek: 0,
    staffCategoryKeyFilter: null,
    shiftTypeKeyFilter: null,
  }),
};

export const ModalsActions = {
  openModal: <T extends keyof TModalsState>(
    { modals }: THolidayScheduleState,
    { payload }: PayloadAction<{ modal: T; data?: TModalsState[T]["data"] }>,
  ) => {
    modals[payload.modal].isOpen = true;
    if (payload.data) {
      modals[payload.modal].data = payload.data;
    }
  },

  closeModal: (
    { modals }: THolidayScheduleState,
    { payload }: PayloadAction<keyof TModalsState>,
  ) => {
    modals[payload].isOpen = false;
    modals[payload].data = defaultModalState(modals[payload].data).data;
  },

  updateModalData: <T extends keyof TModalsState>(
    { modals }: THolidayScheduleState,
    { payload }: PayloadAction<{ modal: T; data: Partial<TModalsState[T]["data"]> }>,
  ) => {
    modals[payload.modal].data = {
      ...modals[payload.modal].data,
      ...payload.data,
    };
  },
} satisfies TStoreSlice<THolidayScheduleState>;
