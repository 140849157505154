/* eslint-disable @typescript-eslint/no-namespace */
import { Uuid } from "~/common/types";
import { IUnitBasic } from "~/routes/api/types";

import { APIListResponse } from "../_shared/types";

export type IStaffCategory = StaffCategory.DTO;

export namespace StaffCategory {
  export enum EKey {
    houseSupervisor = "houseSupervisor",
    nurse = "nurse",
    tech = "tech",
    rt = "rt",
    other = "other",
    provider = "provider",
  }

  export enum EName {
    houseSupervisor = "House Supervisor",
    nurse = "Nurse",
    tech = "Tech",
    rt = "RT",
    other = "Other",
    provider = "Provider",
  }

  export interface DTO {
    id: Uuid;
    key: EKey;
    name: EName;
    usedForTargetLevel: boolean;
  }

  export namespace API {
    const PATH_ROOT = "/staff-category";
    export namespace List {
      export const PATH = PATH_ROOT;
      export interface QueryParams {
        unitIds?: IUnitBasic["id"][];
        includeNonHomeUnit?: boolean;
      }

      export type Response = APIListResponse<DTO>;
    }
  }
}
