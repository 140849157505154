import { EUnitPermissionAreas } from "@m7-health/shared-utils";

import { StaffDetails } from "~/api/staffDetails/types";

import { User } from "@/api";

export interface IProtectedRoute {
  children: JSX.Element;
  roles: User.ERole[];
  allowedEmploymentType?: StaffDetails.EEmploymentType[];
  permissions: (EUnitPermissionAreas | TSingularPermission)[];
}

export type TDefaultRoleRoute = {
  [role in User.ERole]: string;
};

/**
 * For now we don't have granularity on permission for admins and staff
 *  so we use those constants to still make the permissions check on routes
 */
export const STAFF_PERMISSIONS = "STAFF_PERMISSIONS" as const;
export const KIOSK_PERMISSIONS = "KIOSK_PERMISSIONS" as const;
export const PERMISSION_ALLOW_ALL = "PERMISSION_ALLOW_ALL" as const;
export const ADMIN_PERMISSIONS = "ADMIN_PERMISSIONS" as const;
export type TSingularPermission =
  | typeof PERMISSION_ALLOW_ALL
  | typeof KIOSK_PERMISSIONS
  | typeof STAFF_PERMISSIONS
  | typeof ADMIN_PERMISSIONS;
