import { Typography } from "@mui/material";

import { FairnessStyled } from "~/common/components/Fairness/Fairness.styled";
import { getFairnessToAverageColor, getFairnessToBeginnerColor } from "~/common/utils/fairness";

export const FairnessToAverage = ({
  count,
  average,
  variant = "big",
  isBeginner = false,
}: {
  count: number;
  average: number;
  variant?: "small" | "big";
  isBeginner?: boolean;
}) => {
  const backgroundColor = isBeginner
    ? getFairnessToBeginnerColor(count, average)
    : getFairnessToAverageColor(count, average);
  const isSmall = variant === "small";

  return (
    <FairnessStyled $background={backgroundColor} $small={isSmall}>
      <Typography className="text">{count}</Typography>
    </FairnessStyled>
  );
};
