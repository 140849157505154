import { useForm } from "react-hook-form";

import { yupResolver } from "@hookform/resolvers/yup";
import { array, date, mixed, object, string } from "yup";

import { dateMsg } from "~/common/validation/messages";
import {
  booleanShape,
  booleanShapeRequired,
  dateShapeRequired,
  emailShapeRequired,
  integerShapeRequired,
  stringShapeRequired,
} from "~/common/validation/shapes";
import { EStaffStatus } from "~/features/Roster/types";

import { User } from "@/api";
import { localDayJs } from "@/common/packages/dayjs";

export const useStaffDataForm = (selectedUnitId: string, isEditStaff: boolean) => {
  return useForm({
    mode: "onChange",
    defaultValues: isEditStaff
      ? {}
      : {
          ...createStaffDefaultValues,
          unitAssignment: [selectedUnitId],
          homeUnitId: selectedUnitId,
        },

    // if still not use floating, then can only assign a user to one unit
    resolver: yupResolver(
      object()
        .shape(isEditStaff ? editStaffRequirements : createStaffRequirements)
        .required(),
    ),
  });
};

/**
 * Yup validation schema for ensuring the home unit is included in the unit assignment.
 * If not included, does not allow to save (as the Home Unit field is required with the * displayed)
 *
 * @param {mixed} - The schema to be extended.
 * @returns {mixed} - The extended schema with the custom test.
 */
const ensureHomeUnitIsIncludedInUnitAssignment = mixed().when("unitAssignment", {
  is: Array.isArray,
  then: (schema) =>
    schema.test({
      name: "homeUnitInUnitAssignment",
      message: "Home unit must be included in unit assignment",
      test: function (homeUnitId) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-assignment
        const unitAssignment = this.parent.unitAssignment;
        return Array.isArray(unitAssignment) && unitAssignment.includes(homeUnitId);
      },
    }),
  otherwise: (schema) => schema,
});

const createStaffRequirements = {
  // don't require chargeEligibility for now, but still send to API on BE for staff details, has default false value
  email: emailShapeRequired,
  phoneNumber: stringShapeRequired,
  employmentStartDate: dateShapeRequired,
  employmentType: stringShapeRequired,
  firstName: stringShapeRequired,
  lastName: stringShapeRequired,
  homeUnitId: ensureHomeUnitIsIncludedInUnitAssignment,
  maxConsecutiveShifts: integerShapeRequired,
  maxRequestsOffPerSchedule: integerShapeRequired,
  maxShiftsPerWeek: integerShapeRequired,
  minShiftsPerWeek: integerShapeRequired,
  moreThanOneDayBetweenShifts: booleanShape,
  onOrientation: booleanShapeRequired,
  orientationEndDate: mixed().when("onOrientation", ([onOrientation]) =>
    onOrientation === true ? date().typeError(dateMsg).required(dateMsg) : string().notRequired(),
  ),
  preceptor: booleanShapeRequired,
  shiftType: stringShapeRequired,
  status: stringShapeRequired,
  staffTypeName: stringShapeRequired,
  totalShiftsRequiredPerSchedule: integerShapeRequired,
  totalWeekendShiftsRequiredPerSchedule: integerShapeRequired,
  unitAssignment: array().of(string().required()).min(1, "Select at least one unit").required(),
  workingMultipleShiftTypes: booleanShapeRequired,
  roleTypes: array().of(string().required()).min(1, "Select at least one role type").required(),
};

const editStaffRequirements = {
  email: emailShapeRequired,
  firstName: stringShapeRequired,
  lastName: stringShapeRequired,
  unitAssignment: array().of(string().required()).min(1, "Select at least one unit").required(),
  staffTypeName: stringShapeRequired,
  roleTypes: array().of(string().required()).min(1, "Select at least one role type").required(),
  homeUnitId: ensureHomeUnitIsIncludedInUnitAssignment,
};

const createStaffDefaultValues = {
  // while we expand and contract, need to make sure chargeEligibility is still sent to API on BE for staff details, even if doesn't show up on dropdown
  chargeEligibility: false,
  contractEndDate: null,
  email: "",
  phoneNumber: "",
  employmentStartDate: localDayJs(),
  employmentType: "",
  externalStaffIdentifier: "",
  firstName: "",
  lastName: "",
  maxConsecutiveShifts: 1,
  maxRequestsOffPerSchedule: 1,
  maxShiftsPerWeek: 1,
  minShiftsPerWeek: 1,
  moreThanOneDayBetweenShifts: false,
  onOrientation: "",
  orientationEndDate: null,
  preceptor: "",
  shiftType: "",
  status: EStaffStatus.Active,
  totalShiftsRequiredPerSchedule: 1,
  totalWeekendShiftsRequiredPerSchedule: 1,
  staffTypeName: null,
  workingMultipleShiftTypes: false,
  roleTypes: [User.ERole.staff, User.ERole.kiosk],
};
