/* eslint-disable no-console */
import { memo } from "react";

import { Roster } from "@/api";

import { HeaderCell } from "./HeaderCell";

// notification titles are the same as the enum values of ENotificationType and ENotificationEntityOption
// except for dropShiftRequest, which is "Replacement"
export const NOTIFICATIONS_COLUMN_TITLES = ["Unit"]
  .concat(Object.values(Roster.ENotificationType))
  .concat(
    Object.values(Roster.ENotificationEntityOption).map((entityOption) =>
      entityOption === Roster.ENotificationEntityOption.dropShiftRequest
        ? "Replacement Request"
        : entityOption
            .replace(/([A-Z])/g, " $1")
            .replace(/(Request)/g, "")
            .trim(),
    ),
  );

export const NotificationsHeaderRow = memo(function Memoized_NotificationsHeaderRow() {
  const HeaderCellComponent = HeaderCell;

  return (
    <tr>
      {NOTIFICATIONS_COLUMN_TITLES.map((title) => {
        return (
          <th key={title} className={title === Roster.ENotificationType.sms ? "thick" : ""}>
            <div>
              <HeaderCellComponent key={title + "-header"} title={title} />
            </div>
          </th>
        );
      })}
    </tr>
  );
});
