import { Stack } from "@mui/material";

import { FairnessToRequired } from "~/common/components/FairnessToRequired/FairnessToRequired";

export const Cell = ({
  workedWeekendShifts,
  requiredWeekendShifts,
}: {
  workedWeekendShifts: number;
  requiredWeekendShifts: number;
}) => (
  <Stack alignItems="center">
    <FairnessToRequired count={workedWeekendShifts} required={requiredWeekendShifts} />
  </Stack>
);
