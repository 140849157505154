import { Grid } from "@mui/material";

import { CaptionsForChargeShifts } from "~/features/Fairness/components/Captions/CaptionsForChargeShifts";
import { CaptionsForExperiencePerShift } from "~/features/Fairness/components/Captions/CaptionsForExperiencePerShift";
import { CaptionsForHonoredPreferences } from "~/features/Fairness/components/Captions/CaptionsForHonoredPreferences";
import { CaptionsForWeekendShifts } from "~/features/Fairness/components/Captions/CaptionsForWeekendShifts";
import { EFairnessTypes } from "~/features/Fairness/types";

export const Captions = ({ type }: { type: EFairnessTypes }) => {
  const getCaptionsComponent = () => {
    switch (type) {
      case EFairnessTypes.PreferencesHonored:
      case EFairnessTypes.BlockedDaysHonored:
        return <CaptionsForHonoredPreferences />;
      case EFairnessTypes.ChargeShifts:
        return <CaptionsForChargeShifts />;
      case EFairnessTypes.WeekendShifts:
        return <CaptionsForWeekendShifts />;
      case EFairnessTypes.ExperiencePerShift:
        return <CaptionsForExperiencePerShift />;
    }
  };

  return (
    <Grid
      container
      sx={{ width: "100%", marginTop: "16px", marginBottom: "32px" }}
      justifyContent="flex-end"
    >
      {getCaptionsComponent()}
    </Grid>
  );
};
