import { ShiftType } from "@m7-health/shared-utils";

import { IUnitBasic } from "~/routes/api/types";

import { ISchedule, IStaffDetails, IStaffShift, IUnit } from "@/api";
import { TSx } from "@/common/types";

type TPropsBase = {
  staffShifts: IStaffShift[];
  staffShiftsWithEdits?: IStaffShift[];
  readonly?: boolean;
  isLoading?: boolean;
  readonlyUnit?: boolean;
  staffDetails?: IStaffDetails;
  unitsByScheduleId: Record<ISchedule["id"], IUnit | IUnitBasic>;
  newStaffShiftScaffold?:
    | (Pick<IStaffShift, "scheduleId" | "date"> & {
        shiftTypeKey?: IStaffShift["shiftTypeKey"];
        scheduleType?: IStaffShift["scheduleType"];
      })
    | null;
  readonlyIfShiftNotOnSelectedUnit?: boolean;
  sx?: TSx;
};

type TPropsWithBuffer = TPropsBase & {
  autoSave?: boolean;
  saveChanges?: ({
    toCreate,
    toDelete,
    toUpdate,
  }: {
    toCreate: IStaffShift[];
    toDelete: IStaffShift[];
    toUpdate: IStaffShift[];
  }) => void;
  cancelChanges?: () => void;

  onCreate?: never;
  onUpdate?: never;
  onDelete?: never;
};

type TPropsWithInstantPropagation = TPropsBase & {
  autoSave?: never;
  saveChanges?: never;
  cancelChanges?: never;

  onCreate?: (staffShift: IStaffShift) => void;
  onUpdate?: (updatedShift: IStaffShift, originalShift: IStaffShift) => void;
  onDelete?: (staffShift: IStaffShift) => void;
};

export type TShiftsEditorProps = TPropsWithBuffer | TPropsWithInstantPropagation;

export const EMPTY_SHIFT_TYPE_KEY = "__TO_BE_SELECTED__" as ShiftType.Key;
