import { Box, Grid, Link, Typography } from "@mui/material";

import CustomButton from "~/common/components/TrackedComponents/Button";
import { darkGray, darkPurple } from "~/common/theming/colors";
import { pxToRem } from "~/common/utils/pxToRem";

const ErrorPage = () => {
  return (
    <Box height="100vh" display="grid" gridTemplateRows="88px 1fr">
      <Box padding="24px 48px">
        <img alt="App Logo" style={{ width: "47px", padding: "12px 0" }} src="/M7_logo.svg" />
      </Box>
      <Box display="flex" justifyContent="center" alignItems="center" width="100%" height="100%">
        <Grid container direction="column" alignItems="center" textAlign="center" gap={2}>
          <Grid item>
            <Typography
              variant="h1"
              fontSize={pxToRem(28)}
              lineHeight={pxToRem(20)}
              fontWeight={500}
            >
              Something went wrong...
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              p={2}
              variant="body1"
              fontSize={pxToRem(14)}
              lineHeight={pxToRem(17)}
              color={darkGray}
            >
              For some reason we couldn't display this page.
              <br />
              If you keep seeing this message, please contact your administrator at{" "}
              <Link
                variant="caption"
                fontWeight={100}
                color={darkPurple}
                fontSize="inherit"
                href="mailto:help@m7health.com"
                borderBottom={darkPurple + " 2px solid"}
                noWrap
              >
                help@m7health.com
              </Link>{" "}
              or{" "}
              <Link
                variant="caption"
                fontWeight={100}
                color={darkPurple}
                fontSize="inherit"
                href="tel:(727)777-7391"
                mb={5}
                borderBottom={darkPurple + " 2px solid"}
                noWrap
              >
                727-777-7391
              </Link>
              .
            </Typography>
          </Grid>
          <Grid item>
            <Link href="/?reload_window=true">
              <CustomButton label="Return to home page" />
            </Link>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default ErrorPage;
