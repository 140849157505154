import { Typography } from "@mui/material";

import { FairnessStyled } from "~/common/components/Fairness/Fairness.styled";
import { getFairnessToRequiredColor } from "~/common/utils/fairness";

export const FairnessToRequired = ({
  count,
  required,
  variant = "big",
}: {
  count: number;
  required: number;
  variant?: "small" | "big";
}) => {
  const backgroundColor = getFairnessToRequiredColor(count, required);
  const isSmall = variant === "small";

  return (
    <FairnessStyled $background={backgroundColor} $small={isSmall}>
      <Typography className="text">
        {count}/{required}
      </Typography>
    </FairnessStyled>
  );
};
