export const styles = {
  minWidth: "unset",
  "&.small": {
    height: "20px",
    padding: "6px",
    svg: {
      fontSize: "0.75rem !important",
    },
  },
  "&.medium": {
    height: "30px",
    padding: "8px 10px",
    svg: {
      fontSize: "0.8rem !important",
    },
  },
  "&.big": {
    height: "40px",
    padding: "12px 14px",
    svg: {
      fontSize: "0.875rem !important",
    },
  },

  borderRadius: "4px",
  "box-sizing": "border-box",

  "&.MuiButton-startIcon": {
    marginRight: "8px",
    marginLeft: "-4px",
  },

  "&.no-text": {
    ".MuiButton-startIcon": {
      marginRight: "0px",
      marginLeft: "0px",
    },
  },

  "&.block:disabled": {
    svg: { stroke: "currentColor" },
  },
  "&.clear:disabled": {
    svg: { stroke: "currentColor" },
  },
} as const;
