import { Note, StaffDetails, StaffShift } from "~/api";
// eslint-disable-next-line deprecate/import
import { YyyyMmDd } from "~/common/types";
import { EShiftStatus } from "~/features/DailyReports/types";

export const shiftLastNoteUpdatedAt = (
  metadata: StaffDetails.ShiftMetadataDTO | undefined,
  target: StaffShift.EStatus | EShiftStatus | Note.EUpdate | undefined | null,
): YyyyMmDd | undefined | null => {
  if (!metadata) return;

  switch (target) {
    case EShiftStatus.calledIn:
      return metadata?.lastCalledInAt;
    case EShiftStatus.cancelled:
      return metadata?.lastCancelledAt;
    case EShiftStatus.floated:
      return metadata?.lastFloatedAt;
    case EShiftStatus.onCall:
      return metadata?.lastOnCallAt;
    case EShiftStatus.flexed:
      return metadata?.lastFlexedAt;
    case StaffShift.EStatus.requestOnCall:
      return metadata?.lastRequestOnCallAt;
    case StaffShift.EStatus.onCall4h:
      return metadata?.lastOnCall4hAt;
    default:
      return metadata?.lastCalledInAt;
  }
};

export const shiftLastStatusUpdatedAt = shiftLastNoteUpdatedAt;
