import { PayloadAction } from "@reduxjs/toolkit";

import { THouseViewState, initialState } from ".";

export type SidebarCurrentAction = {
  inProgress: (typeof availableActions)[number] | null;
  isDirty?: boolean;
};

const availableActions = [
  "assignAttributes",
  "editShifts",
  "updateStatus",
  "findStaffToWork",
  "floating",
] as const;

const actionSliceKeyByAction: Record<
  NonNullable<SidebarCurrentAction["inProgress"]>,
  keyof THouseViewState
> = {
  assignAttributes: "assignAttributes",
  editShifts: "editShifts",
  findStaffToWork: "findStaffToWork",
  floating: "floating",
  updateStatus: "updateStatus",
};

export const SidebarActions = {
  startAction: (
    { sidebarCurrentAction }: THouseViewState,
    action: PayloadAction<SidebarCurrentAction["inProgress"]>,
  ) => {
    sidebarCurrentAction.inProgress = action.payload;
  },

  endAction: (state: THouseViewState) => {
    let result = { ...state };

    result = { ...result, sidebarCurrentAction: initialState.sidebarCurrentAction };

    availableActions.forEach((action) => {
      const actionSliceKey = actionSliceKeyByAction[action];
      result = { ...result, [actionSliceKey]: initialState[actionSliceKey] };
    });

    return result;
  },

  setCurrentActionDirtiness: (
    { sidebarCurrentAction }: THouseViewState,
    action: PayloadAction<SidebarCurrentAction["isDirty"]>,
  ) => {
    sidebarCurrentAction.isDirty = action.payload;
  },
};
