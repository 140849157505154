import { isEqual, sortBy } from "lodash";

import { IPreferenceRequirementRule } from "@/api";

import { useDeepMemo } from "../../useDeepMemo";

export const useOrderedPreferenceRequirement = (
  preferenceRequirements: IPreferenceRequirementRule[],
) => {
  return useDeepMemo(() => {
    return sortPreferenceRequirements(preferenceRequirements);
  }, [preferenceRequirements]);
};

type TOrderedPreferenceRequirement = IPreferenceRequirementRule & {
  type: "regular" | "weekend" | "block";
};

/**
 * Sort preference requirements by
 * 1. Rule set id
 * 2. Created at
 * 3. Type:
 *  - Regular
 *  - Weekend
 *  - Block
 *
 * @returns an object with the ordered requirements and the separated requirements by type
 */
export const sortPreferenceRequirements = (
  preferenceRequirements: IPreferenceRequirementRule[],
) => {
  const regularRequirements: TOrderedPreferenceRequirement[] = [];
  const weekendRequirements: TOrderedPreferenceRequirement[] = [];
  const blockRequirements: TOrderedPreferenceRequirement[] = [];

  sortBy(preferenceRequirements, ["preferenceRequirementRuleSetId", "createdAt"]).forEach(
    (requirement) => {
      if (isBlockRequirement(requirement))
        blockRequirements.push({ ...requirement, type: "block" });
      else if (isWeekendRequirement(requirement))
        weekendRequirements.push({ ...requirement, type: "weekend" });
      else regularRequirements.push({ ...requirement, type: "regular" });
    },
  );

  return {
    ordered: [...regularRequirements, ...weekendRequirements, ...blockRequirements],
    regularRequirements,
    weekendRequirements,
    blockRequirements,
  };
};

export const isBlockRequirement = (requirement: IPreferenceRequirementRule) => {
  return isEqual(requirement.targetShiftTypeKeys, ["block"]);
};

export const isWeekendRequirement = (requirement: IPreferenceRequirementRule) => {
  return requirement.targetIsWeekend;
};
