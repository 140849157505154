import { useCallback, useEffect } from "react";

import { Millisecond } from "@m7-health/shared-utils";
import { useQueryClient } from "@tanstack/react-query";
import { debounce, isEqual } from "lodash";

import { httpClient } from "@/common/packages/httpClient";

const debounceValue = 200 as Millisecond;

export const useResetQueriesOnM7HeadersChange = () => {
  const queryClient = useQueryClient();

  const onContextChange = useCallback(
    () =>
      debounce(() => {
        // Reset all queries except the app config query and staffDetails query
        const queriesToKeep = [["appConfig"], ["staffDetails"]];
        void queryClient.invalidateQueries();
        const allQueries = queryClient.getQueryCache().getAll();
        allQueries.forEach((query) => {
          if (isEqual(queriesToKeep, query.queryKey)) return;
          queryClient.removeQueries({ queryKey: [query.queryKey] });
        });
      }, debounceValue)(),
    [queryClient],
  );

  const resetAllQueries = useCallback(
    () =>
      debounce(() => {
        void queryClient.invalidateQueries();
        const allQueries = queryClient.getQueryCache().getAll();
        allQueries.forEach(
          (query) => void queryClient.removeQueries({ queryKey: [query.queryKey] }),
        );
      }, debounceValue)(),
    [queryClient],
  );

  useEffect(() => {
    httpClient.eventEmitter.on("unitIdChanged", onContextChange);
    httpClient.eventEmitter.on("facilityIdChanged", onContextChange);
    httpClient.eventEmitter.on("currentRoleChanged", resetAllQueries);

    return () => {
      httpClient.eventEmitter.removeListener("unitIdChanged", onContextChange);
      httpClient.eventEmitter.removeListener("facilityIdChanged", onContextChange);
      httpClient.eventEmitter.removeListener("currentRoleChanged", resetAllQueries);
    };
  }, [onContextChange, resetAllQueries]);
};
