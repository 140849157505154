import { THexColor } from "@m7-health/shared-utils";

import { Box, SelectChangeEvent, SxProps } from "@mui/material";

import {
  dayShift,
  dayShiftDark,
  educationShift,
  lightPurple,
  schoolShift,
  selectorBlue,
  selectorPurple,
  selectorPink,
  selectorYellow,
  selectorGreen,
  selectorBrown,
  vacationShift,
} from "@/common/theming";

import { CustomSelect } from "../TrackedComponents";
import { ICustomSelectItem } from "../TrackedComponents/Select/types";

const defaultM7ColorOptions = [
  dayShift,
  dayShiftDark,
  vacationShift,
  educationShift,
  schoolShift,
  lightPurple,
  selectorBlue,
  selectorPurple,
  selectorPink,
  selectorYellow,
  selectorGreen,
  selectorBrown,
] as THexColor[];

const defaultColorSelectItems = defaultM7ColorOptions.map((color) => ({
  value: color,
  label: color,
  item: color,
}));

export const ColorSelector = ({
  selectedColor,
  onChange,
  colorItems = defaultColorSelectItems,
  trackingLabel = "Color Selector",
  error,
  label,
  variant,
  sx,
}: {
  selectedColor: THexColor;
  onChange: (event: SelectChangeEvent) => void;
  colorItems?: ICustomSelectItem<THexColor>[];
  trackingLabel?: string;
  error?: boolean;
  label: string;
  variant?: "standard" | "outlined" | "filled";
  sx?: SxProps;
}) => {
  const ColorComponent = ({ color }: { color: THexColor }) => (
    <Box display="flex" flexGrow={1} width="100%" height="20px" sx={{ backgroundColor: color }} />
  );
  return (
    <CustomSelect<THexColor>
      trackingLabel={trackingLabel}
      variant={variant}
      hideSelectedOption={false}
      label={label}
      items={colorItems}
      value={selectedColor}
      onChange={onChange}
      optionRenderComponent={(color) => <ColorComponent color={color?.value as THexColor} />}
      renderValue={(value) => <ColorComponent color={value as THexColor} />}
      sx={sx}
      error={error}
    />
  );
};
