import { EStaffShiftType } from "~/features/Roster/types";

import { IOpenShiftsState } from "../types";

export const initialState: IOpenShiftsState = {
  shiftTypeFilter: EStaffShiftType.Day,
  date: null,
  selectedStaffCategory: null,
  selectedDayKey: null,
  createOpenShiftsModal: {
    isOpen: false,
  },
  createBulkOpenShiftsModal: {
    isOpen: false,
  },
  availableSchedules: null,
  selectedScheduleId: null,
  deleteOpenShiftModal: {
    isOpen: false,
  },
  approveOpenShiftModal: {
    isOpen: false,
  },
  rejectOpenShiftModal: {
    isOpen: false,
  },
  metadataOfAllOpenShifts: {},
};
