import { m7DayJs } from "@m7-health/shared-utils";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

import { Box } from "@mui/material";

import { lineChartGridLines, lineChartPersonalScore, lineChartUnitAverage } from "@/common/theming";

import { NUMBER_OF_MONTHS, TPulseCheckData } from "../types";

import CustomTooltip from "./CustomTooltip";
import { ChartDataPoint } from "./types";

export const SimpleLineChart = ({ data, year }: { data: TPulseCheckData[]; year: number }) => {
  const months = Array.from({ length: NUMBER_OF_MONTHS }, (_, index) =>
    m7DayJs().month(index).format("MMM"),
  );

  const chartData: ChartDataPoint[] = data.map((item) => {
    const date = m7DayJs(item.date);
    const monthIndex = date.month();
    const dayOfMonth = date.date();
    const daysInMonth = date.daysInMonth();

    const xPosition = monthIndex + (dayOfMonth - 1) / daysInMonth;

    return {
      ...item,
      x: xPosition,
      year: year,
    };
  });

  return (
    <Box sx={{ height: 300, mt: 2 }}>
      <ResponsiveContainer width="100%" height="100%">
        <LineChart data={chartData} margin={{ top: 5, right: 30, left: 20, bottom: 25 }}>
          <CartesianGrid strokeDasharray="3 3" stroke={lineChartGridLines} />
          <XAxis
            dataKey="x"
            type="number"
            domain={[0, 11]}
            ticks={Array.from({ length: NUMBER_OF_MONTHS }, (_, index) => index)}
            tickFormatter={(value: number) => months[Math.floor(value)] || ""}
            label={{ value: `Months (${year})`, position: "insideBottom", offset: -15 }}
          />
          <YAxis
            domain={[0, 5]}
            ticks={[0, 1, 2, 3, 4, 5]}
            label={{ value: "Score", angle: -90, position: "insideLeft" }}
          />
          <Tooltip content={<CustomTooltip />} />
          <Legend verticalAlign="top" height={36} />
          <Line
            type="monotone"
            dataKey="personalScore"
            stroke={lineChartPersonalScore}
            activeDot={{ r: 8 }}
            name="Personal Score"
          />
          <Line
            type="monotone"
            dataKey="unitAverage"
            stroke={lineChartUnitAverage}
            name="Unit Average"
          />
        </LineChart>
      </ResponsiveContainer>
    </Box>
  );
};
