import { YyyyMmDd } from "@m7-health/shared-utils";

import { DeprecatedTShift } from "#/features/SchedulerGrid/types";
import { EStaffCategoryKey, EStaffTypeKey } from "@/common/types";

import { APIElementResponse, APIListResponse, SDTO } from "../_shared/types";
import { ISchedule } from "../schedule";
import { IShiftIncentiveLevel } from "../shiftIncentiveLevel";
import { IShiftType } from "../shiftType";
import { StaffCategory } from "../staffCategory";

export type IOpenShift = OpenShift.DTO;

export namespace OpenShift {
  export interface DTO extends SDTO.base, SDTO.withTimestamps {
    date: YyyyMmDd;
    shiftType: IShiftType["key"];
    staffCategoryKey: StaffCategory.EKey;
    shiftCount: number;
    scheduleId: ISchedule["id"];
    note?: string;
    shiftIncentiveLevelId?: IShiftIncentiveLevel["id"] | null;
  }

  export namespace API {
    export const ROOT_PATH = "/open-shifts";
    export namespace List {
      export const PATH = ROOT_PATH;
      export interface QueryParams {
        staffCategoryKeys?: StaffCategory.EKey[];
        shiftTypeKeys?: IShiftType["key"][];
        shiftCount?: {
          min?: number;
          max?: number;
        };
        includePast?: boolean;
      }

      export type Response = APIListResponse<DTO>;
    }

    export namespace Create {
      export const PATH = (params: QueryParams) => `${ROOT_PATH}/${params.scheduleId}`;

      export type QueryParams = {
        date: string;
        shiftCount: number;
        shiftType?: DeprecatedTShift;
        staffTypeKey?: EStaffTypeKey;
        scheduleId: string;
        staffCategoryKey?: EStaffCategoryKey;
        note?: string;
      };

      export type Response = APIElementResponse<IOpenShift["id"]>;
    }

    export namespace CreateBulk {
      export const PATH = (params: QueryParams) => `${ROOT_PATH}/${params.scheduleId}/fill-gaps`;

      export type QueryParams = {
        scheduleId: ISchedule["id"];
      };

      export type Response = APIListResponse<IOpenShift["id"][]>;
    }
  }
}
