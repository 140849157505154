import styled from "styled-components";

import { Box, lighten } from "@mui/material";

import {
  black,
  darkBlue,
  darkGray,
  darkGreen,
  darkOrange,
  darkPurple,
  darkRed,
  dayShiftDark,
  floatedStaffColor,
  lightGray,
  lightOrange,
  lines,
  mediumGray,
  mediumGreen,
  successMain,
  white,
} from "~/common/theming/colors";

export const CalendarStyled = styled(Box)<{ $isPlanningDrawerExpanded?: boolean }>`
  width: 100%;
  overflow: auto;
  padding-bottom: 30px;
  position: relative;

  .time-off-request-layer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;

    background: repeating-linear-gradient(
      -45deg,
      transparent,
      transparent 1px,
      rgba(0, 0, 0, 0.4) 7px,
      rgba(0, 0, 0, 0.1) 5.3px
    );
  }

  .grid.v2 {
    z-index: 100;
    padding: 10px;

    .tile {
      position: relative;
      border-style: solid;
      border-radius: 5px;
      padding: 0;
      overflow: initial;

      // Remove old style
      &:after {
        display: none;
      }

      // Opacity changes based on the fact that something is present
      //  or not on the tile
      .tile-content {
        opacity: 0.6;
        transition: opacity 0.2s ease-in-out;
      }

      &.highlighted-day,
      &.with-shift,
      &:hover {
        .tile-content {
          opacity: 1;
        }
      }
    }

    // Note updates on the bottom-right corner of the tile
    .with-note-update {
      .tile-content {
        overflow: hidden;
      }

      .note-update-container:after {
        content: "";
        width: 50%;
        height: 170%;
        transform: rotate(45deg);
        bottom: -70%;
        right: -15%;
        position: absolute;
        z-index: 10;
        border: 1px solid white;
      }
      &.called-in .note-update-container:after {
        background: ${darkBlue};
      }
      &.floated .note-update-container:after {
        background: ${floatedStaffColor};
      }
      &.on-call-roc .note-update-container:after {
        background: ${darkOrange};
      }
      &.on-call .note-update-container:after {
        background: ${dayShiftDark};
      }
      &.cancelled .note-update-container:after {
        background: ${darkRed};
      }
      &.flexed .note-update-container:after {
        background: ${mediumGray};
      }
    }

    .preference-container {
      position: absolute;
      top: -5px;
      right: -6px;
      border: 2px solid ${white};
      z-index: 1;
      width: 18px;
      height: 20px;
      border-radius: 50%;
      display: flex;
      flex-flow: column;
      align-items: center;
      justify-content: center;
      padding: 0 0.5rem;
      box-sizing: border-box;
      pointer-events: none;
      z-index: 2;
      // opacity: 0.6;

      svg {
        scale: 0.8;
        color: ${lighten(black, 0.2)};
      }
    }

    .today {
      border-color: ${mediumGray};
      border-top: 1px solid ${lines};
    }

    // Display the month inside the tile, on the corner
    // Display the day of the week inside the tile, on the top
    .month-in-day-name,
    .day-of-week-in-day-name {
      position: absolute;
      background-color: white;
      font-size: 0.75rem;
      line-height: 10px;
      padding: 2px;
      z-index: 9;
      font-weight: 500;
      border-radius: 20px;
      padding: 2px;
    }

    .month-in-day-name {
      top: 0px;
      left: -10px;
      transform: rotate(-90deg);
    }

    .day-of-week-in-day-name {
      top: -9px;
    }

    .highlighted-day {
      border: 2px solid ${darkPurple};
    }

    .tile-content {
      display: flex;
      flex-flow: column;
      align-items: center;
      justify-content: space-between;
      height: 100%;
      width: 100%;
      position: relative;
      z-index: 1;
    }

    .day-text {
      padding-top: 0.25rem;
      font-size: 0.775rem;
    }

    .tile svg {
      width: 18px;
      height: 18px;
      position: relative;
      right: 0;
      bottom: 0;
      z-index: 2;
    }

    .today .day-text {
      text-decoration: underline;
    }
  }

  .grid {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    gap: 0.375rem;
  }

  .tile {
    height: 3rem;
    color: ${black};
    font-size: 0.875rem;
    font-weight: 500;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: space-between;
    border-radius: 0 0 0.25rem 0.25rem;
    border-top: 1px solid ${lines};
    padding: 0.25rem 0 0.5rem;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;

    svg {
      width: 11px;
      height: 11px;
    }

    &.holidayDate {
      border-top: 2px solid ${darkBlue} !important;
      .holiday-icon {
        z-index: 2;
        overflow: hidden;
        opacity: 1;
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        top: -13px;
        right: -6px;
        width: 17px;
        height: 17px;
        color: ${darkBlue};
        border: 2px solid ${darkBlue};
        border-radius: 50%;
        padding: 2px;
        background-color: ${white};
        span {
          position: relative;
          top: 1px;
          // Zoom a bit
          transform: scale(1.1);
          // Keep the size of the icon even if browser font size is changed
          font-size: min(3.75vw, 3.75vh);
        }
      }

      &.published-holiday {
        border: 2px solid ${darkGreen} !important;
        .holiday-icon {
          color: ${darkGreen};
          border: 2px solid ${darkGreen};
        }
      }

      &.selected {
        background-color: ${darkBlue};
      }

      &.readonly-preference-holiday {
        border: 2px solid ${lighten(darkBlue, 0.4)} !important;
        & > * {
          opacity: 0.4;
        }
        .holiday-icon {
          opacity: 1;
        }
      }
    }

    &.today {
      border: 1px solid ${black};
    }

    &.highlighted {
      border: 2px solid ${black};
    }

    &.selectable {
      border: 1px dashed #5b43aca6;
      cursor: pointer;
    }

    &.open {
      position: relative;
      overflow: hidden;

      &:after {
        content: "";
        width: 50%;
        height: 170%;
        transform: rotate(45deg);
        background: ${mediumGreen};
        bottom: -70%;
        right: -15%;
        position: absolute;
        z-index: 1;
      }

      &.incentive:after {
        background: ${lighten(successMain, 0.3)};
      }

      svg {
        position: absolute;
        right: 5%;
        bottom: 5%;
        z-index: 2;
      }

      .ops-money-sign {
        svg {
          height: 1rem;
          width: 1rem;
          bottom: 0%;
          right: -2%;
        }
        &.has-incentive-level {
          svg:first-child {
            right: -7%;
          }
          svg:last-child {
            right: 8%;
          }
        }
      }
    }

    &.on-call {
      position: relative;
      overflow: hidden;

      &:after {
        content: "";
        width: 50%;
        height: 170%;
        transform: rotate(45deg);
        background: ${dayShiftDark};
        bottom: -70%;
        right: -15%;
        position: absolute;
        z-index: 1;
      }

      svg {
        position: absolute;
        right: 5%;
        bottom: 5%;
        z-index: 2;
      }
    }

    &.flexed {
      position: relative;
      overflow: hidden;

      &:after {
        content: "";
        width: 50%;
        height: 170%;
        transform: rotate(45deg);
        background: ${mediumGray};
        bottom: -70%;
        right: -15%;
        position: absolute;
        z-index: 1;
      }

      svg {
        position: absolute;
        right: 5%;
        bottom: 5%;
        z-index: 2;
      }
    }

    &.floated {
      position: relative;
      overflow: hidden;

      &:after {
        content: "";
        width: 50%;
        height: 170%;
        transform: rotate(45deg);
        background: ${lightOrange};
        bottom: -70%;
        right: -15%;
        position: absolute;
        z-index: 1;
      }

      svg {
        position: absolute;
        right: 5%;
        bottom: 5%;
        z-index: 2;
      }
    }

    &.cancelled-called-in {
      position: relative;
      overflow: hidden;

      &:after {
        content: "";
        width: 50%;
        height: 170%;
        transform: rotate(45deg);
        background: ${darkRed};
        bottom: -70%;
        right: -15%;
        position: absolute;
        z-index: 1;
      }

      svg {
        position: absolute;
        right: 5%;
        bottom: 5%;
        z-index: 2;
      }
    }

    &.pending {
      &:after {
        background: ${black};
      }

      svg {
        color: ${white};
      }
    }

    &.past,
    &.future {
      background-color: ${lightGray};
      color: ${darkGray};
    }

    &.selected {
      background-color: ${black};
      color: ${white};

      svg {
        stroke: ${white};
      }
    }

    &.ptoselected {
      border: 2px solid ${black};
    }
  }
`;
