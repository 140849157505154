import { useEffect, useState } from "react";

import { filter, isEqual } from "lodash";

import { CircularProgress, Grid, Typography } from "@mui/material";

import { Roster, useListRosterQuery, useUpdateRosterMutation } from "~/api";
import CustomModal from "~/common/components/Modal";
import { useAppDispatch, useAppSelector } from "~/common/hooks/useRedux";
import { useToast } from "~/common/hooks/useToast";
import {
  setEditSettingsModalOpen,
  setEditSettingsModalSelectedEmailRosterIds,
  setEditSettingsModalSelectedRosterIdsByEntityOption,
  setEditSettingsModalSelectedSMSRosterIds,
} from "~/features/Roster/store";
import { useAppConfigQuery } from "~/features/User/queries";

import { RosterIdsByEntityOption } from "#/features/Roster/types";

import { NotificationsTable } from "./NotificationsTable/Table";

const Modal = () => {
  const dispatch = useAppDispatch();
  const { showSuccess, showError } = useToast();
  const currentUser = useAppSelector((state) => state.user.userData);
  const config = useAppConfigQuery();
  const {
    data: rosterEntriesOfUser = [],
    refetch: refetchRoster,
    isLoading: isLoadingRosterEntries,
  } = useListRosterQuery(
    {
      userIds: [currentUser.id],
    },
    { skip: !currentUser.id },
  );
  const currentUserUnits = config.data?.units;

  useEffect(() => {
    dispatch(
      setEditSettingsModalSelectedSMSRosterIds(
        rosterEntriesOfUser
          .filter(({ notificationPreferences }) =>
            notificationPreferences.includes(Roster.ENotificationType.sms),
          )
          .map(({ id }) => id),
      ),
    );
    dispatch(
      setEditSettingsModalSelectedEmailRosterIds(
        rosterEntriesOfUser
          .filter(({ notificationPreferences }) =>
            notificationPreferences.includes(Roster.ENotificationType.email),
          )
          .map(({ id }) => id),
      ),
    );

    dispatch(
      setEditSettingsModalSelectedRosterIdsByEntityOption(
        rosterEntriesOfUser.reduce(
          (acc: { [entityOptionName: string]: number[] }, { id, entityPreferences }) => {
            entityPreferences?.forEach((entityPreference: string) => {
              if (acc[entityPreference] && acc[entityPreference]?.length !== 0) {
                acc[entityPreference]?.push(id);
              } else {
                acc[entityPreference] = [id];
              }
            });
            return acc;
          },
          {} as RosterIdsByEntityOption,
        ),
      ),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(rosterEntriesOfUser)]);

  const isOpen = useAppSelector((state) => state.roster.editSettingsModal.isOpen);
  const selectedSMSRosterIds = useAppSelector(
    (state) => state.roster.editSettingsModal.selectedSMSRosterIds,
  );
  const selectedEmailRosterIds = useAppSelector(
    (state) => state.roster.editSettingsModal.selectedEmailRosterIds,
  );
  const selectedRosterIdsByEntityOption = useAppSelector(
    (state) => state.roster.editSettingsModal.selectedRosterIdsByEntityOption,
  );

  let entityOrNotificationPreferencesEdited = 0;
  const [_entityOrNotificationPreferencesSaved, setEntityOrNotificationPreferencesSaved] =
    useState(0);
  const { mutate: updateRoster } = useUpdateRosterMutation({
    onSuccess: () => {
      setEntityOrNotificationPreferencesSaved((prev) => {
        // if all notification preferences have been saved THAT HAVE BEEN EDITED, then show that preferences have been saved
        if (prev + 1 === entityOrNotificationPreferencesEdited) {
          showSuccess("Settings updated successfully.");
          dispatch(setEditSettingsModalOpen(false));
          void refetchRoster();
          return 0;
        }
        return prev + 1;
      });
    },
    onError: () => {
      showError("Failed to update settings.");
    },
  });

  const onSubmit = () => {
    // go through selectedSMSRosterIds and selectedEmailRosterIds and update the notificationPreferences
    // only update the ones that have changed
    rosterEntriesOfUser.forEach(({ id, notificationPreferences, entityPreferences }) => {
      const newNotificationPreferences = [];
      if (selectedSMSRosterIds.includes(id)) {
        newNotificationPreferences.push(Roster.ENotificationType.sms);
      }
      if (selectedEmailRosterIds.includes(id)) {
        newNotificationPreferences.push(Roster.ENotificationType.email);
      }
      const newEntityPreferences: Roster.ENotificationEntityOption[] = [];
      Object.values(Roster.ENotificationEntityOption).forEach((entityOption) => {
        if (selectedRosterIdsByEntityOption[entityOption]?.includes(id)) {
          newEntityPreferences.push(entityOption);
        }
      });
      // only update if there are changes

      if (
        !isEqual(
          newNotificationPreferences.sort((a, b) => a.localeCompare(b)),
          notificationPreferences.sort((a, b) => a.localeCompare(b)),
        ) ||
        !isEqual(
          newEntityPreferences.sort((a, b) => a.localeCompare(b)),
          entityPreferences?.sort((a, b) => a.localeCompare(b)),
        )
      ) {
        entityOrNotificationPreferencesEdited += 1;
        updateRoster({
          id,
          notificationPreferences: newNotificationPreferences,
          entityPreferences: newEntityPreferences,
        });
      }
    });
  };

  const handleSecondaryBtnClick = () => {
    dispatch(setEditSettingsModalOpen(false));
    void refetchRoster();
  };

  if (isLoadingRosterEntries) {
    return <CircularProgress />;
  }

  return (
    <CustomModal
      isOpen={isOpen}
      primaryBtnText="Save"
      modalHeaderText={`Edit Settings`}
      onSecondaryBtnClick={handleSecondaryBtnClick}
      onSubmit={onSubmit}
      modalContent={
        <Grid container spacing={2}>
          <Grid item xs={12}></Grid>
          <Grid item xs={12}>
            <Typography fontSize={19} fontWeight={500}>
              Notification Settings
            </Typography>
            <Typography fontSize={16}>
              Step 1. Select how you want to receive notifications: sms, email, or both.
            </Typography>
            <Typography fontSize={16}>
              Step 2. Select requests you want notifications for.
            </Typography>
          </Grid>
          <NotificationsTable
            units={filter(currentUserUnits)}
            rosterEntries={rosterEntriesOfUser}
          />
        </Grid>
      }
    />
  );
};

export const EditSettings = {
  Modal,
};
