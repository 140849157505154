import { useCallback, useMemo } from "react";

import { m7DayJs } from "@m7-health/shared-utils";
import { keyBy, keys, map } from "lodash";

import { useAppConfigQuery } from "~/features/User/queries";

import { StaffDetails, useListStaffDetailsQuery } from "@/api";
import { NOT_EXISTING_UUID } from "@/common/constants";

import { useAllAttributesByKey } from "./useAllAttributesByKey";
import { useCurrentSelectedUnitId } from "./useCurrentUnitId";

export const useDownloadRoster = () => {
  // ** Queries -- data
  // Units
  const { data: { units = [] } = {} } = useAppConfigQuery();
  const unitsById = useMemo(() => keyBy(units, "id"), [units]);
  const currentUnitId = useCurrentSelectedUnitId();
  const unitId = currentUnitId || NOT_EXISTING_UUID;
  const unit = unitsById[unitId];
  const attributesByKey = useAllAttributesByKey();

  const { data: staffDetailsDtos } = useListStaffDetailsQuery(
    { homeUnitIds: [unitId], active: [true] },
    { skip: !currentUnitId },
  );

  return useCallback(() => {
    // File name: survey-responses__{unitName}__{date}.csv
    const fileName = `roster__${unit?.name || ""}__${m7DayJs().format("YYYY-MM-DD")}.csv`;

    // staff headers
    const staffHeaders: {
      [key: string]: (staff: StaffDetails.DTO) => string;
    } = {
      "First name": ({ user }) => user.firstName,
      "Last name": ({ user }) => user.lastName,
      "Staff type": ({ staffType }) => staffType.name,
      Email: ({ user }) => user.email,
      "Phone number": ({ user }) => user.phoneNumber,
      Status: ({ status }) => status,
      "Employment type": ({ employmentType }) => employmentType,
      "Position eligibility": ({ attributeKeys }) =>
        attributeKeys.map((key) => attributesByKey[key]?.name).join(", "),
    };

    // Returns an array of headers, and an array of rows
    const formattedData = {
      // Specific order that must match the order of the data
      headers: [...keys(staffHeaders)],
      data:
        staffDetailsDtos?.map((staffItem) => {
          // Specific order that must match the order of the headers
          return [...map(staffHeaders, (headerFn) => headerFn(staffItem))];
        }) || [],
    };

    // Create blob
    const content = arrayToCsv([formattedData.headers, ...formattedData.data]);
    const blob = new Blob([content], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);

    // Create a link to download it
    const elementToClick = document.createElement("a");
    elementToClick.href = url;
    elementToClick.setAttribute("download", fileName);
    elementToClick.click();
  }, [staffDetailsDtos, unit, attributesByKey]);
};

function arrayToCsv(data: (string | number | undefined)[][]) {
  return data
    .map(
      (row) =>
        row
          .map(String) // convert every value to String
          .map((val) => val.replaceAll('"', '""')) // escape double quotes
          .map((val) => `"${val}"`) // quote it
          .join(","), // comma-separated
    )
    .join("\r\n"); // rows starting on new lines
}
