import { useMemo } from "react";

import { useAppSelector } from "~/common/hooks/useRedux";

import { User } from "@/api";

export const useIsAdmin = (): boolean => {
  const currentRole = useAppSelector((state) => state.user.currentRole);

  return useMemo(() => {
    return currentRole === User.ERole.admin;
  }, [currentRole]);
};
