// basic colors
export const black = "#141414";
export const white = "#FFFFFF";
export const brandGreen = "#caf036";
export const brandPurple = "#A4ADED";

// functional colors
export const borderGrey = "#C9C9C9";
export const disabledGrey = "#A0A0A0";
export const selectableLiHover = "#f5f5f5";
export const lines = "#EAEAEA";
export const danger = "#B02020";
export const dangerAlt = "#FF4545";
export const warning = "#FFC179";
export const warningText = "#321800";
export const redAlert = "#d32f2f";
export const paleAlert = "#FCF6F6";
export const successMain = "#2E7D32";
export const rosterIconsGrey = "#919599";
export const floatedStaffColor = "#FFB8ED";
export const workingAwayStaffColor = "#FFB8ED";

// shifts colors
export const dayShift = "#FFEBB8";
export const dayShiftDark = "#FFD059";
export const nightShift = "#EBEAFD";
export const nightShiftDark = "#B4B2D3";
export const nightShiftDarkAlt = "#BDBAF5";
export const blockShift = "#FFDBDB";
export const blockShiftDark = "#D79F9F";
export const vacationShift = "#F1FFDB";
export const educationShift = "#C4ECE2";
export const multipleShiftsColor = "#C4ECE2";
export const schoolShift = "#E7B3F4";

// other
export const disabledGray = "#9E9E9E";
export const lightGray = "#F7F7F7";
export const moderateGray = "#e2e3e5";
export const mediumGray = "#D5D8DC";
export const darkGray = "#72767A";
export const darkPurple = "#5B43AC";
export const lightPurple = "#CFC1FF";
export const azureishWhite = "#DAECEB";
export const lightYellow = "#FBDC8C";
export const darkGreen = "#20846C";
export const lavender = "#ECE7FF";
export const mediumGreen = "#D9EFB4";
export const mediumGreenDark = "#B3C891";
export const green = "#CFEDCC";
export const lightGreen = "#E2EDCB";
export const darkOrange = "#F28C28";
export const lightOrange = "#FFDAB8";
export const seaShell = "#F1F1F1";
export const darkRed = "#D85454";
export const lightRed = "#FFDBDB";
export const defaultGrayForShifts = "#E8E8E8";
export const darkBlue = "#33B3FF";

export const lightBlue = "#D6F0FF";

// grade colors
export const bad = "#FFDBDB";
export const poor = "#FFDAB8";
export const equal = "#F7F7F7";
export const satisfactory = "#FFEBB8";
export const good = "#E2EDCB";
export const excellent = "#CFEDCC";

// Staff profile page
export const avatarBackground = "#3f51b5";
export const cardBackground = "#f5f5f5";
export const lineChartPersonalScore = "#8884d8";
export const lineChartUnitAverage = "#82ca9d";
export const lineChartGridLines = "#e0e0e0";

// Color selector component colors
export const selectorBlue = "#c4e7fe";
export const selectorPurple = "#fcdefa";
export const selectorPink = "#facfd4";
export const selectorYellow = "#fcf88f";
export const selectorGreen = "#c9f99c";
export const selectorBrown = "#f0c7af";
