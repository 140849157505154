import React, { useMemo } from "react";

import { Avatar } from "@mui/material";

import { IAvatarProps } from "./types";

export const CustomAvatar = (props: IAvatarProps) => {
  const { email, firstName, lastName, fontSize, height, width, style } = props;

  const avatarText = useMemo(
    () =>
      firstName || lastName
        ? `${firstName?.at(0) || ""}${lastName?.at(0) || ""}`
        : email?.at(0) || "",
    [firstName, lastName, email],
  );

  return (
    <Avatar sx={{ fontSize, height, width, textTransform: "uppercase", ...style }}>
      {avatarText}
    </Avatar>
  );
};
