import { useEffect, useMemo } from "react";

import { TimeString } from "@m7-health/shared-utils";
import { debounce, groupBy, keyBy, keys } from "lodash";

import { Grid, TextField, Typography } from "@mui/material";
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import { TRealTimeStaffingTargetToCreate } from "~/api/realTimeStaffingTargets";
import CustomInput from "~/common/components/Input";
import { useAppDispatch, useAppSelector } from "~/common/hooks/useRedux";
import { houseViewStore } from "~/features/HouseView/store";
import { IUnitBasic } from "~/routes/api/types";

import {
  HVSpecificPositionsTabs,
  TPositionAsTab,
} from "#/features/HouseView/hooks/useStaffingTabs";
import { useAppConfigQuery } from "#/features/User/queries";
import { IStaffShift, StaffCategory } from "@/api";
import { useAppFlags, useCurrentTimezone } from "@/common/hooks";
import { localDayJs } from "@/common/packages/dayjs";
import { darkGreen, darkRed } from "@/common/theming/colors";
import { dateString } from "@/common/types";
import { dateStringInTimeRangeGivenTimezone, timeAdd } from "@/common/utils/dates";
import { isOnMobile } from "@/common/utils/isOnMobile";
import { voidingShiftStatus } from "@/common/utils/shifts";

import { getMatchingStaffingLevel } from "../../helpers";

type TStaffShiftWithStartEndTime = IStaffShift & {
  startTime: TimeString;
  endTime: TimeString;
};

export const StaffingLevelsNewEntryRow = ({
  shiftsByUnit,
  unitStaffingLevel,
}: {
  shiftsByUnit: { [unitId: IUnitBasic["id"]]: IStaffShift[] };
  unitStaffingLevel: TRealTimeStaffingTargetToCreate;
}) => {
  const dispatch = useAppDispatch();
  const { hvPositionAsTab } = useAppFlags();

  const selectedUnitId = useAppSelector((state) => state.houseView.pageFilters.selectedUnitId!);
  const currentTimezone = useCurrentTimezone(selectedUnitId);
  const unitCategories = useAppSelector(
    (state) => state.houseView.staffingLevels.selectedUnitCategories,
  );

  const updateStaffingLevelHandler = debounce((payload: TRealTimeStaffingTargetToCreate) => {
    dispatch(houseViewStore.state.updateStaffingLevel(payload));
  }, 10);

  const { data: config } = useAppConfigQuery();
  const selectedUnitFromConfig = config?.units.find((unit) => unit.id === selectedUnitId);
  const staffingLevelMatrix = selectedUnitFromConfig?.staffingLevelMatrix;
  const unitAttributes = selectedUnitFromConfig?.attributes;

  const shifts = shiftsByUnit[selectedUnitId];

  const stateStaffDetails = useAppSelector((state) => state.houseView.pageData.staffDetails);
  const stateShiftTypes = useAppSelector((state) => state.houseView.pageData.shiftTypes);

  const groupedShifts = useMemo(() => {
    const liveShifts: TStaffShiftWithStartEndTime[] = [];
    shifts?.forEach((shift) => {
      const shiftStaffDetails = stateStaffDetails[shift.staffId];
      const shiftType = stateShiftTypes[shift.scheduleId]?.[shift.shiftTypeKey];
      const category = shiftStaffDetails?.staffType?.staffCategory?.key;
      if (!category || !shiftType) return;
      const shiftStatus = voidingShiftStatus(shift);

      const { startTime: defaultStartTime, durationSeconds: defaultDuration } = shiftType;

      const startTime: TimeString = (shift.customStartTime || defaultStartTime) as TimeString;
      const shiftDuration = shift.customDuration || defaultDuration;
      const endTime: TimeString | null =
        startTime && shiftDuration ? timeAdd(startTime, shiftDuration) : null;

      if (startTime && endTime && !shiftStatus && shiftType?.isCountedForRealTimeStaffingTarget) {
        liveShifts.push({ ...shift, startTime, endTime });
      }
    });

    const indexedUnitAttributes = keyBy(unitAttributes, "key");
    return groupBy(liveShifts, ({ staffId, attributes }) => {
      if (hvPositionAsTab) {
        for (const attribute of attributes || []) {
          const unitAttribute = indexedUnitAttributes[attribute];
          const attributeName = unitAttribute?.name.toLocaleLowerCase() as TPositionAsTab;
          if (HVSpecificPositionsTabs[attributeName]?.countInTarget === false) {
            return attributeName;
          }
        }
      }

      return stateStaffDetails[staffId]?.staffType.staffCategoryKey;
    });
  }, [shifts, unitAttributes, stateStaffDetails, stateShiftTypes, hvPositionAsTab]);

  keys(groupedShifts).forEach((categoryKey) => {
    groupedShifts[categoryKey] = groupedShifts[categoryKey]!.filter(
      ({ scheduleId, shiftTypeKey }) =>
        stateShiftTypes[scheduleId]?.[shiftTypeKey]?.isCountedForRealTimeStaffingTarget,
    );
  });
  type TPosition = TPositionAsTab;
  const unitCategoriesAndAttributes = useMemo(() => {
    const attributesAsCategories: { key: TPosition; id: TPosition }[] = [];
    if (hvPositionAsTab) {
      unitAttributes?.forEach((attribute) => {
        const attributeName = attribute.name.toLocaleLowerCase() as TPosition;
        // If attribute doesn't count toward "regular" target, then separate target
        if (HVSpecificPositionsTabs[attributeName]?.countInTarget === false) {
          attributesAsCategories.push({ key: attributeName, id: attributeName });
        }
      });
    }

    // combine all the unit categories AND the attributes we want targets for
    return [...(unitCategories || []), ...attributesAsCategories] as {
      key: TPosition | StaffCategory.EKey;
      id: TPosition | StaffCategory.EKey;
    }[];
  }, [hvPositionAsTab, unitAttributes, unitCategories]);
  const countsByCategoryAndAttribute: Partial<{ [key in StaffCategory.EKey | TPosition]: number }> =
    {};

  // update staffing level with the current count of shifts
  // this useEffect will run when all the counts are updated
  useEffect(() => {
    // if all counts are updated, update the staffing level with the current counts
    // AND if staffing level is not set or length is 0, then set it
    if (
      Object.keys(countsByCategoryAndAttribute).length === unitCategoriesAndAttributes.length &&
      (!unitStaffingLevel.staffingTarget ||
        Object.keys(unitStaffingLevel.staffingTarget).length === 0)
    ) {
      updateStaffingLevelHandler({
        ...unitStaffingLevel,
        staffingTarget: countsByCategoryAndAttribute,
      });
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [
    JSON.stringify(countsByCategoryAndAttribute),
    JSON.stringify(unitCategoriesAndAttributes),
    updateStaffingLevelHandler,
    JSON.stringify(unitStaffingLevel),
  ]);
  /* eslint-enable react-hooks/exhaustive-deps */

  // set the countsByCategoryAndAttribute to be used on render
  unitCategoriesAndAttributes?.forEach((categoryOrPosition) => {
    const countOfShiftsAtTime =
      groupedShifts[categoryOrPosition.key]?.filter((shift) => {
        return dateStringInTimeRangeGivenTimezone(shift, unitStaffingLevel.date, currentTimezone);
      })?.length || 0;
    // when page loads all counts, update staffing levels with the current count
    countsByCategoryAndAttribute[categoryOrPosition.key] = countOfShiftsAtTime;
  });

  return (
    <tr key={"row-new-entry"} className="new-entry">
      <td key={`time-new-entry`}>
        <Grid
          container
          minWidth={!isOnMobile() ? "0vw" : "24vw"}
          maxWidth={!isOnMobile() ? "10vw" : "40vw"}
        >
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <TimePicker
              timezone={currentTimezone}
              views={["hours", "minutes"]}
              value={localDayJs(unitStaffingLevel.date)}
              onChange={(date) => {
                if (date) {
                  updateStaffingLevelHandler({
                    ...unitStaffingLevel,
                    date: date.toISOString() as dateString,
                  });
                }
              }}
              slotProps={{
                textField: {
                  // smaller box
                  size: "small",
                  // smaller text
                  InputProps: {
                    style: { fontSize: "1rem" },
                  },
                },
              }}
            />
          </LocalizationProvider>
        </Grid>
      </td>
      <td key={`patient count-new-entry`}>
        <Grid
          container
          minWidth={!isOnMobile() ? "0vw" : "22vw"}
          maxWidth={!isOnMobile() ? "10vw" : "40vw"}
        >
          <CustomInput
            label=""
            name="cell patient count"
            type="number"
            counter
            onChange={(event) => {
              updateStaffingLevelHandler({
                ...unitStaffingLevel,
                patientCount: Number(event.target.value),
              });
            }}
            field={{
              value: unitStaffingLevel.patientCount,
              onChange: (newValue: string) =>
                updateStaffingLevelHandler({
                  ...unitStaffingLevel,
                  patientCount: Number(newValue),
                }),
            }}
            dontShowNegative={true}
            sx={(theme) => ({
              ...(isOnMobile() ? {} : { ml: "10%", width: "80%" }),
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor:
                    Number(unitStaffingLevel.patientCount) < 0 ? "red" : theme.palette.primary.main,
                },
                "&:hover fieldset": {
                  borderColor:
                    Number(unitStaffingLevel.patientCount) < 0 ? "red" : theme.palette.primary.main,
                },
                "&.Mui-focused fieldset": {
                  borderColor:
                    Number(unitStaffingLevel.patientCount) < 0 ? "red" : theme.palette.primary.main,
                },
              },
            })}
          />
        </Grid>
      </td>
      {unitCategoriesAndAttributes?.map((categoryOrPosition) => {
        const matchingStaffingLevel = getMatchingStaffingLevel(
          categoryOrPosition.key,
          unitStaffingLevel,
          staffingLevelMatrix,
          currentTimezone,
        );
        const countOfShiftsAtTime = countsByCategoryAndAttribute[categoryOrPosition.key] || 0;

        return (
          <td key={`${categoryOrPosition.key}-new-entry`}>
            <Typography align="center">
              {countOfShiftsAtTime}
              {matchingStaffingLevel && (
                <Typography
                  display={"inline"}
                  color={
                    countOfShiftsAtTime >= matchingStaffingLevel.staffingLevel ? darkGreen : darkRed
                  }
                >
                  {" "}
                  / {matchingStaffingLevel.staffingLevel}
                </Typography>
              )}
            </Typography>
          </td>
        );
      })}
      <td key={`note-new-entry`} className="entry-note">
        <Grid minWidth={"200px"} p={1} container>
          <TextField
            variant="standard"
            placeholder="Enter note here..."
            InputProps={{
              multiline: true,
              rows: 2,
              disableUnderline: true,
            }}
            size="small"
            onChange={(e) => {
              updateStaffingLevelHandler({
                ...unitStaffingLevel,
                note: e.target.value,
              });
            }}
            defaultValue={unitStaffingLevel?.note || ""}
            fullWidth
          />
        </Grid>
      </td>
    </tr>
  );
};
