import { useEffect } from "react";

import { isEqual } from "lodash";

import { useAppDispatch, useAppSelector } from "~/common/hooks/useRedux";
import { setCurrentRole } from "~/features/User/store";

import { User } from "@/api";

/**
 * Defines the priority order for default roles upon login.
 * Lower numbers indicate higher priority.
 *
 * @type {Record<User.ERole, number>}
 */
const defaultRoleOnLogInPriority: Record<User.ERole, number> = {
  [User.ERole.admin]: 0,
  [User.ERole.scheduler]: 1,
  [User.ERole.staff]: 2,
  [User.ERole.kiosk]: 3,
};

export const useSetCurrentRole = () => {
  const dispatch = useAppDispatch();
  const { currentRole, roles } = useAppSelector(
    (state) => ({
      currentRole: state.user.currentRole,
      roles: state.user.userData.roles,
    }),
    isEqual,
  );

  useEffect(() => {
    const sortedRoles = [...roles].sort(
      (aRole, bRole) => defaultRoleOnLogInPriority[aRole] - defaultRoleOnLogInPriority[bRole],
    );
    // if don't yet have a current role, set the correct role in order of priority
    // else if the current role is not in the list of roles, set the correct role in order of priority (eg if someone is removed from a role)
    if (!currentRole) {
      dispatch(setCurrentRole(sortedRoles[0]));
    } else if (!roles.includes(currentRole)) {
      dispatch(setCurrentRole(sortedRoles[0]));
    }
  }, [roles, currentRole, dispatch]);
};
