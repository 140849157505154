import Captions from "~/common/components/Captions";
import { ECaptionsVariant } from "~/common/components/Captions/types";

const data = [
  { variant: ECaptionsVariant.Bad, text: "Below required" },
  { variant: ECaptionsVariant.Satisfactory, text: "Above required" },
  { variant: ECaptionsVariant.Excellent, text: "Equal to required" },
];

export const CaptionsForWeekendShifts = () => <Captions data={data} />;
