import { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";

import { capitalize, compact, intersection, isEqual, kebabCase } from "lodash";

import { Add, Close, KeyboardArrowDown, Menu, ScreenRotation } from "@mui/icons-material";
import {
  AppBar,
  Badge,
  Box,
  createFilterOptions,
  Grid,
  Menu as NavMenu,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";

import CustomButton from "~/common/components/TrackedComponents/Button";
import { useAppDispatch, useAppSelector } from "~/common/hooks/useRedux";
import { setSelectedUnit, setUnitModal } from "~/common/store";
import { darkGray, lines, white } from "~/common/theming/colors";
import { isOnMobile } from "~/common/utils/isOnMobile";
import { HouseView } from "~/features/HouseView/components";
import { MixpanelProvider } from "~/modules/mixpanel/Provider";
import { Mxp } from "~/modules/mixpanel/types";
import { IMainRoute, routes } from "~/routes";
import { IUnitCustomSelect } from "~/routes/api/types";
import AvatarMenu from "~/routes/components/Header/AvatarMenu";
import { useUnitQuery } from "~/routes/queries";

import { schedulePermissions } from "#/features/SchedulerGrid/constants";
import { useScheduleUnsavedChanges } from "#/features/SchedulerGrid/hooks/useScheduleUnsavedChanges";
import { setCurrentRole } from "#/features/User/store";
import { useGetNotificationsCountQuery, useInvalidateQuery } from "@/api";
import { Autocomplete, Link, MenuItem } from "@/common/components";
import {
  useAppFlags,
  useCheckUserPermissions,
  useCurrentUnitId,
  useIsAdmin,
  useIsKiosk,
  useIsScheduler,
} from "@/common/hooks";
import { useResetAllStates } from "@/common/utils/resetAllStates";

import { defaultRoleRoute } from "../ProtectedRoute/constants";

import { useSetFallbackUnit } from "./hooks/useSetFallbackUnit";
import { PageRefresher } from "./PageRefresher";

import "./scheduler-headers.scss";

type TMainRouteWithDynamicData = IMainRoute & {
  dynamicData?: {
    badgeCount?: number;
    subPages?: { id: string; path: string; name: string }[];
  };
};

const emptyUnits = [] as IUnitCustomSelect[];

/** Given a list of subPages, return a list of routes with the subPages as children.
 * If there is only one subPage, return the subPage as a main route.
 */
const possibleSubPages = (
  subPages: (TMainRouteWithDynamicData[] | null | undefined)[],
  parentRoute: Pick<IMainRoute, "id" | "name" | "path">,
) => {
  const subRoutes = compact(subPages).flat(1);
  if (subRoutes.length === 0) return [];
  if (subRoutes.length === 1) return subRoutes;

  return [
    {
      ...parentRoute,
      dynamicData: { subPages: subRoutes },
    },
  ];
};

export const SchedulerHeader = () => {
  const { holidaySchedule, showShiftReports, clientFacingUnitConfig } = useAppFlags();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const isAdmin = useIsAdmin();
  const isKiosk = useIsKiosk();
  const isScheduler = useIsScheduler();
  const currentUnitId = useCurrentUnitId();
  const invalidateQuery = useInvalidateQuery();
  const resetAllState = useResetAllStates();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [appExperienceAnchorEl, setAppExperienceAnchorEl] = useState<null | HTMLElement>(null);
  const subPageMenuIsOpen = Boolean(anchorEl);
  const appExperienceMenuIsOpen = Boolean(appExperienceAnchorEl);
  const { userRoles, currentRole } = useAppSelector(
    (state) => ({
      userRoles: state.user.userData.roles,
      currentRole: state.user.currentRole,
    }),
    isEqual,
  );
  const userCan = useCheckUserPermissions();

  useSetFallbackUnit();
  const userCanChangeCurrentRole = userRoles.length > 1;

  const { data: notificationsCounts } = useGetNotificationsCountQuery(
    { version: "2" },
    { skip: !currentUnitId || isKiosk },
  );

  const checkPermissionForSection = useMemo(() => {
    const cache: Record<string, TMainRouteWithDynamicData[]> = {};

    return (route?: TMainRouteWithDynamicData) => {
      if (!route) return [];

      const key = (route?.id || "") + (route?.name || "") + JSON.stringify(route?.dynamicData);
      const cachedValue = cache[key];
      if (cachedValue) return cachedValue;

      const result = userCan("read", route?.permissions) ? [route] : [];
      cache[key] = result;

      return result;
    };
  }, [userCan]);

  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const showFacilityPicker =
    location.pathname.endsWith("house-view") ||
    location.pathname.includes("/roster/scheduler") ||
    location.pathname.includes("/shift-reports");

  const scheduleWithMaybeHoliday = checkPermissionForSection({
    ...(routes.schedule as IMainRoute),
    ...(holidaySchedule
      ? {
          name: "Schedules",
          dynamicData: {
            subPages: [{ ...routes.schedule, name: "Full Schedules" }, routes.holidaySchedule],
          },
        }
      : {}),
  });
  const fairness = checkPermissionForSection(routes.fairness);
  const shiftReports = showShiftReports ? checkPermissionForSection(routes.shiftReports) : null;
  const staffRoster = checkPermissionForSection(routes.staffRosterUnit);
  const schedulerRoster = checkPermissionForSection(routes.schedulerRoster);
  const SCHEDULER_PAGES = useMemo(() => {
    const reportsRoute = possibleSubPages([shiftReports, fairness], {
      id: "6.1",
      name: "Reports",
      path: "/reports",
    });
    const rosterRoute = possibleSubPages([staffRoster, schedulerRoster], {
      id: "16.1",
      name: "Roster",
      path: "/roster",
    });
    let settingsRoute = possibleSubPages(
      [
        checkPermissionForSection(routes.adminPanelUnit),
        checkPermissionForSection(routes.adminPanelCxDashboard),
      ],
      {
        id: "15.1",
        name: "Admin Panel",
        path: "/settings",
      },
    );
    if (!clientFacingUnitConfig && !isAdmin) settingsRoute = [];

    return [
      ...checkPermissionForSection(routes.dailyReports),
      ...rosterRoute,
      ...scheduleWithMaybeHoliday,
      ...checkPermissionForSection({
        ...(routes.shiftSwapRequest as IMainRoute),
        name: "Approvals",
        dynamicData: {
          badgeCount: notificationsCounts?.approvalRequestCount,
        },
      }),
      ...checkPermissionForSection({
        ...(routes.openShifts as IMainRoute),
        dynamicData: {
          badgeCount: notificationsCounts?.OPSRequestCount,
        },
      }),
      ...checkPermissionForSection(routes.houseView),
      ...reportsRoute,
      ...settingsRoute,
    ] as TMainRouteWithDynamicData[];
  }, [
    shiftReports,
    fairness,
    staffRoster,
    schedulerRoster,
    checkPermissionForSection,
    clientFacingUnitConfig,
    isAdmin,
    scheduleWithMaybeHoliday,
    notificationsCounts?.approvalRequestCount,
    notificationsCounts?.OPSRequestCount,
  ]);

  const { handleUnsavedChanges } = useScheduleUnsavedChanges();

  const selectedUnit = useAppSelector((state) => state.common.selectedUnit);

  const { data: units = emptyUnits } = useUnitQuery();
  const schedulePath = routes.schedule?.path;
  const houseViewPath = routes.houseView?.path;
  // Default landing path is house view for mobile non-kiosk users and schedule for others
  // for general default landing page of app: look at defaultRoleRoute from ProtectedRoute/constants
  // used in frontend/src/routes/components/ProtectedRoute/ProtectedRoute.tsx
  const defaultLandingPath = !isKiosk && isOnMobile() ? houseViewPath : schedulePath;

  // On unit change, invalidate notifications count query
  useEffect(() => {
    invalidateQuery(useGetNotificationsCountQuery);
  }, [currentUnitId, invalidateQuery]);

  // On first render, preselect first unit, and navigate to landing page
  useEffect(() => {
    if (!selectedUnit && units[0]) {
      dispatch(setSelectedUnit(units[0]));
      defaultLandingPath && navigate(defaultLandingPath);
    }
  }, [selectedUnit, units, dispatch, navigate, defaultLandingPath]);

  const selectUnit = useCallback(
    (newSelectedUnit: IUnitCustomSelect | null, defaultNavigateToSchedule = true) => {
      resetAllState();
      dispatch(setSelectedUnit(newSelectedUnit));
      if (!defaultNavigateToSchedule) return;

      defaultLandingPath && navigate(defaultLandingPath);
    },
    [dispatch, navigate, resetAllState, defaultLandingPath],
  );
  const navigateTo = useCallback(
    (path: string) => () => {
      setMobileMenuOpen(false);
      navigate(path);
      setAnchorEl(null);
    },
    [navigate],
  );

  const hasUnitCreationPermission =
    intersection(userRoles, schedulePermissions.addSchedule)?.length > 0;

  //Only show schedule page for kiosk users
  const pagesToDisplay = useMemo(
    () => (isScheduler || isAdmin ? SCHEDULER_PAGES : scheduleWithMaybeHoliday),
    [isScheduler, isAdmin, SCHEDULER_PAGES, scheduleWithMaybeHoliday],
  );

  return (
    <MixpanelProvider properties={{ [Mxp.Property.layout.section]: "scheduler-headers" }}>
      <AppBar
        className={`scheduler-header-wrapper mobile-menu-${mobileMenuOpen ? "open" : "closed"}`}
        elevation={0}
        position="sticky"
        sx={{
          backgroundColor: white,
          borderBottomColor: lines,
          borderBottomWidth: "1px",
          borderBottomStyle: "solid",
          display: "flex",
          height: "5.5rem",
          padding: "0 3rem",
        }}
      >
        {isOnMobile() && (
          <Box className="banner-mobile-mode switch-to-landscape">
            <Typography>
              <ScreenRotation /> We are working hard to make a mobile friendly version of this page
              🤖 <br />
              In the meantime, <b style={{ padding: "0 3px" }}>landscape mode </b> is recommended.
            </Typography>
          </Box>
        )}
        {isOnMobile() && (
          <Box className="banner-mobile-mode switch-to-portrait">
            <Typography>
              <ScreenRotation /> New mobile view is available for this page ✨{" "}
              <b style={{ padding: "0 3px" }}>Portrait mode </b> is recommended.
            </Typography>
          </Box>
        )}
        <CustomButton
          className="toggle-menu-button open"
          trackingLabel="menu"
          iconOnly
          startIcon={<Menu />}
          onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
        />
        <CustomButton
          className="toggle-menu-button close"
          trackingLabel="menu"
          iconOnly
          startIcon={<Close />}
          onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
        />

        <Toolbar disableGutters sx={{ height: "100%" }} className="scheduler-headers">
          <Box display="flex" justifyContent="space-between" flex="1">
            <Box display="flex" alignItems="center" className="main-items">
              <Box display="flex" gap="2rem" alignItems="center" className="logo-and-picker">
                {schedulePath && (
                  <Grid
                    sx={{ display: "flex" }}
                    alignItems={"center"}
                    flexDirection={"row"}
                    flexWrap={"nowrap"}
                    className="logo-link"
                  >
                    <Link
                      trackingLabel={"app-logo"}
                      component={NavLink}
                      to={schedulePath}
                      onClick={(event) =>
                        handleUnsavedChanges({
                          onActionPrevented: () => {
                            event.preventDefault();
                          },
                          onActionSuccess: navigateTo(schedulePath),
                        })
                      }
                    >
                      <img alt="App logo" src="/M7_logo.svg" width="44px" />
                    </Link>
                    {userCanChangeCurrentRole && (
                      <CustomButton
                        iconOnly
                        startIcon={<KeyboardArrowDown />}
                        trackingLabel="app-experince-toggle"
                        onClick={(event) => setAppExperienceAnchorEl(event.currentTarget)}
                      />
                    )}
                  </Grid>
                )}

                {showFacilityPicker ? (
                  <HouseView.FacilityPicker />
                ) : (
                  selectedUnit && (
                    <Autocomplete
                      trackingLabel="unit-picker"
                      options={[...units, ...(isAdmin ? [null] : [])]}
                      renderInput={(params) => <TextField {...params} label="Select Unit" />}
                      getOptionLabel={(option) => option?.name || ""}
                      onChange={(_, unit) => {
                        if (!unit) return;
                        handleUnsavedChanges({
                          onActionSuccess: () => {
                            selectUnit(unit);
                          },
                        });
                      }}
                      blurOnSelect
                      disableClearable
                      filterOptions={(options, state) => {
                        const results = createFilterOptions<IUnitCustomSelect | null>()(
                          options,
                          state,
                        );
                        if (hasUnitCreationPermission && !results.includes(null)) {
                          results.push(null);
                        }
                        return results;
                      }}
                      renderOption={(props, option) => {
                        if (!option && hasUnitCreationPermission) {
                          return (
                            <li {...props} onClick={() => dispatch(setUnitModal("create"))}>
                              <Add sx={{ color: darkGray }} />
                              <Typography ml={1} variant="body1">
                                New Unit
                              </Typography>
                            </li>
                          );
                        } else if (option) {
                          return <li {...props}>{option.name}</li>;
                        }
                      }}
                      value={selectedUnit}
                      sx={{
                        ".MuiFormLabel-root": { top: "-8px" },
                        ".MuiInputBase-root": { padding: "0 10px" },
                        "@media (max-width: 960px)": {
                          width: "10vw",
                        },
                        "@media (min-width: 961px)": {
                          width: "200px",
                        },
                      }}
                    />
                  )
                )}
              </Box>
              <Box display="flex" gap="1.5rem" alignItems="center" className="menu-items">
                {pagesToDisplay.map(({ id, name, path, dynamicData }) => (
                  <Fragment key={id}>
                    {dynamicData?.subPages && dynamicData.subPages.length > 1 ? (
                      <>
                        <Link
                          // Because item with sub pages doesn't link to anywhere
                          trackingLabel={null}
                          className={"menu-item-link " + kebabCase(name)}
                          component={"button"}
                          onClick={(event) => setAnchorEl(event.currentTarget)}
                          key={id}
                          title={name}
                          sx={{
                            ...(location.pathname.includes(path)
                              ? {
                                  color: "primary.main",
                                  fontWeight: 500,
                                }
                              : {}),
                          }}
                        >
                          <Grid container flexWrap="nowrap" sx={{ alignItems: "center" }}>
                            {name}
                            <KeyboardArrowDown sx={{ mt: 0.2, ml: 0.5 }} />
                          </Grid>
                        </Link>
                      </>
                    ) : (
                      <Link
                        trackingLabel={name}
                        className={"menu-item-link " + kebabCase(name)}
                        component={NavLink}
                        onClick={(event) =>
                          handleUnsavedChanges({
                            onActionPrevented: () => {
                              event.preventDefault();
                            },
                            onActionSuccess: navigateTo(dynamicData?.subPages?.[0]?.path || path),
                          })
                        }
                        key={id}
                        sx={{
                          "&.active": {
                            color: "primary.main",
                            fontWeight: 500,
                          },
                        }}
                        to={dynamicData?.subPages?.[0]?.path || path}
                      >
                        {(dynamicData?.badgeCount && (
                          <Badge
                            // overlap on text when not used on icons
                            //  so we offset it a bit to the left
                            sx={{ ".MuiBadge-badge": { right: "-8px" } }}
                            badgeContent={dynamicData.badgeCount}
                            color="error"
                          >
                            {name}
                          </Badge>
                        )) ||
                          name}
                      </Link>
                    )}
                  </Fragment>
                ))}
              </Box>
            </Box>
            <Box flexGrow={1} />
            <Box className="help-and-avatar-container">
              <AvatarMenu />
              <PageRefresher />
            </Box>
          </Box>
          <NavMenu
            className="sub-page-menu"
            elevation={0}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={subPageMenuIsOpen}
            anchorEl={anchorEl}
            onClose={() => setAnchorEl(null)}
          >
            {pagesToDisplay
              .find(({ name }) => name === anchorEl?.title)
              ?.dynamicData?.subPages?.map(({ name, path, id }) => (
                // Tracked by the child link
                <MenuItem key={id} sx={{ p: 0 }} trackingLabel={null}>
                  <Link
                    trackingLabel={name}
                    className={"menu-item-link " + kebabCase(name)}
                    component={NavLink}
                    sx={{
                      width: "100%",
                      p: 1,
                      "&.active": {
                        color: "primary.main",
                        fontWeight: 500,
                      },
                    }}
                    to={path}
                    onClick={() => setAnchorEl(null)}
                  >
                    {name}
                  </Link>
                </MenuItem>
              ))}
          </NavMenu>
          <NavMenu
            elevation={0}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={appExperienceMenuIsOpen}
            anchorEl={appExperienceAnchorEl}
            onClose={() => setAppExperienceAnchorEl(null)}
          >
            {userRoles.map((role, i) => (
              <MenuItem
                trackingLabel={role}
                selected={role === currentRole}
                onClick={() => {
                  dispatch(setCurrentRole(role));
                  navigateTo(defaultRoleRoute(role));
                  setAppExperienceAnchorEl(null);
                }}
                sx={{ p: 1 }}
                key={i}
              >
                {capitalize(role)}
              </MenuItem>
            ))}
          </NavMenu>
        </Toolbar>
      </AppBar>
    </MixpanelProvider>
  );
};
