import { ISODateString, TimeString, Timezone } from "@m7-health/shared-utils";
import { PayloadAction } from "@reduxjs/toolkit";

import {
  RealTimeStaffingTarget,
  TRealTimeStaffingTargetToCreate,
  TRealTimeStaffingTargetToDelete,
  TRealTimeStaffingTargetToUpdate,
} from "~/api/realTimeStaffingTargets";

import { StaffCategory, Unit } from "@/api";
import { localDayJs } from "@/common/packages/dayjs";
import { dateStringInTimeRangeGivenTimezone, getTzDayjs } from "@/common/utils/dates";

import { TUpdatedStaffingLevel } from "../components/modals/StaffingLevelModal/helpers";

import { THouseViewState } from ".";

export type StaffingLevels = {
  modalIsOpen?: boolean;
  // contains all staffing targets for all units
  // every unit can have a MAXIMUM OF ONE target being created at a time (so one target of type TRealTimeStaffingTargetToCreate)
  // and multiple targets being updated or deleted at a time
  // see updateStaffingLevel action below for more details on how this works
  allStaffingTargets: {
    [unitId: string]: (
      | TRealTimeStaffingTargetToCreate
      | TRealTimeStaffingTargetToUpdate
      | TRealTimeStaffingTargetToDelete
    )[];
  };
  selectedUnitCategories?: Pick<StaffCategory.DTO, "key" | "name">[];

  /** @deprecated use selectedUnitId from pageFilters,
   * staffingTargetsUnit selected will be same as that of page */
  selectedUnitId?: Unit.DTO["id"];
  /** @deprecated use allStaffingTargets */
  editedStaffingTargets: {
    [unitId: string]: Partial<RealTimeStaffingTarget.DTO> | undefined;
  };
};

export const StaffingLevelActions = {
  setSelectedUnitId: (
    { staffingLevels }: THouseViewState,
    action: PayloadAction<StaffingLevels["selectedUnitId"]>,
  ) => {
    staffingLevels.selectedUnitId = action.payload;
  },
  setStaffingLevelModalIsOpen: (
    { staffingLevels }: THouseViewState,
    action: PayloadAction<boolean>,
  ) => {
    staffingLevels.modalIsOpen = action.payload;
  },
  setAllStaffingTargets: (
    { staffingLevels }: THouseViewState,
    action: PayloadAction<StaffingLevels["allStaffingTargets"]>,
  ) => {
    staffingLevels.allStaffingTargets = action.payload;
  },
  /**
   * Updates the staffing level for a specific unit in the HouseView state.
   *
   * @param {Object} param0 - The current state object containing staffingLevels.
   * @param {Object} param0.staffingLevels - The staffing levels state object.
   * @param {Object} action - The Redux action object.
   * @param {TRealTimeStaffingTargetToCreate | TRealTimeStaffingTargetToUpdate | TRealTimeStaffingTargetToDelete} action.payload - The payload containing the staffing target data.
   *
   * @description
   * This function handles three types of staffing level updates:
   * 1. Creating a new staffing target (every unit can have one new staffing target being created at a time, so it either already is in the state or not)
   * 2. Updating an existing staffing target
   * 3. Deleting an existing staffing target
   *
   * The function first extracts the unitId from the payload and retrieves the current targets for that unit.
   * It then determines whether to update an existing target or create a new one based on the presence of an 'id' in the payload.
   *
   * For existing targets (with 'id'):
   * - It maps through the current targets and updates the matching target.
   * - This handles both updates and deletions (deletion payload should have a special flag or empty data).
   *
   * For new targets (without 'id'):
   * - It checks if there's an existing target being created (without an 'id') (since every unit only has ONE target being created).
   * - If found, it updates this target; otherwise, it adds a new target to the list.
   *
   * Finally, it updates the allStaffingTargets in the state with the new list of targets for the specific unit.
   *
   * @example
   * // Updating an existing target
   * updateStaffingLevel(state, {
   *   payload: { id: '123', unitId: 'unit1', staffCount: 5 }
   * });
   *
   * // Updating a target to be created
   * updateStaffingLevel(state, {
   *   payload: { unitId: 'unit1', staffCount: 3 }
   * });
   *
   * // Updating a target to be deleted (assuming deletion is handled by sending an empty object with id)
   * updateStaffingLevel(state, {
   *   payload: { id: '123', unitId: 'unit1' }
   * });
   */
  updateStaffingLevel: (
    { staffingLevels }: THouseViewState,
    action: PayloadAction<
      | TRealTimeStaffingTargetToCreate
      | TRealTimeStaffingTargetToUpdate
      | TRealTimeStaffingTargetToDelete
    >,
  ) => {
    const { unitId, ...rest } = action.payload;
    const currentUnitTargets = staffingLevels.allStaffingTargets[unitId] || [];

    let updatedUnitTargets;
    // IF DEALING WITH EXISTING TARGETS (ToUpdate or ToDelete)...
    // ELSE IF DEALING WITH NEW TARGETS (ToCreate)...
    if ("id" in action.payload) {
      // Updating or deleting existing target (if being deleted, the payload)
      updatedUnitTargets = currentUnitTargets.map((target) =>
        "id" in target && "id" in action.payload && target.id === action.payload.id
          ? { ...target, ...rest }
          : target,
      );
    } else {
      // Updating the target that is being created if it exists (the one without an id in it), else creating it
      const existingCreatedTarget = currentUnitTargets.find((target) => !("id" in target));
      if (existingCreatedTarget) {
        updatedUnitTargets = currentUnitTargets.map((target) =>
          target === existingCreatedTarget ? { ...target, ...rest } : target,
        );
      } else {
        updatedUnitTargets = [...currentUnitTargets, action.payload];
      }
    }

    staffingLevels.allStaffingTargets = {
      ...staffingLevels.allStaffingTargets,
      [unitId]: updatedUnitTargets,
    };
  },
  /**
   * Creates a new staffing level for a specific unit in the HouseView state.
   *
   * @param {Object} param0 - The current state object containing staffingLevels and pageFilters.
   * @param {Object} param0.staffingLevels - The staffing levels state object.
   * @param {Object} param0.pageFilters - The page filters state object.
   * @param {Object} action - The Redux action object.
   * @param {Uuid} action.payload - The unitId for which to create a new staffing level.
   *
   * @description
   * This function creates a new staffing level entry for the specified unit. It uses the following logic:
   *
   * 1. Date: Uses the selectedDate from pageFilters.
   * 2. Time:
   *    - If the current time is within the selected custom time range, it uses the current time.
   *    - Otherwise, it uses the start time of the selected custom time range.
   * 3. Staffing Target: Initialized as an empty object.
   * 4. Patient Count:
   *    - If there are existing targets for the unit, it uses the patient count from the first target.
   *    - If no existing targets, it defaults to -1.
   *
   * The new staffing level is added to the existing array of staffing targets for the unit,
   * or creates a new array if none exists.
   *
   * @example
   * createStaffingLevel(state);
   */
  createStaffingLevel: (
    { staffingLevels, pageFilters }: THouseViewState,
    { payload: timezone }: PayloadAction<Timezone>,
  ) => {
    const selectedCustomTimeRange = pageFilters.customTimeRange!;
    const selectedDate = pageFilters.selectedDateForData!;
    const unitId = pageFilters.selectedUnitId!;

    // Create a dayjs object for the selected date in the unit's timezone
    const selectedDateInUnitTz = getTzDayjs(selectedDate, timezone);

    // Get the current time in the unit's timezone
    const currentDateTimeInUnitTz = getTzDayjs(localDayJs(), timezone);
    const currentTimeInUnitTz = currentDateTimeInUnitTz.format("HH:mm:ss") as TimeString;

    // Check if the current time is within the selected time range
    const isWithinRange = dateStringInTimeRangeGivenTimezone(
      selectedCustomTimeRange,
      currentDateTimeInUnitTz.toISOString() as ISODateString,
      timezone,
    );

    // Use current time if within range, otherwise use selected start time
    let dateTimeToUse;
    if (isWithinRange) {
      const [hours, minutes, seconds]: [number, number, number] = currentTimeInUnitTz
        .split(":")
        .map(Number) as [number, number, number];
      dateTimeToUse = selectedDateInUnitTz.hour(hours).minute(minutes).second(seconds);
    } else {
      const [hours, minutes, seconds]: [number, number, number] = selectedCustomTimeRange.startTime
        .split(":")
        .map(Number) as [number, number, number];
      dateTimeToUse = selectedDateInUnitTz.hour(hours).minute(minutes).second(seconds);
    }

    // Convert the final date and time to UTC
    const date = dateTimeToUse.utc().toISOString() as ISODateString;

    const existingTargetsOfUnit = staffingLevels.allStaffingTargets[unitId];

    staffingLevels.allStaffingTargets = {
      ...staffingLevels.allStaffingTargets,
      [unitId]: [
        ...(staffingLevels.allStaffingTargets[unitId] || []),
        {
          unitId,
          staffingTarget: {},
          patientCount: existingTargetsOfUnit?.[0]?.patientCount || -1,
          date,
        },
      ],
    };
  },
  setSelectedUnitCategories: (
    { staffingLevels }: THouseViewState,
    action: PayloadAction<Pick<StaffCategory.DTO, "key" | "name">[]>,
  ) => {
    staffingLevels.selectedUnitCategories = action.payload;
  },
  /** @deprecated, uses editedStaffingTargets */
  setEditedStaffingTargets: (
    { staffingLevels }: THouseViewState,
    action: PayloadAction<StaffingLevels["editedStaffingTargets"]>,
  ) => {
    staffingLevels.editedStaffingTargets = action.payload;
  },
  /** @deprecated, uses editedStaffingTargets */
  updateStaffingLevelDeprecated: (
    { staffingLevels }: THouseViewState,
    action: PayloadAction<TUpdatedStaffingLevel>,
  ) => {
    const { unitId, ...rest } = action.payload;
    staffingLevels.editedStaffingTargets = {
      ...staffingLevels.editedStaffingTargets,
      [unitId || ""]: {
        ...staffingLevels.editedStaffingTargets[unitId || ""],
        ...rest,
        unitId: unitId || "",
      },
    };
  },
};
