import { createSlice } from "@reduxjs/toolkit";

import { EStatisticsTypes, IStatisticsState } from "../types";

import type { PayloadAction } from "@reduxjs/toolkit";

const initialState: IStatisticsState = {
  schedulesCount: EStatisticsTypes.Current,
};

export const statisticsSlice = createSlice({
  name: "statistics",
  initialState,
  reducers: {
    setSchedulesCount: (state, action: PayloadAction<EStatisticsTypes>) => {
      state.schedulesCount = action.payload;
    },
  },
});

export const { setSchedulesCount } = statisticsSlice.actions;
export default statisticsSlice.reducer;
