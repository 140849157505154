import { PayloadAction } from "@reduxjs/toolkit";

import { SidebarCurrentAction } from "./sidebarActions";

import { THouseViewState, initialState } from ".";

export type AssignAttributes = Record<string, never>;

export const AssignAttributesActions = {
  startAssignAttributes: ({ sidebarCurrentAction }: THouseViewState) => {
    sidebarCurrentAction.inProgress = "assignAttributes";
  },
  endAssignAttributes: (state: THouseViewState) => {
    state.assignAttributes = initialState.assignAttributes;
    state.sidebarCurrentAction = initialState.sidebarCurrentAction;
  },

  setAssignAttributesDirtiness: (
    { sidebarCurrentAction }: THouseViewState,
    action: PayloadAction<SidebarCurrentAction["isDirty"]>,
  ) => {
    sidebarCurrentAction.isDirty = action.payload;
  },
};
