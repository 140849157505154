import { Grid, Typography } from "@mui/material";

import { Dayjs } from "~/common/packages/dayjs";
import { pxToRem } from "~/common/utils/pxToRem";

export const Dates = ({ startDate, endDate }: { startDate: Dayjs; endDate: Dayjs }) => (
  <Grid container alignItems="center" height="40px" pl={2}>
    <Typography sx={{ fontSize: pxToRem(15), fontWeight: 500 }}>
      {startDate.format("MMM YYYY")} - {endDate.format("MMM YYYY")}
    </Typography>
  </Grid>
);
