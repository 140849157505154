import { createSlice } from "@reduxjs/toolkit";

import { AssignAttributes, AssignAttributesActions } from "./assignAttributesActions";
import { EditShifts, EditShiftsActions } from "./editShiftsActions";
import { FindStaffToWork, FindStaffToWorkActions } from "./findStaffToWorkActions";
import { Floating, FloatingActions } from "./floatingActions";
import { PageData, PageDataActions, pageDataInitState } from "./pageDataActions";
import { PageFilters, PageFiltersActions, PageFiltersInitialValues } from "./pageFiltersActions";
import { SidebarActions, SidebarCurrentAction } from "./sidebarActions";
import { StaffingLevelActions, StaffingLevels } from "./staffingLevelActions";
import { UpdateStatus, UpdateStatusActions } from "./updateStatusActions";

export type THouseViewState = {
  pageData: PageData;
  pageFilters: PageFilters;
  staffingLevels: StaffingLevels;
  // side bar Actions
  sidebarCurrentAction: SidebarCurrentAction;
  floating: Floating;
  assignAttributes: AssignAttributes;
  findStaffToWork: FindStaffToWork;
  editShifts: EditShifts;
  updateStatus: UpdateStatus;
};

export const initialState: THouseViewState = {
  // High level state
  pageData: pageDataInitState,
  pageFilters: PageFiltersInitialValues,
  // Staffing levels
  staffingLevels: {
    modalIsOpen: false,
    /** @deprecated */
    editedStaffingTargets: {},
    /** source of truth */
    allStaffingTargets: {},
    selectedUnitId: undefined,
  },
  // sidebar Actions
  sidebarCurrentAction: {
    inProgress: null,
    isDirty: false,
  },
  floating: {},
  findStaffToWork: {},
  assignAttributes: {},
  editShifts: {},
  updateStatus: {},
};

const houseViewState = createSlice({
  name: "houseView",
  initialState,
  reducers: {
    resetState: () => initialState,
    ...PageFiltersActions,
    ...StaffingLevelActions,
    ...FloatingActions,
    ...AssignAttributesActions,
    ...FindStaffToWorkActions,
    ...EditShiftsActions,
    ...UpdateStatusActions,
    ...SidebarActions,
    ...PageDataActions,
  },
});

export const houseViewStore = {
  state: houseViewState.actions,
};

export default houseViewState.reducer;
