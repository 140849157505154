import { useEffect, useRef } from "react";

/**
 * A custom hook that triggers a service worker update check when the route changes.
 *
 * @param updateServiceWorker - A function that updates the service worker.
 * @returns void
 */
export const useRouteChangeServiceWorkerUpdate = (
  updateServiceWorker: (checkForUpdate: boolean) => Promise<void>,
) => {
  const location = window.location;
  const previousRouteSegmentRef = useRef<string>("");

  useEffect(() => {
    const getRouteSegment = (pathname: string) => {
      const cleanPath = pathname.startsWith("/") ? pathname.slice(1) : pathname;
      return cleanPath.split("/")[0] || "";
    };

    const currentRouteSegment = getRouteSegment(location.pathname);

    if (previousRouteSegmentRef.current === "") {
      previousRouteSegmentRef.current = currentRouteSegment;
      return;
    }

    if (currentRouteSegment !== previousRouteSegmentRef.current) {
      void updateServiceWorker(true);
      previousRouteSegmentRef.current = currentRouteSegment;
    }
  }, [location.pathname, updateServiceWorker]);
};
