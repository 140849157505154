import { memo } from "react";

import { StaffShiftUpdated } from "~/api/eventLog/events";

import { EventLog } from "@/api";
import { useCurrentUnitId } from "@/common/hooks";

import { IEventLogComponent } from "../..";
import { ShiftDetailsDifferences } from "../helpers";

export const StaffShiftUpdatedV1 = memo(
  ({
    event,
    indexedShiftTypes,
    usersById,
    unitIdByScheduleId,
  }: IEventLogComponent<
    EventLog.DTO<StaffShiftUpdated.PayloadByVersion[StaffShiftUpdated.EVersions.v1]>
  >) => {
    const author = usersById?.[event.authorId] || { firstName: "An M7 Admin", lastName: "" };

    const unitIdFromShift = unitIdByScheduleId?.[event.eventPayload.staffShift.scheduleId];
    const currentUnitId = useCurrentUnitId();
    const unitId = unitIdFromShift || currentUnitId;

    return (
      <>
        <b>
          {author.firstName} {author.lastName}
        </b>{" "}
        updated the shift:
        <ShiftDetailsDifferences
          indexedShiftTypes={indexedShiftTypes}
          unitIdByScheduleId={unitIdByScheduleId}
          userById={usersById}
          differences={event.eventPayload.updatedFields}
          origin={event.eventPayload.origin}
          unitId={unitId}
          staffShift={event.eventPayload.staffShift}
        />{" "}
      </>
    );
  },
);
