import { useMemo } from "react";

import { includes } from "lodash";

import { User } from "@/api";

import { useAppSelector } from "./useRedux";

const schedulerAppRoles = [User.ERole.scheduler, User.ERole.admin, User.ERole.kiosk];

export const useIsSchedulerApp = (): boolean => {
  const currentRole = useAppSelector((state) => state.user.currentRole);

  return useMemo(() => {
    return !!currentRole && includes(schedulerAppRoles, currentRole);
  }, [currentRole]);
};
