import Captions from "~/common/components/Captions";
import { ECaptionsVariant } from "~/common/components/Captions/types";

const data = [
  { variant: ECaptionsVariant.Bad, text: "Below schedule avg" },
  { variant: ECaptionsVariant.Equal, text: "Equal to schedule avg" },
  { variant: ECaptionsVariant.Excellent, text: "Above schedule avg" },
];

export const CaptionsForChargeShifts = () => <Captions data={data} />;
