import { buildApiListQueryHook } from "../_shared/helpers";

import { Facility } from "./types";

export const useGetFacilityWorkDaySummary = buildApiListQueryHook<
  Facility.API.GetWorkDaySummary.QueryParams,
  Facility.API.GetWorkDaySummary.Response
>(Facility.API.GetWorkDaySummary.PATH);

export const useGetFacility = buildApiListQueryHook<
  Facility.API.Get.QueryParams,
  Facility.API.Get.Response
>(Facility.API.Get.PATH);
