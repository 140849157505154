import { SxProps } from "@mui/material";

export enum ECaptionsVariant {
  Bad = "bad",
  Poor = "poor",
  Equal = "equal",
  Satisfactory = "satisfactory",
  Good = "good",
  Excellent = "excellent",
}

export interface ICaptionsItemProps {
  variant: ECaptionsVariant;
  text: string;
}
export interface ICaptionsProps {
  data: ICaptionsItemProps[];
  sx?: SxProps;
}
