import { AppBar, Toolbar } from "@mui/material";

import { white } from "~/common/theming/colors";
import { useAppConfigQuery } from "~/features/User/queries";
import { MixpanelProvider } from "~/modules/mixpanel/Provider";
import { Mxp } from "~/modules/mixpanel/types";

import { Top } from "./Top";

export const StaffHeader = () => {
  const { data: config } = useAppConfigQuery();

  const { units } = config || {};
  const [unit] = units || [];
  const { scheduleDurationInWeeks } = unit || {};

  if (!scheduleDurationInWeeks) return <></>;

  return (
    <MixpanelProvider properties={{ [Mxp.Property.layout.section]: "staff-headers" }}>
      <AppBar
        elevation={0}
        position="sticky"
        sx={{
          backgroundColor: white,
        }}
      >
        <Toolbar
          sx={{
            flexDirection: "column",
            paddingTop: "0.5rem",
            backgroundColor: white,
            minHeight: "initial",
          }}
        >
          <Top />
        </Toolbar>
      </AppBar>
    </MixpanelProvider>
  );
};
