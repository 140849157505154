import { PayloadAction } from "@reduxjs/toolkit";

import { IAttribute, IUnitShiftType, TargetLevel } from "@/api";
import { KeyBy } from "@/common/types";

import { THouseViewState } from ".";

export type TUnitTargetLevelState = {
  toUpdate: Record<TargetLevel.Unit.DTO["id"], TargetLevel.Unit.DTO>;
  toDelete: Record<TargetLevel.Unit.DTO["id"], TargetLevel.Unit.DTO>;
  toCreate: Record<TargetLevel.Unit.DTO["id"], TargetLevel.Unit.DTO>;
  recordsInDb: Record<TargetLevel.Unit.DTO["id"], TargetLevel.Unit.DTO>;
  unitShiftTypesByKey: Record<IUnitShiftType["key"], IUnitShiftType>;
  unitAttributesByKey: Record<IAttribute["key"], IAttribute>;
};

export const UnitTargetLevelActionsInitState: TUnitTargetLevelState = {
  toUpdate: {},
  toDelete: {},
  toCreate: {},
  recordsInDb: {},
  unitShiftTypesByKey: {},
  unitAttributesByKey: {},
};

export const UnitTargetLevelActions = {
  updateUnitTargetLevel: (
    { unitTargetLevels }: THouseViewState,
    action: PayloadAction<TargetLevel.Unit.DTO>,
  ) => {
    if (unitTargetLevels.toCreate[action.payload.id]) {
      unitTargetLevels.toCreate[action.payload.id] = action.payload;
    } else if (unitTargetLevels.toUpdate[action.payload.id]) {
      unitTargetLevels.toUpdate[action.payload.id] = action.payload;
    } else if (unitTargetLevels.toDelete[action.payload.id]) {
      unitTargetLevels.toDelete[action.payload.id] = action.payload;
    } else {
      unitTargetLevels.toUpdate[action.payload.id] = action.payload;
    }
  },
  deleteUnitTargetLevel: (
    { unitTargetLevels }: THouseViewState,
    action: PayloadAction<TargetLevel.Unit.DTO>,
  ) => {
    if (unitTargetLevels.toCreate[action.payload.id]) {
      delete unitTargetLevels.toCreate[action.payload.id];
      return;
    } else if (unitTargetLevels.toUpdate[action.payload.id]) {
      delete unitTargetLevels.toUpdate[action.payload.id];
    }
    unitTargetLevels.toDelete[action.payload.id] = action.payload;
  },
  restoreUnitTargetLevel: (
    { unitTargetLevels }: THouseViewState,
    action: PayloadAction<TargetLevel.Unit.DTO>,
  ) => {
    delete unitTargetLevels.toDelete[action.payload.id];
    delete unitTargetLevels.toUpdate[action.payload.id];
    delete unitTargetLevels.toCreate[action.payload.id];
  },
  duplicateUnitTargetLevel: (
    { unitTargetLevels }: THouseViewState,
    action: PayloadAction<TargetLevel.Unit.DTO>,
  ) => {
    const id = window.crypto.randomUUID();
    unitTargetLevels.toCreate[id] = { ...action.payload, id };
  },

  setDbUnitTargetLevels: (
    { unitTargetLevels }: THouseViewState,
    action: PayloadAction<KeyBy<TargetLevel.Unit.DTO, "id">>,
  ) => {
    unitTargetLevels.recordsInDb = action.payload;
  },

  setUnitShiftTypesByKey: (
    { unitTargetLevels }: THouseViewState,
    action: PayloadAction<KeyBy<IUnitShiftType, "key">>,
  ) => {
    unitTargetLevels.unitShiftTypesByKey = action.payload;
  },

  setUnitAttributesByKey: (
    { unitTargetLevels }: THouseViewState,
    action: PayloadAction<KeyBy<IAttribute, "key">>,
  ) => {
    unitTargetLevels.unitAttributesByKey = action.payload;
  },

  cancelAllUnitTargetLevelChanges: ({ unitTargetLevels }: THouseViewState) => {
    unitTargetLevels.toCreate = {};
    unitTargetLevels.toDelete = {};
    unitTargetLevels.toUpdate = {};
  },
};
