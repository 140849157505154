import styled from "styled-components";

import { TableContainer } from "@mui/material";

import { lightBlue, lightGray, lines } from "~/common/theming/colors";
import { css } from "~/common/utils/cl";

const style = css`
  /** TABLE ----*/
  table th {
    font-weight: 100;
  }

  /** BORDERS ----*/
  table th:not(.delete.cell, .info.cell) {
    /* For headers apply both top and bottom borders to the <th> */
    border-top: 0.5px solid ${lines};
    border-bottom: 0.5px solid ${lines};
    border-right: 0.5px solid ${lines};
  }

  table td:not(.delete.cell, .info.cell) {
    /* For cells, apply the border to one of each side only (right but not left, bottom but not top) */
    border-bottom: 0.5px solid ${lines};
    border-right: 0.5px solid ${lines};
  }

  table th:first-child,
  table td:first-child:not(.delete.cell, .info.cell) {
    /* Apply a left border on the first <td> or <th> in a row */
    border-left: 0.5px solid ${lines};
  }

  table .patientCount,
  table .time {
    width: 140px;
  }

  table tr.deleted {
    background-color: ${lightGray};
  }

  table tr.new-entry {
    background-color: ${lightBlue};
  }

  table.desktop-view {
    td.entry-note,
    th.reasonForStaffingDiscrepancy {
      width: 300px;
    }
    .delete.cell,
    .info.cell {
      width: 30px;
      svg,
      button {
        padding: 0;
      }
    }
  }
`;

export const TableStyleContainer = styled(TableContainer)`
  ${style}
`;
