import { useCallback, useState } from "react";

import { PreferencesTemplate, THexColor, Uuid } from "@m7-health/shared-utils";
import { isEqual } from "lodash";

import { Grid, TextField, Typography } from "@mui/material";

import { ColorSelector, CustomModal } from "@/common/components";
import CustomInput from "@/common/components/Input";
import { useAppSelector, useCurrentUnitId } from "@/common/hooks";
import { dayShift } from "@/common/theming";

import { TemplateChanger } from "./components";
import { useSaveTemplate } from "./hooks/useSaveTemplate";

const initialColor = dayShift as THexColor;

export const UpdatePreferencesTemplateModal = () => {
  const { template: templateFromState, isOpen } = useAppSelector(
    (state) => ({
      template: state.roster.updatePreferencesTemplateModal.preferencesTemplateToUpdate,
      isOpen: state.roster.updatePreferencesTemplateModal.isOpen,
    }),
    isEqual,
  );
  const unitId = useCurrentUnitId();
  const [templateLabel, setTemplateLabel] = useState(templateFromState?.label || "");
  const [templateName, setTemplateName] = useState(templateFromState?.name || "");
  const [templateDescription, setTemplateDescription] = useState(
    templateFromState?.description || "",
  );
  const [templateColor, setTemplateColor] = useState<THexColor>(
    templateFromState?.color || initialColor,
  );
  const [template, setTemplate] = useState<PreferencesTemplate.TTemplatedWeek[]>(
    templateFromState?.templatedPreferencesWeeks.map((week) => [...week]) || [
      [null, null, null, null, null, null, null],
    ],
  );

  const isEditing = !!templateFromState?.id;

  const { updateTemplate, createTemplate, closeTemplateModal, isSavePending } = useSaveTemplate();

  const templateCalendarIsValid = template.some((week) => week.some((day) => day !== null));

  const isSaveDisabled =
    isSavePending ||
    !unitId ||
    !templateLabel ||
    !templateName ||
    !templateColor ||
    !templateCalendarIsValid;

  const save = useCallback(() => {
    if (isSaveDisabled) return;

    if (isEditing && templateFromState) {
      updateTemplate({
        id: templateFromState.id,
        templatedPreferencesWeeks: template,
        label: templateLabel,
        name: templateName,
        description: templateDescription,
        color: templateColor,
      });
    } else {
      createTemplate({
        unitId: unitId as Uuid,
        name: templateName,
        description: templateDescription,
        color: templateColor,
        templatedPreferencesWeeks: template,
        label: templateLabel,
      });
    }
  }, [
    isEditing,
    templateFromState,
    template,
    templateLabel,
    updateTemplate,
    createTemplate,
    unitId,
    templateName,
    templateDescription,
    templateColor,
    isSaveDisabled,
  ]);

  const modalContent = (
    <>
      <Typography fontSize="0.875rem" sx={{ opacity: 0.6 }}>
        * Required information
      </Typography>
      <Grid
        container
        sx={{
          flexDirection: "column",
          alignItems: "center",
          maxHeight: "calc(100vh - 200px)",
          width: 700,
          overflow: "auto",
          flexWrap: "nowrap",
          pt: 2,
        }}
        gap={2}
      >
        <Grid
          container
          item
          sx={{
            width: "100%",
            flexDirection: "row",
            flexWrap: "nowrap",
            gap: 2,
          }}
        >
          <Grid container item>
            <CustomInput
              variant="standard"
              label={
                <>
                  <span className="title">Label*</span>
                  <span className="helper">(Max 8 characters)*</span>
                </>
              }
              sx={{
                ".MuiFormLabel-root .helper": { display: "none" },
                ".MuiFormLabel-root .title": { display: "initial" },
                ".MuiFormLabel-root.Mui-focused .helper": { display: "initial" },
                ".MuiFormLabel-root.Mui-focused .title": { display: "none" },
              }}
              name="Template Label"
              onChange={(event) => setTemplateLabel(event.target.value)}
              maxLength={8}
              field={{
                value: templateLabel,
                onChange: (newValue: string) => setTemplateLabel(newValue),
              }}
            />
          </Grid>
          <Grid container item>
            <CustomInput
              variant="standard"
              label="Name*"
              name="Template Name"
              onChange={(event) => setTemplateName(event.target.value)}
              field={{
                value: templateName,
                onChange: (newValue: string) => setTemplateName(newValue),
              }}
            />
          </Grid>
          <Grid container item>
            <ColorSelector
              selectedColor={templateColor}
              onChange={(event) => setTemplateColor(event.target.value as THexColor)}
              label="Color*"
              variant="standard"
              trackingLabel="Template Color Selector"
            />
          </Grid>
        </Grid>
        <Grid container item sx={{ width: "100%" }}>
          <TextField
            size="small"
            label="Description"
            variant="filled"
            sx={{ width: "100%", my: 2 }}
            multiline
            rows={3}
            onChange={(input) => setTemplateDescription(input.target.value)}
            placeholder="Describe the purpose and content of this template here ..."
            value={templateDescription}
          />
        </Grid>

        <TemplateChanger template={template} setTemplate={setTemplate} />
      </Grid>
    </>
  );

  return (
    <CustomModal
      trackingLabel="preferences-template-form"
      isOpen={isOpen}
      primaryBtnText="Save"
      modalContent={modalContent}
      modalHeaderText={`${isEditing ? "Edit" : "Create"} Template`}
      onSecondaryBtnClick={() => closeTemplateModal(true)}
      onSubmit={save}
      primaryDisabled={isSaveDisabled}
    />
  );
};
