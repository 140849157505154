import { captureException } from "@sentry/react";
import axios, { AxiosError, AxiosHeaderValue, AxiosInstance, isAxiosError } from "axios";
import { EventEmitter } from "eventemitter3";

import { localDayJs } from "~/common/packages/dayjs";
import { CustomError } from "~/common/types";

import { User } from "@/api";

export const UNIT_CONTEXT_HEADER_KEY = String("X-M7-Context-Unit-Id").toLowerCase();
export const FACILITY_CONTEXT_HEADER_KEY = String("X-M7-Context-Facility-Id").toLowerCase();
export const ROLE_CONTEXT_HEADER_KEY = String("X-M7-Context-Current-Role").toLowerCase();

const defaultTimeout = 20000;
class HttpClient {
  instance: AxiosInstance & {
    getM7ContextUnitId?: () => AxiosHeaderValue | undefined;
    getM7ContextFacilityId?: () => AxiosHeaderValue | undefined;
  };
  private tokenGenerator: any;

  eventEmitter = new EventEmitter();

  constructor() {
    const timeout =
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      parseInt(import.meta.env.VITE_HTTP_CLIENT_TIMEOUT) || defaultTimeout;

    this.instance = axios.create({
      baseURL: import.meta.env.VITE_BASE_URL,
      timeout,
      headers: {
        "Content-Type": "application/json",
      },
    });
    this.instance.getM7ContextUnitId = this.getUnitId.bind(this);
    this.instance.getM7ContextFacilityId = this.getFacilityId.bind(this);

    this.instance.interceptors.request.use(
      async (config: any) => {
        const token = await this.getToken();

        return {
          ...config,
          headers: {
            ...config.headers,
            Authorization: `Bearer ${token}`,
            "User-Timezone-Id": localDayJs.tz.guess(),
          },
        };
      },
      (error: Error) => {
        Promise.reject(error);
      },
    );

    this.instance.interceptors.response.use(
      (response) => {
        return response;
      },
      (error: any) => {
        if (isAxiosError(error)) {
          const {
            statusCode,
            message,
            error: errorKey,
          } = (error as AxiosError).response?.data as CustomError;

          const convertedError: CustomError = {
            statusCode,
            message: message || "Something went wrong",
            error: errorKey,
            type: "customError",
          };

          throw convertedError;
        }
        captureException(error);

        throw error;
      },
    );

    return this;
  }

  /**
   * Sets the tenant name in the default headers of the HTTP client instance for load balancer routing.
   *
   * @param {string | undefined} tenantName - The tenant name to set in the HTTP headers.
   */
  /** @deprecated("Use setTenantApiHost instead") **/
  setTenantName(tenantName: string | undefined) {
    if (tenantName) {
      this.instance.defaults.headers.common["X-M7-TENANT"] = tenantName;
    } else {
      delete this.instance.defaults.headers.common["X-M7-TENANT"];
    }
  }

  /**
   * Sets the base URL for the HTTP client instance. This overrides VITE_BASE_URL.
   *
   * @param {string | undefined} tenantApiHost - The tenant API host.
   */
  setTenantApiHost(tenantApiHost: string) {
    if (tenantApiHost) {
      this.instance.defaults.baseURL = `https://${tenantApiHost}`;
    }
  }

  setTokenRetriever(tokenGenerator: any) {
    const tokenChanged = this.tokenGenerator !== tokenGenerator;
    this.tokenGenerator = tokenGenerator;
    if (tokenChanged) this.eventEmitter.emit("tokenChanged", tokenGenerator);
    return this;
  }

  getUnitId(): string | null {
    return (this.instance.defaults.headers[UNIT_CONTEXT_HEADER_KEY] as string) || null;
  }

  setUnitId(unitId: string | undefined) {
    const unitIdChanged = this.instance.defaults.headers[UNIT_CONTEXT_HEADER_KEY] !== unitId;
    if (unitId) {
      this.instance.defaults.headers[UNIT_CONTEXT_HEADER_KEY] = unitId;
    }

    if (!unitId) {
      delete this.instance.defaults.headers[UNIT_CONTEXT_HEADER_KEY];
    }

    if (unitIdChanged) this.eventEmitter.emit("unitIdChanged", unitId);

    return this;
  }

  setFacilityId(facilityId: string | undefined) {
    const facilityIdChanged =
      this.instance.defaults.headers[FACILITY_CONTEXT_HEADER_KEY] !== facilityId;

    if (facilityId) {
      this.instance.defaults.headers[FACILITY_CONTEXT_HEADER_KEY] = facilityId;
    }

    if (!facilityId) {
      delete this.instance.defaults.headers[FACILITY_CONTEXT_HEADER_KEY];
    }

    if (facilityIdChanged) this.eventEmitter.emit("facilityIdChanged", facilityId);

    return this;
  }

  getFacilityId(): string | null {
    return (this.instance.defaults.headers[FACILITY_CONTEXT_HEADER_KEY] as string) || null;
  }

  setCurrentRole(currentRole: User.ERole | undefined) {
    const currentRoleChanged =
      this.instance.defaults.headers[ROLE_CONTEXT_HEADER_KEY] !== currentRole;

    if (currentRole) {
      this.instance.defaults.headers[ROLE_CONTEXT_HEADER_KEY] = currentRole;
    }

    if (!currentRole) {
      delete this.instance.defaults.headers[ROLE_CONTEXT_HEADER_KEY];
    }

    if (currentRoleChanged) this.eventEmitter.emit("currentRoleChanged", currentRole);

    return this;
  }

  getToken() {
    return this.tokenGenerator();
  }
}

const httpClient = new HttpClient();
const axiosInstance = httpClient.instance;

export { axiosInstance, httpClient };
