/* eslint-disable no-console */

import { useMemo } from "react";

import { YyyyMmDd } from "@m7-health/shared-utils";

import { IUnit, Schedule, StaffDetails } from "~/api";
import { Uuid } from "~/common/types";

import { THouseViewState } from "#/features/HouseView/store";

import { TableParts } from "..";
import { TGridActions } from "../hooks/useGridActions";
import { ITablesData } from "../types";

export const ScheduleTableStaffRows = ({
  gridActions,
  staffIdsOfFilteredCategory,
  staffDetailsByStaffId,
  datesRowsFormatted,
  schedules,
  tablesData,
  unit,
  shiftTypes,
}: {
  gridActions: TGridActions;
  staffIdsOfFilteredCategory: Uuid[];
  staffDetailsByStaffId: {
    [x: string]: StaffDetails.DTO;
  };
  datesRowsFormatted: YyyyMmDd[];
  schedules: Schedule.DTO[];
  tablesData: ITablesData;
  unit: IUnit;
  shiftTypes: THouseViewState["pageData"]["shiftTypes"];
}) => {
  const allSchedulesOfUnit = schedules.filter(
    ({ unitId: possibleUnitId }) => unit.id === possibleUnitId,
  );

  const staffIdsWithShifts = useMemo(() => {
    const staffIdsSet = new Set<Uuid>();

    allSchedulesOfUnit.forEach((schedule) => {
      const scheduleData = tablesData[schedule.id];
      if (scheduleData) {
        // values of scheduleData are tablesData for each day
        Object.values(scheduleData).forEach((dayData) => {
          // keys of shiftsByStaff are staffIds
          Object.keys(dayData.shiftsByStaff).forEach((staffId) => {
            staffIdsSet.add(staffId);
          });
        });
      }
    });

    return Array.from(staffIdsSet);
  }, [allSchedulesOfUnit, tablesData]);

  // Get the intersection while maintaining the order from staffIdsOfFilteredCategory
  const staffIdsWithShiftsAndFilteredCategory = staffIdsOfFilteredCategory.filter((staffId) =>
    staffIdsWithShifts.includes(staffId),
  );

  return (
    <>
      {staffIdsWithShiftsAndFilteredCategory.map((staffId) => {
        const staffDetails = staffDetailsByStaffId[staffId];
        return (
          <tr key={"row-" + staffId}>
            <th className="sticky-col">
              <TableParts.StaffNameHeaderCell staffDetails={staffDetails} unitId={unit.id} />
            </th>
            {datesRowsFormatted.map((formattedDate) => {
              const schedule = allSchedulesOfUnit.find(
                ({ startDay, endDay }) => startDay <= formattedDate && formattedDate <= endDay,
              );
              const shiftsByDayByRange = tablesData?.[schedule?.id || ""];
              const shiftInfo = shiftsByDayByRange?.[formattedDate]?.shiftsByStaff[staffId];

              return (
                <TableParts.StaffShiftCell
                  gridActions={gridActions}
                  key={`scheduler-grid-cell-${formattedDate}-${staffId}`}
                  staffId={staffId}
                  date={formattedDate}
                  scheduleId={schedule?.id || ""}
                  unitId={unit.id}
                  timezone={unit.timezone}
                  shiftInfo={shiftInfo}
                  shiftTypes={shiftTypes}
                />
              );
            })}
          </tr>
        );
      })}
    </>
  );
};
