import { Typography } from "@mui/material";

import FairnessBox from "~/common/components/Fairness";
import { NameCellStyled } from "~/features/Fairness/components/Cells/NameCell.styled";

export const NameCell = ({
  firstName,
  lastName,
  score,
}: {
  firstName: string;
  lastName: string;
  score: number;
}) => {
  return (
    <NameCellStyled>
      <FairnessBox fairness={score} variant="small" />
      <Typography variant="small">
        {lastName} {firstName}
      </Typography>
    </NameCellStyled>
  );
};
