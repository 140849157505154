export const formatPhoneNumber = (phoneNumber?: string) => {
  if (!phoneNumber) return null;

  // Remove all non-digit characters except +
  let cleaned = phoneNumber.replace(/[^\d+]/g, "");

  // Remove the +1 prefix if it exists
  if (cleaned.startsWith("+1")) {
    cleaned = cleaned.substring(2);
  }

  // Ensure the number has 10 digits after removing +1 prefix
  if (cleaned.length !== 10) {
    return phoneNumber; // Return the original if it doesn't have 10 digits
  }

  // Format the phone number
  return cleaned.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
};
