import { Tooltip } from "@mui/material";

import { FLOATING_ICON } from "~/common/constants";
import { black, darkGray, floatedStaffColor } from "~/common/theming/colors";
import { TSx, emptySx } from "~/common/types";
import { useAppConfigQuery } from "~/features/User/queries";
import { IUnitBasic } from "~/routes/api/types";

/**
 * Renders a compact floated tag component.
 *
 * @param unitId - The ID of the unit.
 * @param grayIfNotFloatedStatus - Determines if the tag should be grayed out when not in floated status.
 * Note: this is used for the new type of floated variation which will be gray if working away without floated status.
 * To be used in DailyReport, HouseView etc.
 * @param isFloatedStatus - Determines if the tag is in floated status.
 * Optional: should be passed if grayIfNotFloatedStatus is true.
 * @param sx - Additional styles for the component.
 * @returns The rendered compact floated tag component.
 */
export const CompactFloatedTag = ({
  unitId,
  grayIfNotFloatedStatus = false,
  isFloatedStatus,
  sx = emptySx,
}: {
  unitId: IUnitBasic["id"];
  grayIfNotFloatedStatus?: boolean;
  isFloatedStatus?: boolean;
  sx?: TSx;
}) => {
  const unitName = useAppConfigQuery().data?.accessibleUnits.find(
    (unit) => unit.id === unitId,
  )?.name;

  return grayIfNotFloatedStatus ? (
    <Tooltip
      placement="top"
      title={`Home unit: ${unitName || ""}`}
      enterTouchDelay={0}
      sx={{
        color: isFloatedStatus ? floatedStaffColor : darkGray,
        "&:hover": { color: black },
        ...sx,
      }}
    >
      <FLOATING_ICON className="m7-compact-floated-tag" fontSize="small" />
    </Tooltip>
  ) : (
    <Tooltip
      placement="top"
      title={`Home unit: ${unitName || ""}`}
      enterTouchDelay={0}
      sx={{ color: floatedStaffColor, "&:hover": { color: black }, ...sx }}
    >
      <FLOATING_ICON className="m7-compact-floated-tag" fontSize="small" />
    </Tooltip>
  );
};
